
/**
 * @author Iker Hurtado, Andrey Sobolev
 *
 * @overview Application level utility file
 */


import {Conf} from '../Conf.js'
// import * as THREE from "../../lib/three.js"
import * as mathlib from './math.js'
import Structure from './Structure.js'
import * as UserMsgBox from "./UserMsgBox";

// import * as math from '../../lib/math.js'


/**
 * Divides a text in lines and returns them in an array
 * @param  {string} text
 * @return {Array<string>}
 */
export function getTokenizedLines(text){
  // regex: remove several spaces and tabs in a row and split the text in lines
  return text.replace(/[ \t]+/g, ' ').split('\n')
}


/**
 * Loads a file from the application data folder and
 * passes the content in a handler
 * @param  {string} fileName
 * @param  {string} type Only 'text' type supported for now
 * @param  {function} handler
 */
export async function loadDataFile(fileName, type, handler) {
  let data
  try{
    let response = await fetch(Conf.BASE_FOLDER+'data/'+fileName)
    if (type === 'text') data = await response.text()
  }catch(err){
    console.log('loadDataFile error:', err)
  }
  handler(data)
}

/**
 * Generates a supercell of the given structure.
 * @param  {Array<string>} dimArray
 * @param  {Structure} sourceStruct
 * @return {Structure} Returns a new Structure representing the supercell
 */
export function generateSupercell(dimArray, sourceStruct){

  let supercellMatrix = [[0,0,0],[0,0,0],[0,0,0]]
  if (dimArray.length === 9){
    for (let i in dimArray){
      supercellMatrix[Math.floor(i/3)][i%3] = parseInt(dimArray[i])
    }
  }else if (dimArray.length === 3){
    for (let i in dimArray){
      supercellMatrix[i%3][i%3] = parseInt(dimArray[i])
    }
  }
  const lattice = sourceStruct.latVectors
  const superLattice = mathlib.matrixDot(supercellMatrix,lattice,[...lattice.map(_ => [0,0,0])])

  let fracPoints = latticePointsInSupercell(supercellMatrix)
  let newStruct = new Structure()

  newStruct.fileSource = sourceStruct.fileSource + " (supercell)"
  newStruct.updateLatticeVectors(superLattice, true)
  let points = mathlib.matrixDot(fracPoints, superLattice,[...fracPoints.map(_ => [0,0,0])])
  for (let p in points){
    sourceStruct.atoms.forEach( atom => {
      let newCart = mathlib.add(atom.position, points[p])
      let newFract = newStruct.getFractionalCoordinates(newCart).map(x => {
        if (x < 0.) return x + 1.
        else if (x >= 1.) return x - 1.
        else return x
      })
      let newPosition = newStruct.getCartesianCoordinates(newFract)
      newStruct.addAtomData(newPosition,
        atom.species,false, atom.initMoment, atom.constraint, atom.charge)
    })
  }
  return newStruct
}


/**
 * Returns all lattice points of the old unit cell within the new supercell.
 * Basically re-written in pymatgen
 * @param  {number[][]} supercellMatrix
 * @return {number[][]}
 */
function latticePointsInSupercell(supercellMatrix){
    //
    //
    let diags = [[0,0,0],[0,0,1],[0,1,0],[1,0,0],[0,1,1],[1,0,1],[1,1,0],[1,1,1]]
    let dPoints = [...diags.map(_ => [0,0,0])] // Initialize with same size as diags

    mathlib.matrixDot(diags, supercellMatrix, dPoints)

    let mins = mathlib.minmax(dPoints, 0, Math.min)
    let maxs = mathlib.minmax(dPoints, 0, Math.max).map(x => x + 1)
    let r = [[],[],[]]
    for (let i=0;i<3;i++){
        for (let j=mins[i];j<maxs[i];j++)
            r[i].push([j])
    }

    let ar = mathlib.tensorDot(r[0],[[1,0,0]])
    let br = mathlib.tensorDot(r[1],[[0,1,0]])
    let cr = mathlib.tensorDot(r[2],[[0,0,1]])

    let all_points = []
    for (let i in ar){
        for (let j in br){
            for (let k in cr){
                all_points.push(mathlib.addArrays(ar[i],mathlib.addArrays(br[j],cr[k])))
            }
        }
    }

    let fracPoints = mathlib.matrixDot(all_points,mathlib.invert33(supercellMatrix),[...all_points.map(_ => [0,0,0])])
    let result = []
    for (let i in fracPoints){
        if (fracPoints[i].every(x => x<1-1e-10 && x>-1e-10)){
            result.push(fracPoints[i])
        }
    }
    console.assert(result.length === mathlib.determinant33(supercellMatrix),
      'We are missing some lattice points. Check precision of supercell matrix')
    return result
}


/**
 * Generates a slab on the backend
 * @param {Object} slabData
 * @param {Structure} structure
 * @returns {Structure} A slab structure 
*/
export async function generateSlab(slabData, structure) {
  if (!structure.atoms.length) return
  let sendStructure = {
    cell: structure.latVectors,
    positions: structure.atoms,
    slab_data: slabData,
    fileName: structure.fileSource
  }
  const endPoint = ('terminations' in slabData) ? '/terminate-slab' : '/get-slab'
  let response = await fetch(endPoint, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=utf-8' },
    body: JSON.stringify(sendStructure)
  })

  if (!response.ok){
    UserMsgBox.setError('Unknown server ERROR ')
    return
  }

  const text = await response.text()
  const slab_json = JSON.parse(text)
  let slab_structure = getStructureFromJSON(slab_json)
  // add terminations data to the structure
  slab_structure.extraData['terminations'] = slab_json.terminations
  return slab_structure
}

// /**
//  * Generates k-mesh data on the backend
//  * @param kMeshData {Object} A map with k-mesh data embedded
//  * @returns {Promise} A 2D array of k-mesh points
// */
// export async function getKMesh(kMeshData) {
//   let response = await fetch('/get-k-mesh', {
//     method: 'POST',
//     headers: {'Content-Type': 'application/json;charset=utf-8'},
//     body: JSON.stringify(kMeshData)
//   })
//
//   if (!response.ok) {
//     UserMsgBox.setError('Unknown server ERROR ')
//     return
//   }
//   const text = await response.text()
//   const data = JSON.parse(text)
//   return data['mesh']
// }

/**
 * Gets structure information from the backend
 * @param structure {Structure} a structure to get info on
 * @returns {Promise} a JSON with structure information
 */
export async function getStructureInfo(structure) {
  let structureData = undefined
  if (structure.atoms.length) {
    let sendStructure = {
      cell: structure.latVectors,
      positions: structure.atoms,
      symThresh: Conf.settings.symmetryThreshold
    }

    let response = await fetch('/update-structure-info', {
      method: 'POST',
      headers: {'Content-Type': 'application/json;charset=utf-8'},
      body: JSON.stringify(sendStructure)
    })
    if (!response.ok) {
      UserMsgBox.setError('Unknown server ERROR ')
      throw new Error(`HTTP error: ${response.status}`);
    }
    structureData = await response.json()
  }
  return structureData ? structureData.structureInfo : undefined
}
// ***************************
// File parsing helpers
// ***************************


/**
 * Returns a Structure from a json
 * @param  {object} json
 * @return {Structure}
 */
export function getStructureFromJSON(json){
  const structure = new Structure()
  // console.log('getStructureFromJSON', json)
  structure.fileSource = json.fileName
  structure.structureInfo = json.structureInfo
  // console.log('structureInfo', json.structureInfo)
  structure.latVectors = (json.lattice === null ? undefined : json.lattice)
  json.atoms.forEach( atom => {
    structure.addAtomData(atom[0], atom[1], false, atom[3], atom[4], atom[5]) // cartesian coordinates
  })
  return structure
}


/**
 * Returns a Structure from text content in a file (FHIamis geometry file)
 * @param  {string} fileName
 * @param  {string} fileContent
 * @return {Structure}
 */
export function getStructureFromFileContent(fileName, fileContent){
  let fileExt = fileName.substring(fileName.lastIndexOf('.')+1);
  let structure;
  if (fileExt === 'in')
    structure =  parseGeometryInFileFormat(fileContent);
  // else if (fileExt === 'cif')   structure = parseCIFFileFormat(fileContent);
  structure.fileSource = fileName;
  return structure;
}


/**
 * Returns a Structure from text content in a file (FHIamis geometry file)
 * @param  {string} text
 * @return {Structure}
 */
export function parseGeometryInFileFormat(text){

  const VECTOR_KEYWORD = 'lattice_vector';
  const ATOM_CART_KEYWORD = 'atom';
  const ATOM_FRAC_KEYWORD = 'atom_frac';
  const ATOM_INIT_MOMENT_KEYWORD = 'initial_moment';
  const ATOM_INIT_CHARGE_KEYWORD = 'initial_charge';

  let lines = getTokenizedLines(text)

  let structure = new Structure()
  // If periodic system
  if ((text.indexOf(VECTOR_KEYWORD) >= 0)) structure.latVectors = []
  else structure.latVectors = undefined

  lines.forEach( line => {
    let tokens = line.trim().split(' ')

    if (tokens[0] === VECTOR_KEYWORD){
      structure.latVectors.push([parseFloat(tokens[1]), parseFloat(tokens[2]), parseFloat(tokens[3])])

    }else if (tokens[0] === ATOM_CART_KEYWORD || tokens[0] === ATOM_FRAC_KEYWORD){
      let atomPos = [parseFloat(tokens[1]), parseFloat(tokens[2]), parseFloat(tokens[3])]
      structure.addAtomData(atomPos, tokens[4], tokens[0] === ATOM_FRAC_KEYWORD)

    }else if (tokens[0] === ATOM_INIT_MOMENT_KEYWORD)
      structure.setLastAtomInitMoment(parseFloat(tokens[1]))
    else if (tokens[0] === ATOM_INIT_CHARGE_KEYWORD)
      structure.setLastAtomCharge(parseFloat(tokens[1]))
  })
    return structure
}


/**
 * Generates and returns texts in the FHIaims geometry file format from a Structure.
 * It returns two texts: both in cartesian and in fractional coordinates
 * @param  {Structure} structure
 * @return {Array<string>} Two dimension array
 *  [cartesian-coordinates, fractional-coordinates]
 */
function getGeometryInTexts(structure){
  //if (structure.latVectors === undefined) return [undefined, undefined]
  let text = '', textFrac= ''
  let decimalDigits = Conf.settings.decimalDigits

  if (structure.isAPeriodicSystem()) {
    structure.latVectors.forEach( v => {
      text += 'lattice_vector '+v[0].toFixed(decimalDigits)+' '+v[1].toFixed(decimalDigits)+' '+v[2].toFixed(decimalDigits)+'\n'
    })
    text += '\n'
  }

  textFrac += text
  structure.atoms.forEach( a => {
    if (a !== undefined) {
      //const coors = (isFract ? a.fractPosition : a.position)
      text += 'atom '+a.position[0].toFixed(decimalDigits)+' '+a.position[1].toFixed(decimalDigits)+' '+a.position[2].toFixed(decimalDigits)+' '+a.species+'\n'
      text += getRelaxationAndInitMomentText(a)
      if (structure.isAPeriodicSystem()) {
        const fractPosition = structure.getFractionalCoordinates(a.position)
        textFrac += 'atom_frac '+fractPosition[0].toFixed(decimalDigits)+' '+fractPosition[1].toFixed(decimalDigits)+' '+fractPosition[2].toFixed(decimalDigits)+' '+a.species+'\n'
        textFrac += getRelaxationAndInitMomentText(a)
      }
    }
  })
  //console.log('getGeometryInTextFile', g, text)
  return [text, (structure.isAPeriodicSystem() ? textFrac : undefined) ]

  function getRelaxationAndInitMomentText(atom){
    let text = ''
    if (atom.constraint) text += '    constrain_relaxation .true. \n'
    if (atom.initMoment !== undefined && parseFloat(atom.initMoment) !== 0.0000)
      text += '    initial_moment '+atom.initMoment+'\n'
    if (atom.charge !== undefined && parseFloat(atom.charge) !== 0.0000)
      text += '    initial_charge '+atom.charge+'\n'
    return text
  }
}


/**
 * Generates and returns texts in the Exciting input file format from a Structure.
 * It returns two texts: both in cartesian and in fractional coordinates
 * @param  {Structure} structure
 * @return {Array<string>} Two dimension array
 *  [cartesian-coordinates, fractional-coordinates]
 */
function getInputXmlTexts(structure){
  //if (structure.latVectors === undefined) return [undefined, undefined]
  let text = '', textFrac= ''
  let decimalDigits = Conf.settings.decimalDigits
  if (structure.isAPeriodicSystem()) {
    text += '<crystal>\n'
    structure.latVectors.forEach( v => {
      text += '<basevect>'+v[0].toFixed(decimalDigits)+' '+v[1].toFixed(decimalDigits)+' '+v[2].toFixed(decimalDigits)+'</basevect>\n'
    })
    text += '</crystal>\n\n'
  }

  textFrac += text
  const speciesAtomsMap = new Map()
  structure.atoms.forEach( a => {
    if (a !== undefined) {
      if (!speciesAtomsMap.has(a.species)) speciesAtomsMap.set(a.species, [])
      let atoms = speciesAtomsMap.get(a.species)
      atoms.push(a)
    }
  })
//  log('speciesAtomsMap', speciesAtomsMap)
  speciesAtomsMap.forEach(  (atoms, species) => {
    //log('speciesAtomsMap', species, atoms)
    text +=  '<species speciesfile="'+species+'.xml">\n' // textFrac +=
    textFrac +=  '<species speciesfile="'+species+'.xml">\n'
    atoms.forEach( a => {
      text += '<atom coord="'+a.position[0].toFixed(decimalDigits)+' '+a.position[1].toFixed(decimalDigits)+' '+a.position[2].toFixed(decimalDigits)+'" />\n'
      if (structure.isAPeriodicSystem()) {
        const fractPosition = structure.getFractionalCoordinates(a.position)
        textFrac += '<atom coord="'+fractPosition[0].toFixed(decimalDigits)+' '+fractPosition[1].toFixed(decimalDigits)+' '+fractPosition[2].toFixed(decimalDigits)+'" />\n'
      }
    })
    //***  the constrain relaxation and initial moment atom values are not included yet
    text += '<species/>\n\n'; textFrac += '<species/>\n\n'
  })
// log('text ', text, textFrac)
  return [getXmlWrapper(text), (structure.isAPeriodicSystem() ? getXmlWrapper(textFrac, false) : undefined) ]

  function getXmlWrapper(structureElement, cartesian = true, title = 'Structure generated with elGUI'){
    return `<input>\n\n<title>${title}</title>\n\n`+
    `<structure speciespath="./" cartesian="${cartesian ? 'true' : 'false'}">\n\n`+
    `${structureElement}</structure>\n\n<groundstate></groundstate>\n\n</input>`
      //'<groundstate rgkmax="7.0d0" ngridk="4 4 4" xctype="GGA_PBE_SOL"></groundstate>\n\n</input>' // Default values
  }
}


/**
 * Returns a URL object containing an input text file (with geometry)
 * either for FHIaims or Exciting codes.
 * It returns two objects: both in cartesian and in fractional coordinates
 * @param  {Structure} structure
 * @param  {boolean} fhiAims FHIaims or Exciting code
 * @return {Array<string>} Two dimension array
 *  [cartesian-coordinates, fractional-coordinates]
 */
export function getInputTextFilesURL(structure, fhiAims = true){

  const [inputText, inputTextFrac] =
    (fhiAims ? getGeometryInTexts(structure) : getInputXmlTexts(structure))
  return [
    window.URL.createObjectURL(new Blob([inputText], {type: 'text/plain'} )),
    (inputTextFrac === undefined ? undefined : window.URL.createObjectURL(new Blob([inputTextFrac], {type: 'text/plain'} )))
  ]
}


/**
 * Generates and returns geometry file texts from a Structure.
 * In either FHIaims or Exciting formats
 * It returns two texts: both in cartesian and in fractional coordinates
 * @param  {Structure} structure
 * @param  {boolean} fhiAims FHIaims or Exciting code format
 * @return {Array<string>} Two dimension array
 *  [cartesian-coordinates, fractional-coordinates]
 */
export function getGeometryFiles_testing(structure, fhiAims = true){
  return  (fhiAims ? getGeometryInTexts(structure) : getInputXmlTexts(structure))
}

// ***************************
// Miscellaneous
// ***************************

/**
 * A function to deep clone a JS object, credits to https://stackoverflow.com/a/57340254/1027367
 * @param obj {*|*[]|{}} original object (no null values and `length` keys)
 * @returns {*|*[]|{}} cloned object
 */
export function deepClone(obj) {
  if (typeof obj !== "object") {
    return obj;
  } else {
    let newObj =
      typeof obj === "object" && obj.length !== undefined ? [] : {};
    for (let key in obj) {
      if (key) {
        newObj[key] = deepClone(obj[key]);
      }
    }
    return newObj;
  }
}

// validator functions
export function intValidator(e) {
  const value = parseInt(e.target.value)
  if (isNaN(value)) {
    e.target.value = ''
  } else {
    e.target.value = value
  }
}

export function intInputValidator(e) {
  const value = parseInt(e.target.value)
  if (["0", "-"].includes(e.target.value.slice(-1))) {
    // wait for next char
  } else if (isNaN(value)) {
    e.target.value = ''
  } else {
    e.target.value = value
  }
}

export function floatValidator(e) {
  const value = parseFloat(e.target.value)
  if (isNaN(value)) {
    e.target.value = ''
  } else {
    e.target.value = value
  }
}

export function floatInputValidator(e) {
  const value = parseFloat(e.target.value)
  if ([".", "0", "-"].includes(e.target.value.slice(-1))) {
    // wait for next char
  } else if (isNaN(value)) {
    e.target.value = ''
  } else {
    e.target.value = value
  }
}