/**
 * @author Iker Hurtado
 *
 * @overview File holding the Structure class
 */

import * as mathlib from './math.js'
import {getStructureInfo} from "./util";
import {Conf} from "../Conf";


/**
 * A class modeling an atomic structure, either a periodic or non-periodic system
 * It's usually gotten from a file
 */
export default class Structure {

  constructor() {
    this.latVectors = undefined  // Array of arrays (3x3)
    this.atoms = []
    this.fileSource = ""
    this.structureInfo = {}
    this.extraData = {}
    this.id = undefined
  }


  /**
   * Reset the structure
   */
  reset(){
    this.latVectors = undefined
    this.atoms = []
    this.fileSource = undefined
    this.structureInfo = undefined
    this.extraData = undefined
  }


  /**
   * Returns if it's a periodic system or not
   * @return {boolean}
   */
  isAPeriodicSystem(){
    //console.log('isAPeriodicSystem',this.latVectors !== undefined, this.latVectors)
    return this.latVectors !== undefined
  }

  /**
   * Removes the lattice vectors becoming a non-periodic system
   */
  removeLatticeVectors(){
    this.latVectors = undefined
  }

  /**
   * Updates the lattice vectors and scales the atoms positions if specified
   * @param {number[][]} vectors
   * @param {boolean} scaleAtomsPosition This indicates if the atoms positions must be scaled
   */
  updateLatticeVectors(vectors, scaleAtomsPosition){ // Set or transform a periodic system
    const fractPositions = []
    if (scaleAtomsPosition)
      this.atoms.forEach( atom => {
        fractPositions.push(this.getFractionalCoordinates(atom.position))
      })

    this.latVectors = vectors

    if (scaleAtomsPosition)
      for (let i = 0; i < this.atoms.length; i++) {
        this.atoms[i].position = this.getCartesianCoordinates(fractPositions[i])
      }
  }


  /**
   * Creates a new atom object (it's not added to the structure)
   * @param {number[]} cartCoords Atom cartesian coordinates
   * @param {string} species
   * @param {number} moment Atom initial moment
   * @param {boolean} constraint
   * @param {number} charge
   * @return {object}
   */
  createAtom(cartCoords, species, moment, constraint, charge){
      return {
        'position': cartCoords,
        'species': species,
        'initMoment': moment,
        'constraint': constraint,
        'charge': charge,
        'radius': Conf.getSpeciesRadius(species)

        /**** Nicer solution
        'fractPosition': undefined // For non periodic

        'fractPosition': function(){
          const fractCoors = math.lusolve(math.transpose(latVectors), cartCoords)
          return math.transpose(fractCoors)[0]
        }*/
      }
  }

  /**
   * Creates and add a new atom object
   * @param {number[]} pos Atom cartesian coordinates
   * @param {string} species
   * @param index {number}
   * @param {boolean} isFractional It indicates if the position coordinates are fractional
   * @param {int} moment Atom initial moment
   * @param {boolean} constraint
   * @param charge {number}
   */
  addAtomData(pos, species, isFractional = false, moment = 0,
              constraint = false, charge = 0, index = undefined){
    let cCoors = (isFractional ? this.getCartesianCoordinates(pos) : pos)
    let atom = this.createAtom(cCoors, species, moment, constraint, charge)
    if (index === undefined) this.atoms.push(atom)
    else this.atoms.splice(index, 0, atom)
    //console.log('Structure.addAtomData', this.atoms)
  }


  /**
   * Creates an undefined atom.
   * It returns the new atom position in the structure
   * @return {int}
   */
  addUndefinedAtom(){
    this.addAtomData([0, 0, 0], undefined)
    return this.atoms.length-1
  }


  /**
   * Updates a structure atom species
   * @param {int} atomIndex Index of the atom  to be updated
   * @param {string} species
   */
  updateAtomSpecies(atomIndex, species){
    this.atoms[atomIndex].species = species
  }


  /**
   * Updates a structure atom initialization moment
   * @param {int} atomIndex Index of the atom  to be updated
   * @param {int} initMoment
   */
  updateAtomInitMoment(atomIndex, initMoment){
    this.atoms[atomIndex].initMoment = initMoment
  }


  /**
   * Updates a structure atom constrain
   * @param {int} atomIndex Index of the atom  to be updated
   * @param {int} constraint
   */
  updateAtomConstraint(atomIndex, constraint){
    this.atoms[atomIndex].constraint = constraint
  }

  updateAtomCharge(atomIndex, charge){
    this.atoms[atomIndex].charge = charge
  }

  /**
   * Updates a structure atom position
   * @param {int} atomIndex Index of the atom  to be updated
   * @param {number[]} values
   * @param {boolean} isFract If the values are fractional
   */
  updateAtomPosition(atomIndex, values, isFract = false){
    this.updateAtomCoorValue(atomIndex, 0, values[0], isFract)
    this.updateAtomCoorValue(atomIndex, 1, values[1], isFract)
    this.updateAtomCoorValue(atomIndex, 2, values[2], isFract)
  }


  /**
   * Updates a coordinate of a structure atom
   * If the
   * @param {int} atomIndex Index of the atom to be updated
   * @param {int} coorIndex
   * @param {number} value
   * @param {boolean} isFract If the value is given in fractional
   */
  updateAtomCoorValue(atomIndex, coorIndex, value, isFract = false){
    let atom = this.atoms[atomIndex]
    if (atom === undefined){
      atom = this.createAtom([0, 0, 0], undefined, 0, false, 0)
      this.atoms[atomIndex] = atom
    }
    if (isFract){
      // console.log('POSSIBLE ERROR HERE')
      atom.position[coorIndex] = this.getCartesianCoordinates(value)[coorIndex]
    }else
      atom.position[coorIndex] = value
  }

  /**
   * Updates information on this structure
   */
  updateInfo() {
    return getStructureInfo(this).then((info) => this.structureInfo = info)
  }

  /**
   * Sets the initialization moment to the last atom
   * @param {number} moment
   */
  setLastAtomInitMoment(moment){
    //console.log(this.atomPositions.length-1, moment)
    this.atoms[this.atoms.length-1].initMoment = moment;
  }
  
  /**
   * Sets the initialization charge to the last atom
   * @param {number} charge
   */
  setLastAtomCharge(charge){
    //console.log(this.atomPositions.length-1, moment)
    this.atoms[this.atoms.length-1].charge = charge;
  }

  /**
   * Returns the cartesian coordinates corresponding to the
   * fractional coordinates passed as the argument.
   * It behaves as a static method
   * @param {number[]} fractCoors
   * @return {number[]}
   */
  getCartesianCoordinates(fractCoors){
    let cartPos = mathlib.multiplyScalar(this.latVectors[0], fractCoors[0])
    cartPos = mathlib.addArrays( cartPos, mathlib.multiplyScalar(this.latVectors[1], fractCoors[1]) ) // absPos.add(basisVectors[1].clone().multiplyScalar(position.y));
    cartPos = mathlib.addArrays( cartPos, mathlib.multiplyScalar(this.latVectors[2], fractCoors[2]) ) // absPos.add(basisVectors[2].clone().multiplyScalar(position.z));
    //console.log('getCartesianCoordinates', cartPos)
    return cartPos;
  }

  /**
   * Finds if the structure is magnetic, that is, if any of initial moments has been set
   * @returns {boolean} Check result
   */
  isMagnetic(){
    for (const atom of this.atoms)
      if (atom.initMoment !== 0) return true;
    return false;
  }

  /**
   * Returns a structure atom fractional coordinates
   * @return {int} i Atom index
   * @return {number[]}
   */
  getAtomFractPosition(i){
    return this.getFractionalCoordinates(this.atoms[i].position)
  }

  /**
   * Returns the fractional coordinates corresponding to the
   * cartesian coordinates passed as the argument.
   * It behaves as a static method
   * @param {number[]} cartCoors
   * @return {number[]}
   */
  getFractionalCoordinates(cartCoors){
    // transform the column format to a regular array
    return this.isAPeriodicSystem() ? mathlib.solve33(this.latVectors, cartCoors) : undefined
  }

}
