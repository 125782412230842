/**
 * @author Iker Hurtado
 *
 * @fileoverview File holding the main StructureViewer class
 */


// import * as THREE from '../../lib/three.js'
import * as THREE from 'three'
import ThreeViewer from './ThreeViewer.js'
import * as util from './util.js'
import {Conf} from '../Conf.js'
import {CHANGE} from '../State.js'
import * as mathlib from '../common/math.js'
import InfoCanvas from './InfoCanvas.js'
import AtomSelector from './AtomSelector.js'
import ViewOptionsDropDown from './ViewOptionsDropDown.js'
import Switch from '../common/Switch.js'
import UIComponent from '../common/UIComponent.js'
import ScreenshotTaker from '../common/ScreenshotTaker.js'
// import * as math from '../../lib/math.js'
import undoIcon from '../../img/undo-icon.png'
import redoIcon from '../../img/redo-icon.png'
import playIcon from '../../img/play-icon.png'
import pauseIcon from '../../img/pause-icon.png'
import {arrayToColors} from "../output-analyzer-mod/util";

const CELL_BASIS_COLORS = ["#C52929", "#47A823", "#3B5796"];
const BOND_FACTOR = 1.2 // this factor multiplies the theoretical atoms radius addition
const DUPLICATE_ATOMS_DEFAULT = false
const nSegments = 10


/**
 * Structure viewer main class
 */
export default class StructureViewer{

	/**
     * @param  {HTMLElement}  hostElement HTML element hosting the canvas
     * @param  {boolean} editable StructureViewer mode
     *   editable/advantage (false) or simple (true)
     * @param  {State | undefined} state
     *   Object representing the state of the Structure builder module
     */
	constructor(hostElement, editable = false, state) {
		// we need to know the coordinate mode:
		// cartesian or fractional in order to represent them in numbers
		this.editable = editable
		this.fractionalMode = false

		this.hostElement = hostElement
		this.threeViewer = new ThreeViewer(hostElement)
		this.threeViewer.init()

		this.infoCanvas = new InfoCanvas(hostElement, !editable, state)
		window.addEventListener('scroll', _ => {
            this.infoCanvas.adaptToScroll(window.scrollY)
        }, false
		)
		if (this.editable) {
			this.atomSelector = new AtomSelector(this.threeViewer, this.infoCanvas, state)

			const wrapperBox = new UIComponent('div', '.structure-edit-controls-box')

			this.atomDisplSwitch = new Switch('Edit structure')
			wrapperBox.e.appendChild(this.atomDisplSwitch.e)
			this.atomDisplSwitch.setListener( on => {
				if (this.threeViewer.params.orthographicCamera) {
					this.atomSelector.enableAtomDisplacement(on)
					return true
				} else {
					return false
				}
					
			})

			if (state){
				this.undoRedoComp = new UndoRedoComponent(state)
				wrapperBox.e.appendChild(this.undoRedoComp.e)
			}
			this.hostElement.appendChild(wrapperBox.e)
		}

		this.createViewingControls()
		this.screenTaker = new ScreenshotTaker('structure')
		this.screenTaker.e.style = 'margin-top: 3px; padding-left: 30px'
		this.screenTaker.setScreenshotListener( linkElement => {
		   this.threeViewer.render()
		   linkElement.href = this.threeViewer.renderer.domElement.toDataURL()
		   // this.threeViewer.render()
		})
    this.viewerControls.appendChild(this.screenTaker.e)

		if (this.editable){


	        // const shortcutsBox = new UIComponent('div', '.shortcuts-box')
	        // shortcutsBox.setHTML(`
	        // 	<table>
	        // 		<tr> <td>Undo/Redo</td> <td>Ctrl+Z / Ctrl+Y</td> </tr>
					//
	        // 		<tr> <td class="shortcuts-title" colspan="2"><b>Structure modification</b> (<i>Edit structure</i> button ON)</td> </tr>
	        // 		<tr> <td>Move Atom</td> <td>Atom dragging (mouse)</td> </tr>
	        // 		<tr> <td>Delete Atom</td> <td>D + Click on atom</td> </tr>
					//
	        // 		<tr> <td class="shortcuts-title" colspan="2"><b>Atoms selection and measurements</b></td> </tr>
	        // 		<tr> <td>Atom Distance</td> <td>Shift + Click on two atoms</td> </tr>
	        // 		<tr> <td>Atom Angle</td> <td>Shift + Click on three atoms</td> </tr>
	        // 		<tr> <td>Torsion Angle </td> <td>Shift + Click on four atoms</td> </tr>
	        // 	</table>
	        // `)
	        // this.hostElement.appendChild(shortcutsBox.e)
        }

	}


	/**
	 * Resets the viewer
	 */
	reset(){
		this.threeViewer.clearScenes()
		// Clear canvas context
		this.infoCanvas.clear()
		this.threeViewer.render()
	}


	/**
	 * Updates the component to a fractional change in the module
	 * Listener for the 'update fractional' module level event
	 * @param {boolean} fract
	 */
	updateFractional(fract){
		this.fractionalMode = fract
  	}


  	/**
	 * Updates the component to a atom species change
	 * Listener for the 'change species' module level event
	 * @param {string} species
	 * @param {string} color Color code
	 */
  	changeSpeciesColor(species, color){
  		const atoms = this.structure.atoms
  		for (let i = 0; i < atoms.length; i++)
  			if (atoms[i].species === species) {
  				this.atoms.children[i].material.color.set(Conf.getSpeciesColor(species))
  			}
				if (this.duplicateAtoms) this.recalculateDuplicateAtoms(atoms)
  	}

  	/**
	 * Updates the component to an atom change
	 * @param {number[]} values Numeric values according to which the coloring takes place
	 */
		changeAtomsColors(values= undefined) {
			const atoms = this.structure.atoms
			const bySpecies = (values === undefined)
			const colorStrings = bySpecies ? atoms.map(atom => Conf.getSpeciesColor(atom.species)) : arrayToColors(values, true)
			if (bySpecies)
				this.infoCanvas.legend.setAtomsData(atoms)
			else
				this.infoCanvas.legend.setColorBar(values, true)

			for (let i = 0; i < atoms.length; i++) {
				this.atoms.children[i].material.color.set(colorStrings[i])
			}
		}


  	/**
	 * Updates the component to a new structure
	 * Listener for the 'new structure' module level event
	 * @param {Structure} structure
	 * @param {boolean} reset
	 */
  	setNewStructure(structure,reset=true){
		// console.log('this.threeViewer.camera.position',this.threeViewer.camera.position)
		if (this.animationTimer !== undefined) clearInterval(this.animationTimer)
		this.threeViewer.clearScenes()
		// Clear canvas context
		this.infoCanvas.clear()
		if (this.animationControl !== undefined) {
			this.hostElement.removeChild(this.animationControl.e)
			this.animationControl = undefined
		}
		this.setupVisualization(structure,reset)

		this.threeViewer.render()
	}

	selectStructure(structure){
		// Don't use setNewStructure. Future Basti: Do it manually and save the scene settings.
		this.setNewStructure(structure,false)
		this.undoRedoComp.update()
	}


	/**
	 * Updates the component to a structure change
	 * Listener for the 'update structure' module level event
	 * @param {Structure} structure
	 * @param {object} change
	 */
	updateStructure(structure, change){
		// console.log('StructureViewer updateStructure', change, structure)
		if (change === undefined || change.type === CHANGE.STRUCTURE.LATTICE_VECTORS){
			// General case
			this.threeViewer.clearScenes()
			// Clear canvas context
			this.infoCanvas.clear()

			this.setupVisualization(structure, false)

		}else{ // Atoms update
			const atoms = structure.atoms
			let atom = atoms[change.atomIndex]
			let atomMesh = this.atoms.children[change.atomIndex]
			//console.log('updateStructure', change)
			if (change.type === CHANGE.STRUCTURE.ATOM.SPECIES){ // Atom change (movement or species change)
				let {radius, color} = Conf.getRadiusAndColor(atom.species)
				atomMesh.material.color = new THREE.Color(color)
				atomMesh.geometry = new THREE.SphereGeometry(radius, 2*nSegments, nSegments)

            }else if (change.type === CHANGE.STRUCTURE.ATOM.INITMOMENT) {
                // console.log('do sth with initMoment',atom.initMoment)

            }else if (change.type === CHANGE.STRUCTURE.ATOM.CONSTRAINT) {
                let atomMeshAdd = this.getMesh(atom.species, false, atom.constraint)
                atomMeshAdd.position.fromArray(atom.position)
                this.atoms.children.splice(change.atomIndex, 1, atomMeshAdd)
                atomMeshAdd.parent = this.atoms

			}else if (change.type === CHANGE.STRUCTURE.ATOM.MOVE){
				atomMesh.position.fromArray(atom.position)

			}else if (change.type === CHANGE.STRUCTURE.ATOM.ADD){ // Atom creation
				// The atom is already created on the structure atoms array and the index is known
				let atomMeshAdd = this.getMesh(atom.species, false, atom.constraint)// species undefined for that case of a new atom in the structure
				atomMeshAdd.position.fromArray(atom.position)
				this.atoms.children.splice(change.atomIndex, 0, atomMeshAdd)
				atomMeshAdd.parent = this.atoms

			}else if (change.type === CHANGE.STRUCTURE.ATOM.REMOVE){ // Atom removal
				this.atoms.remove(atomMesh)
				//this.threeViewer.clearObject(atomMesh)
			}
			this.recalculateBonds(atoms)

			if (this.periodic) this.recalculateDuplicateAtoms(atoms) //**** No tested , this.duplicateAtoms)
			//update the legend
			this.infoCanvas.createAtomsLegend(atoms)
		}

		this.threeViewer.render()
	}


	/**
	 * Sets up the visualization with a new structure
	 * @param  {Structure} data
	 * @param  {boolean} resetZoom If the camera zoom has to be reseted
	 * @returns {boolean} True if everything was right
	 */
	setupVisualization(data, resetZoom = true) {

		this.structure = data
		const atomNum = this.structure.atoms.length
		const DEFAULT_ZOOM_DISTANCE = 20

		this.periodic = this.structure.isAPeriodicSystem()

		this.basisVectors = undefined
		if (this.periodic)
			this.basisVectors = util.createBasisVectors(this.structure.latVectors)

		this.root = new THREE.Group()
		this.root.name = 'structure'
		this.threeViewer.mainScene.add(this.root)

		// Set position and adjust the zoom to the structure size: if the structure is smaller the zoom is larger
		if (this.periodic){
			this.root.position.copy(util.getParallelepipedCenter(this.basisVectors))
			if (resetZoom){
				let diagonal = this.basisVectors[0].clone().add(this.basisVectors[1])
		    			.add(this.basisVectors[2])
		    	const factor = this.threeViewer.hostElement.clientWidth/820 - 0.15
		    	this.threeViewer.adjustZoomToStructSize(diagonal.length()*factor)//this.editable ?  diagonal.length() : diagonal.length()*0.4 )
		    }
		}
		else{ // non-periodic
			if (atomNum === 0){ // No atoms
				this.root.position.copy(new THREE.Vector3())
				if (resetZoom)
					this.threeViewer.adjustZoomToStructSize(DEFAULT_ZOOM_DISTANCE)
			}else{ // One or more atoms
				const center = util.getCenterOfPositions(this.structure.atoms)
				this.root.position.copy(new THREE.Vector3().fromArray(center).negate())
				if (resetZoom){
					if (atomNum === 1){
						this.threeViewer.adjustZoomToStructSize(DEFAULT_ZOOM_DISTANCE)
					}else{
						const maxDistance = util.getMaxDistance(center, this.structure.atoms)
						//console.log('maxDistance',center, maxDistance)
						const factor = (maxDistance < 2 ? 4 : 2)
						this.threeViewer.adjustZoomToStructSize(maxDistance*factor)
					}
				}
			}
		}
		//console.log('this.root.position',this.root.position)
		//setInitialRotation(this.root);

		this.infoCanvas.createAtomsLegend(this.structure.atoms)

		const [regularAtoms, duplicateAtoms] = this.createAtoms(this.structure.atoms)
		this.root.add(this.atoms = regularAtoms)
		// duplicate atoms are added only for periodic systems
		if (this.periodic){
			this.root.add(this.duplicateAtoms = duplicateAtoms)
			this.duplicateAtoms.visible = DUPLICATE_ATOMS_DEFAULT
		}

		// duplicate bonds across the cell boundaries. This is an array to store them
		// Only being used for periodic systems
		// In order to be represented they are part of the this.bonds group and created by the regular createBond() method
		this.acrossBonds = []

		this.bonds = this.createBonds(this.structure.atoms)
		//console.log('this.bonds', this.bonds)
		this.root.add(this.bonds)

		if (this.periodic){
			this.convCell = this.createCell( 0x000000, 1.5)
			this.root.add(this.convCell)

			this.latticeVectors = this.createLatticeVectors()
			this.root.add(this.latticeVectors)

			this.latticeParamsLabels = this.createLatticeParamsLabels()
			this.latticeParamsLabels.name = 'lattice-parameters'
			this.latticeParamsLabels.position.copy(util.getParallelepipedCenter(this.basisVectors))
			//setInitialRotation(this.latticeParamsLabels);
			this.threeViewer.infoScene.add(this.latticeParamsLabels)
		}

		// console.log('this.root', this.root)

		if (this.editable){
			// We need atom meshes and structure references in the atomSelector class to detect the mouse on them
			this.atomSelector.atoms = this.atoms //this.threeViewer.setAtoms(this.atoms)
			this.atomSelector.structure = this.structure
			this.atomSelector.setAtomHitListener( i => {
				const coors = ( this.fractionalMode ?
					this.structure.getAtomFractPosition(i) : this.structure.atoms[i].position )
				this.infoCanvas.showAtomInfo(i, this.structure.atoms[i].species, coors)
			})
		}

		// Init viewing options
		this.viewOptionsDropdown.reset(this.periodic,this.threeViewer.params.orthographicCamera)

		return true
	}


	/**
	 * Sets a new structure animation up
	 * @param structures {Array<Structure>}
	 */
	setNewAnimation(structures) {

		let lastFrame = structures.length-1
		this.setNewStructure(structures[lastFrame])
		//console.log('ATOMS', structures[lastFrame], this.atoms)

		if (this.animationControl === undefined){
			this.animationControl = new AnimationControl(this.threeViewer)
			this.hostElement.appendChild(this.animationControl.e)
		}
		this.animationControl.setFrameNumber(structures.length)
		this.animationControl.setFrame(lastFrame)

		const atomMeshes = this.atoms.children
		let frame = 0
		this.animationTimer = setInterval( () => {

			if (!this.animationControl.running) return

			// the this.structure represents the current structure being repressented.
			// This is necessary for some methods (e.g.recalculateDuplicateAtoms )
			this.structure = structures[frame]

			if (this.periodic){
				this.basisVectors = util.createBasisVectors(structures[frame].latVectors)
				this.root.position.copy(util.getParallelepipedCenter(this.basisVectors))
				// Cell update
				this.root.remove(this.convCell)
				if (this.viewOptionsDropdown.isOptionChecked(this.viewOptionsDropdown.UNIT_CELL_OPTION)){
					this.convCell = this.createCell( 0x000000, 1.5)
					this.root.add(this.convCell)
				}
				// Lattice vectors update
				this.root.remove(this.latticeVectors)
				this.threeViewer.infoScene.remove(this.latticeParamsLabels)
				if (this.viewOptionsDropdown.isOptionChecked(this.viewOptionsDropdown.LATTICE_VECTORS_OPTION)){
					this.latticeVectors = this.createLatticeVectors()
					this.root.add(this.latticeVectors)

					this.latticeParamsLabels = this.createLatticeParamsLabels()
					this.latticeParamsLabels.name = 'lattice-parameters'
					this.latticeParamsLabels.position.copy(util.getParallelepipedCenter(this.basisVectors))
					this.threeViewer.infoScene.add(this.latticeParamsLabels)
				}

			}else{ // Non periodic
				let center = util.getCenterOfPositions(structures[frame].atoms)
				this.root.position.copy(new THREE.Vector3().fromArray(center).negate())
			}

			// Atoms update
			for (let i = 0; i < atomMeshes.length; i++) {
				atomMeshes[i].position.fromArray(structures[frame].atoms[i].position)
			}
			if (this.periodic) this.recalculateDuplicateAtoms(structures[frame].atoms)

			// Bonds update
			this.root.remove(this.bonds)
			this.bonds = this.createBonds(structures[frame].atoms)
			// *** This could be optimized changing all bond ends
			this.root.add(this.bonds)
			// this.acrossBonds are included into this.bonds

			this.threeViewer.render()
			this.animationControl.setFrame(frame)
			frame++
			//console.log('Structure frame', frame)
			if (frame > lastFrame) frame = 0

		}, 400)

	}


	/**
	 * Creates the dropdown controling the scene visualization elements
	 */
	createViewingControls(){

		this.viewerControls = document.createElement('div');
		this.hostElement.appendChild(this.viewerControls);
		this.viewerControls.className = 'viewing-controls'

    this.viewOptionsDropdown = new ViewOptionsDropDown()//this)
    this.viewerControls.appendChild(this.viewOptionsDropdown.e)

	  this.viewOptionsDropdown.setCheckListener( e => {
	   		//console.log('setCheckListener e', e.target.name)
	   		if (e.target.name === this.viewOptionsDropdown.ATOMS_DUPLICATED_OPTION) {
	   			this.duplicateAtoms.visible = e.target.checked

	   		}else if (e.target.name === this.viewOptionsDropdown.BONDS_OPTION){
	   			this.viewOptionsDropdown.getBondsAcrossOptionElement().style.display =
	   				( e.target.checked && this.periodic ? 'block' : 'none')
	   			this.bonds.visible = e.target.checked

	   			console.log('this.root', this.bonds, this.bonds.visible)
	   			//this.root.visible = e.target.checked

	   		}else if (e.target.name === this.viewOptionsDropdown.BONDS_BOUNDARIES_OPTION){
	   			this.acrossBonds.forEach( b => b.visible = e.target.checked)

	   		}else if (e.target.name === this.viewOptionsDropdown.UNIT_CELL_OPTION){
	   			this.convCell.visible = e.target.checked

	   		}else if (e.target.name === this.viewOptionsDropdown.LATTICE_VECTORS_OPTION){
	   			this.latticeVectors.visible = e.target.checked
	   			this.latticeParamsLabels.visible = e.target.checked

	   		}else if (e.target.name === this.viewOptionsDropdown.ORTHOGRAPHIC_CAMERA) {
					this.threeViewer.params.orthographicCamera = e.target.checked
					this.atomDisplSwitch.switchOff()
					this.atomSelector.enableAtomDisplacement(false)
					this.threeViewer.camera = e.target.checked ? this.threeViewer.orthographicCamera : this.threeViewer.perspectiveCamera
					this.atomSelector.camera = this.threeViewer.camera
					this.threeViewer.controls.dispose()
					this.threeViewer.setupControls()
					// this.updateStructure(this.structure)
				}else{ // wrap-atoms-into-cell
	   			let atomsData = this.structure.atoms
	   			// Change the atoms positions
				for (let i = 0; i < atomsData.length; i++)
   					this.atoms.children[i].position.fromArray(
   						e.target.checked ?
   						twoModOneAndGetCartesians(this.structure, i)
   						: atomsData[i].position
   					)
   				// Update the bonds
   				if (e.target.checked)
					atomsData = getWrappedAtomsData(this.structure)
   				this.recalculateBonds(atomsData)
	   		}

	   		this.threeViewer.render()
	   	})


		function twoModOneAndGetCartesians(structureData, i){
			const v = structureData.getAtomFractPosition(i)
			let newFractCoors = [(v[0]%1 + 1)%1, (v[1]%1 + 1)%1, (v[2]%1 + 1)%1]
			return structureData.getCartesianCoordinates(newFractCoors)
		}

		function getWrappedAtomsData(structureData){
			const atomsData = structureData.atoms

	   		let atomsDataForNewBonds = []
	   		//console.log(atomsData.length, this.atoms.children.length)
	   		for (let i = 0; i < atomsData.length; i++) { // Data for the creation of new bonds
	   			atomsDataForNewBonds.push({
	   				'position': twoModOneAndGetCartesians(structureData, i),
	   				'species': atomsData[i].species
	   			})
	   		}
	   		return atomsDataForNewBonds
		}
  	}


  	/**
  	 * Recalculates the bonds for new atom positions
  	 * @param  {Array<object>} atomsData
  	 */
  	recalculateBonds(atomsData){
  		this.root.remove(this.bonds)
  		this.threeViewer.clearObject(this.bonds)
		this.bonds = this.createBonds(atomsData, this.periodic)
		this.root.add(this.bonds)
		this.bonds.visible = this.viewOptionsDropdown.isBondsOptionChecked()
		// If the bonds across checkbox is checked these bonds have to be set visible (created not visible)
		if (this.viewOptionsDropdown.isBondsAcrossOptionChecked())
			this.acrossBonds.forEach( b => b.visible = true)
  	}


	/**
	 * Creates the cell box
	 * @param  {string} color
	 * @param  {int} linewidth
	 */
	createCell(color, linewidth) {

		const basisVectors = this.basisVectors;
		let origin = new THREE.Vector3(); // 0,0,0 point
		let cell = new THREE.Group(); // holding the cell lines
		let lineMaterial = new THREE.LineBasicMaterial({ color: color,
			linewidth: linewidth });

		// Draws two lines per basis vector to complete the cube (cell)
		// The first line is compound of 3 segments and the second of 1
		// 3+1 = 4 segments (cube edges) x 3 basis vectors = 12 total cube edges
		for (let len = basisVectors.length, i = 0; i < len; ++i) {

			const basisVector = basisVectors[i].clone();

			// Forms the first line (3 segments) geometry
			let thirdPoint = basisVectors[(i + 1) % len].clone().add(basisVector);
			let fourthPoint = basisVectors[(i + 2) % len].clone().add(thirdPoint);
			// lineGeometry.vertices.push(origin, basisVector, thirdPoint, fourthPoint);
			let lineGeometry = new THREE.BufferGeometry().setFromPoints([
				origin, basisVector, thirdPoint, fourthPoint
			])
			cell.add(new THREE.Line(lineGeometry, lineMaterial));
			// lineGeometry.computeLineDistances();

			// Forms the second line (1 segment) geometry
			// let lineGeometry2 = new THREE.Geometry();
			// lineGeometry2.vertices.push(basisVector,
				// basisVector.clone().add(basisVectors[(i === 0 ? 2 : i-1)]));
			let lineGeometry2 = new THREE.BufferGeometry().setFromPoints([
				basisVector, basisVector.clone().add(basisVectors[(i === 0 ? 2 : i-1)])
			])
			cell.add(new THREE.Line(lineGeometry2, lineMaterial));
		   //console.log('cell created:',cell);
		}
		return cell
	}


	/**
	 * Create the atom meshes, both the regular atoms and the duplicate ones
	 * @param  {Array<Array<object>>} atomsData
	 */
	createAtoms(atomsData){

		let atoms = new THREE.Group();
		atoms.name = 'atoms'
		let duplAtoms = new THREE.Group();
		// Code snippet for instanced atom meshes:
		// let species = {}
		// atomsData.forEach((atom,iAtom) => {
		// 	if (species[atom.species]){
		// 		species[atom.species].push(iAtom)
		// 	} else {
		// 		species[atom.species] = [iAtom]
		// 	}
		// })
		// console.log(species);
		// for (let [key,value] of Object.entries(species)){
		// 	const {radius, color} = Conf.getRadiusAndColor(key)
		// 	const matProps = { color: color }
		// 	const geometry = new THREE.IcosahedronGeometry( radius, 2 )//THREE.SphereBufferGeometry(radius, 2*nSegments, nSegments)
		// 	const material = new THREE.MeshLambertMaterial(matProps)
		// 	const matrix = new THREE.Matrix4();
		// 	let mesh = new THREE.InstancedMesh( geometry, material, value.length )
		// 	value.forEach((ind,i) => {
		// 		matrix.setPosition(atomsData[ind].position[0],atomsData[ind].position[1],atomsData[ind].position[2])
		// 		mesh.setMatrixAt( i, matrix )
		// 	})
		// 	atoms.add(mesh)
		// }
		

		for (let i = 0; i < atomsData.length; i++) {
			const atomData = atomsData[i]

			// const elementIndex = (atomData.species === undefined ? -1 : Conf.ELEMENT_NAMES.indexOf(atomData.species))
			let atomMesh = this.getMesh(atomData.species, false, atomData.constraint)//, atomsData.length)
			atomMesh.position.copy(new THREE.Vector3().fromArray(atomData.position))
			atoms.add(atomMesh)

			if (this.periodic && this.structure !== undefined)
				this.calculateDuplicateAtom(atomData, duplAtoms)
		}// for
		return [atoms, duplAtoms]
	}


	/**
	 * Recalculates the duplicate atoms
	 * @param  {Array<object>} atomsData
	 */
	recalculateDuplicateAtoms(atomsData){
		// *** release old duplicate atoms memory
		this.threeViewer.clearObject(this.duplicateAtoms)
		for (let i = 0; i < atomsData.length; i++) {
			const atomData = atomsData[i]
			this.calculateDuplicateAtom(atomData, this.duplicateAtoms)
		}
	}


	/**
	 * Calculates the duplicate atoms
	 * @param  {Array<object>} atomData
	 * @param  {Array<object>} duplAtoms Array to be populated with the new atoms
	 */
	calculateDuplicateAtom(atomData, duplAtoms){
		// If the atom sits on the cell surface, add the mirror images
		const fractPos = this.structure.getFractionalCoordinates(atomData.position)
		let xZero = almostZero(fractPos[0])
		let yZero = almostZero(fractPos[1])
		let zZero = almostZero(fractPos[2])

		if (xZero && yZero && zZero) { // Duplicate (x7) the atom at the 0,0,0 position
			this.duplicateAtomsMethod(duplAtoms, atomData.species, fractPos,
				[[1, 0, 0], [0, 1, 0], [0, 0, 1], [1, 1, 0], [0, 1, 1], [1, 0, 1], [1, 1, 1]])
		}else if (xZero && yZero && fractPos[2] > 0) { // Duplicate (x3) the atoms along the z axis
			this.duplicateAtomsMethod(duplAtoms, atomData.species, fractPos,
				[[1, 0, 0], [0, 1, 0], [1, 1, 0]])

		}else if (fractPos[0] > 0 && yZero && zZero) { // Duplicate (x3) the atoms along the x axis
			this.duplicateAtomsMethod(duplAtoms, atomData.species, fractPos,
				[[0, 1, 0], [0, 0, 1], [0, 1, 1]])

		}else if (xZero && fractPos[1] > 0 && zZero) {// Duplicate (x3) the atoms along the y axis
			this.duplicateAtomsMethod(duplAtoms, atomData.species, fractPos,
				[[1, 0, 0], [0, 0, 1], [1, 0, 1]])

		}else if (xZero && fractPos[1] > 0 && fractPos[2] > 0) { // Duplicate (x1) the atoms on the YZ plane
			this.duplicateAtomsMethod(duplAtoms, atomData.species, fractPos, [[1, 0, 0]])

		}else if (fractPos[0] > 0 && yZero && fractPos[2] > 0) { // Duplicate (x1) the atoms on the XZ plane
		    this.duplicateAtomsMethod(duplAtoms, atomData.species, fractPos, [[0, 1, 0]])

		}else if (fractPos[0] > 0 && fractPos[1] > 0 && zZero) // Duplicate (x1) the atoms on the XY plane
		   this.duplicateAtomsMethod(duplAtoms, atomData.species, fractPos, [[0, 0, 1]])

		function almostZero(value){
     		return value === 0
    	}
	}


	/**
	 * Returns an atom mesh
	 * @param  {string} species
	 * @param  {boolean} transparent
	 * @param  {boolean} constraint
	 * @return {THREE.Mesh}
	 */
	getMesh(species, transparent, constraint){

		let {radius, color} = Conf.getRadiusAndColor(species)

		//const nSegments = 18 // 5 + Math.ceil(15*(1 - numAtoms/2500)*radius)//

		let matProps = { color: color }
		if (transparent) {	matProps.transparent = true; matProps.opacity = 0.6; }

		return new THREE.Mesh(
			new THREE.SphereGeometry(radius, 2 * nSegments, nSegments),
			(constraint ?
				new THREE.MeshBasicMaterial(matProps) : new THREE.MeshLambertMaterial(matProps))
		)
	}


	/**
	 * Duplicates atoms for a species based on plains and axes
	 * and adds them to an array
	 * @param  {Array<object>} atoms Array to be populated with the new atoms
	 * @param  {int} elementIndex Species index
	 * @param  {Array<float>} fractPosition
	 * @param  {Array<Array<int>>} shifts Places for the atom to be duplicated
	 */
	duplicateAtomsMethod(atoms, elementIndex, fractPosition, shifts ){

		shifts.forEach( shift => {
			let fractFinalPos = mathlib.addArrays(fractPosition, shift)
			const finalPos = this.structure.getCartesianCoordinates(fractFinalPos)
			//console.log('duplicateAtoms', structureData.atoms.length)
			let nMesh = this.getMesh(elementIndex, true,false)//, structureData.atoms.length)
			nMesh.position.copy(new THREE.Vector3().fromArray(finalPos))
			atoms.add(nMesh)
		});
	}


	createBonds(atomsData) {

		const corners = iterProduct([-1,1,0],3)
		let cornersNoZero = []
		corners.forEach(corner => {
			if (corner[0] !== 0 || corner[1] !== 0 || corner[2] !== 0 ) {
				cornersNoZero.push(corner)
			}
		})

		let basisMatrix
		let basisMatrix_inv
		let maxBondRadius = 0
		let binNumbers
		let faceDist
		
		let species = new Set(atomsData.map(atom => atom.species))
		species.forEach(s => {
			maxBondRadius = Math.max(maxBondRadius, Conf.getSpeciesRadius(s))
		})
		maxBondRadius *= 2

		if (this.periodic){
			basisMatrix = this.basisVectors.map( v => [v.x, v.y, v.z] )
			basisMatrix_inv = mathlib.invert33(basisMatrix)
			let basisMatrix_inv_T = mathlib.transpose33(basisMatrix_inv)
			faceDist = basisMatrix_inv_T.map(v => {
				let norm = mathlib.getDistance(v,[0,0,0])
				return norm > 0 ? 1/norm : 1 
			})
			binNumbers = faceDist.map(d => Math.max(parseInt(d/maxBondRadius),1))
			
		} else
			binNumbers = [1,1,1]
		console.log('maxBondRadius',maxBondRadius);
		let binFracs = [1.0/binNumbers[0],1.0/binNumbers[1],1.0/binNumbers[2]]
		// console.log("binFracs",binFracs);
		let bins = new Map()
		let binNeighbors = new Map()
		let outsideBin = [] // Collecting all atoms that are outside the unit cell.
		for (let i = 0; i < binNumbers[0]; i++) {
		for (let j = 0; j < binNumbers[1]; j++) {
		for (let k = 0; k < binNumbers[2]; k++) {
			// console.log(currentIndex,diffIndex);
			let i_bin = [i,j,k].join(',')
			bins.set(i_bin,[])
			let neighborBin = []
			if (this.periodic) cornersNoZero.forEach(corner => {
				// console.log("corner,diffIndex",corner,diffIndex,cornersNoZero.length);
				if (
					(k>0 && corner[2]<0 && corner[0] === 0 && corner[1] === 0) ||
					(k>0 && j> 0 && corner[1]<0 && corner[0] === 0) ||
					(k>0 && j>0 && k>0 && corner[0]<0)
				) {
				// 		// console.log("I am not");
					} else {
						// console.log("I am in");

						let n = [i + corner[0],j + corner[1],k + corner[2]]
						neighborBin.push(n.join(','))
					}
					
			})
			else // not periodic! no neighbors
				neighborBin = []
			binNeighbors.set(i_bin,neighborBin)
		}}}
		if (this.periodic) atomsData.forEach((atom) => {
			let x = vectorDotMatrix(atom.position,basisMatrix_inv)
			x = x.map(x => parseFloat(x.toFixed(12))) // We need some float cleaning
			// let iAmDetected = false
			if (x.some(i => (i>=1 || i<0))) {
				console.log('I am outside');
				outsideBin.push(atom)
				return
			}
			let i = Math.floor(x[0]/binFracs[0])
			let j = Math.floor(x[1]/binFracs[1])
			let k = Math.floor(x[2]/binFracs[2])
			console.log(i,j,k);
			bins.get([i,j,k].join(',')).push(atom)

		})
		else // Non-periodic: put all atoms in one bin
			bins.get('0,0,0').push(...atomsData)
		// console.log(binFracs);

		// console.log(binNeighbors);
		// console.log("bins", bins);

		if (this.bondMaterial === undefined)
			this.bondMaterial = new THREE.MeshLambertMaterial({ color: 0xFFFFFF })
		const tooCloseMaterial = new THREE.MeshLambertMaterial({ color: 0xFF0000 })

		let bonds = new THREE.Group();
		bonds.name = 'bonds'
		
		bins.forEach((bin,iBin) => {
			// console.log(bin);
			for (let i_atom_1 = 0; i_atom_1 < bin.length; ++i_atom_1) {
				let pos1 = bin[i_atom_1].position
				let r1 = bin[i_atom_1].radius
				for (let i_atom_2 = i_atom_1+1; i_atom_2 < bin.length; ++i_atom_2) {
					// Intra-bin bonds
					let pos2 = bin[i_atom_2].position
					let r2 = bin[i_atom_2].radius
					let v = mathlib.subtract(pos1, pos2) // i-j in cartesian coodinates
					const minAtomsSep = (r1 + r2)*BOND_FACTOR
					const minAtomsDist = 0.6 * minAtomsSep
					let vnorm = Math.sqrt(v[0]**2+v[1]**2+v[2]**2)
					// console.log(i_atom_1,i_atom_2,vnorm,minAtomsSep);
					if ( vnorm < minAtomsSep && vnorm > 1e-8) { // If the atom in the cell is near
						//let bondEndPos = mathlib.addArrays(pos2, mathlib.multiplyScalar(unitVector, RADIUS_FACTOR*r2))
							if (vnorm > minAtomsDist) {
								bonds.add(createBondMesh(pos1, pos2, this.bondMaterial)) // bondEndPos))
							}else{
								 bonds.add(createBondMesh(pos1, pos2, tooCloseMaterial,0.15))
							}
		
					}
				}
				binNeighbors.get(iBin).forEach(iBinNeighbor => {
					// console.log(iBinNeighbor, iBinNeighbor.split(',').map(x=>parseInt(x)));
					let indices = iBinNeighbor.split(',').map(x=>parseInt(x))
					// console.log(indices);
					if (
						indices.some(x => x < 0) || indices.some((x,i) => x >= binNumbers[i])
					) { // bonds from bins across boundaries
						let shiftVector = [0,0,0]
						let realIndices = indices.map((ind,iInd) => {
							if (ind === -1) {
								shiftVector = mathlib.add(shiftVector,mathlib.multiplyScalar(basisMatrix[iInd],-1))
								return ind + binNumbers[iInd]
							} else if (ind === binNumbers[iInd]) {
								shiftVector = mathlib.add(shiftVector,basisMatrix[iInd])
								return 0
							}else
								return ind
							
						})
						// console.log("shiftVector,realIndices",shiftVector,realIndices);
						let binNeighborAtoms = bins.get(realIndices.join(','))
						for (let i_atom_N = 0; i_atom_N < binNeighborAtoms.length; ++i_atom_N) {
							let pos2 = binNeighborAtoms[i_atom_N].position
							let r2 = Conf.getSpeciesRadius(binNeighborAtoms[i_atom_N].species)
							let v2 = mathlib.subtract(mathlib.subtract(pos1, pos2),shiftVector)
							const minAtomsSep = (r1 + r2)*BOND_FACTOR
							const minAtomsDist = 0.6 * minAtomsSep
							let vnorm2 = Math.sqrt(v2[0]**2+v2[1]**2+v2[2]**2)
							if ( vnorm2 < minAtomsSep && vnorm2 > 1e-8) {
								let altPos2 = mathlib.subtract(pos1, mathlib.divideScalar(v2,1.25))
								let pos2PlusBondv = mathlib.add(pos2, mathlib.divideScalar(v2,1.25))
		
								if (vnorm2 > minAtomsDist) {
								// First stick creation (one side of the cell)
									bonds.add(createAcrossBoundaryBondMesh(pos1, altPos2, this.acrossBonds, this.bondMaterial))
		
								// Second stick creation: in this case (bonds across the cell boundary) the bond has to be duplicated
								// The corresponding atoms of the pair are separated and far in the cell, so both need a different bond representation
									bonds.add(createAcrossBoundaryBondMesh(pos2, pos2PlusBondv, this.acrossBonds, this.bondMaterial))
								}else{
									bonds.add(createAcrossBoundaryBondMesh(pos1, altPos2, this.acrossBonds, tooCloseMaterial,0.15))
									bonds.add(createAcrossBoundaryBondMesh(pos2, pos2PlusBondv, this.acrossBonds, tooCloseMaterial,0.15))
								}
							}
						}
						
						// return
					} else { // Normal case: no bins across boundaries
						let binNeighborAtoms = bins.get(iBinNeighbor)
						// console.log(iBinNeighbor,binNeighborAtoms);
						for (let i_atom_N = 0; i_atom_N < binNeighborAtoms.length; ++i_atom_N) {
							// Inter-bin bonds
							let pos2 = binNeighborAtoms[i_atom_N].position
							let r2 = Conf.getSpeciesRadius(binNeighborAtoms[i_atom_N].species)
							let v = mathlib.subtract(pos1, pos2) // i-j in cartesian coodinates
							const minAtomsSep = (r1 + r2)*BOND_FACTOR
							const minAtomsDist = 0.6 * minAtomsSep
							let vnorm = Math.sqrt(v[0]**2+v[1]**2+v[2]**2)
							if ( vnorm < minAtomsSep && vnorm > 1e-8) { // If the atom in the cell is near
								//let bondEndPos = mathlib.addArrays(pos2, mathlib.multiplyScalar(unitVector, RADIUS_FACTOR*r2))
									if (vnorm > minAtomsDist) {
										bonds.add(createBondMesh(pos1, pos2, this.bondMaterial)) // bondEndPos))
									}else{
										 bonds.add(createBondMesh(pos1, pos2, tooCloseMaterial,0.15))
									}
					
							}
						}
					}
					

				})
				
			}
		})
		
		outsideBin.forEach(atom => {
			let pos1 = atom.position
			let r1 = Conf.getSpeciesRadius(atom.species)
			for (let i_atom_2 = 0; i_atom_2 < atomsData.length; ++i_atom_2) {
				let pos2 = atomsData[i_atom_2].position
				let r2 = Conf.getSpeciesRadius(atomsData[i_atom_2].species)
				let v = mathlib.subtract(pos1, pos2) // i-j in cartesian coodinates
				const minAtomsSep = (r1 + r2)*BOND_FACTOR
				const minAtomsDist = 0.6 * minAtomsSep
				let vnorm = Math.sqrt(v[0]**2+v[1]**2+v[2]**2)
				if ( vnorm < minAtomsSep && vnorm > 1e-8) { // If the atom in the cell is near
					//let bondEndPos = mathlib.addArrays(pos2, mathlib.multiplyScalar(unitVector, RADIUS_FACTOR*r2))
						if (vnorm > minAtomsDist) {
							bonds.add(createBondMesh(pos1, pos2, this.bondMaterial)) // bondEndPos))
						}else{
							 bonds.add(createBondMesh(pos1, pos2, tooCloseMaterial,0.15))
						}
		
				}
			}
		})

		return bonds

		function vectorDotMatrix(A,B){ //*** Move to the math.js module
			let result = [0.,0.,0.]
					result[0] = A[0]*B[0][0]+A[1]*B[1][0]+A[2]*B[2][0]
					result[1] = A[0]*B[0][1]+A[1]*B[1][1]+A[2]*B[2][1]
					result[2] = A[0]*B[0][2]+A[1]*B[1][2]+A[2]*B[2][2]
					return result
		}

		function createBondMesh(startPos, endPos, material,rad=0.05){
				return util.createCylinder(
					new THREE.Vector3().fromArray(startPos),
						new THREE.Vector3().fromArray(endPos),
						rad, 5, material)//new THREE.MeshPhongMaterial({ color: 0xFFFFFF }) )
		}

		function createAcrossBoundaryBondMesh(startPos, endPos, acrossBonds, material, rad=0.05){
				let bondMesh = createBondMesh(startPos, endPos, material, rad)
				bondMesh.visible = false
				acrossBonds.push(bondMesh)
				return bondMesh
		}


		function iterProduct(array,repeat=1) {
		  let args = Array(repeat).fill(array)
		  return args.reduce((accumulator, value) => {
		    let tmp = [];
		    accumulator.forEach(a0 => {
		      value.forEach(a1 => {
		        tmp.push(a0.concat(a1));
		      });
		    });
		    return tmp;
		  }, [[]]);
		}
	}


	/**
	 * Creates the lattice vectors meshes
	 * @return {THREE.Group}
	 */
	createLatticeVectors(){

		let latticeVectors = new THREE.Group();
		const origin = new THREE.Vector3();

		let minVectorLength = Math.min(this.basisVectors[0].length(),
			this.basisVectors[1].length(), this.basisVectors[2].length())
			// console.log('minVectorLength', minVectorLength)

		for (let basisIndex = 0; basisIndex < 3; ++basisIndex) {
			const color = CELL_BASIS_COLORS[basisIndex];
			const basisVector = this.basisVectors[basisIndex].clone();
			// Add basis vector colored line
			const vectorMaterial = new THREE.MeshBasicMaterial({
				color: color, transparent: true, opacity: 0.8 });
			const radius = (minVectorLength > 20 ? 0.2 : minVectorLength*0.01)
			let basisVectorMesh =
				util.createCylinder(origin, basisVector, radius, 10, vectorMaterial);
			latticeVectors.add(basisVectorMesh);
			// Add an arrow following the basis vector direction
			let arrowMesh = util.createArrow(origin, basisVector, minVectorLength);
			latticeVectors.add(arrowMesh);
		}
		return latticeVectors;
	}


	/**
	 * Creates and returns the lattice parameters labels
	 * @return {THREE.Group}
	 */
	createLatticeParamsLabels(){

		let latticeParams = new THREE.Group();
		// const origin = new THREE.Vector3();
		const labels = ["a", "b", "c"];
		const angleLabels = ["γ", "α", "β"];

		const minVectorLength = Math.min(this.basisVectors[0].length(),
			this.basisVectors[1].length(), this.basisVectors[2].length())

		for (let basisIndex = 0; basisIndex < 3; ++basisIndex) {
			const color = CELL_BASIS_COLORS[basisIndex];
			const basisVector = this.basisVectors[basisIndex].clone();
			const nextBasisVector = this.basisVectors[(basisIndex+1)%3].clone();
			let labelPos = basisVector.clone().normalize()
				.multiplyScalar(basisVector.length() + (minVectorLength+2)*0.14 + 0.4)
			let vectorLabelSprite = util.createLabel(labels[basisIndex], color, labelPos, minVectorLength)
			latticeParams.add(vectorLabelSprite);
      //this.axisLabels.push(axisLabel);

      let angleLabelPos = basisVector.clone().add(nextBasisVector).multiplyScalar(0.15);
      let angleLabelSprite = util.createLabel(angleLabels[basisIndex], "#FFFFFF", angleLabelPos, minVectorLength);
     	latticeParams.add(angleLabelSprite);

     	// let radius = Math.max(Math.min(0.18*basisVector.length(), 0.18*nextBasisVector.length()), 1);
      // let angle = basisVector.angleTo(nextBasisVector);
      // let points = new THREE.EllipseCurve(0, 0, radius, radius, 0, angle, false).getPoints(20);
      // let arcGeometry = new THREE.BufferGeometry().setFromPoints( points );
      //arcGeometry.computeLineDistances();
      // let arcMesh = new THREE.Line(arcGeometry, new THREE.LineBasicMaterial( { color : 0x606060 } ));

      // First rotate the arc so that it's x-axis points towards the
            // first basis vector that defines the arc
      // let xAxis = new THREE.Vector3(1, 0, 0);
      // let quaternion = new THREE.Quaternion().setFromUnitVectors(xAxis, basisVector.clone().normalize());
      // arcMesh.quaternion = quaternion;

      // Then rotate the arc along it's x axis so that the xy-plane
            // coincides with the plane defined by the the two basis vectors
            // that define the plane.
						// console.log(points);
            // let lastArcPointLocal = points[points.length-1];
            // arcMesh.updateMatrixWorld(); // The positions are not otherwise updated properly
            // let lastArcPointWorld = arcMesh.localToWorld(lastArcPointLocal.clone());
            // The angle direction is defined by the first basis vector
            //let axis = basisVec1;
            // let normal = new THREE.Vector3().crossVectors(basisVector, nextBasisVector);
            // let arcNormal = new THREE.Vector3().crossVectors(basisVector, lastArcPointWorld);
            // let planeAngle = normal.angleTo(arcNormal);
            // let planeCross = new THREE.Vector3().crossVectors(nextBasisVector, lastArcPointWorld);
            // let directionValue = planeCross.dot(basisVector);
            // if (directionValue > 0)     planeAngle = -planeAngle;
            // arcMesh.rotateX(planeAngle);

      // latticeParams.add(arcMesh);
		}
		return latticeParams;
	}


	/**
     * Highligths an atom
     * @param  {int} atomIndex Index of atom to highlight
     * @param  {boolean} on
     *   If true the atom is highlighted if false it's toned-down
     */
 	highlightAtom(atomIndex, on){
 		this.atomSelector.highlightAtom(atomIndex, on)
 	}

}



/**
 * Undo/redo functionality UI component. HTML layer on the canvas
 */
class UndoRedoComponent{

	/**
	 * @param provider {State} Undo/redo functionality provider
	 */
    constructor(provider){
    	this.provider = provider
        // UI creation
        this.e = document.createElement('div');
        this.e.setAttribute('class','UndoRedoComponent');
        this.e.innerHTML = `
            <img src="${undoIcon}" width="30px"  alt="Undo"/>
            <span> 0/0 </span>
            <img src="${redoIcon}" width="30px" alt="Redo"/>
        `

        // UI interaction
        let buttons = this.e.querySelectorAll('img')
        buttons[0].addEventListener('click', _ => {
           this.provider.undo()
        })
        buttons[1].addEventListener('click', _ => {
           this.provider.redo()
        })

        const indexElement = this.e.querySelector('span')

        this.provider.setUndoRedoListener( (index, listLength) => {
            indexElement.innerHTML = index+'/'+listLength
        })
    }

		update () {

			this.provider.undoRedoListener(this.provider.undoIndex+1, this.provider.changeList.length)
		}
}



/**
 * Structure animation control UI component. HTML layer on the canvas
 */
class AnimationControl extends UIComponent{

    constructor(threeViewer){
    	super('div', '.AnimationControl')

        this.threeViewer = threeViewer
        this.running = false

        // UI creation
        this.frameLabel = document.createElement('span')
        this.e.appendChild(this.frameLabel)

        this.button = document.createElement('img')
        this.button.src = playIcon
        this.e.appendChild(this.button)

        // UI interaction
        this.button.addEventListener('click', _ => {
           this.running = !this.running
           this.button.src = (this.running ? pauseIcon : playIcon)
        })
    }


    /**
     * Sets the animation frame number
     * @param {int} frameNum
     */
    setFrameNumber(frameNum){
    	this.lastFrame = frameNum-1
    }


    /**
     * Sets the current frame
     * @param {int} frame
     */
    setFrame(frame){
    	this.frameLabel.textContent = frame+'/'+this.lastFrame
    }

}
