/**
 * @author Iker Hurtado
 *
 * @fileOverview Utility file for the StructureViewer
 */

// import * as THREE from "../../lib/three.js"
import * as THREE from 'three';
import * as mathlib from '../common/math.js'


// ***************************
// 3D viewer/geometry generation helpers
// ***************************

/**
 * Creates and returns a Three.js basis vectors set from regular vectors in arrays
 * @param  {Array<Array<float>>} vectors
 * @return {Array<THREE.Vector3>}
 */
export function createBasisVectors(vectors) { // units = Angstroms
	let result = [];
	for (let len = vectors.length, i = 0; i < len; ++i) {
		let basis_vector = new THREE.Vector3().fromArray(vectors[i]);//.multiplyScalar(1.0e10);
		result.push(basis_vector);
	}
	return result;
}


/**
 * Returns the position of the center of a given parallelepiped
 * @param  {Array<Vector3>} vectors
 * @return {Vector3}
 */
export function getParallelepipedCenter(vectors) { // units = Angstroms
	let centerVector = vectors[0].clone().add(vectors[1]).add(vectors[2])
	return centerVector.divideScalar(-2);
}


/**
 * Returns the distance between a center and the farthest atom in a set
 * @param  {number[]} center
 * @param  {Object[]} atoms
 * @return {float}
 */
export function getMaxDistance(center, atoms){

    let max_distance = 0
    atoms.forEach( atom => {
        let d = mathlib.getDistance(atom.position, center)
        if ( d > max_distance) max_distance = d
    })
    //console.log('getMaxDistance', max_distance)
    return max_distance
}


/**
 * Returns the center of a list of atos
 * @param  {Array<object>} atoms
 * @return {number[]}
 */
export function getCenterOfPositions(atoms){

  if (atoms.length === 0) throw Error('No atoms')

  let center = [0,0,0]
  atoms.forEach( atom => {
     mathlib.addUp(center, atom.position)
  })
  return mathlib.divideScalar(center, atoms.length)
}


/**
 * Creates and returns a cylinder mesh
 * @param  {Vector3} pos1
 * @param  {Vector3} pos2
 * @param  {number} radius
 * @param  {int} nSegments
 * @param  {Material} material
 * @return {Mesh}
 */
export function createCylinder(pos1, pos2, radius, nSegments, material) {
  let direction = new THREE.Vector3().subVectors(pos2, pos1)
  let dirLen = direction.length()
  let dirNorm = direction.clone().divideScalar(dirLen)
  let arrow = new THREE.ArrowHelper(dirNorm, pos1)
  let edgeGeometry = new THREE.CylinderGeometry(radius, radius, dirLen, nSegments,1)
  let edge = new THREE.Mesh(edgeGeometry, material)
  edge.rotation.copy(arrow.rotation.clone())
  edge.position.copy(new THREE.Vector3().addVectors(pos1, direction.multiplyScalar(0.5)))
  return edge;
}


/**
 * Creates and returns an arrow mesh
 * @param  {Vector3} pos1
 * @param  {Vector3} pos2
 * @param  {number} refLength Reference length
 * @param {number} color Arrow color
 * @return {Mesh}
 */
export function createArrow(pos1, pos2, refLength,color=0x000000) {
  let direction = new THREE.Vector3().subVectors(pos2, pos1)
  let dirLen = direction.length()
  let dirNorm = direction.clone().divideScalar(dirLen)
  return new THREE.ArrowHelper(dirNorm, pos1,
    dirLen + (refLength+2)*0.14, color, (refLength+2)*0.1,  0.1+refLength*0.025)
}


/**
 * Creates a label for the axes
 * @param label {string}
 * @param color {string}
 * @param position {Vector3}
 * @param minVectorLength {number}
 * @return {[type]}
 */
export function createLabel(label, color, position, minVectorLength){
  // Configure canvas
  let canvas = document.createElement('canvas');
  const size = 256;
  canvas.width = size;
  canvas.height = size;
  let ctx = canvas.getContext('2d');
  // Draw label
  ctx.fillStyle = color;
  //ctx.fillStyle = "#ffffff";
  ctx.font = "155px Arimo";
  ctx.textAlign = "center";
  //if (stroked) {
      ctx.font = (minVectorLength < 10 ? 18*minVectorLength : 180)+'px Arimo'
      ctx.lineWidth = (minVectorLength < 5 ? 3 : 8)
      ctx.strokeStyle = "#000000";
      ctx.strokeText(label, size / 2, size / 2);
  //}
  ctx.fillText(label, size / 2, size / 2);

  let texture = new THREE.Texture(canvas);
  texture.needsUpdate = true;
  let sprite = new THREE.Sprite(new THREE.SpriteMaterial({ map: texture }));

  sprite.position.copy(position)

  sprite.scale.set(1.5, 1.5, 1);
  return sprite;
}
