import UIComponent from '../common/UIComponent.js'
import collapsibleIcon from '../../img/collapsible-icon.png'


/**
 * Accordion UI component used to fold/unfold the different sections in the form
 */
export default class Accordion extends UIComponent{

  /**
   * @param  {string} className Component class name
   */
  constructor(className){ //
    super('div', '.Accordion'+ (className === undefined ? '' : ' '+className ))

    this.ROTATE_STYLE = 'transform: rotate(180deg)'
    this.sectionHeaders = []
    this.sectionContents = []
  }


  /**
   * Resets the component. Remove everything
   */
  reset(){
    this.sectionHeaders = []
    this.sectionContents = []
    this.setHTML('')
  }


  /**
   * Sets a section in accordeon
   * @param {string} name
   * @param {HTMLElement} element
   */
  setSection(name, element){
    let sectionHeader = this.addElement('div', name+` <img src="${collapsibleIcon}"  alt="collapse"/> `)
    let icon = sectionHeader.querySelector('img')
    sectionHeader.className = 'sectionHeader'
    this.sectionHeaders.push(sectionHeader)

    sectionHeader.addEventListener('click', _ => {
      this.foldSection(this.sectionHeaders.indexOf(sectionHeader))
    })

    let sectionContent = this.addElement('div')
    sectionContent.className = 'sectionContent'
    sectionContent.appendChild(element)
    this.sectionContents.push(sectionContent)
    // if (this.sectionContents.length > 1){
    //   sectionContent.style.display = 'none'
    // }else
    icon.style = this.ROTATE_STYLE
    //this.e.appendChild(element)
  }

  /**
   * Folds or unfolds the section depending on its current state
   * @param  n{int} section index
   */
  foldSection(n){ //
    let content = this.sectionContents[n]
    let folded = (content.style.display === 'none')
    content.style.display = (folded ? 'block' : 'none')
    this.sectionHeaders[n].querySelector('img').style = (folded ? this.ROTATE_STYLE : '')
  }

  isSectionFolded(n) {
    let content = this.sectionContents[n]
    return content.style.display === 'none'
  }

  /**
   * Completely hides the section
   * @param  n{int} section index
   */
  hideSection(n){ //
    let header = this.sectionHeaders[n]
    let content = this.sectionContents[n]
    header.style.display = 'none'
    content.style.display = 'none'
  }
    /**
   * Shows the hidden section
   * @param  n{int} section index
   */
  showSection(n){ //
    let header = this.sectionHeaders[n]
    let content = this.sectionContents[n]
    header.style.display = 'flex'
    content.style.display = 'block'
  }
}
