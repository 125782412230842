
/**
 * @author Iker Hurtado
 *
 * @overview File holding the ScreenshotTaker UI component
 */

import icon from '../../img/screenshot-icon.png'

/**
 * A UI component that provides a convenient way to 
 * take a screenshot from a canvas element.
 */
export default class ScreenshotTaker{

    /**
     * @param {string} label Screenshot identifier (it appears in the download file name)
     * @param {string} text (optional) text next to the icon
     * @param {int} iconHeight (optional) Icon height in pixels
     */
    constructor(label, text=undefined, iconHeight=undefined){
        // UI creation
        this.e = document.createElement('div');
        this.e.setAttribute('class','ScreenshotTaker');
        this.e.innerHTML = `
            <a download="${label}_screenshot.png" href=""  >
                <img src="${icon}" ${iconHeight ? 'height='+iconHeight+'px' : ''} alt="Screenshot"/></a>
            ${ text ? text : ''}
        `
        // UI interaction
        this.e.querySelector('img').addEventListener('click', _ => {
          this.screenshotListener(this.e.querySelector('a'))  //.href = canvasElement.toDataURL()
        })
    }

    /**
     * Set the listener that will be called when the button is clicked
     * A link element will be passed in to the listener in order to be 
     * assigned a data URL object with the image
     * @param {function} listener 
     */
    setScreenshotListener(listener){
    	this.screenshotListener = listener
    }
}