/**
 * @author Iker Hurtado
 *
 * @overview File holding the ControlGeneratorMod class and module
 */

import UIComponent from '../common/UIComponent.js'
import Form from '../common/Form.js'
import {Conf} from '../Conf.js'
import * as UserMsgBox from '../common/UserMsgBox.js'
import * as Tooltip from '../common/Tooltip.js'
import * as AppState from '../State.js'
import downloadIcon from '../../img/download-icon.png'
import AllFields from './AllFields.js'
import Accordion from './Accordion.js'
import Constraint from "../common/Constraints";
import ExtrasTableForm from "./extrasTable";
import {deepClone} from "../common/util";

/**
 * ControlGenerator application module UI component
 */
export default class ControlGeneratorMod extends UIComponent{

	constructor() {
    super('div', '#ControlGeneratorMod')
    this.setHTML(`
      <h1> Control Generator </h1>

      <div class="content">

        <div class="form-accordion" > </div>

        <div class="download-input-files-button">
          <a class="download-input-files-link" download="input_files.tar">
            <img src="${downloadIcon}" alt="Download"/>Generate and download input file(s)
          </a>
        </div>

      </div>
    `)

    this.allFields = new AllFields()  // All available fields
    this.fields = [] // The effective fields (data) for this instance
    this.forms = []// There is a form per accordion section
    this.workflow = undefined


    this.formAccordion = new Accordion('control-gen-accordion')
    this.getElement('.form-accordion').appendChild(this.formAccordion.e)

    this.button = this.getElement('.download-input-files-button')
    this.downloadLink = this.getElement('.download-input-files-link')
    this.button.addEventListener('click', e => {
      e.preventDefault() // This prevents the regular link behavior (the link is empty at that time)
      // because we want it after the request callback when it is linked to the tar file
      this.requestInputFiles( tarFiles => {
        this.downloadLink.href =
          window.URL.createObjectURL( new Blob([tarFiles], {type: 'application/x-tar'} ))
        // The first time the user clicks on the link the tar file is got from the server,
        // the link href is still empty so no file to download is shown to the user
        // therefore a new click event is created and issued on the link to get the download offer
        this.downloadLink.dispatchEvent(new MouseEvent('click'))
      })
    })

    this.standalone = true // standalone module (not inside a workflow)
  }


  /**
   * Initialization method
   * @param  {string} workflow A name of the workflow  for which the control generator form is built
   */
  init(workflow){
    this.buildAccordion(workflow)
  } // init()

  /**
   * Builds the accordion UI component
   */
  buildAccordion(workflow){
    // reset
    // console.log(workflow);
    this.workflow = workflow
    workflow = workflow ? workflow : 'default'
    // console.log('buildAccordion',workflow)
    this.fields = {}
    this.forms = []
    this.constraints = {}
    this.functions = {}
    this.sanityChecks = []
    this.formAccordion.reset()
    Tooltip.setRelPosition('right', -25)

    let myCode = AppState.getCode()
    let myGroups = deepClone(this.allFields[myCode])
    myGroups.forEach((group, i) => {
      let form = new Form('control-gen-form')
      form.parent = this.formAccordion
      form.idx = i
      form.disableUserMsg()
      group = this.collectFunctionalValues(i, group)
      if (group.info) form.addInfo(group.info)
      for (const prop in group.fields){
        let data = group.fields[prop]
        if ( data.workflow === workflow || data.workflow === 'default'){
          // collect data values given as functions
          data = this.collectFunctionalValues(prop, data)
          form.addField(
						prop, data.text, data.inputType, data.value, data.dataType, i,
						!!data.required, data.explicitInclusion, data.units, data.explanation
					)
          this.fields[prop] = form.fieldMap.get(prop)
          // get constraints; clone them as they'll be changed in-place
          if (data.constraint) this.constraints[prop] = structuredClone(data.constraint)
        }
      }
      this.forms.push(form)
      this.formAccordion.setSection(group.label, form.e)
      if (form.getFieldNum() === 0){ // The empty sections are not shown
        this.formAccordion.hideSection(form.idx)
      }
      if (group.folded) this.formAccordion.foldSection(form.idx)
    })
    // the last form in accordion
    this.extrasTable = new ExtrasTableForm('control-gen-form')
    this.formAccordion.setSection("Extra keywords", this.extrasTable.e)

    // prepare and set constraints
    for (let [name, c] of Object.entries(this.constraints)) {
      const constraint = new Constraint(this.#prepareConstraint(c))
      let f = this.fields[name]
      f.setConstraint(constraint)
    }
  }

  /**
   * Stores the values given by functions in instance attribute. Changes them to defaults until no structure is set
   * @param name {string | number}
   * @param data {object}
   */
  collectFunctionalValues(name, data) {
    for (const [key, field] of Object.entries(data)) {
      if (field instanceof Function) {
        this.functions[`${name}.${key}`] = field
        delete data[key]
      }
    }
    return data
  }

  /**
   * Prepare constraints, setting the masters' names to actual master fields
   * @param constraintData {Object|Array}
   */
  #prepareConstraint(constraintData) {
    if (Array.isArray(constraintData)) {
      if (Array.isArray(constraintData[0]))
        for (let i = 0; i < constraintData.length; i++)
          constraintData[i][0] = this.fields[constraintData[i][0]]
      else constraintData[0] = this.fields[constraintData[0]]
      return constraintData
    } else if (constraintData instanceof Object) {
      let [k, v] = Object.entries(constraintData)[0]
      return {[k]: this.#prepareConstraint(v)}
    } else return []
  }

  /**
   * Provides an input structure. The species filed is filled up
   * Optional use for the instance. Used in instances inside workflows.
   * @param {Structure} structure
   */
  setStructure(structure){
    this.standalone = false
    this.button.style.display = 'none'
    this.sanityChecks = []   // sanity check is a check of a field against the structure
    // this.periodicSystem = structure.isAPeriodicSystem()
    for (let [name, f] of Object.entries(this.functions)) {
      const [field_name, subfield] = name.split('.')
      // if field_name is a number, it refers to a form, otherwise it refers to a field
      let field = parseInt(field_name) ? this.forms[parseInt(field_name)] : this.fields[field_name]
      // use context for hiding fields
      let context = {
        'structure': structure,
        'workflow': this.workflow}
      switch (subfield) {
        case 'value':
          field.setValue(f(structure))
          break;
        case 'info':
          field.addInfo(f(structure))
          break;
        case 'constraint':
          field.setConstraint(f(context))
          break;
        case 'sanity_check':
          this.sanityChecks.push({field: field, check: (field) => f(field, structure)})
      }
    }
    this.structure = { cell: structure.latVectors, positions: structure.atoms, info: structure.structureInfo}
  }

  /**
   * Runs the sanity check functions found in the fields' dictionary; collects the results
   * @returns {(boolean|string[])[]}
   */
  runSanityChecks() {
    let messages = []
    let isSane = true
    for (let check of this.sanityChecks) {
      const field = check['field'], checkFunction = check['check']
      const checkResult = checkFunction(field)
      if (checkResult === undefined) continue
      if (checkResult['type'] === 'error') isSane = false
      messages.push(checkResult)
    }
    return [isSane, messages]
  }

  /**
   * Updates the component for a code change (application level event)
   * @param  {string} code
   */
  updateForCode(code){
    const speciesFieldValue = this.forms[0].getField('species').getValue()
    this.buildAccordion(this.workflow)
    this.forms[0].getField('species').setValue(speciesFieldValue)

    this.downloadLink.href = undefined
  }


  /**
   * Updates the component for a setting change (application level event)
   * @param  {object} newSettings
   */
  updateSettings(newSettings){
    console.log('new setting! ', newSettings)
  }


  /**
   * Goes through the field getting the values entered and builds a json object
   * representing a server request to get an input file
   * @return {object}
   */
  getInputFilesRequestJson(){

    /* Convenience lines for development
    this.forms[0].getField('species').value = 'H'
    if (AppState.isExcitingCode()) this.forms[0].getField('xctype').value = 'EXX' // Exciting
    else this.forms[0].getField('xc').value = 'hf' // FHIAims
    this.forms[0].getField('kgrid')[0].value = 9;  this.forms[0].getField('kgrid')[1].value = 9;  this.forms[0].getField('kgrid')[2].value = 9
    if (AppState.isExcitingCode())  this.forms[0].getField('rgkmax').value = 7 // Exciting
    else this.forms[0].getField('basisSettings').value = 'light' // FHIAims
  */

    // getting the results from the forms
    let namesValues = Object.assign({}, ...this.forms.map(form => form.getFieldsNameValue()))

    // SPECIES FIELD. It's assumed the species field is present
		let allSpecies = namesValues.species
		let species = []
		if (allSpecies.includes(',')) {
			allSpecies.split(',').forEach(s => species.push(s.trim()))
		} else {
			allSpecies.split(' ').forEach(s => species.push(s.trim()))
		}
    for (let i = 0; i < species.length; i++)
      if (Conf.getSpeciesAtomicNumber(species[i]) === 0){
        this.forms[0].markField('species', 0)
        UserMsgBox.setError('Some species is not valid: ' + species[i]) //this.form.userMsg.innerHTML = 'Some species is not valid'
        return
      }
    namesValues.species = species

    // A new object is built with the field flags as object keys
    let flagsValues = {}
    let myCode = AppState.getCode()
    let allFields = Object.assign({}, ...this.allFields[myCode].map(el => el.fields))

    for (let fieldName in namesValues){
        if (allFields[fieldName] && allFields[fieldName]['flag'])
						flagsValues[allFields[fieldName]['flag']] = namesValues[fieldName]
        else
          flagsValues[fieldName] = namesValues[fieldName]
    }
    // update flagsValues object with the data from extras
    Object.assign(flagsValues, this.extrasTable.getData())

    // log('inputFilesRequestJson', inputFilesRequestJson)
    return {code: AppState.getCode(), structure: this.structure, form: flagsValues}
  }


    /**
     * Fetches a server request asking for a tar file packaging a set of input files
     * That is passed in a listener
     * @param {function} listener
     */
  async requestInputFiles(listener){
    if (!this.getInputFilesRequestJson()) return
    let response = await fetch('/generate-control-in', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      body: JSON.stringify(this.getInputFilesRequestJson())
    })

    if (response.ok) {  // File or server controlled errors
      // It's needed to get the text because of the FileNotFoundError checking
      let tarFiles = await response.text() //await response.arrayBuffer()
      if (tarFiles === 'FileNotFoundError')
        UserMsgBox.setError('Species defaults not found. Check your species. Not all species are defined for <i>intermediate</i>.')
      else
        listener(tarFiles)  //, requestJson)
    } else  // Unknown server ERROR
      UserMsgBox.setError('Unknown server ERROR ')
}

  /** Runs sanity and validity checks on the form
   *
   * @return {boolean}
   */
  runChecks() {
    // Run validity checks. If any of the forms is not validated the function returns
    let isValid = true
    for (let i = 0; i < this.forms.length; i++)
      isValid = this.forms[i].validate() && isValid
    if (!isValid) {
      UserMsgBox.setError('There are some fields required to generate the control.in file not filled')
      return false
    }
    // } else UserMsgBox.setError('')

    // Run sanity checks including the given structure
    let [isSane, messages] = this.runSanityChecks()
    for (const message of messages)
      if (message['type'] === 'error')
        UserMsgBox.setError(message['message'])
      else UserMsgBox.setMsg(message['message'])
    return isSane
  }

  async requestDownloadInfo(listener){
    if (!this.runChecks()) return
    //log('DownloadInputFilesPage requestInputFiles', requestJson)
    if (!this.getInputFilesRequestJson()) return
    let response = await fetch('/get-download-info', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      body: JSON.stringify(this.getInputFilesRequestJson())
    })

    if (response.ok){ // File or server controlled errors
      // It's needed to get the text because of the FileNotFoundError checking
      let downloadInfo = await response.text() //await response.arrayBuffer()
      console.log(downloadInfo)
      listener(downloadInfo)//, requestJson)
    }else{ // Unknown server ERROR
      UserMsgBox.setError('Unknown server ERROR ')
    }
  }


}
