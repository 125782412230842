/**
 * @author Andrey Sobolev
 *
 * @fileOverview File holding the Convergence graph dashboard based on Plotly.js
 */
import {PlotlyDashboard} from "./dashboard";
import {InteractiveGraph} from "./InteractiveGraphPlotly";
import PaginationSelector from "../common/PaginationSelector";

export class ConvergenceDashboard extends PlotlyDashboard {

  /** A relaxation and convergence data plotter
   *
   * @param parent {HTMLElement}
   * @param uiClass {string}
   * @param isRelaxation {boolean}
\   */
  constructor(parent, uiClass, isRelaxation) {
    super(parent, uiClass);
    this.setHTML(`
      <div class="relaxation-graph-ph" style="padding-bottom: 20px"></div>
      <div class="convergence-graph-ph" ></div>
    `)
    this.isRelaxation = isRelaxation
    this.stepSelector = undefined

    // Relaxation graph
    if (isRelaxation && !this.relaxationGraph) // Print this graph only if relaxation
        this.relaxationGraph = new RelaxationGraph(this.getElement('.relaxation-graph-ph'))
    // Convergence graph
    if (!this.convergenceGraph)
      this.convergenceGraph = new ConvergenceGraph(this.getElement('.convergence-graph-ph'))
    this.stepSelector = new PaginationSelector('Relaxation step', 1)
    this.e.insertBefore(this.stepSelector.e, this.convergenceGraph.parentElement)
    this.stepSelector.setPrevListener(s => this.plotIterData(s))
    this.stepSelector.setNextListener(s => this.plotIterData(s))
  }

  /** Plots data on the dashboard
   * @param convergenceData
   * @param relaxationData
   */
  plotData(convergenceData, relaxationData=undefined) {
    // turn on the pagination selector
    // console.log(relaxationData)
    this.stepSelector.e.style.display = convergenceData.length > 1 ? 'block' : 'none'
    if (convergenceData.length > 1)
      this.stepSelector.init(convergenceData.length)

    this.convergenceData = convergenceData
    this.relaxationData = relaxationData

    // clear the graphs
    if (this.isRelaxation && this.relaxationData !== undefined) {
      this.plotRelaxData()
    }
    // draw convergence data
    this.plotIterData(1)
  }

  /**
   * Sets and draws the data of an iteration
   * @param {number} iter
   */
  plotIterData(iter) {
    // begin the number from 0
    iter -= 1
    console.log(this.convergenceData)
    let activeGroup = this.convergenceGraph.isDrawn ? this.convergenceGraph.getDropdownGroup() : 'Change of Charge Density'
    this.convergenceGraph.clear()
    let stepData = this.convergenceData[iter]
    for (const name in stepData) {
      const datum = stepData[name]
      const x = [...Array(datum.data.length).keys()]
      const y = datum.data.map(el => Math.abs(el))
      if (!this.convergenceGraph.hasLineGroup(datum.label))
        this.convergenceGraph.addLineGroup(datum.label, {
          defaultColor: datum.color,
          yTitle: this.convergenceGraph.Y_AXIS_LABEL[name]})
      this.convergenceGraph.setLineData([x, y], datum.label)
    }
    this.convergenceGraph.draw(true, activeGroup)
  }

  plotRelaxData() {
    this.relaxationGraph.clear()
    delete this.relaxationData.labels
    for (const name in this.relaxationData) {
      const datum = this.relaxationData[name]
      const x = [...Array(datum.data.length).keys()]
      const y = datum.data
      if (!this.relaxationGraph.hasLineGroup(datum.label))
        this.relaxationGraph.addLineGroup(datum.label, {
          defaultColor: datum.borderColor,
          yTitle: this.relaxationGraph.Y_AXIS_LABEL[name]})
      this.relaxationGraph.setLineData([x, y], datum.label)
    }
    this.relaxationGraph.draw(true, 'Maximum Force Component')
  }
}

class RelaxationGraph extends InteractiveGraph {
  Y_AXIS_LABEL = {
    forces: 'Maximum force component (eV/Å)',
    energy: 'Change of total energy (eV)',
  }
  constructor(element) {
    const layout = {
      title: 'Relaxation',
      xaxis: {
        title: 'Number of iterations',
        rangemode: 'tozero'
      },
      yaxis: {
        type: 'log',
        showexponent: 'all',
        exponentformat: 'power',
        autorange: true
      }
    }
    super(element, undefined, undefined, layout);
    this.NUMBER_OF_POINTS_IN_SEGMENT = 0
  }
}

class ConvergenceGraph extends InteractiveGraph {
  Y_AXIS_LABEL = {
    eigenvalues: 'Change of sum of eigenvalues (eV)',
    totalEnergy: 'Change of total energy (eV)',
    chargeDensity: `Change of charge density (Bohr<sup>-3</sup>)`,
    chargeDensityUp: `Change of charge density (Bohr<sup>-3</sup>)`,
    chargeDensityDown: `Change of spin density (Bohr<sup>-3</sup>)`,
  }

  constructor(element) {
    const layout = {
      xaxis: {
        title: 'Number of iterations',
        rangemode: 'tozero'
      },
      yaxis: {
        type: 'log',
        showexponent: 'all',
        exponentformat: 'power',
        autorange: true
      }
    }
    super(element, undefined, undefined, layout);
    this.NUMBER_OF_POINTS_IN_SEGMENT = 0
  }
}