/**
 * @author Andrey Sobolev
 *
 * @fileOverview File holding the SurfaceConstructionField UI component
*/

import UIComponent from '../common/UIComponent.js'
import helpIcon from '../../img/help-icon.png'
import {floatInputValidator, floatValidator, intInputValidator, intValidator} from "../common/util";


 /**
  * A field for constructing a slab from the bulk. For that we need: 
  * 1. Miller indices of the surface; 
  * 2. the number of atomic layers in the slab; 
  * 3. the thickness of vacuum layer around the slab. 
  */
export default class SurfaceConstructionField extends UIComponent{
 
  constructor(){

    super('span', '.SurfaceConstructionField')

    this.setHTML(`
      <div class="surface-input-div">Miller indices:
        <input type="text" class="miller" />
        <input type="text" class="miller" />
        <input type="text" class="miller" />
      </div>
      <div class="surface-input-div">Number of atomic layers:
        <input type="text" class="number-of-layers" />
      </div>
      <div class="surface-input-div">Vacuum thickness:
        <input type="text" class="vacuum-layer" /> Å  
        <img class="help-button" src="${helpIcon}" width="16" alt="Help"/>
        <div class="button-explanation">
          In FHI-aims you may easily use a relatively large vacuum thickness of 50 Å or more without a noticeable 
          performance impact. 
        </div>
      </div>
      <div class="create-button-div">
       <button class="create-button">Create Slab</button>
      </div>
      <div class="termination-div" style="display:none">
        <div class="surface-input-div">Top layer:
          <select id="top-layer">
          </select>
        </div>
        <div class="surface-input-div">Bottom layer:
          <select id="bottom-layer">
          </select>
        </div>
        <div class="terminate-button-div">
          <button class="terminate-button">Terminate Slab</button>
        </div>
      </div>

    `)
  
    this.miller = this.getElements('input.miller')
    this.miller.forEach( f => f.addEventListener('input', intInputValidator) )
    this.miller.forEach( f => f.addEventListener('blur', intValidator) )
    this.layers = this.getElement('input.number-of-layers')
    this.layers.addEventListener('input', intInputValidator)
    this.layers.addEventListener('blur', intValidator)
    this.vacuum = this.getElement('input.vacuum-layer')
    this.vacuum.addEventListener('input', floatInputValidator)
    this.vacuum.addEventListener('blur', floatValidator)
    this.top_select = this.getElement('#top-layer')
    this.bottom_select = this.getElement('#bottom-layer')
  }

  showTermination(fileName){
    let f = this.getElement('div.termination-div')
    if (f.style.display === 'none' && fileName.includes('slab')) {
      f.setAttribute("style", "")
    } else {
      f.style.display = 'none'
    }
  }

   /**
    * Populates slab termination boxes
    * @param terminations {(string|number)[][]} an array with terminations
    */
  populateTerminationBoxes(terminations){
    const n = terminations.length
    getUniqueTerminations(terminations).forEach( (el) => this.bottom_select.add(new Option(el[0], el[1])))
    getUniqueTerminations(terminations.slice().reverse()).forEach( (el) => this.top_select.add(new Option(el[0], n-1-el[1])))
    function getUniqueTerminations(terms){
      let layer_idxs = []
      let res = []
      terms.forEach( (el, idx) => {
        if (!(layer_idxs.includes(el[1]))){
          layer_idxs.push(el[1])
          res.push([el[0], idx])
      }})
      return res
    }    
  }    

  /**
  * Returns the surface creation data
  * @returns {Object} A dict containing the data needed to create the surface 
  */
  getCreationValues(){
    let res = {}
    let m_idx = []
    this.miller.forEach(f => {
      if (f.value === '') {
        m_idx.push(0)
      } else {
        m_idx.push(parseInt(f.value))
      }
    })
    res["miller"] = m_idx
    res["layers"] = this.layers.value !== "" ? parseInt(this.layers.value) : 0
    res["vacuum"] = this.vacuum.value !== "" ? parseInt(this.vacuum.value) : 0
    return res
   }
 
   getTermValues(){
    return [this.bottom_select.value, this.top_select.value] 
   }
 
   /**
    * Clears the field values
    */
   clearValues(){
    this.miller.forEach(f => f.value = "")
    this.layers.value = ""
    this.vacuum.value = ""
    const L = this.bottom_select.options.length - 1;
    for (let i = L; i >= 0; i--) {
      this.bottom_select.remove(i);
      this.top_select.remove(i)
    }
   }
 }
 