/**
 * @author Iker Hurtado
 *
 * @overview File holding the InfoCanvas layer
 */

import {Conf} from '../Conf.js'
import Canvas from '../common/Canvas.js'
// import ColorPicker from '../../lib/vanilla-picker.js'
import refreshIcon from '../../img/refresh-icon.png'
import {arrayToColors} from "../output-analyzer-mod/util";


/**
 * StructureViewer 2D layer showing 3D structure contextual information 
 */
export default class InfoCanvas{

  /**
   * @param  {HTMLElement}  hostElement HTML element hosting the canvas
   * @param  {boolean} editable StructureViewer mode view 
   *   editable/advantage (false) or simple (true)
   * @param  {State} moduleState 
   *   Object representing the state of the Structure builder module
   */
  constructor(hostElement, editable, moduleState){
      this.hostElement = hostElement
      this.editable = editable

      this.c = new Canvas(this.hostElement, '.info-canvas')
      this.canvas = this.c.canvas

      // Legend object
      this.legend = new AtomLegend(this.c, this.editable, moduleState)

      // Selected atom info area
      this.c.addGroup('atomInfo')
      // Distance, Angle, Torsion info area
      this.c.addGroup('datInfo')

    }


    /**
     * Returns the position of the canvas element
     * @return {object} X and Y coordinates of the canvas
     */
    getCanvasPosition(){
        return this.c.getPosition()
    }


    /**
     * Updates the vertical scroll figure
     * @param {int} scrollY - Current vertical scroll
     */
    adaptToScroll(scrollY){
        this.c.adaptToScroll(scrollY)
    }


    /**
     * Clears the canvas 
     */
    clear(){
      this.c.clearCanvas() 
    }


    /**
     * Generates the structure atoms legend 
     * @param  {Array<object>} atoms
     */
    createAtomsLegend(atoms){
      this.legend.setAtomsData(atoms)
    }


    /**
     * Shows the basic atom information
     * @param  {int} i Atom index
     * @param  {string} species 
     * @param  {Array<number>} coors
     */
    showAtomInfo(i, species, coors){
        //console.log('showAtomInfo',pos)
      const x = this.canvas.width- 280 //this.canvas.width/2// this.canvas.width- 420
      const y = 36

      this.clearSelectedAtomArea()

      const style = { color: Conf.getSpeciesColor(species), strokeColor: '#444'}
      this.c.addElement('circle', {x: x+10, y: y-7, r: 8, style: style}, 'atomInfo')

      const coorsText = coors[0].toFixed(5)+' '+coors[1].toFixed(5)+' '+coors[2].toFixed(5)
      const font = '14px Roboto'
      const text = this.c.addElement('text', { text: '#'+(i+1)+' atom  '+coorsText+'  '+species, x: x+25, y: y, style: {font: font} } , 'atomInfo')//addText( { text: '#'+(i+1)+' atom  '+coorsText+'  '+species, x: x+30, y: y-3, font: font } , 'atomInfo')
      text.style.color = '#444'
      this.c.clearAndDrawAll()
    }


    /**
     * Clears the canvas area showing the atom information
     */
    clearSelectedAtomArea(){
      this.c.removeGroupElements('atomInfo')
      this.c.clearAndDrawAll()
    }


    /**
     * Shows the Distance, Angle, Torsion info 
     * @param  {string} info
     */
    showDATAtomsInfo(info){
      //console.log('showDATAtomsInfo',pos)
      const x = this.canvas.width - 280
      const y = 58

      this.clearDATAtomsArea()
       
      const font = (info.startsWith('Torsion angle') ? 11 : 12)+'px Roboto'
      const text = this.c.addElement('text', { text: info, x: x+20, y: y-4, style: {font: font} } , 'datInfo')
      text.style.color = '#444'
      this.c.clearAndDrawAll()
    }


    /**
     * Clears the Distance, Angle, Torsion info area
     */
    clearDATAtomsArea(){
      this.c.removeGroupElements('datInfo')
      this.c.clearAndDrawAll()
    }

}



/**
 * Structure atom legend UI layer
 */
class AtomLegend{

  /**
   * @param  {Canvas}  canvas
   * @param  {boolean}  editable StructureViewer mode
   * @param  {State} moduleState 
   *   Object representing the state of the Structure builder module
   */
  constructor(canvas, editable, moduleState){
    this.moduleState = moduleState

    this.canvas = canvas
    this.editable = editable
    this.legendSpecies = undefined// Map (species, { canvasElement, initColor})
    this.LEGEND_INFO_AREA = {x: 0, y: (editable ? 100 : 200), w: 200, h: 500}
    this.canvas.addGroup('legend')

    this.currentSpeciesCircle = undefined
    this.currentTooltip = undefined

    if (!editable){ // Editable mode
      this.canvas.setCursorOnElement('pointer')
      this.colorInput = document.createElement('input')

      // TODO: remove or maintain this change
      this.colorInput.style.display = 'none'
      this.canvas.canvas.appendChild(this.colorInput)
      
      this.colorInput.type = 'color'
      this.colorInput.addEventListener('input', e => {
        this.currentSpeciesCircle.style.color = e.target.value // input color code
        this.canvas.clearAndDrawAll()
        this.moduleState.changeSpeciesColor(this.currentSpeciesCircle.species, e.target.value)
      })

      this.canvas.setEventListener(this.canvas.ENTER_ELEMENT, element => {
        const X_MARGIN = this.editable ? 10 : 20
        const props = { x: X_MARGIN-10, y: this.LEGEND_INFO_AREA.y, style: {font: '12px Roboto'} }
        if (element.type === 'circle') props.text = 'Click to change color'
        else props.text = 'Reset the species colors' // reset button
        this.currentTooltip = this.canvas.addElement('text', props, 'legend') 
      })

      this.canvas.setEventListener(this.canvas.LEAVE_ELEMENT, _ => {
        this.canvas.removeElement(this.currentTooltip, 'legend')
      })

      this.canvas.setEventListener(this.canvas.CLICK_ON_ELEMENT, element => {
        if (element.type === 'circle'){
          this.colorInput.value = element.style.color
          // log('Circle event',element.style.color, this.colorInput.parentElement )
          this.colorInput.dispatchEvent(new MouseEvent('click'))
          this.currentSpeciesCircle = element
        }else{ // reset button
          this.legendSpecies.forEach( (data, species) =>{
            data.element.style.color = data.initColor
            this.moduleState.changeSpeciesColor(species, data.initColor)
            this.canvas.clearAndDrawAll()
          })
        }
      })
    }
    
  }


  /**
   * Sets the atoms information
   * @param atoms {Array<object>}
   */
  setAtomsData(atoms){

    this.legendSpecies = new Map()
    atoms.forEach( atom => {
      if (atom !== undefined && !this.legendSpecies.has(atom.species)) 
        this.legendSpecies.set(atom.species, {element: undefined, initColor: undefined} )
    })
    
    const Y_MARGIN = this.LEGEND_INFO_AREA.y
    const Y_SEPARATION = this.editable ? 21 : 27
    const X_MARGIN = this.editable ? 10 : 20
    const CIRCLE_RADIUS = this.editable ? 7 : 10 
    
    this.canvas.removeGroupElements('legend') // Reset the previous elements

    let i = 0
    this.legendSpecies.forEach( (data, species) =>{
      const style = { color: Conf.getSpeciesColor(species), strokeColor: '#444'}
      let circle = this.canvas.addElement('circle', {x: X_MARGIN, y: Y_MARGIN + Y_SEPARATION*i+24, r: CIRCLE_RADIUS, style: style }, 'legend')
      circle.species = species // adhoc property
      data.initColor = circle.style.color
      data.element = circle

      const font = (this.editable ? '15px' : '18px')+' Roboto'
      const xValue = X_MARGIN+(this.editable ? 16 : 20)
      const yValue = Y_MARGIN + Y_SEPARATION*i+27
      const text = this.canvas.addElement('text', { text: species, x: xValue, y: yValue,  style: {font: font, baseline: 'middle'} } , 'legend')
      text.style.color = '#444'
      i++
    })

    if (!this.editable){
      const BUTTON_WIDTH = 25
      const yValue = Y_MARGIN + Y_SEPARATION*this.legendSpecies.size+30-BUTTON_WIDTH/2
      const xValue = X_MARGIN - BUTTON_WIDTH/2 + 12

      const myImage = new Image(); myImage.src = refreshIcon
      myImage.addEventListener('load', _ => {
        this.canvas.addElement('image', { img: myImage, x: xValue, y: yValue, w: BUTTON_WIDTH, h: BUTTON_WIDTH }, 'legend')
        this.canvas.clearAndDrawAll()
      })
    }
     
    this.canvas.clearAndDrawAll()
  }

  /**
   * Sets a color bar for the legend
   * @param values {number[]} an array of values
   * @param aroundZero {boolean} if 0. lies in the middle of color bar
   */
  setColorBar(values, aroundZero) {
    const Y_MARGIN = this.LEGEND_INFO_AREA.y
    const Y_SEPARATION = 24
    const X_MARGIN = 20
    const SQUARE_SIDE = 14

    this.canvas.removeGroupElements('legend') // Reset the previous elements
    const absMax = Math.max(...values.map(x => Math.abs(x)))
    const lo = aroundZero ? -absMax : Math.min(...values)
    const hi = aroundZero ? absMax : Math.max(...values)
    let nDigits = Math.ceil(-Math.log10(hi - lo)) + 1
    if (nDigits < 0) nDigits = 0
    let colorBarValues = [0., 0.1, 0.3, 0.5, 0.7, 0.9, 1.]
    colorBarValues = colorBarValues.map((x, i) =>
      (i !== 0 && i !== colorBarValues.length - 1) ? +(lo + x * (hi - lo)).toFixed(nDigits) : lo + x * (hi - lo))

    const colors = arrayToColors(colorBarValues, true)
    for (let i = 0; i < colors.length; i++) {
      const style = { color: colors[i], strokeColor: '#444'}
      this.canvas.addElement('rectangle',
        {x: X_MARGIN, y: Y_MARGIN + Y_SEPARATION*i, w: SQUARE_SIDE, h: SQUARE_SIDE, style: style }, 'legend')
      const font = '14px Roboto'
      const xValue = X_MARGIN + 20
      const yValue = Y_MARGIN + Y_SEPARATION*i+7
      const text = this.canvas.addElement('text',
        { text: colorBarValues[i], x: xValue, y: yValue,  style: {font: font, baseline: 'middle'} } , 'legend')
      text.style.color = '#444'
    }
    this.canvas.clearAndDrawAll()
  }

}


