/**
 * @author Iker Hurtado
 *
 * @overview Workflow configuration file
 */

// Predefined workflow steps

const StructureBuilderStep = {
  	title: 'Create or upload geometry',
  	nextButtonText: 'Save geometry & ',
   	component: 'StructureBuilder',
}

const ControlGeneratorStep = {
  	title: 'Specify calculation control parameters',
    nextButtonText: 'Save control info & ',
    component: 'ControlGenerator:default',
}

const ControlGeneratorBSDOSStep = {
    title: 'Specify calculation control parameters',
    nextButtonText: 'Save control info & ',
    component: 'ControlGenerator:BandStructure',
}

const ControlGeneratorGWStep = {
    title: 'Specify calculation control parameters',
    nextButtonText: 'Save control info & ',
    component: 'ControlGenerator:GW',
}

const ControlGeneratorMDStep = {
    title: 'Specify calculation control parameters',
    nextButtonText: 'Save control info & ',
    component: 'ControlGenerator:MD',
}

const WorkflowDesignerStep = {
    title: 'Design a workflow',
    nextButtonText: 'Save a workflow & ',
    component: 'WorkflowDesigner',
}

const DownloadInputFilesPageStep = {
  	title: 'Download the input files and start your calculation',
    nextButtonText: '',
    component: 'DownloadInputFilesPage',
}

const OutputAnalyzerStep = {
  	title: 'Upload the output file',
    //  nextButtonText - No next button
    component: 'OutputAnalyzer',
}

// Workflows configuration

export const workflows = {

  SimpleCalculation: {
    text: 'Simple Calculation',
    steps: [
      StructureBuilderStep,
      ControlGeneratorStep,
      DownloadInputFilesPageStep,
      OutputAnalyzerStep
    ]
  },
  BandStructure: {
    text: 'Band Structure',
    steps: [
      StructureBuilderStep,
      ControlGeneratorBSDOSStep,
      DownloadInputFilesPageStep,
      OutputAnalyzerStep
    ]
  },
  GWCalculation: {
    text: 'GW Calculation',
    steps: [
      StructureBuilderStep,
      ControlGeneratorGWStep,
      DownloadInputFilesPageStep,
      OutputAnalyzerStep
    ]
  },

  MDCalculation: {
    text: 'MD Calculation',
    steps: [
      StructureBuilderStep,
      ControlGeneratorMDStep,
      DownloadInputFilesPageStep,
      OutputAnalyzerStep
    ]
  },

  Designed: {
    text: 'Designed',
    steps: [
      WorkflowDesignerStep,
      DownloadInputFilesPageStep,
      OutputAnalyzerStep
    ]
  },
}
