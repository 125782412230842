
/**
 * @author Iker Hurtado, Sebastian Kokott
 *
 * @overview This is a basic math library.
 * It's coupled with the Three.js library in order to carry out
 * some geometry operations
 */


/**
 * Adds two vectors and returns a new one
 * @param {number[]} p1
 * @param {number[]} p2
 * @return {number[]}
 */
export function add(p1, p2){
    return [ p2[0]+p1[0], p2[1]+p1[1], p2[2]+p1[2] ]
}


/**
 * Adds up a vector (the second parameter) to first one and returns it
 * @param {number[]} p1
 * @param {number[]} p2
 * @return {number[]} Returns p1
 */
export function addUp(p1, p2){
    p1[0] += p2[0]
    p1[1] += p2[1]
    p1[2] += p2[2]
    return p1
}


/**
 * Subtracts the second vector from the first one and returns a new one
 * @param {number[]} p1
 * @param {number[]} p2
 * @return {number[]}
 */
export function subtract(p2, p1){
	return [ p2[0]-p1[0], p2[1]-p1[1], p2[2]-p1[2] ]
}


/**
 * Multiplies a vector by a scalar number and returns a new vector
 * @param {number[]} vector
 * @param {number} scalar
 * @return {number[]}
 */
export function multiplyScalar(vector, scalar){
	return [ vector[0]*scalar, vector[1]*scalar, vector[2]*scalar ]
}


/**
 * Divides a vector by a scalar number and returns a new vector
 * @param {number[]} vector
 * @param {number} scalar
 * @return {number[]}
 */
export function divideScalar(vector, scalar){
    return [ vector[0]/scalar, vector[1]/scalar, vector[2]/scalar ]
}


/**
 * Returns the distance between two points
 * @param {number[]} p1
 * @param {number[]} p2
 * @return {number}
 */
export function getDistance(p1, p2){
	return Math.sqrt( (p1[0]-p2[0])**2 + (p1[1]-p2[1])**2 + (p1[2]-p2[2])**2)
}


/** returns vector length
 * @param v {number[]} a 3D vector
 * @return {number} the length of the vector
 */
function norm (v) {
  return Math.sqrt(v[0] ** 2 + v[1] ** 2 + v[2] ** 2)
}

/** returns dot product of two 3D vectors
 * @param x {number[]} first vector
 * @param y {number[]} second vector
 * @return {number} dot product
 */
function cdot (x, y) {
  return x[0] * y[0] + x[1] * y[1] + x[2] * y[2]
}

/** returns cross product of two 3D vectors
 * @param a {number[]} first vector
 * @param b {number[]} second vector
 * @returns {number[]} cross product
 */
function cross (a, b) {
  return [a[1]*b[2] - a[2]*b[1], a[2]*b[0] - a[0]*b[2], a[0]*b[1] - a[1]*b[0]]
}

/** returns angle between two 3D vectors
 * @param x {number[]} first vector
 * @param y {number[]} second vector
 * @return {number} angle in radians
 */
function angle (x, y) {
  return Math.acos(cdot(x, y) / (norm(x) * norm(y)))
}

/** Normalizes 3D vector v
 *
 * @param v {number[]} vector
 * @returns {number[]} normalized vector
 */
function normalize(v) {
  const lenV = norm(v)
  return [v[0]/lenV, v[1]/lenV, v[2]/lenV]
}

/**
 * Returns the angle made up of three points
 * @param {number[]} p1
 * @param {number[]} p2
 * @param {number[]} p3
 * @return {number}
 */
export function getAngle(p1, p2, p3){
  let p12v = subtract(p1, p2)
  let p32v = subtract(p3, p2)
  return (angle(p12v, p32v)*180)/Math.PI
}

/**
 * Returns the torsion angle comprised of four points using praxeolitic formula from
 * https://stackoverflow.com/questions/20305272/dihedral-torsion-angle-from-four-points-in-cartesian-coordinates-in-python
 * @param {number[]} p0
 * @param {number[]} p1
 * @param {number[]} p2
 * @param {number[]} p3
 * @return {number}
 */
export function getTorsionAngle(p0, p1, p2, p3){
  const b0 = subtract(p0, p1)
  let b1 = subtract(p2, p1)
  const b2 = subtract(p3, p2)
  b1 = normalize(b1)
  const v = subtract(b0, multiplyScalar(b1, cdot(b0, b1)))
  const w = subtract(b2, multiplyScalar(b1, cdot(b2, b1)))

  const x = cdot(v, w)
  const y = cdot(cross(b1, v), w)
  return Math.atan2(y, x)*180/Math.PI
}


/**
 * Determines minimum or maximum (op=Math.min or =Math.max, respectively) of
 * array along certain axis.
 * @param  {number[][]} mat Matrix
 * @param  {int} axis
 * @param  {function} op Operation minimum or maximum
 * @return {[]}
 */
export function minmax(mat,axis,op){
    //
    let extr = []
    let [m,n] = getDim(mat)
    let l,matrix
    if (axis === 0){
        matrix = mat[0].map((col, i) => mat.map(row => row[i])) //Transpose
        l = n
    }else {
        matrix = mat
        l = m}
    for (let i=0; i<l;i++){
        extr.push(op(...matrix[i]))
    }
    return extr
}


/**
 * Multiplies (dot) two matrices. Needs final array initialized (Much better performance).
 * @param {number[][]} A
 * @param {number[][]} B
 * @param {number[][]} C Initialized matrix
 * @return {number[][]}
 */
export function matrixDot(A,B,C){
    for (let i in A) {
        for (let j in B) {
            let sum = 0
            for (let k in A[0]) {
                sum += A[i][k]*B[k][j]
            }
            C[i][j] = sum
        }
    }
    return C
}

/**
 * Not yet full tensor dot. Accepts vector B and matrix A.(Sebastian comment)
 * @param  {number[][]} A
 * @param  {number[][]} B
 * @return {number[][]}
 */
export function tensorDot(A,B){
    //
    let C = []
    for (let i=0;i<A.length;i++){
        for (let j=0;j<B.length;j++){
            C.push(multiplyScalar(B[j], A[i][0]))
        }
    }
    return C
}


/**
 * Returns the dimensions of a 2D matrix. If flat, returns 0 as first dimension.
 * @param {number[][]} mat
 * @return {number[]}
 */
function getDim(mat) {
    if (mat[0].length !== undefined){
        return [mat.length,mat[0].length]}
    else {
        return [0, mat.length] }
}


/**
 * Adds the values of two arrays and returns them in a new array
 * @param {number[]} arr1
 * @param {number[]} arr2
 * @return {number[]}
 */
export function addArrays(arr1,arr2){
    // Add two arrays.
    let arr = arr1.slice()
    for (let i in arr1) {
        arr[i] = arr1[i] +arr2[i]
    }
    return arr
}


export function determinant33(m){
  return m[0][0]*m[1][1]*m[2][2] +
         m[0][1]*m[1][2]*m[2][0] +
         m[0][2]*m[1][0]*m[2][1] -
         m[0][0]*m[1][2]*m[2][1] -
         m[0][1]*m[1][0]*m[2][2] -
         m[0][2]*m[1][1]*m[2][0]
}


export function invert33(m){
  const n11 = m[0][0], n21 = m[1][0], n31 = m[2][0],
        n12 = m[0][1], n22 = m[1][1], n32 = m[2][1],
        n13 = m[0][2], n23 = m[1][2], n33 = m[2][2],

      t11 = n33 * n22 - n32 * n23,
      t12 = n32 * n13 - n33 * n12,
      t13 = n23 * n12 - n22 * n13,

      det = n11 * t11 + n21 * t12 + n31 * t13;

  if (det === 0) {
    console.error( "invert3b3(m): det(m) == 0. m cannot be inverted!")
    return undefined
  }
  const detInv = 1 / det
  let i =[[0,0,0],[0,0,0],[0,0,0]];
  i[0][0] = t11 * detInv;
  i[1][0] = ( n31 * n23 - n33 * n21 ) * detInv;
  i[2][0] = ( n32 * n21 - n31 * n22 ) * detInv;

  i[0][1] = t12 * detInv;
  i[1][1] = ( n33 * n11 - n31 * n13 ) * detInv;
  i[2][1] = ( n31 * n12 - n32 * n11 ) * detInv;

  i[0][2] = t13 * detInv;
  i[1][2] = ( n21 * n13 - n23 * n11 ) * detInv;
  i[2][2] = ( n22 * n11 - n21 * n12 ) * detInv;

  return i
}


export function multiplyM33V3(m,v) {
  return [
    m[0][0]*v[0]+m[0][1]*v[1]+m[0][2]*v[2],
    m[1][0]*v[0]+m[1][1]*v[1]+m[1][2]*v[2],
    m[2][0]*v[0]+m[2][1]*v[1]+m[2][2]*v[2],
  ]
}


export function transpose33(m){
  return [
    [m[0][0],m[1][0],m[2][0]],
    [m[0][1],m[1][1],m[2][1]],
    [m[0][2],m[1][2],m[2][2]]
  ]
}


export function solve33(m,v) {
  const i = transpose33(invert33(m))
  return multiplyM33V3(i,v)

}
