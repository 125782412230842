/**
 * @author Iker Hurtado
 *
 * @fileOverview File holding the BasisAtoms UI component
 */


import {Conf} from '../Conf.js'
import UIComponent from '../common/UIComponent.js'
import CoordinatesField from './CoordinatesField.js'
import binIcon from '../../img/binicon.png'
import prevArrowIcon from '../../img/prev-arrow-white-icon.png'
import nextArrowIcon from '../../img/next-arrow-white-icon.png'
import {CHANGE} from "../State";

const ROW_NUMBER = 10


/**
 * A UI panel showing the list of atoms comprising a structure
 */
export default class BasisAtoms extends UIComponent{

	/**
	 * @param  {State} moduleState
	 *   Object representing the state of the Structure builder module
	 */
	constructor(moduleState){

		super('div', '#BasisAtoms')

		// component state
		this.page = 1
		this.pagesNum = 1
		this.moduleState = moduleState

    	this.setHTML(`

    		<div id="atom-selector">
    			<button class="prev-page">
    			  <img src="${prevArrowIcon}" height="16px" style="vertical-align: middle;" alt="Prev page">
    			</button>
    			<span class="current-page-label" style="font-size: 1.2em; vertical-align: middle;"></span>
    			<button class="next-page">
    			  <img src="${nextArrowIcon}" height="16px" style="vertical-align: middle;" alt="Next page">
    			</button>
    			<!--<button class="next-page"> &gt; </button> -->
    		</div>
				<div id="NewRowButton" style="display:flex; justify-content:center">
					<div id="coordinates-switch">
						<input type="checkbox" >Fractional coordinates
					</div>
					<div style="display:inline-block; text-align:center">
						<button id="new-atom-button">New atom</button>
						<button id="constrain-button">Constrain all atoms</button>
					</div>
				</div>
				<div class="" style="margin-left:30px; font-size:14px;font-weight:lighter">Click on the species color to specify more atomic properties (e.g. initial moment).<br></div>
    		<div class="div-table AtomsBlock">
					<div class="div-table-row header">
						<div class="div-table-col atom-order">&nbsp;</div>
						<div class="div-table-col vector">All numbers in units of Å</div>
						<div class="div-table-col species">&nbsp;</div>
						<div class="div-table-col options">Options</div>
					</div>
				</div>
    	`)

    	const prevButton = this.getElement('button.prev-page')
    	const nextButton = this.getElement('button.next-page')
    	prevButton.addEventListener( 'click', _ => {
    		if (this.page > 1){
    			this.page--
    			this.update()
    		}
    	})
    	nextButton.addEventListener( 'click', _ => {
    		let atoms = this.moduleState.getStructure().atoms
    		if (atoms.length >= this.page*10+1){
    			this.page++
    			this.update()
    		}
    	})

    	this.currentPageLabel = this.getElement('.current-page-label')
			this.units = this.getElement('.header .vector')
    	this.fractCheckbox = this.getElement('#coordinates-switch input')
    	this.fractCheckbox.addEventListener('click', e => {
    		this.moduleState.setFractional(e.target.checked)
				this.units.innerHTML = e.target.checked ? "&nbsp;" : "All numbers in units of Å"
    	})

    	this.button = this.getElement('#new-atom-button')
    	this.button.addEventListener('click', _ => {
    		this.moduleState.addUndefinedAtom()
    		this.page = this.pagesNum
    		this.update()
    	})
			this.button = this.getElement('#constrain-button')
    	this.button.addEventListener('click', _ => {
				const nAtoms = this.moduleState.getStructure().atoms.length
				const isConstrained = this.button.textContent.includes("Release")
				let change = this.moduleState.change()
				for (let i=0; i < nAtoms; i++)
					if (this.moduleState.structure.atoms[i].constraint === isConstrained)
						change.addStep(CHANGE.STRUCTURE.ATOM.CONSTRAINT, i, [isConstrained, !isConstrained])
    		change.do()
				this.button.textContent = (isConstrained ? "Constrain" : "Release") + " all atoms"
				this.update()
    	})

    	this.atomRows = []
    	this.tooltip = new AtomRowTooltip(this.moduleState) // hidden initially
	    this.tooltip.e.style.visibility = 'hidden'

    	const atomsBlockEl = this.getElement('.AtomsBlock')
	    for (let i = 0; i < ROW_NUMBER; i++) {
	    	const row = new AtomRow(this.tooltip, this.moduleState)
	    	this.atomRows.push(row)
	      	atomsBlockEl.appendChild(row.e)
	      	row.e.addEventListener('mouseenter', _ => {
	    			this.moduleState.highlightAtom((this.page-1)*ROW_NUMBER+i)
	    		})
	      	row.e.addEventListener('mouseleave', _ => {
	    			this.moduleState.highlightAtom((this.page-1)*ROW_NUMBER+i, false)
	    		})
	    }
	}

	 /**
	 * Updates the component to a new structure
	 * Listener for the 'new structure' module level event
	 * @param {Structure} structure
	 */
	 setNewStructure(structure){
	    this.page = 1
	    this.updateStructure(structure)
  	}

		selectStructure(structure){
	    this.setNewStructure(structure)
	  }

    /**
	 * Updates the component to a structure change
	 * Listener for the 'update structure' module level event
	 * @param {Structure} _structure
	 * @param {object} _change
	 */
  	updateStructure(_structure, _change = undefined){
  		// console.log('BasisAtoms.updateStructure', change)
	    let atoms = this.moduleState.getStructure().atoms

	    this.pagesNum = Math.ceil(atoms.length/ROW_NUMBER)

	    // When the first atmos is created the page has to be set to 1
	    if (this.pagesNum > 0 && this.page === 0 ) this.page = 1
	    // keep the this.page state if it's not bigger than the page number (the number of atoms could change)
	    if (this.page > this.pagesNum) this.page = this.pagesNum

	    this.update()
	 }

    /**
	 * Updates the component to a fractional change in the module
	 * Listener for the 'update fractional' module level event
	 * @param {boolean} _fractional
	 */
  	updateFractional(_fractional){
  		this.update()
  	}

  	/**
	 * Updates the component to an atom species change
	 * Listener for the 'change species' module level event
	 * @param {string} _species
	 * @param {string} _color Color code
	 */
  	changeSpeciesColor(_species, _color){
  		this.update()
  	}

  	/**
  	 * Updates the component looking at the data model
  	 */
  	update(){

  		this.getElement('#atom-selector').style.display = (this.pagesNum > 1 ? 'block' : 'none')
  		this.currentPageLabel.innerHTML = this.page+'/'+this.pagesNum

  		const periodic = this.moduleState.getStructure().isAPeriodicSystem()
  		this.getElement('#coordinates-switch').style.display = (periodic ? 'flex': 'none')

  		this.fractCheckbox.checked = this.moduleState.getFractional()

  		let atoms = this.moduleState.getStructure().atoms

  		if (atoms.length === 0){ // exceptional case
  			for (let i = 0; i < ROW_NUMBER; i++)
	    		this.atomRows[i].e.style.display = 'none'
	    	return
  		}

	    const initIndex = (this.page-1)*ROW_NUMBER

  		for (let i = 0; i < ROW_NUMBER; i++) {
	    	let atomsIndex = initIndex+i
	    	if (atomsIndex < atoms.length){
	    		this.atomRows[i].setValues(atomsIndex, atoms[atomsIndex])
	    		this.atomRows[i].e.style.display = 'flex'//visibility = 'visible'
	    	}else this.atomRows[i].e.style.display = 'none'//visibility = 'hidden'
	    }
  	}
}

/**
 * Tooltip showing extra info about the atom. It's editable.
 * The atom constraint and initial moment can be changed
 */
class AtomRowTooltip extends UIComponent{

  /**
	* @param  {State} moduleState
	*   Object representing the state of the Structure builder module
	*/
  constructor(moduleState){

    super('div', '.AtomRowTooltip')

    this.setHTML(`<table>
      <tr> <td style="text-align: center"><input type="checkbox" class="constraint" /> </td><td>Constrain Atom</td></tr>
      <tr> <td style="text-align: center"> <input type="text" class="init-moment" /></td><td>Initial Moment</td></tr>
			<tr> <td style="text-align: center"> <input type="text" class="charge" /></td><td>Initial Charge</td></tr>
      <tr> <td class="close-button" colspan="2" style="text-align: center; font-size: smaller"> CLOSE</td></tr>
	  </table>
    `)

    this.moduleState = moduleState

    this.constraintCB = this.getElement('.constraint')
    this.constraintCB.addEventListener('change', _ => {
      //console.log('constraint',constraint)
      this.moduleState.updateAtomConstraint(this.atomIndex, this.constraintCB.checked)
    })

    this.initMomentField = this.getElement('.init-moment')
    this.initMomentField.addEventListener('change', _ => {
      let initMoment = (this.initMomentField.value === '' ? undefined : this.initMomentField.value)
      this.moduleState.updateAtomInitMoment(this.atomIndex, initMoment)
    })
		
		this.chargeField = this.getElement('.charge')
		this.chargeField.addEventListener('change', _ => {
			let charge = (this.chargeField.value === '' ? undefined : this.chargeField.value)
			this.moduleState.updateAtomCharge(this.atomIndex, charge)
		})

	this.getElement('.close-button').addEventListener('click', _ => {
	  this.e.style.visibility = 'hidden'
	})
  }

  /**
   * Sets the atom index in the structure in order to
   * show/edit it
   * @param {int} index
   */
  setAtomIndex(index){
  	this.atomIndex = index
  }

  // /**
  //  * Returns true if either the constraint or the init moment are filled
  //  * @return {boolean}
  //  */
  // isMomentOrConstraintSet(){
  // 	return (this.initMomentField.value !== '' || this.constraintCB.checked || this.chargeField.value !== '')
  // }

  /**
   * Resets the atom constraint and init moment
   * @param  {string} moment
   * @param  {boolean} constraint
	 * @param  {string} charge
   */
  reset(moment='', constraint=false, charge=''){
  	this.constraintCB.checked = constraint
  	this.initMomentField.value = moment
		this.chargeField.value = charge
  }
}


/**
 * Editable atom row
 */
class AtomRow extends UIComponent{

	/**
	 * @param {AtomRowTooltip} tooltip Row tooltip component
	 * @param  {State} moduleState
	 *   Object representing the state of the Structure builder module
	 */
	constructor(tooltip, moduleState){
    	super('div', '.div-table-row')
	    this.setHTML(`
	      <div class="div-table-col atom-order"></div>
	      <div class="div-table-col vector">
	      	<span class="lattice-vector-wrapper"></span>
				</div>
	      <div class="div-table-col species">
	      	<input type="text" class="species-value" />
		  	</div>
		  	<div class="div-table-col options">
					<span style="position: relative" class="species-circle">
						<canvas width="18" height="19" ></canvas>
					</span>
		  		<img class="img-button" src="${binIcon}" alt="Delete">
		  	</div>
	    `)

	    this.ctx = this.getElement('.species-circle canvas').getContext("2d")

	    this.tooltip = tooltip
	    this.moduleState = moduleState

	    this.coorsField = new CoordinatesField()
	    this.getElement('.lattice-vector-wrapper').appendChild(this.coorsField.e)
	    this.coorsField.setChangeListener(_ => {
	    	this.moduleState.updateAtomPosition(this.atomIndex, this.coorsField.getValues(), undefined)
	    })

	    this.speciesField = this.getElement('.species-value')
	    this.speciesField.addEventListener('change', _ => {
	    	let species = (this.speciesField.value === '' ? undefined : this.speciesField.value)
	    	this.moduleState.updateAtomSpecies(this.atomIndex, species)
	    })

	    this.speciesCircle = this.getElement('.species-circle canvas')
	    this.getElement('.species-circle').appendChild(this.tooltip.e)

	    this.speciesCircle.addEventListener('click', _ => {
	    	this.speciesCircle.parentElement.appendChild(this.tooltip.e)
	    	this.tooltip.e.style.visibility = 'visible'
	    	this.tooltip.reset(this.initMomentValue, this.constraintChecked,this.chargeValue)
	    	this.tooltip.setAtomIndex(this.atomIndex)
	    })

	    this.getElement('.img-button').addEventListener('click', _ => {
		  this.moduleState.removeAtom(this.atomIndex)
		  // The next atom falls into this index and needs to be highlighted (the mouse pointer is on its row)
		  this.moduleState.highlightAtom(this.atomIndex)  // If it was the last no one is highlighted
		})
	}

	/**
	 * Sets the atom values into the row fields
	 * @param {int} index Atom index in the structure
	 * @param {object} atom
	 */
	setValues(index, atom){
		this.atomIndex = index
		this.getElement('.atom-order').textContent = (index+1)+'.'
		this.coorsField.setValues( this.moduleState.getAtomPosition(index))
		this.speciesField.value = (atom.species === undefined ? '' : atom.species)
    this.initMomentValue = (atom.initMoment === undefined ? '' : atom.initMoment.toString())
		this.chargeValue = (atom.charge === undefined ? '' : atom.charge.toString())
    this.constraintChecked = atom.constraint
		this.drawCircle(atom.species, (
			this.constraintChecked
			|| (this.initMomentValue !== '' && this.initMomentValue !== '0')
			|| (this.chargeValue !== '' && this.chargeValue !== '0')
		))
	}

	/**
	 * Draws the circle representing the species
	 * @param  {string} species
	 * @param  {boolean} triangle
	 */
	drawCircle(species, triangle = false){

		this.ctx.clearRect(0, 0, 18, 19)
	    this.ctx.beginPath()
	    if (species === undefined) this.ctx.fillStyle = 'black'
	    else this.ctx.fillStyle = Conf.getSpeciesColor(species)
	    this.ctx.arc(9, 10, 8, 0, 2*Math.PI, true)
	    this.ctx.fill()

	    if ( this.ctx.fillStyle.indexOf('ffff') > 0 ) { // IF clear color outline is drawn
	      this.ctx.lineWidth = 0.5;
	      this.ctx.strokeStyle = "#000";
	      this.ctx.stroke();
	    }

			this.ctx.lineWidth = 1.5;
			this.ctx.strokeStyle = "#777";
			this.ctx.stroke();

	    if (triangle){

	      this.drawTriangle()
	    }
	}

	/**
	 * Draws a triangle
	 */
	drawTriangle(){

		this.ctx.beginPath();
		this.ctx.moveTo(0, 0);
		this.ctx.lineTo(0, 5);
		this.ctx.lineTo(5, 0);
		this.ctx.closePath();

		this.ctx.fillStyle = "#777";
		this.ctx.fill();
	}
}
