/**
 * @author Andrey Sobolev
 *
 * @overview A Workflow Designer configuration
 */


export const configuration = {

	toolbox: {
		groups: [  // is built dynamically from the available steps
		]
	},
	validator: { // is built dynamically
	},
  steps: {
    iconUrlProvider: (componentType, type) => {
      return `${type}-icon.png`;
    },
    canInsertStep: (step, targetSequence, targetIndex) => {
      if (targetIndex === 0
				&& targetSequence.length > 0
				&& targetSequence[0].type === "StructureBuilder") return false
			if (step.type === "StructureBuilder") return targetIndex === 0
			return targetSequence.length < 5;
    },
    canMoveStep: (sourceSequence, step, targetSequence, targetIndex) => {
      if (step.type === "StructureBuilder" && targetIndex !== 0) return false
      if (targetIndex === 0
				&& targetSequence.length > 0
				&& targetSequence[0].type === "StructureBuilder") return false
    },
    // isDeletable: (step, parentSequence) => {
    //   return step.name !== 'Structure Builder';
    // },
  },
	stepEditorContext: {
	},
  editors: {
    globalEditorProvider: (definition, context) => {
      let editor = document.createElement('div');
			editor.classList.add('swd-editor')
			editor.innerHTML = `<h3><i>How to use the designer:</i></h3>
			<li> Drag the calculation steps from the toolbox on the left to the working field. After finishing, click 'Go 
			ahead' to choose the structure and generate the control.in file. As of now, only one structure 
			is supported.
			<li> <b>Note:</b> GW calculations are very computationally expensive! Check the size of your structure before 
			running calculations!
			<li> <b>One more note:</b> The restart of GW from DFT is not yet implemented! All the calculations are independent.`
			return editor
    },
		stepEditorProvider: (_) => document.createElement('div') // are built dynamically
  },
	controlBar: true
};
