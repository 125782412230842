import kGridDensityFormula from '../../img/k_grid_density-formula.png'
import atomPropertiesShot from '../../img/atom_properties_shot.png'


export const Fields_FHIaims = [
  {
    label: 'Basic Settings',
    // info: 'To activate "spin collinear" please specify initial moments in the Structure Builder.\n(click on the species color right to the atomic coordinates)',
    fields:{
      species: {
        // If there are no flags attributes the field name is taken ('species' in this case)
        text:'Geometry species',
        inputType:'input-text',
        required: true,
        dataType: 'string',
        explanation: `
            This field reflects the different species included in your structure.
            The ControlGenerator accepts any valid element of the periodic table.
        `,
        value: s => {
          let species = []
          s.atoms.forEach( atom => {if (!species.includes(atom.species)) species.push(atom.species)})
          return species.join(' ')
        },
        workflow: 'default'
      },
      xc: { // XC functional for FHIaims
        text:'XC functional',
        inputType:'select',
        required: true,
        value: [
          '',
          'pz-lda',
          'pw-lda',
          'pbe',
          'pbe+TS:{"xc":"pbe","vdw_correction_hirshfeld":true}',
          'pbe+MBD:{"xc":"pbe","many_body_dispersion":""}',
          'pbe+MBD-NL:{"xc":"pbe","many_body_dispersion_nl":""}',
          'b86bpbe+XDM:{"xc": "b86bpbe","xdm":""}',
          'pbesol',
          'pbe0',
          'pbe0+TS:{"xc":"pbe","vdw_correction_hirshfeld":true}',
          'pbe0+MBD:{"xc":"pbe0","many_body_dispersion":""}',
          'pbe0+MBD-NL:{"xc":"pbe0","many_body_dispersion_nl":""}',
          'hse06:{"xc":"hse06 0.11","hse_unit":"bohr","hybrid_xc_coeff":"0.25"}',
          'hse06+TS:{"xc":"hse06 0.11","hse_unit":"bohr","hybrid_xc_coeff":"0.25","vdw_correction_hirshfeld":true}',
          'hse06+MBD:{"xc":"hse06 0.11","hse_unit":"bohr","hybrid_xc_coeff":"0.25","many_body_dispersion":""}',
          'hse06+MBD-NL:{"xc":"hse06 0.11","hse_unit":"bohr","hybrid_xc_coeff":"0.25","many_body_dispersion_nl":""}',
          'b86bpbe-25+XDM:{"xc": "b86bpbe-25","xdm":""}',
          'hf'
        ],
        dataType: 'string',
        explanation: `
            Select the exchange correlation (XC) functional for your calculation. <br>
            <b>pz-lda:</b> Perdew-Zunger LDA<br>
            <b>pw-lda:</b> Perdew-Wang LDA<br>
            <b>pbe:</b> Perdew-Burke-Ernzerhof GGA<br>
            <b>pbe+TS:</b> pbe + Tkatchenko-Scheffler vdW correction<br>
            <b>pbe+MBD:</b> pbe + Many-Body-Dispersion correction<br>
            <b>pbe+MBD-NL:</b> pbe + Non-Local Many-Body-Dispersion correction<br>
            <b>b86bpbe+XDM:</b> pbe + eXchange-hole Dipole Model<br>
            <b>pbesol:</b> pbe optimized for solids<br>
            <b>pbe0:</b> pbe + hf hybrid functional<br>
            <b>pbe0+TS:</b> pbe + hf hybrid functional + Tkatchenko-Scheffler vdW correction<br>
            <b>pbe0+MBD:</b> pbe + hf hybrid functional + Many-Body-Dispersion correction<br>
            <b>pbe0+MBD-NL:</b> pbe + hf hybrid functional + Non-Local Many-Body-Dispersion correction<br>
            <b>hse06:</b> Heyd-Scuseria-Ernzerhof screened exchange hybrid functional<br>
            <b>hse06+TS:</b> hse06 + Tkatchenko-Scheffler vdW correction<br>
            <b>hse06+MBD:</b> hse06 + Many-Body-Dispersion correction<br>
            <b>hse06+MBD-NL:</b> hse06 + Non-Local Many-Body-Dispersion correction<br>
            <b>hf:</b> Hartree-Fock method
        `,
        workflow: 'default'
      },
      basisSettings: { // FHIaims only
        text:'Species Default Settings',
        group: 0,
        inputType:'select',
        required: true,
        value: ['', 'light', 'intermediate', 'tight', 'really_tight'],
        dataType: 'string',
        explanation: `
            The species defaults are a collection of pre-defined numerical parameters for the integration grids and 
            basis functions.<br>
            <b>light:</b> Settings for fast pre-relaxations, structure searches, etc. Usually, no obvious geometry or 
            convergence errors resulted from these settings. Recommended for many household tasks.<br>
            <b>intermediate:</b> Only available for a few elements, but can play an important role for large and 
            expensive calculations, especially for hybrid functionals.<br>
            <b>tight:</b> Regarding the integration grids, Hartree potential, and basis cutoff potentials, the settings
             specified here are rather safe, intended to provide meV-level accurate energy differences also 
             for large structures.
        `,
        workflow: 'default'
      },
      spin: {
        text: 'Spin',
        group: 0,
        inputType:'select',
        required: false,
        value: ['', 'none', 'collinear'],
        dataType: 'string',
        explanation: `
            Specifies whether or not a spin-polarized calculation is run. If you choose 'collinear', please assign 
            initial moments in the Structure Builder to at least one atom. Choosing the right initial spin density 
            can be performance-critical, and critical for the resulting physics.
        `,
        sanity_check: (field, s) => {
          if (field.getValue() === "collinear" && !s.isMagnetic())
            return {type: "error",
              message: `To make use of collinear spin keyword, at least one initial moment has to be set.<br>
                        It can be done in Structure Builder by clicking on species color:<br>
                         <img src="${atomPropertiesShot}" alt="Atom properties"/>`}
          if (['none', ''].includes(field.getValue()) && s.isMagnetic())
            return {type: "error",
              message: "If atomic initial moments are set, spin keyword needs to be set to 'collinear'"}
        },
        workflow: 'default'
      }
    },
  }, // End BasicSettings
  {
    label: 'K-grid',
    info: s => {
      try {
        let [a, b, c] = s.structureInfo["unit_cell_parameters"].value.slice(0,3)
        return `Structure lattice vectors: <br>` +
        `<table style="width:100%"><tr><td>${a.toFixed(3)} &#197;</td>` +
                                    `<td>${b.toFixed(3)} &#197;</td>` +
                                    `<td>${c.toFixed(3)} &#197;</td></tr></table></table>`}
      catch (_) {return ""}
    },
    constraint: ctx => ctx['structure'].isAPeriodicSystem(),
    fields: {

      k_grid_radio: {
        flag: 'k_grid_radio',
        inputType: 'radio',
        required: true,  // if it's not required it's not shown
        workflow: 'default',
        sanity_check: (field, s) => {
          if (!s.isAPeriodicSystem()) return undefined
          if (field.name === "k_grid") {
            // check if a_i * N_i >= 40
            let [a, b, c] = s.structureInfo["unit_cell_parameters"].value.slice(0,3)
            let [i, j, k] = field.getValue()
            if (a * i < 35 || b * j < 35 || c * k < 35)
              return {type: "warning",
                message: "The k-point grid seems to be insufficient"}
          }
        },
        value: {
          kgrid_mesh: {
            flag: 'k_grid',
            text:'k-points grid',
            inputType:'input-text:3',
            dataType: ['integer','integer','integer'],
            explanation: `Enter the number of k-points along each reciprocal lattice vector. The rule of thumb is to use
              such N<sub>i</sub> so that<br><i>N<sub>i</sub> &middot; a<sub>i</sub> &#8805; 40</i>.`,
            workflow: 'default'
          },
          k_grid_density: {
            flag: 'k_grid_density',
            text:'k-points grid density',
            inputType:'input-text',
            dataType:'float',
            explanation: `Sets the k-point density in the mesh (that is, the number of k-points per 1&#197;&#8315;&#185; 
              of the reciprocal lattice vector). The sane choice is 6&#197;. The number of k-points along the axis
              is then found as <div style="text-align:center"><img src="${kGridDensityFormula}" alt="2pi/|a|*rho"/></div>`,
            units: `&#197`,
            workflow: 'default'
          }
        }
      },
      k_grid_offset: {
        flag: 'k_offset',
        constraint: ['xc', 'in', ['pw-lda', 'pz-lda', 'pbe', 'pbesol']],
        text:'k-points grid offset',
        inputType:'input-text:3',
        required: false, // Required in some cases, not always
        dataType: ['integer','integer','integer'],
        explanation: 'K-grid offset can not be used with HF or hybrid potential calculation',
        workflow: 'default'
      }
    }
  },
  {
    label: 'Molecular dynamics',
    fields: {
      mdRun: {
        text: 'MD ensemble',
        flag: 'MD_run',
        group: 0,
        inputType:'select',
        required: true,
        value: ['', 'NVE', 'NVT_parrinello'],
        dataType: 'string',
        explanation: `
            Central controls of the physical parameters of a Born-Oppenheimer molecular dynamics run.
            Specifies the MD ensemble:<br>
            <b>NVE:</b> performs molecular dynamics in the microcanonical ensemble;<br>
            <b>NVT_parrinello:</b> molecular dynamics run using the Bussi-Donadio-Parrinello thermostat.<br>
        `,
        workflow: 'MD'
      },
      mdRunTime: {
        text: 'MD run time',
        flag: 'MD_run_time',
        group: 0,
        inputType:'input-text',
        required: true,
        dataType: 'float',
        units: 'ps',
        explanation: `
          The run time for AIMD simulation in picoseconds
        `,
        workflow: 'MD'
      },
      mdRunParameters: {
        text: 'Thermostat parameters',
        flag: 'MD_run_params',
        group: 0,
        inputType:'input-text:2:Temperature,Tau',
        required: true,
        dataType: ['float', 'float'],
        units: ['K', 'ps'],
        explanation: `
          AIMD thermostat parameters:<br>
            <b>Temperature:</b> the simulation temperature in K;<br>
            <b>Tau:</b> the relaxation time of the thermostat, in ps.<br>
        `,
        constraint: ['mdRun', 'in', ['NVT_parrinello']],
        workflow: 'MD'
      },
      mdTimeStep: {
        text: 'MD time step',
        flag: 'MD_time_step',
        group: 0,
        inputType:'input-text',
        required: true,
        dataType: 'float',
        units: 'ps',
        explanation: `
          The time step for AIMD simulation in picoseconds
        `,
        workflow: 'MD'
      },
      mdMBInit: {
        text: 'Velocity initialization temperature',
        flag: 'MD_MB_init',
        group: 0,
        inputType:'input-text',
        required: true,
        dataType: 'float',
        units: 'K',
        explanation: `
          Initializes random velocities in a molecular dynamics calculation using a Maxwell-Boltzmann distribution.<br>
          Please specify an appropriate temperature in Kelvin.
        `,
        workflow: 'MD'
      },
    }
  },
  {
    label: 'GW self-energy correction',
    fields: {
      qpeCalc: {
        text: 'Perturbative quasiparticle correction',
        flag: 'qpe_calc',
        group: 0,
        inputType:'select',
        required: true,
        value: ['', 'gw', 'gw_expt', 'ev_scgw', 'ev_scgw0'],
        dataType: 'string',
        explanation: `
            Enables a perturbative quasiparticle correction to the single-particle eigenvalues at a postprocessing step. 
            Specifies which correction to use:<br>
            <b>gw:</b> Perturbative G<sub>0</sub>W<sub>0</sub>-type self-energy for cluster geometries, 
            where the Green’s function G<sub>0</sub> and the screened Coulomb interaction W<sub>0</sub> are 
            computed once, based on the self-consistent DFT or HF ground state eigenvalues and eigenfunctions;<br>
            <b>gw_expt:</b> the same as <code>gw</code>, but for periodic structures;<br>
            <b>ev_scgw:</b> the self-energy is evaluated with partial self-consistency in the eigenvalues. 
            The eigenvalues are iterated in G <i>and</i> W;<br>
            <b>ev_scgw0:</b> the self-energy is evaluated with partial self-consistency in the eigenvalues. 
            The eigenvalues are iterated <i>only</i> in G but not in W.<br>
        `,
        sanity_check: (field, s) => {
          if (s.isAPeriodicSystem() && field.getValue() !== 'gw_expt')
            return {type: "error",
              message: "For the periodic system quasiparticle correction flag has to be set to <b>gw_expt</b>"
          }
          if (!s.isAPeriodicSystem() && field.getValue() === 'gw_expt')
            return {type: "error",
              message: "For a non-periodic system quasiparticle correction flag should not be set to <b>gw_expt</b>"
          }
        },
        workflow: 'GW'
      },
      // sc_self_energy -- fully self-consistent GW calculation (for clusters only!)
      anaconType: {
        text: 'Analytic continuation of self-energy',
        flag: 'anacon_type',
        group: 0,
        inputType:'select',
        required: true,
        value: ['', 'two-pole', 'pade'],
        dataType: 'string',
        explanation: `
            Specifies the type of analytical continuation for the self-energy on the real axis:<br>
            <b>two-pole:</b> the normal two-pole fitting (eq. 3.60 in AIMS manual);<br>
            <b>pade:</b> Pade approximation (eq. 3.61 in AIMS manual).<br>
            In practice two-pole fitting is often found to give good performance.
        `,
        workflow: 'GW'
      },
      frequencyPoints: {
        text: 'Number of frequency points',
        flag: 'frequency_points',
        inputType:'input-text',
        required: false,
        explicitInclusion: 'checkbox',
        value: 80,
        dataType: 'integer',
        explanation: `
            Specifies the number of (imaginary) frequency points for the self-energy calculation.<br>
            <i>Default values</i>:<br> 
            40 for two-pole fitting ('two-pole' in the line above);<br>
            100 for Pade approximation ('pade' in the line above).<br>
        `,
        workflow: 'GW'
      },
      GWBandStructure: {
        text:'GW Band Structure',
        inputType:'input-text:1:k-point density',
        dataType: 'float',
        required: true,
        explicitInclusion: false,
        explanation: `
            Specifies the k-point density (Number of k-points over &#197<sup>-1</sup>) for the bands calculation in GW framework. 
            Recommendation: 40.
        `,
        constraint: ['qpeCalc', 'in', ['gw_expt']],
        units: [`k-points &sdot; &#197;`],
        workflow: 'GW'
      },
      needDFTInputs: {
        text:'Also prepare DFT inputs',
        inputType:'no-input',
        required: false,
        explicitInclusion: 'checkbox',
        explanation: `
          A flag that determines whether to prepare inputs for DFT calculations in addition to GW for comparing outputs
        `,
        workflow: 'GW'
      },

    }
  },
  {
    label: 'Post-processing',
    constraint: ctx => ctx['workflow'] !== 'GW' && ctx['workflow'] !== 'MD',
    folded: true,
    fields: {
      includeSpinOrbit: {
        text:'Include Spin-Orbit Coupling',
        flag:'include_spin_orbit',
        inputType: 'no-input',
        dataType: 'none',
        required: false,
        explicitInclusion: 'checkbox',
        explanation: `
           Activates the perturbative spin-orbit coupling calculation for post-processing output,
           such as for the DOS or Band Structure.
        `,
        workflow: 'default'
      },
      bandStructure: {
        text:'Band Structure',
        inputType:'input-text:1:k-point density',
        dataType: 'float',
        required: true,
        explicitInclusion: false,
        explanation: `
            Specifies the k-point density (Number of k-points times AA) for the band path. Recommendation: 40.
        `,
        sanity_check: (field, s) => {
          if (!s.isAPeriodicSystem()) return {
            type: "error",
            message: "You need a <b>periodic</b> system to do a Band structure calculation"
          }
        },
        units: [`k-points &sdot; &#197;`],
        workflow: 'BandStructure'
      },
      dos: {
        flag: 'output dos',
        text:'DOS',
        inputType:'input-text:4:Energy start,Energy end,Number of sampling points,Broadening',
        required: false,
        explicitInclusion: 'checkbox',
        dataType: ['float','float','integer','float'],
        explanation: `
            Writes the density of states (DOS) to an external file for plotting purposes.<br>
            <b>Energy start:</b> Lower bound of single-particle energy range for which the DOS is given. 
            Recommended value: -20 eV.<br>
            <b>Energy end:</b> Upper bound of single-particle energy range for which the DOS is given. 
            Recommended value: 10 eV.<br>
            <b>Number of sampling points:</b> Number of energy data points for which the DOS is given. 
            Recommended value: 3001<br>
            <b>Broadening:</b> Gaussian broadening applied to obtain a smooth DOS based on the peaks 
            produced by individual states. 0.1 eV is a typical value, although it will create a relatively 
            noticeable broadening<br>
        `,
        units: ['eV', 'eV', ' ', 'eV'],
        workflow: 'default'
      },
      dielectric: {
        flag: 'compute_dielectric',
        text:'Absorption Function and Dielectric Tensor',
        inputType:'input-text:2:Upper frequency bound,Number of frequency points',
        required: false,
        explicitInclusion: 'checkbox',
        dataType: ['float','integer'],
        explanation: `
           Sets basic parameters for calculating the imaginary and real part of the inter-band and 
           intra-band contribution to the linear dielectric tensor within the random-phase approximation.<br>
           <b>Upper frequency bound</b> is a real valued number. This defines the upper frequency limit 
           (in eV) up to which the dielectric function should be written to files. Choose 10 eV or larger 
           in order to ensure that later transformations, such as Kramers-Kronig, are accurate.<br>
           <b>Number of frequency points</b> for which the dielectric function is calculated should be at least 
           1000 or larger.`,
        units: ['eV', ''],
        workflow: 'default'
      },
      hirshfeld: {
        flag: 'output                             hirshfeld',
        text: 'Hirshfeld analysis output',
        inputType: 'no-input',
        required: false,
        explicitInclusion: 'checkbox',
        explanation: `
            Writes a Hirshfeld analysis (the difference between the molecular and unrelaxed atomic charge densities) 
            for the final geometry of an FHI-aims run.
        `,
        workflow: 'default'
      },
      mulliken: {
        flag: 'output                             mulliken',
        text:'Mulliken analysis output',
        inputType:'no-input',
        required: false,
        explicitInclusion: 'checkbox',
        explanation: `
            Produces a Mulliken analysis of the occupation of each atom and its angular momentum channels in terms 
            of the basis used.
        `,
        workflow: 'default'
      }
    }
  }, // End output

  {
    label: 'Structure Optimization, Forces, and Stress',
    constraint: ctx => ctx['workflow'] !== 'GW' && ctx['workflow'] !== 'MD',
    folded: true,
    fields: {
      relaxGeometry: { // Only for FHIaims
        flag: 'relax_geometry trm',//'relax_geometry bfgs',s
        text:'Relax Atom Positions',
        inputType:'input-text',
        value: '5e-3',
        required: false,
        explicitInclusion: 'checkbox',
        dataType: 'float',
        explanation: `
            Switches on the optimization of the atom positions. The field specifies the maximum residual force component 
            per atom (in eV/AA). FHI-aims uses a trust radius method enhanced version of the BFGS optimization.
        `,
        units: `eV/&#197;`,
        workflow: 'default'
      },
      relaxUnitCell: { // Only for FHIaims
        flag: 'relax_unit_cell',
        text:'Relax Unit Cell',
        inputType:'select',
        value: ['full','fixed_angles'],
        dataType: 'string',
        required: false,
        explicitInclusion: 'checkbox',
        explanation: `Switches on the optimization of the lattice vectors. Does not have an effect unless <b>Relax Atom Positions</b> is switched on as well.`,
        constraint: ['relaxGeometry', 'included'],
        workflow: 'default'
      },
      calculateForces: {
        flag: 'compute_forces',
        text:'Calculate Forces',
        inputType:'no-input',
        dataType: 'boolean',
        required: false,
        explicitInclusion: 'checkbox',
        explanation: `
           Activates the calculation of the forces at the end of an SCF cycle, only.
        `,
        workflow: 'default'
      },
      calculateStresses: { // Only for FHIaims
        flag: 'compute_analytical_stress',
        text:'Calculate Stress',
        inputType:'no-input',
        required: false,
        dataType: 'boolean',
        explicitInclusion: 'checkbox',
        explanation: `
            Switches on the computation of the analytical stress tensor.
        `,
        workflow: 'default'
      },
    }
  }, // End OptimizationForcesStress

  {
    label: 'Self-Consistent Field Convergence (optional)',
    folded: true,
    info:'Note: FHI-aims usually determines reliable SCF convergence parameters on its own. We recommend to keep the defaults unless for a very good reason.',
    fields: {
      totalEnergyAccuracy: {
        flag: 'sc_accuracy_etot',
        text:'Total Energy Accuracy',
        inputType:'input-text',
        dataType:'float',
        required: false,
        explicitInclusion: 'checkbox',
        explanation: `
           Sets the SCF convergence criterion for the total energy (in eV).
        `,
        units: `eV`,
        workflow: 'default'
      },
      totalDensityAccuracy: {
        flag: 'sc_accuracy_rho',
        text:'Total Density Accuracy',
        inputType:'input-text',
        dataType:'float',
        required: false,
        explicitInclusion: 'checkbox',
        explanation: `
           Sets the SCF convergence criterion for the total electron density (in e/AA^3).
        `,
        units: `e/&#197;<sup>3</sup>`,
        workflow: 'default'
      },
      eigenvalueAccuracy: {
        flag: 'sc_accuracy_eev',
        text:'Eigenvalue Accuracy',
        inputType:'input-text',
        dataType:'float',
        required: false,
        explicitInclusion: 'checkbox',
        explanation: `
           Sets the SCF convergence criterion for the sum of the eigenvalues (in eV).
        `,
        units: `eV`,
        workflow: 'default'
      },
      forcesAccuracy: {
        flag: 'sc_accuracy_forces',
        text:'Accuracy of Forces',
        inputType:'input-text',
        dataType:'float',
        required: false,
        explicitInclusion: 'checkbox',
        explanation: `
           Sets the SCF convergence criterion for the forces (in eV/AA).
        `,
        units: `eV/&#197;`,
        workflow: 'default'
      },
      chargeMixParam: {
        flag: 'charge_mix_param',
        text:'Density mixing ratio',
        inputType:'input-text',
        dataType:'float',
        required: false,
        explicitInclusion: 'checkbox',
        explanation: `
           The ratio by which the updated Kohn-Sham density is mixed with an average of electron densities 
           of previous s.c.f. iterations. For systems with a narrow or vanishing gap, FHI-aims sets a 
           conservative default (0.02) that will converge even difficult systems reliably. If desired, for some
           benign metallic systems, a larger value (e.g., 0.05) may be acceptable and slightly faster. 
           In case of difficulties with converging the s.c.f. cycle, refer to FHI-aims' manual for more details. 
        `,
        workflow: 'default'
      },

    }
  } // End numericalAccuracy
]
