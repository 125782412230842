/**
 * @author Iker Hurtado
 *
 * @fileoverview File holding the application level State and constants
 */


/**
 * Specifies the possible changes for events
 * @todo This should be moved to the StructureBuilder module,
 *   the module is not singleton (application level) longer 
 *   so application level change definition doesn't make sense
 * @type {Object}
 */
export const CHANGE = {
  STRUCTURE: {
    SUPERCELL: 'structure.supercell',
    LATTICE_VECTORS: 'structure.lattVector',
    SURFACE: 'structure.surface',
    ATOM: {
      MOVE: 'structure.atom.move',
      SPECIES: 'structure.atom.species',
      INITMOMENT: 'structure.atom.initMoment',
      CONSTRAINT: 'structure.atom.constraint',
      CHARGE: 'structure.atom.charge',
      ADD: 'structure.atom.add',
      REMOVE: 'structure.atom.remove'
    }
  }
}


// Application code state 

export const CODE = { FHI_AIMS: 'FHIaims', EXCITING: 'Exciting' }

// Application code state variable
let code = CODE.FHI_AIMS // Default code // 'Exciting'  


/**
 * Returns the current code
 * @return {string}
 */
export function getCode(){
  return code
}


/**
 * Returns true when Exciting and false when FHIamis
 * @return {boolean}
 */
export function isExcitingCode(){
  return code === CODE.EXCITING 
}


/**
 * Set the current code
 * @param {string} c
 */
export function setCode(c){
  if (c === CODE.FHI_AIMS || c === CODE.EXCITING){
    code = c
    emitCodeChange(code)
  }else 
    error('Trying to set a wrong code') 
}

// Code change listeners
const codeListeners = []

/**
 * Subscribes a component to a code change event
 * @param  {UIComponent} component
 */
export function subscribeToCodeChange( component ){
  codeListeners.push(component)
}


/**
   * Emits a code change event
   * @param  {string} code
   */
function emitCodeChange(code){
  //console.log('codeListeners', codeListeners)
  codeListeners.forEach( component => {
    component.updateForCode( code )
  })

}


// Settings change event listeners
const settingsListeners = []


/**
 * Subscribes a component to a settings change event
 * @param  {UIComponent} component
 */
export function subscribeToSettingsChange( component ){
  settingsListeners.push(component)
}


/**
 * Updates the settings object
 * @param  {object} newSettings
 */
export function updateSettings(newSettings){
  // console.log('emitStructureChange', change)
  settingsListeners.forEach( component => {
    component.updateSettings( newSettings )
  })

}
