/**
 * @author Iker Hurtado
 *
 * @fileOverview File holding the CoordinatesField UI component
 */

import UIComponent from '../common/UIComponent.js'
import {Conf} from '../Conf.js'


/**
 * A set of three text inputs representing a coordinates form field
 */
export default class CoordinatesField extends UIComponent{

  constructor(){

    super('div', '.coordinates-field')
        
    this.setHTML(`
        <input type="text" class="coor-value" /> 
        <input type="text" class="coor-value" /> 
        <input type="text" class="coor-value" /> 
    `)

    this.fields = this.getElements('input')

    this.fields.forEach( f => f.addEventListener('change', changeEventHandler) )

    const classInstance = this

    // function inputEventHandler(e){
    //   // The value is not parsed therefore accepted
    //   if (e.target.value === '-' || e.target.value === '.' ) return
    //   const value = parseFloat(e.target.value)
    //   //console.log('input event',e.target.value, value,isNaN(value))
    //   if (isNaN(value)) e.target.value = ''
    // }

    function changeEventHandler(e){  //**** Try this syntax: this.changeEventHandler = (val) => {
      const value = parseFloat(e.target.value)
      if (isNaN(value)) e.target.value = '0'
      // e.target.value = value.toFixed(5) -> It's not necessary because 
      // the event modifies the structure and that change comes down to the component
      if (classInstance.changeListener !== undefined) {
        classInstance.changeListener(classInstance.getValues())
      }
    }
  }

  /**
   * Returns the set of values
   * @return {number[]}
   */
  getValues(){
    return [parseFloat(this.fields[0].value), 
      parseFloat(this.fields[1].value), 
      parseFloat(this.fields[2].value) ]
  }

  /**
   * Sets the set of values
   * @param {number[]} coors
   */
  setValues(coors){
    for (let i = 0; i < this.fields.length; i++)
      this.fields[i].value = coors[i].toFixed(Conf.settings.decimalDigits)
  }

  /**
   * Updates the text inputs number of decimals showing
   */
  updateDecimals(){
    for (let i = 0; i < this.fields.length; i++)
      this.fields[i].value = parseFloat(this.fields[i].value).toFixed(Conf.settings.decimalDigits)
  }

  /**
   * Sets the listener that will be called when any coordinate is changed
   * The coordinates values will be passed in
   * @param {function} listener 
   */
  setChangeListener(listener){
    this.changeListener = listener
  }
}
