/**
 * @author Iker Hurtado, Andrey Sobolev
 *
 * @fileOverview File holding the dielectric function plotter
 */
import {Plotter} from "./plotter";
import {transpose} from "./util";

const X_AXIS_LABEL = 'Freq. (eV)'
const Y_AXIS_LABEL = 'Dielectric Function'
const CONFIG = {
  re_vac: {name: 'Real vacuum', color: '#009ddc'},
  im_vac: {name: 'Imag vacuum', color: '#30f243'},
  re_po_inter: {name: 'Real polarization interband', color: '#dfff00'},
  im_po_inter: {name: 'Imag polarization interband', color: '#ffbf00'},
  re_po_intra: {name: 'Real polarization intraband', color: '#ff7f50'},
  im_po_intra: {name: 'Imag polarization interband', color: '#de3163'},
  re_die: {name: 'Real dielectric', color: '#9fe2bf'},
  im_die: {name: 'Imag dielectric', color: '#40e0d0'}
}

/**
 * A plotter displaying the dielectric function
 */
export class DielectricPlotter extends Plotter {

  /**
   * Sets the dielectric data to be plotted.
   * @param {number} xAxisMin
   * @param {number} xAxisMax
   */
  plotData(xAxisMin = -6, xAxisMax = 12){
    for (const data of this.data.values()) {
      const xy = transpose(data)
      let idx = 0
      for (const group in CONFIG) {
        idx++
        if (!this.graph.hasLineGroup(group))
          this.graph.addLineGroup(CONFIG[group].name, {defaultColor: CONFIG[group].color})
        this.graph.setLineData([xy[0], xy[idx]], CONFIG[group].name)
      }
    }
    this.graph.setAxisLabelsAndRange(X_AXIS_LABEL, Y_AXIS_LABEL, false)
    this.graph.draw()
  }
}
