
/**
 * @author Iker Hurtado, Andrey Sobolev
 *
 * @fileOverview File holding the DOSPlotter graph
 */

import {gauss} from "./util";
import {Conf} from "../Conf";
import {Plotter} from "./plotter";
import * as ModalPopup from "../common/ModalPopup";

const X_AXIS_LABEL = 'DOS (states/eV/cell)'
const Y_AXIS_LABEL = 'Energy (eV)'


/**
 * A plotter displaying the DOS data
 */
export class DOSPlotter extends Plotter{

  /** Parses and plots dos files
	 * @param {InteractiveGraph} graph
	 * @param  {Map<string, array>} dosFiles Object with DOS files data
   * @param {boolean} isMolecule a flag for molecular DOS
   * @param {boolean} hasSpin a flag for spin-collinear calculation
   * @param  {string} eAxis A flag denoting energy axis (`x` or `y`)
   */
  constructor(graph, dosFiles, isMolecule, hasSpin, eAxis = 'y') {
    if (!['x', 'y'].includes(eAxis)) throw Error('Energy axis should be x or y')

		super(graph, dosFiles)
		this.graph.NUMBER_OF_POINTS_IN_SEGMENT = 0

    this.broadening = 0.2
		this.isMolecule = isMolecule
		this.hasSpin = hasSpin

    if (this.isMolecule) {
      for (const name of dosFiles.keys()) {
        let mDOS = getMolDos(dosFiles, this.broadening, name)
        this.data.set(name, mDOS)
      }
    }

		this.config = new Map()
    let count = 0
		for (const name of dosFiles.keys()) {
			count++
			this.config.set(name, {'color': Conf.ELEMENT_COLORS[count + 1], 'selected': true})
    }
  }

	/** Updates control form
   * @param form {Form}
   * @param sectionName {string}
   * @param sectionClass {string}
   */
	updateForm(form, sectionName, sectionClass) {
		super.updateForm(form, sectionName, sectionClass);
		if (this.isMolecule) {
			form.addSection('Broadening')
	    let broadenField = form.addField('broadening', 'Broadening', 'input-text', 0.2,
				'float', 0, true, false,'eV')
			broadenField.HTMLFields[0].addEventListener('change', e => {
				this.broadening = e.target.value
				// let mDOS = getMolDos(this.data, newBroadening, molDosName)
				// this.data.set(molDosName,mDOS)
				// this.dosFilesConfig.set(molDosName, {'color':'#000000','selected': true})
				// this.dosPlotter.setData(this.data, ENERGY_AXIS_MIN, ENERGY_AXIS_MAX, this.dosFilesConfig)
			})

		}
	}

	/**
   * Finds the DOS data to be plotted.
   * Also, the top and bottom X values can be passed
   * @param {number} eAxisMin
   * @param {number} eAxisMax
   */
  plotData(eAxisMin = -6, eAxisMax = 12){
    let maxDosVal = -100000000
    let minEnergyVal = eAxisMin
    let maxEnergyVal = eAxisMax

    // FIXME: change of eAxis is not yet implemented
    this.data.forEach((data, name) => {
      let energy = data.map(el => el[0])
      let dosUp = data.map(el => el[1])
      let dosDown = this.hasSpin ? data.map(el => -el[2]) : []
      maxDosVal = Math.max(maxDosVal, ...dosUp, ...dosDown);
      maxEnergyVal = Math.min(maxEnergyVal, Math.max(...energy));
      minEnergyVal = Math.max(minEnergyVal, Math.min(...energy));
			let groupName = this.hasSpin ? name + '_Up' : name
			this.graph.addLineGroup(groupName, {defaultColor: this.config.get(name).color})
			this.graph.setLineData([dosUp, energy], groupName)//, lineWidth: 2})//[{x:1,y:1}, {x:5, y:5}])//
      if (this.hasSpin) {
        this.graph.addLineGroup(name+'_Down', {defaultColor: this.config.get(name).color})
        this.graph.setLineData([dosDown, energy], name+'_Down')
      }
    })

		const minDosVal = this.hasSpin ? -1.*maxDosVal : 0
		this.graph.setAxisLabelsAndRange(X_AXIS_LABEL, Y_AXIS_LABEL, true, minDosVal, maxDosVal,
			eAxisMin, eAxisMax, minEnergyVal, maxEnergyVal);
		this.graph.draw()
  }

	onDosButtonClick() {
		let dosNames = Array.from(this.data.keys())
		let content = ``
		for (let k = 0; k < dosNames.length; k++) { // For every segment
			let dosName = dosNames[k]
			let checked = this.config.get(dosName).selected ? 'checked' : ''
			content += `<input type="checkbox" id="${dosName}" name="dosChoice" value="${dosName}" ${checked}>${dosName}<br>`
	}
		ModalPopup.setModalContent(content)
		let chooseSegment = document.createElement('button')
		chooseSegment.innerHTML = 'Apply'
		// chooseSegment.addEventListener('click', _ => {
		// 	let cb = document.querySelectorAll(`input[name="dosChoice"]:checked`)
		// 	let newDosFiles = new Map()
		// 	this.config.forEach( (value, key) => {
		// 		value.selected = false
		// 		for (const control of this.formControls[key])
		// 			control.hide()
		// 	})
		// 	cb.forEach((checkbox) => {
		// 		this.config.get(checkbox.value).selected = true
		// 		for (const control of this.formControls[checkbox.value])
		// 			control.show()
		// 	 	newDosFiles.set(checkbox.value, this.data.get(checkbox.value))
		// 	})
		// 	let newDosData = this.data.get(cb[0].value)
		// 	this.setData(ENERGY_AXIS_MIN, ENERGY_AXIS_MAX)
		// })
		ModalPopup.setModalComponent(chooseSegment,true)
		ModalPopup.showModal(true)
	}
}


/** Returns molecular DOS (a set of broadened eigenvalues). Original author: Hagen-Henrik Kowalski
 *
 * @param dosFiles
 * @param broadening
 * @param molDosName
 * @returns {*[]}
 */
function getMolDos(dosFiles, broadening, molDosName) {
	//const LINES_THICKNESS = {groups: ['spin1', 'spin2', 'soc'], thicknessMult: DEFAULT_LINES_THICKNESS_MULTIPLIER}
	let start = -100, stop = 50
	let steps = 3001
	let hasSpin = false
	let molDosData = dosFiles.get(molDosName)
	if (molDosName.endsWith('.spin')) {
		hasSpin = true
	}
	let molDosDataLength = molDosData.length / 2
	let finalDos = []
	let step = (stop - start) / (steps - 1)
	for (let i = 0; i < steps; i++) {
		if (hasSpin)
			finalDos.push([start + step * i, 0, 0])
		else
			finalDos.push([start + step * i, 0])
	}
	if (!hasSpin) {
		molDosData.forEach(mDD => {
			finalDos.forEach(xy => {
				if (mDD[2] > start - 5 * broadening) {
					xy[1] += gauss(broadening, xy[0], mDD[2])
				}
			})
		})
	} else {
		molDosData.forEach((mDD, counter) => {
			if (counter < molDosDataLength) {
				finalDos.forEach(xy => {
					if (mDD[2] > start - 5 * broadening) {
						xy[1] += gauss(broadening, xy[0], mDD[2])
					}
				})
			} else {
				finalDos.forEach(xy => {
					if (mDD[2] > start - 5 * broadening) {
						xy[2] += gauss(broadening, xy[0], mDD[2])
					}
				})
			}
		})
	}
	//console.log("it's the final dos")
	//console.log(finalDos)
	return finalDos
}
