/**
 * @author Andrey Sobolev
 *
 * @fileOverview File holding the Dashboard class (one or several graphs with common controls) based on Plotly.js
 */


import {InteractiveGraph, joinY} from "./InteractiveGraphPlotly";
import UIComponent from "../common/UIComponent";
import Form from "../common/Form";

const DEFAULT_LABELS_FONT_MULTIPLIER = 1.

const DEFAULT_COLOR = '#000000'



export class PlotlyDashboard extends UIComponent {
  /** A dashboard with one or several graphs and graph controls
   *
   * @param parent {HTMLElement}
   * @param uiClass {string} a class for Dashboard element
   */
  constructor(parent, uiClass='.plotly-dashboard') {
    super('div', uiClass);
    this.parentElement = parent
    this.parentElement.appendChild(this.e)
    this.graphs = {}
    this.form = undefined
  }

    /**
   * Creates and attaches the graph canvas inside an HTML layout element.
   * Sets the width and height of the canvas, and optionally the
   * canvas resolution can be doubled
   * @param  {string} label
   * @param  {int} width
   * @param  {int} height
   * @param layout {object}
   */
  attach(label, width = undefined, height = undefined, layout = {}) {
    // search in DOM, if not found, then create and append to parent
    if (!['.', '#'].includes(label[0])) label = '.' + label
    if (this.getElement(label) === undefined) {
      const node = new UIComponent('div', label)
      this.parentElement.appendChild(node.e)
    }
    const el = this.getElement(label)
    width = (width !== undefined ? width : el.clientWidth);
    height = (height !== undefined ? height : el.clientHeight);
    //  strip # or . from label
    this.graphs[label.slice(1)] = new InteractiveGraph(el, width, height, layout);
  }

  createForm(label) {
    this.form = new Form(label)
    this.form.disableUserMsg()
    this.form.addSection('Labels style')
    //
    let field = this.form.addField('axesLabelsSize', 'Axes label size', 'input-range',
      [0.8, 2.2, DEFAULT_LABELS_FONT_MULTIPLIER], 'float')
    field.HTMLFields[0].addEventListener('input', e => { // change
      const multiplier = parseFloat(e.target.value)
      this.changeLabelsStyle(multiplier, undefined)
    })
    this.labelColorField = this.form.addField('labelsColor', 'Axes label color', 'input-color',
      DEFAULT_COLOR, 'color')
    this.labelColorField.HTMLFields[0].addEventListener('input', e => {
      this.changeLabelsStyle(undefined, e.target.value)
    })


  }

  /** Couple the axis of two graphs given by labels
   *
   * @param first {string} 1st graph label
   * @param second {string} 2nd graph label
   * @param axis {string} the name of the axis
   */
  _couple(first, second, axis='y') {
    if (axis !== "x" && axis !== "y")
      throw ("Axis parameter has to be either `x` or `y`")
    if (!(first in this.graphs || second in this.graphs))
      throw (`Found no graph with labels ${first} or ${second}`)

    const divs = [this.graphs[first].parentElement, this.graphs[second].parentElement]
    joinY(divs)
    divs.forEach(div => div.on('plotly_relayout', (data) => joinY(divs, data)))
  }

  /** Sets data trace to the given graph; adds metadata to build controls
   *
   * @param data {[[]]} a data object
   * @param layout {object} a layout object
   * @param graphLabel {string} graph label
   */
  setData(data, layout, graphLabel) {
    // sanity check
    if (!(graphLabel in this.graphs)) return;
    // actual addition of data
    this.graphs[graphLabel].setLineData(data, '')
  }


  /**
   * Changes the axes labels and the title styles
   * @param  {number} sizeMult Font size multiplier
   * @param  {string} color Color code
  */
  changeLabelsStyle(sizeMult, color) {
    for (const graph in this.graphs)
      this.graphs[graph].changeLabelsStyle(sizeMult, color)
  }

}