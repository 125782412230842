/**
 * @author Iker Hurtado
 *
 * @fileoverview File holding the UserMsgBox UI component
 */

import UIComponent from './UIComponent.js'


/**
 * Text box on the top of the application layout for warning or showing dynamic 
 * and relevant information to the user
 */
class UserMsgBox extends UIComponent{

  constructor(){
    super('div', '.UserMsgBox')
  }

  /**
   * Adds an alert div to UserMsgBox
   *
   * @param type {string} An alert type (warning, error)
   * @param msg {string} An alert message
   */
  addAlert(type, msg) {
    let alert = document.createElement('div')
    alert.id = Math.floor(Math.random() * Date.now()).toString(16)
    alert.classList.add('alert', type)
    alert.innerHTML = `<span class="alertText">${msg}</span>`
    this.e.appendChild(alert)
    const timeout = type === 'error' ? 5000 : 3000
    setTimeout( () => {alert.style.display = 'none'; this.e.removeChild(alert)}, timeout)
  }
}


/**
 * Creates the application instance (singleton pattern) and adds to the HTML body 
 * @type {UserMsgBox}
 */
const userMsgBox = new UserMsgBox()
document.querySelector('body').appendChild(userMsgBox.e)


/**
 * Sets a user message
 * @param {string} msg
 */
export function setMsg(msg){
  userMsgBox.addAlert('warning', msg)
}


/**
 * Sets an application error for the user
 * @param {string} msg
 */
export function setError(msg){
  userMsgBox.addAlert('error', msg)
}
