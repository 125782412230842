/**
 * @author Iker Hurtado
 *
 * @fileoverview File holding the ThreeViewer class
 */

// import * as THREE from '../../lib/three.js'
import {
	WebGLRenderer,
	OrthographicCamera,
	PerspectiveCamera,
	Scene,
	DirectionalLight,
	AmbientLight,
	Vector3
} from 'three'
// import OrthographicTrackballControls from '../../lib/OrthographicTrackballControls.js'
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';


const MAX_ZOOM = 5
const MIN_ZOOM = 0.1

/**
 * Base class that supports the 3D drawing of the StructureViewer
 */
export default class ThreeViewer{

    /**
     * @param  {HTMLElement} hostElement HTML element hosting the canvas
     */
	constructor(hostElement) {
        this.hostElement = hostElement
        this.cameraWidth = 10.0 // The default "width" of the camera

        // RENDERER SETUP. The "alpha: true" enables to set a background color.
        this.renderer = new WebGLRenderer({
            alpha: true,
            antialias: true,
        });
				this.params = {orthographicCamera: true};

        this.renderer.setSize(this.hostElement.clientWidth, this.hostElement.clientHeight)
        this.renderer.setClearColor(0x000000, 0)
        this.hostElement.appendChild(this.renderer.domElement)
        this.renderer.domElement.id = 'threejs-canvas'

        // This is set so that multiple scenes can be used, see
        // http://stackoverflow.com/questions/12666570/how-to-change-the-zorder-of-object-with-threejs/12666937#12666937
        this.renderer.autoClear = false

        this.setupCamera()
        this.setupScenes()
        this.setupControls()
        this.setupLights()

        window.addEventListener('resize', this.onCanvasResize.bind(this), false)
    }


    /**
     * Sets up the camera for the 3D scene
     */
    setupCamera() {
        let aspectRatio = this.hostElement.clientWidth / this.hostElement.clientHeight;
        let width = this.cameraWidth;
        let height = width / aspectRatio;
        this.orthographicCamera = new OrthographicCamera(width / -2, width / 2, height / 2, height / -2, -100, 1000);
				this.orthographicCamera.position.set( 0, 0, 100 )
				this.perspectiveCamera = new PerspectiveCamera( 3, aspectRatio, 1, 1000 )
				this.perspectiveCamera.position.set( 0, 0, 100 )
				this.camera = this.orthographicCamera
        // this.camera.name = "camera";
        // this.camera.position.set( 0, 0, 100 );//this.camera.position.z = 1; // ***TODO The init value is irrelevant but this is needed
				// console.log('this.camera.position',this.camera.position)
        // this.camera.zoom = 1; // zoom = 1 -> 90 pixels/Angstrom, 0.5 -> 45pixel/Angstrom
        //this.camera.rotateX(Math.PI / 2);
    }


    /**
     * Adjusts the zoom to the structure size: if the structure is smaller the zoom is larger
     * @param  {number} distance
     */
    adjustZoomToStructSize(distance){

        let getCanvasDiagonalPixels = () => {
           return Math.sqrt(this.hostElement.clientWidth*this.hostElement.clientWidth
            + this.hostElement.clientHeight*this.hostElement.clientHeight)
        }

	    // With the initial camera position (the camera is perpendicular to the XY plane)
	    // I could measure the true ratio Angstrom/canvas pixel: zoom = 1 -> 90 pixels/Angstrom
	    // With the structure diagonal we can calculate the ratio pixel/Angstrom
	    let pixelAngstromWanted = getCanvasDiagonalPixels()/distance
	    //console.log('diagonal pixels',this.bViewer.getPixelProjectionPerXUnit(),
        //  this.bViewer.getCanvasDiagonalPixels(), pixelAngstromWanted)
	    // And finally we can guess the right camera zoom (cross multiply)
	    this.orthographicCamera.zoom = 0.4/*factor*/*(pixelAngstromWanted/90)
        // Updates the camera projection matrix. Must be called after any change of parameters
	    this.orthographicCamera.updateProjectionMatrix()
			this.perspectiveCamera.zoom = 0.4/*factor*/*(pixelAngstromWanted/90)
        // Updates the camera projection matrix. Must be called after any change of parameters
	    this.perspectiveCamera.updateProjectionMatrix()
    }


    /**
     * Sets up the mouse controls
     */
    setupControls() {
        this.controls = new TrackballControls(this.camera, this.hostElement)
				this.controls.rotateSpeed = 1.2;
				// this.controls.zoomSpeed = 1.2;
				this.controls.panSpeed = 1.0;
        this.controls.zoomSpeed = 1.2 //controls.rotateSpeed, controls.panSpeed = this.panSpeed
        this.controls.staticMoving = true
				this.controls.keys = [ 'KeyA', 'KeyS', 'KeyD' ];
        // this.controls.addEventListener('change', e =>{
        // 	// Zoom max and min values.
        // 	if (this.camera.zoom < MIN_ZOOM)
        // 		this.camera.zoom = MIN_ZOOM
        // 	else if (this.camera.zoom > MAX_ZOOM)
        // 		this.camera.zoom = MAX_ZOOM
        //     //this.camera.updateProjectionMatrix()
        // 	this.render()
        // } )
    }


    /**
     * Sets up the scenes
     */
    setupScenes() {
        this.mainScene = new Scene();
        this.infoScene = new Scene();
    }


    /**
     * Sets up the lights
     */
    setupLights() {
        // Key light
        let keyLight = new DirectionalLight(0xffffff, 0.5);
        keyLight.position.set(0, 0, 20);
        this.mainScene.add(keyLight);

        // Fill light
        let fillLight = new DirectionalLight(0xffffff, 0.45);
        fillLight.position.set(10, -10, -20);
        this.mainScene.add(fillLight);

        // White ambient light.
        let ambientLight = new AmbientLight(0x404040, 2.1); // soft white light
        this.mainScene.add(ambientLight);
    }


   /**
    * Animates the canvas
    */
    _animate() {
        requestAnimationFrame(this._animate.bind(this));
        this.controls.update();
        this.render();
    }


    /**
     * Clears and renders the scenes
     */
    render() {
    	// this.renderer.clear();
		this.renderer.render( this.mainScene, this.camera );
		// this.renderer.clearDepth()
    	this.renderer.render( this.infoScene, this.camera );
	}


    /**
     * Initializes the rendering
     */
	init(){
		//var axesHelper = new AxesHelper( 10 );
		//this.mainScene.add( axesHelper );

		// this.render()
		this._animate()
	}


    /**
     * Returns the 2D canvas element coordinates for a given scene 3D point
     * @param  {Vector3} point
     * @return {Vector3}
     */
    getCanvasCoordinates(point){
    	let p = point.clone()
			p.project(this.camera)
			p.x = (0.5 + p.x/2 ) * this.hostElement.clientWidth
			p.y = (0.5 - p.y/2) * this.hostElement.clientHeight
			p.z = 0;
			//console.log('getCanvasCoordinates', p);
			return p;
    }


    /**
     * Returns the pixel projection per unit
     * @return {float}
     */
    getPixelProjectionPerXUnit(){
    	let p = new Vector3(1,0,0)
		p.project(this.camera)
		p.x = (p.x/2) * this.hostElement.clientWidth
		//console.log('getPixelProjectionPerXUnit', p.x);
		return p.x
    }

    /**
     * Updates the different renderer elements for new canvas dimensions
     */
	onCanvasResize() {
        // This fixes the bug happening when in a workflow the user hits
        // the step 3 (DownloadInputFilesPage) downloads the input files
        // and goes back to the StructureBuilder: the 3D drawing is hidden.
        // The reason is the hostElement dimensions go to zero I don't why
        // at that moment (when the files are downloaded)
        // This happens on chrome and has to do with the way chrome shows
        // the downloaded files (reducing the effective screen area)
        // The fix prevents the StructureViewer from the adaptation to those zero dimensions
        if (this.hostElement.clientWidth === 0) return

        let aspectRatio = this.hostElement.clientWidth / this.hostElement.clientHeight
        let width = this.cameraWidth
        let height = width / aspectRatio
				this.perspectiveCamera.aspect = aspectRatio;
				this.perspectiveCamera.updateProjectionMatrix();
        this.orthographicCamera.left = -width / 2
        this.orthographicCamera.right = width / 2
        this.orthographicCamera.top = height / 2
        this.orthographicCamera.bottom = -height / 2
        this.orthographicCamera.updateProjectionMatrix()

        this.renderer.setSize(this.hostElement.clientWidth, this.hostElement.clientHeight)
        // this.infoCanvas.resize()

        this.controls.handleResize()

        // this.render()
    }


    /**
     * Clears the scenes
     */
    clearScenes() {

        let mainRootObj = this.mainScene.getObjectByName('structure');
				let bzMainRootObj = this.mainScene.getObjectByName('bz')
        let infoRootObj = this.infoScene.getObjectByName('lattice-parameters');
				let bzInfoRootObj = this.infoScene.getObjectByName('recLattice-parameters');
        //console.log('rootObject', mainRootObj, infoRootObj)
        if (mainRootObj){
            this.mainScene.remove(mainRootObj);
            this.clearObject(mainRootObj);
        }
        if (infoRootObj){
            this.infoScene.remove(infoRootObj);
            this.clearObject(infoRootObj);
        }
				if (bzMainRootObj){
					this.mainScene.remove(bzMainRootObj);
					this.clearObject(bzMainRootObj);
				}
				if (bzInfoRootObj){
            this.infoScene.remove(bzInfoRootObj);
            this.clearObject(bzInfoRootObj);
        }
    }


    /**
     * Removes a scene object and all its descendants freeing their resources
     * @param  {object} object
     */
    clearObject(object) {
        //console.log('clear object', object)
        object.traverse(function (node) {

            if (node.geometry) {
                node.geometry.dispose();
            }
            if (node.material) {
                node.material.dispose();
            }
            if (node.texture) {
                node.texture.dispose();
            }
        })

        while (object.children.length) {
            let child = object.children[0];
            object.remove(child);
        }
    }


}
