/**
 * @author Andrey Sobolev
 * @year 2023
 *
 * @overview File holding the class for the extra control.in parameters code and table
 */
import UIComponent from "../common/UIComponent";


export const deleteButtonSVG = `<svg viewBox="0 0 512 512" width="16">
        <path d="M64 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H64zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 
        0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM464 
        32H344l-9.4-18.7A24 24 0 0 0 313.1 0H198.8a23.72 23.72 0 0 0-21.4 13.3L168 32H48A16 16 0 0 0 32 
        48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path>
      </svg>`

export const addButtonSVG = `<svg viewBox="0 0 512 512" width="16">
        <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 
        12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 
        12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"></path>
      </svg>`


export default class ExtrasTableForm extends UIComponent {

  constructor(className) { //
    super('div', '.Form ' + className)
    this.setHTML(`
      <div style="display:flex">
        <div class="info">Here any non-essential keywords can be added to control.in. Please beware that 
        there are no sanity checks for these and use with discretion.</div>
      </div>
      <!--  Bootstrap table-->
      <div class="extras-table">
          <table>
            <colgroup>
              <col style="width:7%">
              <col style="width:43%">
              <col style="width:37%">
              <col style="width:13%">
            </colgroup>
            <thead>
              <tr>
                <th></th>
                <th>Key</th>
                <th>Value</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            <tr>
              <td></td>
              <td><input class="key-input" type="text" name="key" placeholder="output"></td>
              <td><input class="value-input" type="text" name="value" placeholder="json_log"></td>
              <td><button class="add-data" title="Add New Data">${addButtonSVG}</button></td>
            </tr>
            </tbody>
        </table>
      </div>`
    )
    this.table = this.e.querySelector(".extras-table table")

    let addButton = this.getElement('button.add-data')
    addButton.addEventListener('click', _ => {
      let keyInput = this.e.querySelector('input.key-input')
      let valueInput = this.e.querySelector('input.value-input')
      if (keyInput.value === "") return
      const nRows = this.table.rows.length;
      let newRow = this.table.insertRow(nRows - 1)
      newRow.insertCell(0)
      newRow.insertCell(1).appendChild( document.createTextNode(keyInput.value) )
      newRow.insertCell(2).appendChild( document.createTextNode(valueInput.value) )
      let buttonDOM = document.createElement('template');
      buttonDOM.innerHTML = `<button class="delete-data" title="Remove keyword">${deleteButtonSVG}</button>`;
      newRow.insertCell(3).appendChild( buttonDOM.content )
      const deleteButton = newRow.querySelector('button')
      deleteButton.addEventListener('click', ev => {
        this.table.deleteRow(ev.target.closest('tr').rowIndex);
      })
      keyInput.value = valueInput.value = ""
    })
  }

  /** Finds and returns the data stored in the Extras table
   *
   * @returns {Object}
   */
  getData(){
    const trueValues = ["", "true", ".true.","t"]
    const falseValues = [, "false", ".false.","f"]
    let data = {}
    // output keys stack in array; throw them away afterwards if there are none
    data['output'] = []
    const rows = Array.from(this.table.rows);
    for (let row of rows.slice(1, rows.length - 1)) {
      const key = row.cells[1].innerText.toLowerCase()
      const value = row.cells[2].innerText.toLowerCase()
      if (key === 'output') data[key].push(value)
      else if (trueValues.includes(value)) data[key] = true
      else if (falseValues.includes(value)) data[key] = false
      else data[key] = value
    }
    if (data['output'].length === 0) delete data['output']
    return data
  }

}