/**
 * @author Iker Hurtado, Sebastian Kokott, Andrey Sobolev
 *
 * @fileOverview File holding the OutputAnalyzerMod class and module
 */


import UIComponent from '../common/UIComponent.js'
import FileImporter from '../common/FileImporter.js'
import * as ModalPopup from '../common/ModalPopup.js'
import StructureViewer from "../structure-viewer/StructureViewer.js"
import {getOutputInstance, getHtmlRows} from './util.js'
import * as UserMsgBox from '../common/UserMsgBox.js'
import BZViewer from "../bz-viewer/BZViewer.js"
import downloadIcon from '../../img/download-icon.png'
import {BSDOSDashboard} from "./bsdos_dashboard";
import {AbDiDashboard} from "./absorption_dashboard";
import {ConvergenceDashboard} from "./convergence_dashboard";


let init_html = `

	<h1> Output Analyzer </h1>

	<div class="output-analyzer-importer-ph" > <div> </div>  <label><input type="checkbox"/>Select a folder containing the files</label> </div>

	<div class="no-output-box" style="display: none; ">
	</div>

	<div class="output-file-content" style="display: none">

		<div class="system-info-section page-section" style="margin-bottom: 0" >

			<div style="width: 40%">
				<table class="system-info-fields set-of-fields" style="width: 360px">
						<tr><td>Chemical Formula</td> <td><span class="formula-value"></span></td></tr>
						<tr><td>Number of atoms</td> <td><span class="atom-num-value"></span></td></tr>
				</table>

				<div class="page-section" style="margin-bottom: 0">
					<div class="page-section-title">Results</div>
					<table class="results-info-fields set-of-fields" style="width: 360px">
					</table>
				</div>

			</div>
      <div>
			  <div class="geometry-viz-ph" > </div>
			  <div class="geometry-viz-controls"> </div>
			</div>
		</div>
	</div>

	<div class="dos-bs-box page-section" style="display: none">
		<div class="page-section-title">DOS and Band Structure graphs</div>
		<div class="bs-error-msg" > </div>
		<div class="section-content" style="justify-content: space-evenly;"></div>
	</div>

	<div class="bz-viewer-box page-section" style="display: none;">
		<div class="bz-viewer-ph" style="justify-content: space-evenly;"></div>
	</div>

	<div class="absorption-box page-section" style="display: none">
		<div class="page-section-title">Absorption and Dielectric function graphs</div>
		<div class="absorption-error-msg" > </div>
		<div class="section-content" style="justify-content: space-evenly;"></div>
	</div>

	<div class="output-file-content" style="display: none">
		<div class="page-section" >
			<div class="page-section-title">Calculation Summary</div>
			<div class="section-content" style="justify-content: space-evenly;">
        <table class="calculation-info-fields set-of-fields" style="width: 350px"></table>
        <div id="summary-graphs" style="width: 60%"></div>
      </div>
    </div>
	</div>

	<div class="input-files-section" style="display: none">

		<div class="page-section" >

			<div class="page-section-title">Input files</div>

			<div class="section-content input-files-box" style="justify-content: space-evenly;">
			</div>

		</div>

	</div>

	<div class="parsing-files-section" style="display: none">
		<div class="page-section" >
			<div class="page-section-title">Parsing Errors</div>
			<div class="section-content">
				<table class="parsing-info-fields set-of-fields" style="width: 350px"></table>
			</div>
		</div>
	</div>
`

const NO_MAIN_FILE_TEXT = 'No main output file has been detected'
const NO_FILE_TEXT = 'No parsable file detected'
const PARSING_ERROR = `
	Something unexpectedly went wrong when parsing the main output file.<br>
	Please raise an issue in the <a href="https://gitlab.com/gims-developers/gims/-/issues" target="_blank">GIMS-gitlab</a>. Sorry for any inconvenience!
`

/**
 * OutputAnalyzer application module UI component
 */
export default class OutputAnalyzerMod extends UIComponent{

	constructor() {
    super('div', '#OutputAnalyzerMod')
    this.setHTML(init_html)

    this.importer = new FileImporter('Import output files:', true)
    this.getElement('.output-analyzer-importer-ph div').appendChild(this.importer.e)

    this.getElement('.output-analyzer-importer-ph input[type="checkbox"]')
      .addEventListener('click', e => {
        this.importer.setDirectoryMode(e.target.checked)
    })

    this.buttons = []

    this.noOutputBox = this.getElement('.no-output-box')

    this.outputContentBoxes = this.getElements('.output-file-content')

    this.dosBsBox = this.getElement('.dos-bs-box')
		this.bzViewerBox = this.getElement('.bz-viewer-box')
    this.bsErrorMsgBox = this.getElement('.bs-error-msg')
		this.absorptionBox = this.getElement('.absorption-box')

    // this.absorptionMsgBox = this.getElement('.absorption-error-msg')
    this.inputFilesBox = this.getElement('.input-files-section')
    this.calculationSummary = undefined
  }


  /**
   * Updates the component for a code change (application level event)
   * Do nothing in this case
   * @param  {string} code
   */
  updateForCode(code){
    //log('updateForCode')
  }


  /**
   * Updates the component for a setting change (application level event)
   * Do nothing in this case
   * @param  {object} newSettings
   */
  updateSettings(newSettings){
  }


  /**
   * Module initialization method.
   * Sets the behavior of the module when the calculation output files
   * are imported: they are parsed and the relevant info is shown
   */
  init(){
    this.importer.setImportListener( async files => {
      let promises = []

      // client processing
      for (let i = 0; i < files.length; i++) {
        let file = files[i]
        let reader = new FileReader()
        // when the file is read it triggers the onload event above.
        if (file.type.includes('gzip')) reader.readAsArrayBuffer(file)
        else reader.readAsText(file)

        promises.push(new Promise( (resolve, _) => {
          reader.addEventListener('load', e => {
            resolve({ content: e.target.result, name: file.name, type: file.type })
          })
        }))
      }

      Promise.all(promises)
        .then((filesData) => getOutputInstance(filesData))
        .then((output) => {
          // reset the panels
          showElement(this.noOutputBox, false)
          showElement(this.outputContentBoxes[0], false)
          showElement(this.dosBsBox, false)
					showElement(this.absorptionBox, false)
          showElement(this.outputContentBoxes[1], false)
          showElement(this.inputFilesBox, false)
					showElement(this.bzViewerBox, false)

          if (!output) {
              this.noOutputBox.innerHTML = NO_FILE_TEXT
              showElement(this.noOutputBox)
              return
          }

          // Disambiguation modal window. More than one output file and/or two DOS data files
          const outputFiles = output.files.output
          const dosFiles = output.files.dos
					const absorptionFiles = output.files.absorption
					const dielectricFiles = output.files.dielectric
					// console.log("In OutputAAnalyser")
					// console.log(dielectricFiles)
					// let dosNames = Array.from(dosFiles.keys())
          if (outputFiles.size > 1){
            if (output.workflows.length > 0 && dosFiles.size === 0)
              for (const fileName of outputFiles.keys()) {
                console.log(fileName)
                const workflow = fileName.split('/')[0]
                output.parseOutputFile(fileName)
                if (output.runTimeChoices.isPeriodic === false)
                  if (output.runTimeChoices.spin !== 'collinear')
                    dosFiles.set(workflow + '/MolDOS.dat', output.ksEv)
                  else dosFiles.set(workflow + '/MolDOS.dat.spin', output.ksEv)
            }
            // FileChooser popup appears
            let fileChooser = new FileChooser(outputFiles, dosFiles)
            // this happens when the FileChooser button is clicked
            fileChooser.setListener( (outputFileName) => {
              if (outputFiles.size > 0){
                // If only one output file: outputFileName = undefined, no choice
								try {
									output.parseOutputFile(outputFileName)
								} catch (e) {
									this.noOutputBox.innerHTML = PARSING_ERROR
									showElement(this.noOutputBox)
								}
                 // The first is parsed
                this.showOutputLayout(output)
              } else {
                this.noOutputBox.innerHTML = NO_MAIN_FILE_TEXT
                showElement(this.noOutputBox)
              }
              let bsInfo = output.getBsInfo()

              this.showBSAndDOSData(bsInfo.bsData, dosFiles, bsInfo.lackingSegments, output.getStructure(),
                bsInfo.hasSOC, bsInfo.hasSpin)

              ModalPopup.hideModal()
            })
            ModalPopup.setModalComponent(fileChooser.e)
            ModalPopup.showModal(true)

          } else {  // No modal window: no more than one output file and DOS data file

						if (outputFiles.size === 1) { // there is output file
							try {
								output.parseOutputFile(outputFiles[0])
							} catch(e) {
								this.noOutputBox.innerHTML = PARSING_ERROR + `<br>The error is:<br>` + output.errors
								showElement(this.noOutputBox)
							}

							this.showOutputLayout(output)
						} else {
							 this.noOutputBox.innerHTML = NO_MAIN_FILE_TEXT
               showElement(this.noOutputBox)
						}

            const bsInfo = output.getBsInfo()
						if (
							output.runTimeChoices.isPeriodic === false && output.runTimeChoices.spin !== 'collinear'&&
							dosFiles.size === 0 &&
							outputFiles.size === 1){
						 dosFiles.set("MolDOS.dat", output.ksEv)
					 	}
						else if (
							output.runTimeChoices.isPeriodic === false && output.runTimeChoices.spin === 'collinear'&&
							dosFiles.size === 0 &&
							outputFiles.size === 1){
						  dosFiles.set("MolDOS.dat.spin", output.ksEv)
					 	}
            this.showBSAndDOSData(bsInfo.bsData, dosFiles, bsInfo.lackingSegments, output.getStructure(), bsInfo.hasSOC, bsInfo.hasSpin)
						this.showAbsorptionData(absorptionFiles, dielectricFiles)
          } // else

        },

        error => {
          UserMsgBox.setMsg('Error uploading files')
          console.error('ERROR ', error)
        }
      )
    })

  } // init()


  /**
   * The DOS and band structure plots are built and shown
   * @param  {array} bsData
   * @param  {Map<string,array>} dosFiles
   * @param  {object} lackingSegments
   * @param  {object} structure
   * @param  {boolean} hasSOC
   * @param  {boolean} hasSpin
   *  Info of the bands structure lacking segments
   *///HHK
  showBSAndDOSData(bsData, dosFiles, lackingSegments, structure=undefined,
                   hasSOC=false, hasSpin=false){
		if (this.dosBsGraphs !== undefined){
			this.dosBsGraphs.e.innerHTML = ''
			this.dosBsGraphs = null
		}
    // DOS and band structure graphs
    const hasBS = bsData.length > 0
    const hasDOS = dosFiles.size > 0
		let isStructure =  (structure && structure.atoms.length > 0)
    this.dosBsBox.style.display = (hasBS || hasDOS) ? 'block' : 'none'
		this.bzViewerBox.style.display = ( ( isStructure && (bsData.length > 0)) ? 'flex' : 'none')
    if (hasBS || hasDOS) {
      this.dosBsGraphs = new BSDOSDashboard(this.dosBsBox.querySelector('.section-content'), 'graph-box',
        1150, 700, hasBS, hasDOS, hasSpin, hasSOC)

      this.dosBsGraphs.setBSData(bsData)
      this.dosBsGraphs.setDOSData(dosFiles)
      // couple two graphs
      if (hasBS && hasDOS) this.dosBsGraphs.couple()

      if (bsData.length > 0 && lackingSegments.length > 0)
        this.bsErrorMsgBox.innerHTML = 'Some band structure files are lacking: ' + lackingSegments.join(', ')
      else
        this.bsErrorMsgBox.innerHTML = ''
    }

		let table = this.getElement('.band-path-table')
		if (table)
			this.bzViewerBox.removeChild(table)
		if (isStructure > 0 && bsData && bsData.length > 0) {
			if (this.bzViewer === undefined)
				this.bzViewer = new BZViewer(this.getElement('.bz-viewer-ph'))
			let paths = this.bzViewer.getBandPath(bsData)
			if (paths) {
				this.bzViewer.setNewStructure(structure,true, paths)
				let table = this.bzViewer.getHTMLBandPath(paths)
				this.bzViewerBox.appendChild(table)
			}
		} else {
			this.bzViewerBox.style.display = 'none'
		}
  }


	/**
   * The absorption plots are built and shown
   * @param  {Map<string, array>} absorptionFiles
   * @param  {Map<string, array>} dielectricFiles
   *  Info of the bands structure lacking segments
   *///HHK
  showAbsorptionData(absorptionFiles, dielectricFiles){
		console.log('in show abso')
		let absorptionData = absorptionFiles.size > 0 ? absorptionFiles.get(absorptionFiles.keys().next().value) : undefined
		let dielectricData = dielectricFiles.size > 0 ? dielectricFiles.get(dielectricFiles.keys().next().value) : undefined

    const anyAbsorptionData = (absorptionData)
		const anyDielectricData = (dielectricData)
    this.absorptionBox.style.display = (anyAbsorptionData || anyDielectricData ? 'block' : 'none')
    if (anyAbsorptionData || anyDielectricData) {
      this.absorptionGraphs = new AbDiDashboard(this.absorptionBox.querySelector('.section-content'),
        '.AbsorptionGraphs', 900, 600, !!anyAbsorptionData, !!anyDielectricData)
      this.absorptionGraphs.setAbData(absorptionFiles)
      this.absorptionGraphs.setDiData(dielectricFiles)
    }
  }

  /**
   * The main output information is shown
   * @param  {object} output
   */
  showOutputLayout(output){

    showElement(this.outputContentBoxes[0])
    showElement(this.outputContentBoxes[1])

    const isRelaxation = output.isRelaxation()
		let structure = output.getStructure()

    //  **** System information section ****

    this.getElement('.formula-value').textContent = output.systemInformation.formulaUnit
    this.getElement('.atom-num-value').textContent = output.systemInformation.nAtoms

    // Structure viewer (animation for relaxation case)
    if (this.viewer) this.viewer.reset()
    else this.viewer = new StructureViewer(this.getElement('.geometry-viz-ph'), false, undefined)
    // console.log(output.scfLoops);
    const structureSequence = []
    if (isRelaxation) {
			// console.log(output.calculation.scfLoops);
      output.scfLoops.forEach( scfLoop => {
        // log('scfLoop.structure', i++, scfLoop.structure)
        if (scfLoop.structure) {
          // let loopStructure = new Structure()
          // loopStructure.atoms = scfLoop.structure.atoms
          //***** TODO lattice vector??
          structureSequence.push(scfLoop.structure)
        }
      })
			// console.log(structureSequence);
      if (structureSequence.length > 0)
        this.viewer.setNewAnimation(structureSequence)
    } else {
      this.viewer.setNewStructure(structure)
    }
    // Atom visualization controls
    let divVizControls = this.getElement('.geometry-viz-controls')
    if (output.mulliken || output.hirshfeld)
      divVizControls.innerHTML += `<input type="radio" id="atom-viz-species" name="atom-viz-radio" class="hidden" checked>
        <label for="atom-viz-species" class="atom-viz-label">Species</label>`
    if (output.hirshfeld)
      divVizControls.innerHTML += `<input type="radio" id="atom-viz-hirshfeld" name="atom-viz-radio" class="hidden">
        <label for="atom-viz-hirshfeld" class="atom-viz-label">Hirshfeld</label>`
    if (output.mulliken)
      for (const key in output.mulliken) {
        const mode = (key === 'simple') ? '' : `(${key.toUpperCase()})`
        divVizControls.innerHTML += `<input type="radio" id="atom-viz-mulliken:${key}" name="atom-viz-radio" class="hidden">
            <label for="atom-viz-mulliken:${key}" class="atom-viz-label">Mulliken ${mode}</label>`
      }

    let vizControls = this.getElements('.geometry-viz-controls input')
    vizControls.forEach( e => e.addEventListener('change', ev => {
      let vizType = ev.target.id.split('-')[2]
      switch (vizType) {
        case 'mulliken:simple':
          this.viewer.changeAtomsColors(output.mulliken['simple'])
          break;
        case 'mulliken:sr':
          this.viewer.changeAtomsColors(output.mulliken['sr'])
          break;
        case 'mulliken:soc':
          this.viewer.changeAtomsColors(output.mulliken['soc'])
          break;
        case 'hirshfeld':
          this.viewer.changeAtomsColors(output.hirshfeld)
          break;
        case 'species':
          this.viewer.changeAtomsColors()
      }
    }))

    console.log('Output:', output)
    //  **** Results section ****
    this.getElement('.results-info-fields').innerHTML = getHtmlRows(output.getResultsQuantities())

    //  ***** Calculation summary section
    if (this.calculationSummary === undefined) {
      this.calculationSummary = new ConvergenceDashboard(this.getElement("#summary-graphs"),
        '.convergence-graphs', isRelaxation)
    }
    this.calculationSummary.plotData(output.dataSeries, output.relaxationSeries)

    // Calculation information
		this.getElement('.calculation-info-fields').innerHTML = getHtmlRows(output.getCalculationInfo(), false)

    //**** Input files section ****

    let inputFileMap = output.getInputFilesMap()

    showElement(this.inputFilesBox, inputFileMap.size > 0)
    this.getElement('.input-files-box').innerHTML = ''

    inputFileMap.forEach( (content, name) => {
      const href = window.URL.createObjectURL( new Blob([content], {type: 'text/plain'}) )
      const boxElement = document.createElement("div")
      boxElement.innerHTML = `
          <button class="input-files-button"> View <b>${name}</b> </button>
          <a class="download-link" download="${name}" href="${href}">
            <img style="vertical-align: middle; padding-left: 10px" src="${downloadIcon}" alt="Download" />
          </a>
        `
      let dtext = content.replace(/[\u00A0-\u9999<>&]/gim, function(i) {
        return '&#'+i.charCodeAt(0)+';';
      })

      boxElement.querySelectorAll('.input-files-button').forEach( e => {
        e.addEventListener('click', _ => {
          ModalPopup.setModalContent('<blockquote><pre><code>'+dtext+'</code></pre></blockquote>')
          ModalPopup.showModal(true)
        })
      })

      this.getElement('.input-files-box').appendChild(boxElement)
    })


    // Parsing errors - by Sebastian
		// console.log(output.errors);
		this.getElement('.parsing-files-section').style.display = (output.errors.length > 0 ? 'block' : 'none')
		let html = ''
		output.errors.forEach(er => {
			console.log(er);
			html += `<tr> <td>${er[0]}</td> <td>${er[1].replace(/[\u00A0-\u9999<>&]/gim, function(i) {
        return '&#'+i.charCodeAt(0)+';';
      })}</td> </tr>`
		})
		this.getElement('.parsing-info-fields').innerHTML = html
  }

}


/**
 * Simple UI component that enables the choice between two files of the same type
 * (output and DOS files). It's shown inside a modal popup
 */
class FileChooser extends UIComponent{

  /**
   * @param  outputFiles {Map<string,string>}
   * @param  dosFiles {Map<string,string>}
   */
  constructor(outputFiles, dosFiles){

    super('div', '.extensionChooser')
    let html = ''

    if (outputFiles.size > 1){
      html += '<div>More than one aims output file uploaded, choose the one to be displayed:</div><div class="radios-ph">'
      outputFiles.forEach( (data, fileName) => {
        html += `<input type="radio" name="output-file-name" value="${fileName}"> ${fileName} <br>`
      })
      html += '</div>'
    }

    this.setHTML(html+'<div class="button-ph"><button>Done</button></div>')

    this.getElement('button').addEventListener( 'click', _ => {
      //log('DOS file'+chosenInput.value)
      let outputFileEl = this.getElement('input[name="output-file-name"]:checked')
      let dosFileEl = this.getElement('input[name="dos-data-file"]:checked')
      this.listener( (outputFileEl === null ? undefined : outputFileEl.value), (dosFileEl === null ? undefined : dosFileEl.value))
    })
  }


  /**
   * Sets the listener that will be called when the button is clicked
   * @param {function} listener
   */
  setListener(listener){
    this.listener = listener
  }
}
