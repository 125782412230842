/**
 * @author Andrey Sobolev
 *
 * @overview The Editor for the Convergence workflow step (includes a combobox of convergence parameters and a table of their values)
 */
import {WorkflowStep, WorkflowStepEditor} from "./workflow-step";
import UIComponent from "../common/UIComponent";
import {addButtonSVG, deleteButtonSVG} from "../control-generator-mod/extrasTable";
import {getCode} from "../State";
import {setError} from "../common/UserMsgBox";


export class ConvergenceStep extends WorkflowStep {
  static config = {
    ...WorkflowStep.config,
    group: 'Optimizations',
    name: 'Convergence',
    type: 'Convergence',
  }
  constructor(parent, stepId) {
    super(parent, stepId);
    this.editor = new ConvergenceStepEditor(this)
    this.properties = {}
  }

  save() {
    const param = this.editor.getConvergenceParam()
    if (param === "") return false
    const tableData = this.editor.table.getData()
    if (tableData === []) this.properties.convergence = undefined
    else this.properties.convergence = {[param]: tableData}
    this.editor.update()
    return true
  }

  validate() {
    return (this.workflow.hasStructure(this.id) &&
            this.workflow.hasControl(this.id) &&
            this.properties.convergence !== undefined
    )
  }
}

export class ConvergenceStepEditor extends WorkflowStepEditor {
  constructor(parent) {
    super(parent);
    this.setHTML(`<div class="swd-editor-title">
    Convergence criteria: 
</div>
<div class="swd-editor-div" id="selection-box">
    <select name="input-keys-select" style="width: 80%;">
        <option value="">--- Convergence parameter ---</option>
        <option value="k_grid">k_grid</option>
        <option value="species_defaults">basis</option>
    </select>
</div>
<div class="swd-editor-title">
    Values:
</div>
`)
    this.table = new ValuesTable('.swd-editor-div', this)
    this.e.appendChild(this.table.e)
    let selector = this.getElement('select')
    selector.addEventListener('change', _ => this.table.switchInput(selector.value))
  }

  provide(step, ctx){
    this.step = this.step === undefined ? step : this.step
    this.ctx = this.ctx === undefined ? ctx : this.ctx
    return this.e
  }

  update(){
    this.step.properties = this.parent.properties
    this.ctx.notifyPropertiesChanged()
  }

  getConvergenceParam() {
    return this.getElement('select').value
  }
}

class ValuesTable extends UIComponent {

  constructor(className, parent) {
    super('div', className);
    this.editor = parent
    this.setHTML(`<table style="width:80%; text-align: center;" class="table-class">
        <col style="width: 80%; text-align: center">
        <col style="width: 20%">
        <tbody>
        </tbody>
    </table>
    `)
    this.table = this.e.querySelector("table")
    this.input = undefined
  }

  /** Switches inputs according to the chosen value
   * @param inputName {string}
   */
  switchInput(inputName) {
    this.table.innerHTML = ""  // clear table
    switch (inputName) {
      case "k_grid":
        this.input = new KPointsInput(this, this.table)
        break;
      case "species_defaults":
        this.input = new BasisInput(this, this.table)
        break;
      default:
        this.input = undefined;
    }
    if (this.input !== undefined)
      this.table.append(this.input.e)
  }

  getData() {
    return Array.from(this.table.rows)
      .slice(0, -1)
      .map(row => row.cells[0].textContent)
  }
}

class KPointsInput extends UIComponent {
  constructor(parent, table) {
    super('tr', '.k-grid-input')
    this.setHTML(`<td>
      <input type="text" style="width: 22%"> x
      <input type="text" style="width: 22%"> x
      <input type="text" style="width: 22%">
    </td>
    <td><button class="add-data" title="Add New Data">${addButtonSVG}</button></td>`)
    this.parent = parent
    this.table = table
    const addButton = this.getElement('button.add-data')
    addButton.addEventListener('click', _ => {
      const inputs = Array.from(this.getElements('input'))
      const values = inputs.map(el => el.value)
      if (values.some(x => x === "")) return;
      // get a new row with the values
      let row = this.table.insertRow(this.table.rows.length - 1)
      row.insertCell(0).appendChild(document.createTextNode(values.join(' x ')))
      let buttonDOM = document.createElement('template');
      buttonDOM.innerHTML = `<button class="delete-data" title="Remove keyword">${deleteButtonSVG}</button>`;
      row.insertCell(1).appendChild( buttonDOM.content )
      const deleteButton = row.querySelector('button')
      deleteButton.addEventListener('click', e => {
        this.table.deleteRow(e.target.closest('tr').rowIndex)
        // this I don't like. may be use some signals?
        this.parent.editor.parent.save()
      })
      this.parent.editor.parent.save()
    })
  }
}

class BasisInput extends UIComponent {
  constructor(parent, table) {
    super('tr', '.basis-input');

    this.parent = parent
    this.table = table
    this.setHTML(`<td>
      <select class="input-select" style="width: 80%;">
      </select>
    </td>
    <td><button class="add-data" title="Add New Data">${addButtonSVG}</button></td>`)
    const selector = this.getElement('select')
    this.getBases().then((bases) => {
      bases.forEach(el => {
        let opt = document.createElement('option');
        opt.value = el;
        opt.innerHTML = el;
        selector.appendChild(opt);
      })
    })
    let addButton = this.getElement('.add-data')
    addButton.addEventListener('click', _ => {
      // get a new row with the values
      let row = this.table.insertRow(this.table.rows.length - 1)
      row.insertCell(0).appendChild(document.createTextNode(selector.value))
      let buttonDOM = document.createElement('template');
      buttonDOM.innerHTML = `<button class="delete-data" title="Remove keyword">${deleteButtonSVG}</button>`;
      row.insertCell(1).appendChild( buttonDOM.content )
      // remove the value from the selector
      selector.removeChild(selector.querySelector(`option[value=${selector.value}]`))
      const deleteButton = row.querySelector('button')
      deleteButton.addEventListener('click', e => {
        let value = e.target.closest('tr').querySelector('td').innerHTML
        let opt = document.createElement('option');
        opt.value = value;
        opt.innerHTML = value;
        selector.appendChild(opt);
        this.table.deleteRow(e.target.closest('tr').rowIndex)
        this.parent.editor.parent.save()
      })
      this.parent.editor.parent.save()
    })
  }

  async getBases() {
    let response = await fetch(`/get-bases?code=${getCode()}`, {
      method: 'GET'
    })
    if (!response.ok) {
      setError('Could not get bases')
      return;
    }
    const text = await response.text()
    return JSON.parse(text)
  }
}