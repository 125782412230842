/**
 * @author Iker Hurtado
 *
 * @fileoverview File holding AtomSelector utility
 */

// import * as THREE from '../../lib/three.js'
import * as THREE from 'three'
import {getDistance, getAngle, getTorsionAngle} from '../common/math.js'
import {Conf} from '../Conf.js'


/**
 * Helper class that handles the atom selection and interaction
 * in the StructureViewer
 */
export default class AtomSelector{

    /**
     * @param  {ThreeViewer} threeViewer
     * @param  {InfoCanvas} infoCanvas
     * @param  {State} moduleState
     *   Object representing the state of the Structure builder module
     */
    constructor(threeViewer, infoCanvas, moduleState){

        this.moduleState = moduleState
        this.viewer = threeViewer
        this.infoCanvas = infoCanvas
        const canvas = infoCanvas.canvas//renderer.domElement
        this.camera = threeViewer.camera
        this.raycaster = new THREE.Raycaster()
        // These are set up externally when the Viewer is initilizated with data
        this.atoms // atom meshes
        this.structure // structure data

        // Hovering related variables
        this.hoverAtom = null
        this.keypressed = null
        // Selection related variables
        this.selAtoms = []

        // Dragging related variables
        this.draggingEnabled = false
        this.dragging = false // The dragging is happening at the moment
        this.dragStart = null
        this.draggingAtomStartPos = null
        this.draggingAtom = null
        this.draggingAtomIndex = null

        this.mouse = new THREE.Vector2();
        canvas.addEventListener('pointermove', e => {
            let canvasPos = this.infoCanvas.getCanvasPosition() //  this.infoCanvas.position
            this.mouse.x = ( (e.clientX - canvasPos.x) / canvas.width ) * 2 - 1;
            this.mouse.y = - ( (e.clientY - canvasPos.y) / canvas.height ) * 2 + 1;
            //console.log('Mousemove', this.mouse, canvasPos)

            // if (this.checkIntersection()) this.viewer.render()
            this.checkIntersection()

            if (this.draggingEnabled && this.dragging){ // An atom is being dragging
                let mouseChange = this.mouse.clone().sub( this.dragStart )
                const scale_x = ( this.camera.right - this.camera.left ) / this.camera.zoom;
                const scale_y = ( this.camera.top - this.camera.bottom ) / this.camera.zoom;
                mouseChange.x *= scale_x;
                mouseChange.y *= scale_y;
                let inc = new THREE.Vector3(mouseChange.x/2, mouseChange.y/2, 0) // Increase in real coordinates (amstrongs)
                inc = this.camera.localToWorld(inc) // Converts the vector from local (camera) space to world space -> The movement is happening in a perpendicular plain to the camera
                inc.sub(this.camera.position) // The position of the camaera has to be substructed
                let newPos = inc.clone().add(this.draggingAtomStartPos)
                //console.log('mousemove', this.camera.position, inc, newPos )
                this.draggingAtom.position.copy(newPos)
                this.viewer.render()
                // The selected atom info area is cleared while displacing
                this.infoCanvas.clearSelectedAtomArea() // this.viewer.infoCanvas.clearSelectedAtomArea()
            }

        }, false)

        document.addEventListener('keydown', e => {
            this.keypressed = e.keyCode
        })
        document.addEventListener('keyup', e => {
            this.keypressed = null
        })

        // Atoms selection
        canvas.addEventListener('click', e => {
            // console.log('click', this.hoverAtom, e)
            // SK: Add atom removal
            if (this.draggingEnabled && this.keypressed === 68 && this.hoverAtom) {
               this.moduleState.removeAtom(this.atoms.children.indexOf(this.hoverAtom))
               this.hoverAtom = null
            }
            if (this.hoverAtom === null) return
            // The click is on an atom
            // Shift key is needed to select atoms
            if (e.shiftKey && this.selAtoms.indexOf(this.hoverAtom) < 0) {
                this.selAtoms.push(this.hoverAtom)
                this.highlightAtomMesh(this.hoverAtom, true)
            }

            // calculateFromSelection
            this.infoCanvas.clearDATAtomsArea()

            if (this.selAtoms.length >= 2 && this.selAtoms.length <= 4){
                const atoms = this.structure.atoms//State.getStructure().atoms
                const p1Index = this.atoms.children.indexOf(this.selAtoms[0])
                const p2Index = this.atoms.children.indexOf(this.selAtoms[1])
                const p1 = atoms[p1Index].position
                const p2 = atoms[p2Index].position
                //console.log('DDDDD',  p1, p2)
                if (this.selAtoms.length === 2){ // distance
                    let d = getDistance(p1, p2)
                    this.infoCanvas.showDATAtomsInfo(
                        'Distance (atom#'+(p1Index+1)+' - atom#'+(p2Index+1)+'): '+d.toFixed(5)+'Å')

                }else if (this.selAtoms.length === 3){ // angle
                    const p3Index = this.atoms.children.indexOf(this.selAtoms[2])
                    const p3 = atoms[p3Index].position
                    this.infoCanvas.showDATAtomsInfo(
                        'Angle (atom#'+(p1Index+1)+' - atom#'+(p2Index+1)+' - atom#'+(p3Index+1)+'): '+getAngle(p1, p2, p3).toFixed(5)+'º')

                }else if (this.selAtoms.length === 4){ // angle
                    const p3Index = this.atoms.children.indexOf(this.selAtoms[2])
                    const p3 = atoms[p3Index].position
                    const p4Index = this.atoms.children.indexOf(this.selAtoms[3])
                    const p4 = atoms[p4Index].position
                    this.infoCanvas.showDATAtomsInfo(
                        'Torsion angle (atoms: #'+(p1Index+1)+' - #'+(p2Index+1)+' - #'+(p3Index+1)+' - #'+(p4Index+1)+'): '+getTorsionAngle(p1, p2, p3, p4).toFixed(5)+'º')
                        //'Torsion angle (atom#'+(p1Index+1)+' - #atom'+(p2Index+1)+' - atom#'+(p3Index+1)+' - atom#'+(p4Index+1)+'): '+getTorsionAngle(p1, p2, p3, p4).toFixed(5)+'º')
                }
            }

        })


        // Cancel the selection
        canvas.addEventListener('contextmenu', e => {

            if (this.selAtoms.length > 0){
                let tempSelAtom = this.selAtoms
                // the highlightAtom() needs to have the this.selAtoms updated
                this.selAtoms = []
                tempSelAtom.forEach( atom => {
                    this.highlightAtomMesh(atom, false)
                })
                this.infoCanvas.clearDATAtomsArea()
            }

        })

        // Dragging
        canvas.addEventListener('pointerdown', e => {
            // console.log('pointerdown',  this.hoverAtom)
            if (this.draggingEnabled && this.hoverAtom !== null && this.keypressed === null){ // mouse on an atom
                this.dragging = true
                this.dragStart = this.mouse.clone()
                this.draggingAtomStartPos = this.hoverAtom.position.clone()
                this.draggingAtom = this.hoverAtom
                this.viewer.controls.noRotate = true
            }
        }, false)

        canvas.addEventListener('pointerup', e => { // the dragging atom is released
            if (this.draggingEnabled && this.dragging){
                this.dragging = false
                this.dragStart = null
                const atomIndex = this.atoms.children.indexOf(this.draggingAtom)
                //this.atomDisplacementListener(atomIndex, this.draggingAtom.position)
                this.moduleState.updateAtomPosition(atomIndex, this.draggingAtom.position.toArray(), false)
                this.draggingAtom = null
                this.viewer.controls.noRotate =false
                // The selected atom info area is shown again with the new position data
                this.atomHitListener(atomIndex) // this.atomReleaseListener(atomIndex)
            }
        }, false)
    }


    /**
     * Enabled the atom displacement user interaction
     * @param  {boolean}
     */
    enableAtomDisplacement(dragging){
        this.draggingEnabled = dragging
    }


    /**
     * Checks if there is mouse pointer intersects an atom
     * @return {boolean}
     */
    checkIntersection(){
        let change = false
        if (this.atoms !== undefined ){
            // update the picking ray with the camera and mouse position
            // convenience method to set the raycaster origin and direction
            this.raycaster.setFromCamera( this.mouse, this.camera ) // this.raycaster.ray

            // calculate objects intersecting the picking ray
            let intersects = this.raycaster.intersectObjects( this.atoms.children );
            //if (intersects.length > 0) console.log('intersects', intersects,this.raycaster)// atoms.children)

            // If the mouse pointer is on one (or more) atoms &&
            // that atom (the first) is different from the previous one (continuous hovering)
            if (intersects.length > 0 && this.hoverAtom !== intersects[0].object){

                // if (this.hoverAtom !== null)
                //     this.highlightAtomMesh(this.hoverAtom, false)//changeColor(this.hoverAtom, 0.3)

                this.hoverAtom = intersects[0].object

                change = true
                //console.log('intersects', )
                const atomIndex = this.atoms.children.indexOf(this.hoverAtom)

                // this.highlightAtomMesh(this.hoverAtom, true)//changeColor(this.hoverAtom, -0.3)
                //this.viewer.infoCanvas.showAtomInfo()
                this.atomHitListener(atomIndex)

            // If no mouse pointer is on an atom && there was a previous hovered, this is set to normal
            }else if (intersects.length === 0 && this.hoverAtom !== null){
                // this.highlightAtomMesh(this.hoverAtom, false)//changeColor(this.hoverAtom, 0.3)
                this.hoverAtom = null
                change = true
                this.infoCanvas.clearSelectedAtomArea()
            }
            // Two more circunstances
            // No mouse pointer on an atom && no atom hovered in the previous step -> Do nothing
            // Mouse pointer on an atom && that atom is the previously hovered -> Do nothing
        }
        return change

    }


    /**
      * Sets a listener that will be called when the mouse pointer hits
      * an atom. The hit atom index will be passed in
      * @param {function} listener
      */
    setAtomHitListener(listener){
        this.atomHitListener = listener
    }


    /**
     * Highligths an atom mesh
     * @param  {object} atom Atom object to highlight
     * @param  {boolean} on
     *   If true the atom is highlighted if false it's toned-down
     */
    highlightAtomMesh(atom, on){
        const atomIndex = this.atoms.children.indexOf(atom)
        if (atomIndex < 0) return
        else this.highlightAtom(atomIndex, on)
    }


    /**
     * Highligths an atom mesh
     * @param  {int} atomIndex Index of atom to highlight
     * @param  {boolean} on
     *   If true the atom is highlighted if false it's toned-down
     */
    highlightAtom(atomIndex, on){
        //console.log('highlightAtom', this.atoms,atomIndex, on)
        const atom = this.atoms.children[atomIndex]

        if (atom === undefined || this.selAtoms.indexOf(atom) >= 0)
            return

        let color = new THREE.Color(
            Conf.getSpeciesColor(this.structure.atoms[atomIndex].species))// State.getStructure().atoms[atomIndex].species))
        if (on) { color.r -= 0.4; color.g -= 0.4; color.b -= 0.4 }

        atom.material.color = color
        this.viewer.render()
    }

}
