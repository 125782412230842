/**
 * @author Iker Hurtado
 *
 * @overview Form is a UI component that intends to make easy the setup
 * of fully-featured forms in the application
 */

import UIComponent from './UIComponent.js'
import {RadioField, InputField, NoInputField, SelectField, RangeField, ColorField} from "./FormElements.js"

/**
 * UI component to set up forms
 */
export default class Form extends UIComponent{

  /**
   * @param {string} className - Specific class name for the form instance
   */
  constructor(className){ //

    super('div', '.Form ' + className)

    this.setHTML(`<div class="user-msg"><div>`)

    this.addElement('div',`<div class="info    "><div>`)
    this.parent = undefined
    this.idx = undefined
    this.fieldMap = new Map()
    this.initValues = new Map()
    this.validationInfo = new Map()
    this.explicitInclusionInfo = new Map()
    this.datatype = new Map()
    this.info = this.getElement('.info')

    // User message area. It can be used from outside and disabled
    // The fields validation messages are shown here
    this.userMsg = this.getElement('.user-msg')
  }


  /**
   * It returns the html input of the named field.
   * This could be an array of inputs for multi-fields
   * @param {string} name - Field name (identifier)
   * @return {HTMLElement} Html input of the named field
   */
  getField(name){
    return this.fieldMap.get(name)
  }


  /**
   * It returns the html element containing the input field(s)
   * @param {string} name - Field name (identifier)
   * @return {HTMLElement} Html element containing the field(s)
   */
  getFieldBox(name){
    let field = this.fieldMap.get(name)
    if (field) // it could be undefined, no value field
      if (Array.isArray(field))
        return this.fieldMap.get(name)[0].parentElement
      else return this.fieldMap.get(name).parentElement
  }

  /**
   * It returns the number of fields
   * @return {int} Number of fields
   */
  getFieldNum(){
    return this.fieldMap.size
  }


  /**
   * It adds a new section to the form.
   * The form can be divided in sections holding the fields
   * @param {string} name - Field name (identifier)
   * @return {HTMLElement} The section element
   */
  addSection(name){
    let section = this.addElement('div')
    section.className = 'section-title'
    section.innerHTML = name
    return section
  }

  addInfo(text) {
    this.info.innerHTML = text
  }


  /**
   * It adds a new field to the form.
   *
   * @param {string} name - Field name (identifier)
   * @param {string} text - Field name shown to the user
   * @param {string} inputType - String that determines the type (input-text,
   * input-range, select, etc.) and the number of sub-fields (multi-field)
   * @param {(string | Array<string> | number | array<number>)} value - Default value for the field
   * @param dataType {(string | Array<string>)} data type for input / data types for sub-inputs. used for validating
   * @param group {int}
   * @param {boolean} required - If the value is mandatory or not
   * @param {boolean} explicitInclusion - If the field has to be explicitly
   * indicated (by a checkbox on the left) to be included as a regular field
   * @param units {(string|Array<string>)} units string(s) to be added to the fields
   * @param explanation {string} a tooltip for the field label
   * @return {FormElement}
   * The new field or set of fields added
   */
  addField(name, text, inputType, value, dataType,
           group=0, required=false, explicitInclusion=false,units='',
           explanation=''){

    if (value) this.initValues.set(name, value)
    else value = ''
    // console.log(name)
    this.validationInfo.set(name, required)
    this.explicitInclusionInfo.set(name, explicitInclusion)
    this.datatype.set(name, dataType)

    const controlType = inputType.split(':')[0]
    switch (controlType) {
      case 'radio':
        this.fieldMap.set(name, new RadioField(this, name, value, required))
        break;
      case 'input-text':
        this.fieldMap.set(name, new InputField(this, name, text, inputType, dataType, group, required,
          explicitInclusion, units, explanation))
        // sets value if given
        if (value) this.fieldMap.get(name).setValue(value)
        break;
      case 'no-input':
        this.fieldMap.set(name, new NoInputField(this, name, text, required, explicitInclusion, explanation))
        break;
      case 'select':
        this.fieldMap.set(name, new SelectField(this, name, text, inputType, value, dataType, group, required,
          explicitInclusion, units, explanation))
        break;
      case 'input-range':
        this.fieldMap.set(name, new RangeField(this, name, text, value, group, required,
          explicitInclusion, units, explanation))
        break;
      case 'input-color':
        this.fieldMap.set(name, new ColorField(this, name, text, value, group, required,
          explicitInclusion, units, explanation))
    }
    return this.fieldMap.get(name)
  }


  /**
   * It adds a button to the form (not included by default, it can be controlled
   * from outside)
   * @param {string} label - Button label
   * @return {HTMLElement} The new button
   */
  addButton(label){
    let div = document.createElement('div')
    div.className = 'Form-button-box'
    this.button = document.createElement('button')
    this.button.innerHTML = label
    div.appendChild(this.button)
    this.e.appendChild(div)
    return this.button
  }

  /**
   * It validates that the required fields are filled
   * @return {boolean} If the validation was good or not
   */
  validate(){
    let isValid = true
    for (const field of this.fieldMap.values()) {
      isValid = (field.validate() && isValid)
    }
    if (!isValid) this.userMsg.innerHTML = 'There are some fields required' +
      ' to generate the control.in file not filled'
    else this.userMsg.innerHTML = ''
    return isValid
  }

  /**
   * It marks or unmarks a field normally in the validation process
   * @param {string} fieldName - Field name (identifier)
   * @param {int} i - Subfield index in the field set
   * @param {boolean} unmark - If the operation is the opposite: unmark the
   * field instead of marking it
   */
  markField(fieldName, i, unmark = false){
    let field = (i !== undefined ?
      this.fieldMap.get(fieldName)[i] : this.fieldMap.get(fieldName))
    //log('markField',fieldName, field)
    if (unmark) field.classList.remove('markedField')
    else field.classList.add('markedField')
  }

  setConstraint(condition) {
    // if (condition && this.parent) {
    //   this.parent.showSection(this.idx)
    //   for (const field of this.fieldMap.values()) {
    //     field.show()
    //     if (field.constraint) field.constraint.trigger(true)
    // }}
    // else {
    if (!(condition && this.parent)) {  // everything is shown by default;
                                        // hide the section if the constraint condition is not met
      for (const field of this.fieldMap.values()) field.hide()
      this.parent.hideSection(this.idx)
    }
  }

  /**
   * It sets a user message
   * @param {string} msg - Message for the user
   */
  setUserMsg(msg){
    this.userMsg.innerHTML = msg
  }
  /**
   * It disables the user message area. Enabled by default
   */
  disableUserMsg(){
    this.userMsg.style.display = 'none'
  }

  /**
   * Returns an object with the pairs: field name-value
   * @return {object} Object containing the fields name-value pairs
   */
  getFieldsNameValue(){
    let result = {}
    this.fieldMap.forEach( (field) => {
      if (field.isRequired() || field.isExplicitlyIncluded()) {
        let value = field.getValue()
        if ((value instanceof Object) && !(Array.isArray(value))) Object.assign(result, value)
        else result[field.name] = value
      }})
    return result
  }

  /**
   * It resets the fields values to their initial values
   */
  resetFieldsValues(){
    this.fieldMap.forEach( (field, name) => field.setValue(this.initValues.get(name)))
  }
}
