/**
 * @author Iker Hurtado
 *
 * @overview File holding the application entry point
 */

import 'regenerator-runtime/runtime.js'

import UIComponent from './common/UIComponent.js'
import * as ModalPopup from './common/ModalPopup.js'
import StructureBuilderMod from './structure-builder-mod/StructureBuilderMod.js'
import OutputAnalyzerMod from './output-analyzer-mod/OutputAnalyzerMod.js'
import ControlGeneratorMod from './control-generator-mod/ControlGeneratorMod.js'
import {workflows} from './workflow/workflows.js'
import * as AppState from './State.js'
import Settings from './Settings.js'
import Workflow from './workflow/Workflow.js'

import helpIcon from '../img/help-icon.png'
import downloadIcon from '../img/download-icon.png'

import BandStructureIcon from '../img/BandStructure-icon.png'
import GWWorkflowIcon from '../img/GW-Workflow-icon.png'
import MDWorkflowIcon from '../img/MD-Workflow-icon.png'
import DesignedWorkflowIcon from '../img/DesignedWorkflow-icon.png'
import ControlGeneratorIcon from '../img/ControlGenerator-icon.png'
import OutputAnalyzerIcon from '../img/OutputAnalyzer-icon.png'
import SimpleCalculationIcon from '../img/SimpleCalculation-icon.png'
import StructureBuilderIcon from '../img/StructureBuilder-icon.png'


// Application Dashboard buttons info
const MAIN_BUTTONS_INFO = new Map([

  ['SimpleCalculation', {
    icon: SimpleCalculationIcon,
    text: `Generate the input files needed for a single calculation:<br>
      1) Create the Structure 2) Choose numerical settings 3) Download input files 4) Analyze your results.`
  }],

  ['BandStructure', {
    icon: BandStructureIcon,
    text: `Automatically generate the band path for your periodic structure and setup all parameters needed for the 
    calculation. Finally, import the result and download the band structure.`
  }],
  ['GWCalculation', {
    icon: GWWorkflowIcon,
    text: `Generate band structure using the many-body perturbation theory in the GW approach. It improves the prediction
    of electronic band structure at higher computational cost.`
  }],
  ['MDCalculation', {
    icon: MDWorkflowIcon,
    text: `Generate inputs specific for the molecular dynamics simulation.`
  }],
  ['StructureBuilder', {
    icon: StructureBuilderIcon,
    text: 'Import a structure or design from scratch! Manipulate the structure of molecules or solids. ' +
      'Export the results in the format needed for the code of your choice.'
  }],
  ['ControlGenerator', {
    icon: ControlGeneratorIcon,
    text: `Choose the numerical settings for your calculation! The basis sets are attached, automatically.`
  }],

  ['OutputAnalyzer', {
    icon: OutputAnalyzerIcon,
    text: 'Upload all of your output files and the most important data will be extracted and visualized! ' +
      'E.g.: Band structure, DOS, Energies, Numerical Convergence.'
  }],

  ['Designed', {
    name: 'Workflow',
    icon: DesignedWorkflowIcon,
    text: 'Design a workflow, download its input files and batch process the outputs.'
  }],
])

const DESKTOP_MODE = false // true //


// Application title
let appTitle = document.querySelector('#app-title')
// When the application title is clicked the initial Dashboard module is shown
appTitle.addEventListener( 'click', _ => document.location.hash = '')

// Application breadcrumb and desktop version download popup
let breadcrumbBox = document.querySelector('.breadcrumb-box')
// At the top level page the breadcrumb is replaced by the desktop version download button
let desktopDownloadButton
if (!DESKTOP_MODE){
  desktopDownloadButton = new UIComponent('div', '.desktop-app-download')
  desktopDownloadButton.setHTML('<img style="vertical-align: middle; padding-right: 6px" src="'+
    downloadIcon+'" height="18px" alt="Download standalone version"> Desktop application')
  desktopDownloadButton.e.addEventListener( 'click', _ => {
    ModalPopup.setModalContent(`
			<h3 style="margin-top: 0">How to get the desktop application running on your computer:</h3>
			1. Download the latest release of the application binary file for your platform from &nbsp;&nbsp;
				<a target="_blank" href="https://gitlab.com/gims-developers/gims/-/releases">GIMS Releases page</a> <br><br>
			2. Start up the application server just by executing the downloaded file <br><br>
			3. Enjoy the application on your favorite browser via this local address: <a href="http://localhost:5000/"> GIMS local address </a>`)
    ModalPopup.showModal()
  })
  breadcrumbBox.appendChild(desktopDownloadButton.e)
}
// Application version selection
const gimsSelectionBox = document.querySelector('.gims-selection-box select')
const gimsHost = {
  "gims-dev.ms1p.org/" : "dev",
  "gims.ms1p.org/" : "stable",
  "gims.ms1p.org/fallback/" : "fallback"
}
const gimsVersion = Object.fromEntries(Object.entries(gimsHost).map(a => a.reverse()))
const idx = window.location.pathname.indexOf('static')
const address = window.location.hostname + window.location.pathname.slice(0, idx)
if (address in gimsHost) gimsSelectionBox.value = gimsHost[address]
gimsSelectionBox.addEventListener( 'change', e =>
  window.location.href = 'https://' + gimsVersion[e.target.value])

// Application code selection
let codeSelectionBox = document.querySelector('.code-selection-box')
codeSelectionBox.querySelector('.'+AppState.getCode()+'-code-button').classList.add('code-selected')
codeSelectionBox.addEventListener( 'click', e => {
  const codeClass = e.target.classList.item(0)
  if (codeClass.includes('code-button') ){
    let code = codeClass.substring(0, codeClass.indexOf('-'))
    AppState.setCode(code) // log('code', code)
    codeSelectionBox.querySelector('.code-selected').classList.remove('code-selected')
    codeSelectionBox.querySelector('.'+code+'-code-button').classList.add('code-selected')
  }
})

// Application settings
let settingsButton = document.querySelector('.settings-button')
settingsButton.addEventListener( 'click', _ => {
  ModalPopup.setModalComponent(new Settings().e)
  ModalPopup.showModal()
})


/**
 * Application front page component
 */
class Dashboard extends UIComponent{

  constructor(){
    super('div', '#app-dashboard')

    this.setHTML(`

      <div class="workflow-buttons-box">
        <!-- the buttons here are generated from the workflows declaration file  -->
      </div>

      <div>
        <div class=dashboard-h1>Calculation Apps</div>
        <div class="dashboard-row">
          ${getMainButtonHTML('Simple Calculation',true)}
          ${getMainButtonHTML('Band Structure',true)}
          ${getMainButtonHTML('GW Calculation',true)}
          ${getMainButtonHTML('MD Calculation',true)}
        </div>
      </div>

      <div>
        <div class=dashboard-h1>Elemental Apps</div>
        <div class="dashboard-row">
          ${getMainButtonHTML('Structure Builder')}
          ${getMainButtonHTML('Control Generator')}
          ${getMainButtonHTML('Output Analyzer')}
        </div>
      </div>

      <!--div>
        <div class=dashboard-h1>Workflow Apps</div>
        <div class="dashboard-row">
          ${getMainButtonHTML('Designed', true)}
        </div>
      </div-->


    `)

    // let html = '<div class=dashboard-h1>Workflow Apps</div> <div class="dashboard-row">'
    // for (let w in workflows)
    // 	html += getMainButtonHTML(workflows[w].text, true)
    // html += '</div>'
    // this.getElement('.workflow-buttons-box').innerHTML = html

    function getMainButtonHTML(name, workflow = false){
      const nameRed = name.replace(/ /g,'').replace(/&/g,'')
      //log(nameRed, name)
      return `<div id="${nameRed}${workflow ? '-workflow' : ''}-button" class="dashboard-button">
		          <img class="main" src="${MAIN_BUTTONS_INFO.get(nameRed).icon}" alt="${name}"/>
		          <span style="width: 54%">${(MAIN_BUTTONS_INFO.get(nameRed).name === undefined) ? name : MAIN_BUTTONS_INFO.get(nameRed).name}</span>
		          <img  class="help-button" src="${helpIcon}" width="22" alt="Help"/> &nbsp;

		          <div class="dashboard-button-explanation">
		        	${MAIN_BUTTONS_INFO.get(nameRed).text}
		          </div>

		        </div>
		        `
    }
  }

  /**
   * Sets the listener that will be called when the component is clicked
   * @param {function} listener
   */
  setListener(listener){
    this.e.addEventListener( 'click', e => listener(e))
  }

}


/****** Application start up code ******/

const appElement = document.getElementById('app-placeholder')
let currentModule  // Module showing

const dashboard = new Dashboard()
// Dashboard buttons listener
dashboard.setListener( e => {
  const buttonId = e.target.id || e.target.parentElement.id
  // Regular case, not workflow
  const moduleId = buttonId.substring(0, buttonId.indexOf('-button'))
  let urlHash = moduleId

  if (buttonId.includes('workflow')){ // If workflow
    const workflowId = buttonId.substring(0, buttonId.indexOf('-workflow-button'))
    if (moduleMap.get(moduleId)) // workflow already instanced:
      // it asks for the current component in the workflow
      urlHash = moduleId+'#'+moduleMap.get(moduleId).getComponentId()
    else // workflow not instanced yet: it gets the first component
      urlHash = moduleId+'#'+workflows[workflowId].steps[0].component
  }
  document.location.hash = urlHash
})

// application level modules
const moduleMap = new Map([
  ['StructureBuilder', undefined],
  ['OutputAnalyzer', undefined],
  ['ControlGenerator', undefined],
  ['SimpleCalculation-workflow', undefined],
  ['BandStructure-workflow', undefined],
  ['GWCalculation-workflow', undefined],
  ['MDCalculation-workflow', undefined],
  ['Designed-workflow', undefined],
])


/**
 * Handles the application level modules. It's in charge
 * of displaying them and instantiating when necessary
 * @param  {string} moduleString
 *   module string identifier and configuration arguments
 * @param  {string} moduleParams Module params by the URL fragment
 */
function manageModules(moduleString, moduleParams){
  console.log('manageModules',moduleString,moduleParams)//,  moduleMap)
  if (moduleString === ''){ // exceptional case: Dashboard
    showModuleElement(dashboard)
    breadcrumbBox.innerHTML = ''
    if (desktopDownloadButton)
      breadcrumbBox.appendChild(desktopDownloadButton.e)
    return
  }

  const moduleId = moduleString.split(':')[0]
  const moduleConf = moduleString.split(':')[1]
  let nextModule = moduleMap.get(moduleId)

  // remove extra hash in case of app refresh
  if (nextModule === undefined && moduleParams !== undefined && moduleParams.includes("#")) {
    document.location.hash = ''; return
  }
  if (moduleMap.has(moduleId)){
    if (!nextModule){ // The module is not loaded yet
      nextModule = getModInstance(moduleId)
      moduleMap.set(moduleId, nextModule)//console.log('manageModules 1', moduleMap, nextModule)
      AppState.subscribeToCodeChange(nextModule)
      AppState.subscribeToSettingsChange(nextModule)
      showModuleElement(nextModule)
      if (moduleConf) nextModule.init(moduleConf)
      else nextModule.init()
    } else { // The module is already loaded
      showModuleElement(nextModule)
      if (moduleConf) nextModule.init(moduleConf)
    }
    if (moduleParams) nextModule.processUrlFragment(moduleParams)

  }else{ document.location.hash = ''; return }

  breadcrumbBox.innerHTML = (
    moduleId.includes('workflow') ? moduleId.split('-').join(' ') : moduleId
  )

  // I think this is improvable
  function getModInstance(moduleId){
    console.log('getModInstance', moduleId)
    if (moduleId === 'StructureBuilder') return new StructureBuilderMod()
    else if (moduleId === 'OutputAnalyzer') return new OutputAnalyzerMod()
    else if (moduleId === 'ControlGenerator') return new ControlGeneratorMod()
    else if (moduleId.includes('-workflow'))
      return new Workflow(moduleId.substring(0,moduleId.indexOf('-')))
  }
}


/**
 * Displays the module component
 * @param  {Dashboard} module Module instance
 */
function showModuleElement(module){
  if (module === currentModule) return
  if (currentModule) appElement.removeChild(currentModule.e)
  currentModule = module
  appElement.appendChild(currentModule.e)
}


// initial URL fragment analysis
processUrlHash()

// application level event to check the URL hash changes
window.addEventListener("hashchange", _ => {
  processUrlHash()
})


/**
 * Processes the URL hash. This is the application navigation mechanism
 */
function processUrlHash(){
  let urlHash = document.location.hash.substring(1)
  //log('processUrlHash', urlHash)

  if (urlHash.indexOf('#') > 0){ // Module with params
    const moduleId = urlHash.substring(0, urlHash.indexOf('#'))
    const moduleParams = urlHash.substring(urlHash.indexOf('#')+1)
    manageModules(moduleId, moduleParams)
  }else{// Module or workflows without params, urlHash = moduleId
    manageModules(urlHash)
  }
}


// Application level Escape key function to hide the modal popup
document.addEventListener('keydown', e => {
  if (e.code === 'Escape') {
    e.preventDefault()
    ModalPopup.hideModal()
  }
})
