import {Fields_FHIaims} from './Fields_FHIaims.js'
import {Fields_Exciting} from './Fields_Exciting.js'



export default class AllFields {

  constructor() {
    this.FHIaims = Fields_FHIaims
    this.Exciting = Fields_Exciting
  }
}
