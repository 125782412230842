/**
 * @author Iker Hurtado, Sebastian Kokott
 *
 * @fileoverview File holding the SupercellField UI component
 */

import UIComponent from '../common/UIComponent.js'
import {intInputValidator, intValidator} from "../common/util";


/**
 * Editable field made up of 3 text inputs, one per dimension
 */
export default class SupercellField extends UIComponent{

  constructor(){

    super('span', '.SupercellField')

    this.setHTML(`
        <div>
        <input type="text" class="sc-value" />
        <input type="text" class="sc-value" />
        <input type="text" class="sc-value" />
        </div>
        <div class="supercell-row">
        <input type="text" class="sc-value matrix" style="display:none"/>
        <input type="text" class="sc-value matrix" style="display:none"/>
        <input type="text" class="sc-value matrix" style="display:none"/>
        </div>
        <div class="supercell-row">
        <input type="text" class="sc-value matrix" style="display:none"/>
        <input type="text" class="sc-value matrix" style="display:none"/>
        <input type="text" class="sc-value matrix" style="display:none"/>
        </div>
        <div class="supercell-matrix-checkbox">
             <input type="checkbox" class="isMatrix" onclick="">Use Supercell Matrix
        </div>
        <div class="create-button-div">
          <button class="create-button">Create Supercell</button>
        </div>
    `)

    let showFields = (e) => {
      let fields = this.getElements('input.matrix')
      if (e.target.checked) {
        fields.forEach(f => {f.style = ''})
      } else {
        fields.forEach(f => {f.style.display = 'none'})
      }
    }

    this.fields = this.getElements('input.sc-value')//e.querySelectorAll('input');
    // console.log('this.fields',this.fields)
    this.fields.forEach( f => f.addEventListener('input', intInputValidator) )
    this.fields.forEach( f => f.addEventListener('blur', intValidator) )
    this.isMatrix = this.getElement('.isMatrix')
    this.isMatrix.addEventListener('change', showFields)
  }

  /**
   * Returns the values, one per dimension
   * @return {number[]}
   */
  getValues(){
    let a = []
    this.fields.forEach(f => {
      if (f.style.display !== 'none') {
        if (f.value === '') {
          a.push(0)
        } else {
          a.push(parseInt(f.value))
        }
      }
    })
    return a
  }


  /**
   * Sets the values, one per dimension
   * @param {number} value
   */
  setValues(value){
    for (let i = 0; i < this.fields.length; i++)
      this.fields[i].value = value
  }
}
