/**
 * @author Iker Hurtado
 *
 * @fileoverview DropDownMenu is an UI component implementing a drop-down menu
 * base class
 */


import UIComponent from './UIComponent.js'


/**
 * Drop-down menu base class
 */
export default class DropDownMenu extends UIComponent{

  /**
   * @param {string} label - Text of the drop-down button
   */
  constructor(label){

    super('div', '.DropDownMenu')
        
    this.setHTML(`
        <div>
          <button>${label}</button>
          <ul> </ul> 
        </div>
        
    `)

    this.container = this.getElement('ul')
  }


  /**
   * Init the drop-down with simple text items
   * @param {Array<string>} labels - items texts
   */
  setValues(labels){
    let html = ''
    labels.forEach( label => {
      html += '<li>'+label+'</li>'
    })
    this.container.innerHTML = html
  }
  
}
