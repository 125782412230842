/**
 * @author Iker Hurtado
 *
 * @fileoverview File holding the DownloadInputFilesPage UI component
 */

import UIComponent from '../common/UIComponent.js'
import downloadIcon from '../../img/download-icon.png'


/**
 * Workflow step page that enables the download of the input files
 */
export default class DownloadInputFilesPage extends UIComponent{

  constructor(){ 
    super('div', '.DownloadInputFilesPage')

    this.setHTML(`
      <div style="display: block;">
        <div class="download-info"></div>
      </div>
      <div>
      <a class="download-input-files-link" download="input_files.tar">
          <img style="vertical-align: middle; padding: 0 10px" src="${downloadIcon}" />Download the input files
      </a>
      </div>
    `)
  }


  /**
   * Does nothing but it's mandatory for every workflow page
   */
  init(){
  }


  /**
   * Sets the input files tar bundle on the download link
   * @param {File} filesTar
   */
  setInputFiles(filesTar){
    this.getElement('a.download-input-files-link').href =
          window.URL.createObjectURL( new Blob([filesTar], {type: 'application/x-tar'} ))
  }

  setDownloadInfo(downloadInfo){
    let di = JSON.parse(downloadInfo)
    //console.log(di);
    let html = ''
    for (var [key,entry] of Object.entries(di.DownloadInputFilesPage)) {
      entry.split('\n').forEach( line => {
        html += `${line} <br>`
      })
    }
    /*html += `<h3>References</h3>`
    let i = 0
    di.references.forEach(ref => {
      i += 1
      html += `<h4> <a href="${ref.packageURL}" target="_blank">${ref.packageName}</a></h4>`
      html += `[${i}] ${ref.citeText} <br>`
      html += `    doi: <a href="${ref.doiUrl}" target="_blank">${ref.doiUrl}</a> <br>`
    })
    console.log(html);*/
    this.getElement('.download-info').innerHTML = html
  }


  /**
   * Updates the component for a code change (application level event)
   * @param  {string} code
   */
  updateForCode(code){
  }


  /**
   * Updates the component for a setting change (application level event)
   * @param  {object} newSettings 
   */
  updateSettings(newSettings){
  }

}
