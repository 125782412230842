/**
 * @author Andrey Sobolev
 *
 * @overview A workflow definition
 */

export const definition = {
  properties: {
  },
  sequence: [
  ]
};