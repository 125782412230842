/**
 * @author Iker Hurtado
 *
 * @overview FileImporter is a UI component that allows to import files
 * to the application
 */

import UIComponent from './UIComponent.js'

/**
 * UI component to import files
 */
export default class FileImporter extends UIComponent{

  /**
   * @param {string} text - Text next to the import control
   * @param {boolean} multiple - It enables the multiple selection of files
   */
  constructor(text, multiple = false){
    super('span', '.FileImporter')
    
    this.setHTML(text+` <input type="file"  ${multiple ? 'multiple' : ''} >`) 
    this.input = this.getElement('input')

    this.input.addEventListener( 'change', e => {
      this.listener(e.target.files)
    })
    
  }

  /**
   * It enables to switch between file and directory selection modes.
   * On the directory mode all the files inside the directory will be imported
   * @param {boolean} directory - If true the directory selection is selected
   */
  setDirectoryMode(directory){
    if (directory) this.input.setAttribute('webkitdirectory', '')
    else this.input.removeAttribute('webkitdirectory')
  }

  /**
   * It sets up a listener that will be called when a new set of files 
   * is selected
   * @param {function} listener - Listener
   */
  setImportListener(listener){
    this.listener = listener
  }
}