/**
 * @author Andrey Sobolev <sobolev@fhi.mpg.de>
 *
 * @overview File holding the class describing constraints in control generation form (or any forms, for that case)
 */

export default class Constraint {
/**
 * A Constraints binary tree
 * @param constraintData {Object|Array}
 */
  constructor(constraintData) {
    this.left = undefined
    this.condition = undefined
    this.right = undefined
    this.leaf = false
    if (Array.isArray(constraintData)) {
      if (!Array.isArray(constraintData[0])) {
        [this.left, this.condition, this.right] = constraintData
        this.leaf = true
      } else switch (constraintData.length) {
        case 0:
          throw 'Constraints (if given) should not be empty'
        case 1:
          const c = new Constraint(constraintData[0])
          for (const k in c) this[k] = c[k]
          break;
        default:
          this.condition = 'and'
          this.left = new Constraint(constraintData[0])
          this.right = new Constraint(constraintData.slice(1))
      }
    } else if (constraintData instanceof Object) {
      let k = Object.keys(constraintData)[0]
      let v = constraintData[k]
      this.condition = k
      if (!Array.isArray(v) || v.length !== 2)
        throw "If a condition is given with the Object, it's value has to be an Array with a length of 2"
      this.left = new Constraint(v[0])
      this.right = new Constraint(v[1])
    }
    this.masters = this.getMasters()
  }

  /**
   * Auxiliary function aimed at getting the master fields for the constraint
   */
  getMasters() {
    if (this.leaf) return new Set([this.left])
    else {
      return new Set([...this.left.getMasters(), ...this.right.getMasters()])
    }
  }

  /**
   * Dispatches events that trigger the constraints
   * @param include {boolean} whether to dispatch an include event
   */
  trigger(include=false) {
    if (include) this.masters.forEach(el => el.dispatchIncludeEvent(new Event('change')))
    else this.masters.forEach(el => el.dispatchEvent(new Event('change')))
  }

  /**
   * Evaluates the condition to boolean value
   * @returns {boolean}
   */
  evaluate() {
    if (this.leaf) return this.#evaluateLeaf()
    else return this.#evaluateBranch()
  }
  #evaluateLeaf() {
    switch (this.condition) {
      case '==':
        return (this.left.getValue() === this.right)
      case 'in':
        return (this.right.includes(this.left.getValue()))
      case 'included':
        return this.left.isExplicitlyIncluded()
      default:
        throw 'Not implemented'
    }
  }
  #evaluateBranch() {
    switch (this.condition) {
      case 'and':
        return (this.left.evaluate() && this.right.evaluate())
      case 'or':
        return (this.left.evaluate() || this.right.evaluate())
      default:
        throw 'Not implemented'
    }
  }


}