/**
 * @author Iker Hurtado
 *
 * @fileoverview File holding the Tooltip UI component
 */

import UIComponent from './UIComponent.js'

/**
 * A tootip UI component
 */
export default class Tooltip extends UIComponent{

  constructor(){
    super('div', '.Tooltip')
  }


  /**
   * Sets the relative position regarding the HTML element
   * @param {string} pos Values: left or right
   * @param {int} offset Distance from the element side
   */
  setRelPosition(pos, offset){
  	this.pos = pos
  	this.offset = offset
  	
  }


  /**
   * Sets the HTML element reference for the tooltip
   * @param {HTMLElement} target
   */
  setTargetElement(target){
  	target.addEventListener('mouseover', e => {
  	  this.setHTML(`<div>${target.getAttribute('data-explanation')}</div>`)
      target.appendChild(this.e)
      // Once the tooltip is inserted in the target element
      this.setLeftTop(target)
    })
    target.addEventListener('mouseout', e => {
       target.removeChild(this.e)
    })
  }

  /**
   * Sets effectively the position of the tooltip
   * @param {HTMLElement} target
   */
  setLeftTop(target){
    // the coordinates are absolute to the wrapper div parent
    const getTop = el => el.offsetTop + (el.offsetParent && getTop(el.offsetParent))
    const getLeft = el => el.offsetLeft + (el.offsetParent && getLeft(el.offsetParent))

    let top = getTop(target) + target.clientHeight/2 - this.e.clientHeight/2
    if (this.pos === 'left'){
      this.e.style.left = -(getLeft(target)+this.offset)+'px'
      this.e.style.top = top+'px'
    }else if (this.pos === 'right'){
      this.e.style.left = (getLeft(target) + target.clientWidth + this.offset)+'px'
      this.e.style.top = top+'px'
    }
  }
}


// Creates the application modal popup

/**
 * Tooltip application instance (singleton)
 */
const tooltip = new Tooltip()


/**
 * Sets the HTML element reference for the tooltip
 * @param {HTMLElement} target
 */
export function addTargetElement(target){
  tooltip.setTargetElement(target)
}


/**
 * Sets the relative position regarding the HTML element
 * @param {string} pos Values: left or right
 * @param {int} offset Distance from the element side
 */
export function setRelPosition(pos, offset){
  tooltip.setRelPosition(pos, offset)
}




