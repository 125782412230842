/**
 * @author Iker Hurtado
 *
 * @fileoverview File holding the Switch UI component
 */

import UIComponent from './UIComponent.js'


/**
 * A switch or toggle button
 */
export default class Switch extends UIComponent{

  /**
   * @param {string} label
   */
  constructor(label){

    super('div', '.Switch')

    this.on = false
    this.e.textContent = 'OFF'
        
    this.setHTML(`
      <span class="switch">OFF</span>
      <span class="label">&nbsp;${label}</span>  
    `)

    const switchButton = this.getElement('.switch')

    this.e.addEventListener('click', e => {
      const hasChanged = this.listener(!this.on)
      if (hasChanged) {
        this.on = !this.on
        switchButton.className = 'switch'+(this.on ? ' switch-on' : '')
        switchButton.textContent = (this.on ? 'ON' : 'OFF')
      }
      
    })
    
  }


  /**
   * Sets the listener that will be called when the button is switched
   * A boolean parameter will be passed in, true if the switch is 
   * getting on, false ptherwise
   * @param {function} listener 
   */
  setListener(listener){
    this.listener = listener;
  }
  switchOff(){
    const switchButton = this.getElement('.switch')
    this.on = false
    switchButton.className = 'switch'
    switchButton.textContent = 'OFF'
  }

}