/**
* @author Sebastian Kokott
*
* @fileOverview File holding the Structure Info UI component
*/

import UIComponent from '../common/UIComponent.js'
import * as UserMsgBox from '../common/UserMsgBox.js'
import * as util from '../common/util.js'
import {Conf} from '../Conf.js'

export default class Primitive extends UIComponent{
  constructor(moduleState) {
    super('span', '.Primitive')

    let primitiveHandler = () => {
      this.getCell("primitive").then()
    }

    let conventionalHandler = () => {
      this.getCell("conventional").then()
    }

    this.moduleState = moduleState // We might need this to update the info panel later on...

    this.setHTML(`
      <span class="regular-view">
        <span class="addon-box">
          <span class="addon primitive" >Primitive Cell</span>
        </span>
        <span class="addon-box">
          <span class="addon conventional" >Conventional Cell</span>
        </span>
      </span>
    `)

    this.primitiveButton = this.getElement('.primitive')
    this.primitiveButton.addEventListener('click',primitiveHandler)

    this.conventionalButton = this.getElement('.conventional')
    this.conventionalButton.addEventListener('click', conventionalHandler)
  }

  /**
   * Returns a standardized cell
   * @param which {string}
   * @return {Promise<void>}
   */
  async getCell(which){
    let structure = this.moduleState.getStructure()
    // console.log(structure, !structure.atoms.length)
    if (!structure.atoms.length) return
    let sendStructure = {
      cell: structure.latVectors,
      positions: structure.atoms,
      symThresh: Conf.settings.symmetryThreshold,
      fileName: structure.fileSource
    }
    let isPrimitive = undefined
    if (which === "primitive") isPrimitive = true
    else if (which === "conventional") isPrimitive = false
    else return
    sendStructure['is_primitive'] = isPrimitive

    let response = await fetch('/get-standard-cell', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      body: JSON.stringify(sendStructure)
    })

    if (!response.ok){
      UserMsgBox.setError('Unknown server ERROR ')
      return
    }

    const text = await response.text()
    // console.log(text)
    // console.log(structureData)"ErrorIsAlreadyPrimitive"
    if (text === 'ErrorIsAlreadyPrimitive') {
      UserMsgBox.setError("The input structure is already a primitive unit cell.")
      return
    } else if (text === 'ErrorIsAlreadyConventional'){
      UserMsgBox.setError("The input structure is already a conventional unit cell.")
      return
    }
    const primitive = JSON.parse(text)
    this.moduleState.setStructure(util.getStructureFromJSON(primitive))
  }

  updateStructure(structure, _change){
    const isPeriodic = structure.isAPeriodicSystem()
    // console.log(isPeriodic);
    this.getElement('.regular-view').style.display = (isPeriodic ? 'inline-block' : 'none')
  }

  selectStructure(structure){
    this.updateStructure(structure,undefined)
  }

  setNewStructure(structure){
    this.updateStructure(structure,undefined)
  }
}
