/**
 * @author Andrey Sobolev
 *
 * @fileOverview File holding the InteractiveGraph class based on plotly.js
 */

import Plotly from 'plotly.js-basic-dist-min'
import {deepMerge} from "./util";

const FONT = 'Roboto'
const FONT_SIZE_NORMAL = 16
const TICK_LABEL_SIZE_MULTIPLIER = 0.8
const DEFAULT_THICKNESS = 1.2


const CONFIG = {
  modeBarButtonsToRemove: ['select2d', 'lasso2d'],
  displaylogo: false,
  editable: true,
  // scrollZoom: true,
  showEditInChartStudio: true,
  plotlyServerURL: "https://chart-studio.plotly.com"
}

/**
 * This is the base class that models a highly interactive plotter.
 * It is inherited by several classes implementing specific plotters.
 * This plotter implements zoom and y-axis offset
 */
export class InteractiveGraph{

  /** A plotly.js graph with the methods to control it
   *
   * @param element
   * @param width {number}
   * @param height {number}
   * @param layout {Object|undefined}
   */
  constructor(element, width=undefined, height=undefined, layout = {}) {
    this.NUMBER_OF_POINTS_IN_SEGMENT = 30
    this.parentElement = element
    this.isDrawn = false
    this.lines = new Map()  // []
    this.lineStyles = new Map()  // this.linesProps = []
    this.xTicksValues = []
    this.xTicksTexts = []
    this.data = []
    this.layout = {
      title: '',
      showlegend: false,
      autosize: true,
      hovermode: false,
      // dragmode: 'pan',
      margin: {
        l: 20, // this.margins.left,
        r: 20, // this.margins.right,
        b: 20, // this.margins.bottom,
        t: 30, // this.margins.top,
        pad: 0
      },
      xaxis: {
        showline: true,
        showgrid: true,
        mirror: 'ticks',
        automargin: true,
        titlefont: {
          'family': FONT,
        },
        tickfont: {
          'family': FONT,
          'size': TICK_LABEL_SIZE_MULTIPLIER * FONT_SIZE_NORMAL,
        },
      },
      yaxis: {
        showline: true,
        showgrid: true,
        mirror: 'ticks',
        automargin: true,
        autorange: false,
        titlefont: {
          'family': FONT,
        },
        tickfont: {
          'family': FONT,
          'size': TICK_LABEL_SIZE_MULTIPLIER * FONT_SIZE_NORMAL,
        },
        zerolinecolor: '#969696',
        zerolinewidth: 1,}
    }
    if (width !== undefined) this.layout.width = width
    if (height !== undefined) this.layout.height = height
    // update this layout with the given values
    deepMerge(this.layout, layout)
  }

  /**
   * Sets the labels and value range for the axes
   * @param {string} xLabel X axis label
   * @param {string} yLabel Y axis label
   * @param {boolean} isXFixedRange Indicates if the x-axis has fixed range (for band structure)
   * @param {number} xMin X axis minimum value
   * @param {number} xMax X axis maximum value
   * @param {number} yMinInit Y axis initial minimum value
   * @param {number} yMaxInit Y axis initial maximum value
   * @param {number} yMin Y axis absolute minimum value
   *   (minimum value reachable using the interaction)
   * @param {number} yMax Y axis absolute maximum value
   *   (maximum value reachable using the interaction)
   */
  setAxisLabelsAndRange(xLabel, yLabel, isXFixedRange=false,
    xMin=undefined, xMax=undefined, yMinInit=undefined, yMaxInit=undefined,
                        yMin=undefined, yMax=undefined) {

    if (yMinInit !== undefined || yMin !== undefined) yMin = Math.max(yMinInit, yMin);
    if (yMaxInit !== undefined || yMax !== undefined) yMax = Math.min(yMaxInit, yMax);

    this.layout.xaxis.title = xLabel
    if (xMin !== undefined && xMax !== undefined) this.layout.xaxis.range = [xMin, xMax]
    if (isXFixedRange) this.layout.xaxis.fixedrange = true
    this.layout.yaxis.title = yLabel
    if (yMin !== undefined && yMax !== undefined) this.layout.yaxis.range = [yMin, yMax]

    if (this.isDrawn) Plotly.relayout(this.parentElement, this.layout)
  }

  /**
   * Sets a tick text on the X axis
   * @param {int} x
   * @param {string} tickText
   */
  setXAxisTickText(x, tickText){
    this.xTicksValues.push(x)
    this.xTicksTexts.push(tickText)

    this.layout.xaxis.tickvals = this.xTicksValues
    this.layout.xaxis.ticktext = this.xTicksTexts
    if (this.isDrawn) Plotly.relayout(this.parentElement, this.layout)
  }


  /**
   * Adds a group of lines
   * @param {string} name Group name
   * @param {object} style Line style
   */
  addLineGroup(name, style){
    this.lines.set(name, [])
    if (style.defaultColor) style.strokeColor = style.defaultColor
    this.lineStyles.set(name, style)
  }

  /** Checks if the group with the name has already been added
   * @param name {string}
   * @returns {boolean}
   */
  hasLineGroup(name) {
    return this.lines.has(name)
  }

  /** An iterator over existing line groups
   * @returns {Generator<any, void, *>}
   */
  *lineGroups() {
    for (const key of this.lines.keys())
      yield key
  }

  /**
   * Adds a line (data) to a group of lines
   * @param {(number[])[]} lineData the X and Y data for the line
   * @param {string} group
   */
  setLineData(lineData, group){
    this.lines.get(group).push(lineData)
  }

  /** Getter for line thickness
   * @param groupName {string}
   */
  getThickness(groupName) {
    if (!this.isDrawn)
      throw "the graph is not drawn yet"
    if (!this.lines.has(groupName))
      throw `${groupName} is not in group names`
    const trace = Array.from(this.lines.keys()).indexOf(groupName)
    return this.parentElement.data[trace].line.width
  }

  /** Getter for line color
   * @param groupName {string}
   */
  getColor(groupName) {
    if (!this.isDrawn)
      throw "the graph is not drawn yet"
    if (!this.lines.has(groupName))
      throw `${groupName} is not in group names`
    const trace = Array.from(this.lines.keys()).indexOf(groupName)
    return this.parentElement.data[trace].line.color
  }

  /** Adds dropdown controls to layout (update menus attributes)
   * @param defaultGroup {string} the label of the default
   */
  #addDropdownControl(defaultGroup = '') {
    if (this.isDrawn)
      throw "the graph is already drawn"

    const nGroups = this.lines.size
    const groups = [...Array(nGroups).keys()]
    // hide all the traces
    let trace = 0
    this.data.forEach((group, idx) => {
      group.visible = false
      if (group.meta === defaultGroup) trace = idx
    })
    //  show the default trace
    const name = this.data[trace].meta
    this.data[trace].visible = true
    this.layout.yaxis.title = this.lineStyles.get(name).yTitle
    // make update menus
    let updateMenus = [{
      buttons: [],
      x: 0.9,
      xanchor: 'right',
      y: 0.9,
      yanchor: 'top',
      visible: true,
      active: trace
    }]
    this.data.forEach((group, idx) => {
      const name = group.meta
      let visible = groups.map(_ => false)
      visible[idx] = true
      updateMenus[0].buttons.push({
        args: [
          {visible: visible},
          {'yaxis.title': this.lineStyles.get(name).yTitle}
        ],
        method: 'update',
        label: name
      })
    })
    this.layout.updatemenus = updateMenus
  }

  /** Converts lines and line styles to actual plottable objects
   */
  #dataToLines() {
    this.lines.forEach((lines, groupName) => {
      const style = this.lineStyles.get(groupName)
      let polyLines = {
        x: [],
        y: [],
        meta: groupName,
        connectgaps: false,
        line: {
          color: style.strokeColor,
          width: DEFAULT_THICKNESS
        }}
      lines.forEach((line) => {
        let points = {x: [], y: []}
        //  plotting each STEPth point
        const step = this.NUMBER_OF_POINTS_IN_SEGMENT > 0 && line[0].length > this.NUMBER_OF_POINTS_IN_SEGMENT ?
          Math.round(line[0].length / this.NUMBER_OF_POINTS_IN_SEGMENT) :
          1

        points.x.push(...line[0].filter((_, idx) => idx % step === 0))
        points.y.push(...line[1].filter((_, idx) => idx % step === 0))

        // the last point (is added if not already)
        if ((line[0].length - 1) % step !== 0) {
          points.x.push(line[0][line[0].length - 1])
          points.y.push(line[1][line[1].length - 1])
        }

        // if (Math.min(...points.y) > this.yMin - 1 && Math.max(...points.y) <= this.yMax + 1) {
        polyLines.x.push(...points.x, null)
        polyLines.y.push(...points.y, null)
        // }
      })

      if (polyLines.x.length > 0)
        this.data.push(polyLines)
      })
  }

  /** Returns the name of the chosen group
   */
  getDropdownGroup() {
    let activeGroup = this.parentElement.layout.updatemenus[0].active
    return this.parentElement.layout.updatemenus[0].buttons[activeGroup].label
  }


  /** Adds a graph and draws the data
   * @param withDropdown {boolean} a flag to add the dropdown controls to the graph
   * @param defaultGroup {string} the name of the default group
   */
  draw(withDropdown = false, defaultGroup = ''){
    if (this.isDrawn) throw "The graph is already drawn!"
    this.#dataToLines()
    const start = performance.now()
    if (withDropdown) this.#addDropdownControl(defaultGroup)
    Plotly.newPlot(this.parentElement, this.data, this.layout, CONFIG)
    this.isDrawn = true
    console.log('time building graphs: ' + (performance.now() - start))
  }

  /**
   * Clears and purges the graph
   */
  clear(){
    // clear everything
    this.lines = new Map()  // []
    this.lineStyles = new Map()  // this.linesProps = []
    this.xTicksValues = []
    this.xTicksTexts = []
    this.data = []
    // purge div
    Plotly.purge(this.parentElement)
    this.isDrawn = false
  }

  /**
   * Changes the axes labels and the title styles
   * @param  {number} sizeMult Font size multiplier
   * @param  {string} color Color code
   */
  changeLabelsStyle(sizeMult, color){
    const layout = {
      'xaxis.title.font.size': sizeMult * FONT_SIZE_NORMAL,
      'xaxis.title.font.color': color,
      'xaxis.tickfont.size': TICK_LABEL_SIZE_MULTIPLIER * sizeMult * FONT_SIZE_NORMAL,
      'xaxis.tickfont.color': color,
      'yaxis.title.font.size': sizeMult * FONT_SIZE_NORMAL,
      'yaxis.title.font.color': color,
      'yaxis.tickfont.size': TICK_LABEL_SIZE_MULTIPLIER * sizeMult * FONT_SIZE_NORMAL,
      'yaxis.tickfont.color': color,
    }
  Plotly.relayout(this.parentElement, layout)
  }

  /**
   * Changes the data lines style
   * @param  {string} groupName Lines group name
   * @param  {number} thickness Lines thickness
   * @param  {string} color Color code
   */
  changeDataLinesStyle(groupName, thickness, color){
    if (!this.lines.has(groupName))
      throw (`${groupName} is not in group names`)
    const trace = Array.from(this.lines.keys()).indexOf(groupName)
    const style = {
      'line.color': color,
      'line.width': thickness
    }
    Plotly.restyle(this.parentElement, style, [trace])
  }
}

/**
 * A function to join Y axes of several graphs
 * @param divs {[]} a list of elements with graphs
 * @param data {Object} event data
 */
 export function joinY(divs, data = undefined) {
   if (data === undefined) {  // initial join
    const yRange = [
      Math.min(...divs.map(div => div.layout.yaxis.range[0])),
      Math.max(...divs.map(div => div.layout.yaxis.range[1]))
    ]
    divs.forEach((div) => Plotly.relayout(div, {'yaxis.range[0]': yRange[0], 'yaxis.range[1]': yRange[1]}))
    return
  }
  if (Object.entries(data).length === 0) return
  let yAutoRange = undefined
  divs.forEach((div) => {if (data["yaxis.autorange"] && div.layout.yaxis.autorange) yAutoRange = div.layout.yaxis.range})

  divs.forEach((div) => {
    let y = div.layout.yaxis
    let yRange = yAutoRange !== undefined ? yAutoRange : [data["yaxis.range[0]"], data["yaxis.range[1]"]]
    if (y.range[0] !== yRange[0] || y.range[1] !== yRange[1])
    {

      const update = {
      'yaxis.range[0]': yRange[0],
      'yaxis.range[1]': yRange[1]
     }
     Plotly.relayout(div, update);
    }
  });
}