const defaultResolvers = [sequentialResolver, branchedResolver];
function branchedResolver(step) {
    const branches = step.branches;
    if (branches) {
        return { type: StepChildrenType.branches, items: branches };
    }
    return null;
}
function sequentialResolver(step) {
    const sequence = step.sequence;
    if (sequence) {
        return { type: StepChildrenType.sequence, items: sequence };
    }
    return null;
}

var StepChildrenType;
(function (StepChildrenType) {
    StepChildrenType[StepChildrenType["sequence"] = 1] = "sequence";
    StepChildrenType[StepChildrenType["branches"] = 2] = "branches";
})(StepChildrenType || (StepChildrenType = {}));
class DefinitionWalker {
    constructor(resolvers) {
        this.resolvers = resolvers ? resolvers.concat(defaultResolvers) : defaultResolvers;
    }
    /**
     * Returns children of the step. If the step doesn't have children, returns null.
     * @param step The step.
     */
    getChildren(step) {
        const count = this.resolvers.length;
        for (let i = 0; i < count; i++) {
            const result = this.resolvers[i](step);
            if (result) {
                return result;
            }
        }
        return null;
    }
    /**
     * Returns the parents of the step or the sequence.
     * @param definition The definition.
     * @param needle The step, stepId or sequence to find.
     * @returns The parents of the step or the sequence.
     */
    getParents(definition, needle) {
        const result = [];
        let searchSequence = null;
        let searchStepId = null;
        if (Array.isArray(needle)) {
            searchSequence = needle;
        }
        else if (typeof needle === 'string') {
            searchStepId = needle;
        }
        else {
            searchStepId = needle.id;
        }
        if (this.find(definition.sequence, searchSequence, searchStepId, result)) {
            result.reverse();
            return result.map(item => {
                return typeof item === 'string' ? item : item.step;
            });
        }
        throw new Error(searchStepId ? `Cannot get parents of step: ${searchStepId}` : 'Cannot get parents of sequence');
    }
    findParentSequence(definition, stepId) {
        const result = [];
        if (this.find(definition.sequence, null, stepId, result)) {
            return result[0];
        }
        return null;
    }
    getParentSequence(definition, stepId) {
        const result = this.findParentSequence(definition, stepId);
        if (!result) {
            throw new Error(`Cannot find step by id: ${stepId}`);
        }
        return result;
    }
    findById(definition, stepId) {
        const result = this.findParentSequence(definition, stepId);
        return result ? result.step : null;
    }
    getById(definition, stepId) {
        return this.getParentSequence(definition, stepId).step;
    }
    forEach(sequenceOrDefinition, callback) {
        const sequence = Array.isArray(sequenceOrDefinition) ? sequenceOrDefinition : sequenceOrDefinition.sequence;
        this.iterate(sequence, callback);
    }
    find(sequence, needSequence, needStepId, result) {
        if (needSequence && sequence === needSequence) {
            return true;
        }
        const count = sequence.length;
        for (let index = 0; index < count; index++) {
            const step = sequence[index];
            if (needStepId && step.id === needStepId) {
                result.push({ step, index, parentSequence: sequence });
                return true;
            }
            const children = this.getChildren(step);
            if (children) {
                switch (children.type) {
                    case StepChildrenType.sequence:
                        {
                            const parentSequence = children.items;
                            if (this.find(parentSequence, needSequence, needStepId, result)) {
                                result.push({ step, index, parentSequence });
                                return true;
                            }
                        }
                        break;
                    case StepChildrenType.branches:
                        {
                            const branches = children.items;
                            const branchNames = Object.keys(branches);
                            for (const branchName of branchNames) {
                                const parentSequence = branches[branchName];
                                if (this.find(parentSequence, needSequence, needStepId, result)) {
                                    result.push(branchName);
                                    result.push({ step, index, parentSequence });
                                    return true;
                                }
                            }
                        }
                        break;
                    default:
                        throw new Error(`Step children type ${children.type} is not supported`);
                }
            }
        }
        return false;
    }
    iterate(sequence, callback) {
        const count = sequence.length;
        for (let index = 0; index < count; index++) {
            const step = sequence[index];
            if (callback(step, index, sequence) === false) {
                return false;
            }
            const children = this.getChildren(step);
            if (children) {
                switch (children.type) {
                    case StepChildrenType.sequence:
                        {
                            const childSequence = children.items;
                            if (this.iterate(childSequence, callback) === false) {
                                return false;
                            }
                        }
                        break;
                    case StepChildrenType.branches:
                        {
                            const branches = children.items;
                            const branchNames = Object.keys(branches);
                            for (const branchName of branchNames) {
                                const parentSequence = branches[branchName];
                                if (this.iterate(parentSequence, callback) === false) {
                                    return false;
                                }
                            }
                        }
                        break;
                    default:
                        throw new Error(`Step children type ${children.type} is not supported`);
                }
            }
        }
        return true;
    }
}

export { DefinitionWalker, StepChildrenType };
