/**
 * @author Sebastian Kokott, Iker Hurtado
 *
 * @fileoverview File holding the OutputExciting class
 */


import Output from './Output.js'
import Structure from '../common/Structure.js'
import * as util from '../common/util.js'
import {getParallelepipedVolume,geoTextFromStructure} from './util.js'


const HARTREE = 27.211386245988
const BOHR = 0.529177249

const floatRegex = /[-+]?[0-9]+[.][0-9]*([eE][-+]?[0-9]+)?/g

const parseFloat2AA = (floatString) => {
  return BOHR * parseFloat(floatString)
}

const parseFloat2eV = (floatString) => {
  return HARTREE * parseFloat(floatString)
}


/**
 * Exciting code calculation output
 */
export default class OutputExciting extends Output {

  constructor(){
    super()
    this.inputs = {'InputXML':[]}
    this.structureIn = new Structure()
    this.structureIn.latVectors = []
    this.systemInformation = {'formulaUnit':'','nAtoms':0}
    this.output = {'preamble':[], 'initialization':[], 'groundstate':[], 'finale':[]}
  }


  /**
   * Parses the all the calculation output files
   * @param  {Array<object>} filesData
   */
  parseFiles(filesData){

    this.bsData = []

    filesData.forEach( (fileData) => { // For every file
      if (['application/gzip', 'application/x-gzip'].includes(fileData.type)) {
        console.log('Workflow output for Exciting is not yet implemented')
        return
      }
      const fileName = fileData.name
      let lines = util.getTokenizedLines(fileData.content) // fileData.content.replace(/[ \t]+/g, ' ').split('\n');

      if (fileName === 'dos.xml'){
        this.files.dos.set(fileName, getDOSDataExciting(fileData.content))
        this.filledUp = true
      }else if(fileName === 'bandstructure.xml'){
        this.bsData = getBSDataExciting(fileData.content)
        this.filledUp = true
      }else if (fileName === 'INFO.OUT') {
        this.files.output.set(fileName, fileData.content)
        this.filledUp = true
      }else if (fileName === 'input.xml') {
        this.files.input.set(fileName, fileData.content)

      }
    })

    // method internal functions

    function getDOSDataExciting(xmlText){
      let dosDataDom = new DOMParser().parseFromString(xmlText, "application/xml")
      let data = []
      for (let node of dosDataDom.children[0].children) {
        //log('node', node.tagName)
        if (node.tagName === 'totaldos') {
          for (let pointNode of node.children[0].children)
            data.push([
              HARTREE*parseFloat(pointNode.getAttribute('e')),
              parseFloat(pointNode.getAttribute('dos'))
            ])
        }
      }
      return data
    }

    function getBSDataExciting(xmlText){
      let dosDataDom = new DOMParser().parseFromString(xmlText, "application/xml")
      let bands = [], vertexes = []
      for (let node of dosDataDom.children[0].children) {
        if (node.tagName === 'band') {
          let bandPoints = []
          for (let pointNode of node.children)
            bandPoints.push([parseFloat(pointNode.getAttribute('distance')),
              parseFloat(pointNode.getAttribute('eval'))])
          bands.push(bandPoints)
        }else if (node.tagName === 'vertex'){
          let coords = node.getAttribute('coord').replace(/[ \t]+/g, ' ').split(' ').map( c => parseFloat(c))
          vertexes.push({distance: parseFloat(node.getAttribute('distance')), label: node.getAttribute('label'), coords: coords})
        }
      }
      // The Exciting bandstrcuture format is turned into a one based on segments
      let segments = []
      let kpointIndex = 0
      let currentDistance
      for (let i = 1; i < vertexes.length; i++) { // For very segment

        let segment = {
          segment_num: i, // starts with 1
          band_energies: [[], [], []], // [spin1] and [spin2] and [SOC]
          band_k_points: [],
          band_segm_labels: [vertexes[i-1].label, vertexes[i].label],
          bsName: ["bandstructure.xml"],
          segmentName: ["Segment Number "+i]
        }

        currentDistance = vertexes[i-1].distance
        const segmentLength = vertexes[i].distance - vertexes[i-1].distance
        while ( currentDistance <= vertexes[i].distance) { // For every kpoint
          currentDistance = bands[0][kpointIndex][0]
          let distanceFromSegmentInit = currentDistance - vertexes[i-1].distance
          let kpointCoords = getKpointCoords(vertexes, i, distanceFromSegmentInit/segmentLength)
          //log('kpoint', kpointIndex, currentDistance, distanceFromSegmentInit, kpointCoords)
          segment.band_k_points.push(kpointCoords)
          //segment.band_energies[0]/*spin1*/.push(bands[0][kpointIndex][1])
          let energies = []
          bands.forEach( bandPoints => {
            energies.push(HARTREE*bandPoints[kpointIndex][1]) // the energy of every band for that kpoint is added
          })
          segment.band_energies[0]/*spin1*/.push(energies)
          if (currentDistance === vertexes[i].distance) break
          else kpointIndex++
        }
        segments.push(segment)
      }
      //log('bandstructure segments', segments)
      return segments
    }

    function getKpointCoords(vertexes, index, distPerUnit){
      let coords = []
      for (let i = 0; i < 3; i++)
        coords.push(vertexes[index-1].coords[i] +
          (vertexes[index].coords[i] - vertexes[index-1].coords[i])*distPerUnit)
      return coords
    }

  }


  /**
   * Returns an object with all the band structure info to plot it
   * @return {object}
   */
  getBsInfo(){
    return { bsData: this.bsData, lackingSegments: [] }
  }


  // getResultsQuantities(){
  //     const quantities = new Map()
  //     let lastIter = this.results.length-1
  //     const lastEl = this.results[lastIter]['scfConvergence'][4].length -1
  //
  //     quantities.set('Total Energy (eV)', this.results[lastIter].scEnergy[0] )
  //     quantities.set('Estimated HOMO-LUMO gap (eV)', this.results[lastIter]['scfConvergence'][4][lastEl]*HARTREE )
  //
  //     return quantities
  // }


  /**
   * Gathers and stores the data series for the relaxation graph
   */
  getRelaxationSeries() {
    let iters = [], dataI = [], forceI = [], dEnd = 0.0, evI = []
    let i = 0
    this.scfLoops.forEach(loop => {
      i += 1
      if (loop.isConverged) {
        iters.push(i)
        dEnd = loop['finalScfEnergies']['totalEnergy']
        dataI.push(loop['finalScfEnergies']['totalEnergy'])
        forceI.push(loop['maxForceComponent'])
      }
    })
    this.relaxationSeries = {
      'labels':iters,
      'energy':{"label":"Total Energy","data":dataI.map(function(value){return Math.abs(value-dEnd)}),"yAxisID":'yscenergy',"fill":false,"borderColor":"rgb(42,45,52)","lineTension":0.1},
      'forces':{"label":"Maximum Force Component","data":forceI.map(Math.abs),"yAxisID":'ymaxforce',"fill":false,"borderColor":"rgb(0,157,220)","lineTension":0.1}
    }
  }


  /**
   * Gathers and stores the data series for the convergence graph
   */
  getDataSeries() {
    // convergence accuracy
    let dataSeries = []

    this.scfLoops.forEach(loop => {
      // console.log(loop.iterations);
      // if (loop.iterations) console.log(loop.iteration);
      let hasIter = loop.iterations ? loop.iterations.length > 0 : false
      // console.log('hasIter',hasIter);
      if (hasIter) {
        let dataSeriesIteration = {
          'effectivePotential': {'label': 'Change of effective Potential', 'color':'rgb(0,157,220)', 'data':[]},
          'totalEnergy': {'label': 'Change of Total Energy', 'color':'rgb(242,100,48)', 'data':[]},
          'chargeDistance': {'label': 'Charge Distance', 'color':'rgb(42,45,52)', 'data':[]},
        }
        if (this.runTimeChoices.hasForces) {
          dataSeriesIteration['forces'] = {
            'label': 'Forces', 'color':'rgb(42,45,104)', 'data':[]
          }
        }
        // if (this.runTimeChoices.spin == 'collinear') {
        //   dataSeriesIteration['chargeDensityUp'] = {'label': 'Change of Density (Up)', 'color':'rgb(42,45,52)', 'data':[]}
        //   dataSeriesIteration['chargeDensityDown'] = {'label': 'Change of Density (Down)', 'color':'rgb(84,45,52)', 'data':[]}
        // } else {
        //   dataSeriesIteration['chargeDensity'] = {'label': 'Change of Density', 'color':'rgb(42,45,52)', 'data':[]}
        // }

        loop.iterations.forEach(iteration => {
          for (let [key,entry] of Object.entries(dataSeriesIteration)) {
            // console.log(key, entry);
            if (iteration.convergenceAccuracy){
              entry['data'].push(iteration.convergenceAccuracy[key])
            }
          }
        })
        dataSeries.push(dataSeriesIteration)
      }
    })
    // console.log(dataSeries);
    this.dataSeries = dataSeries
  }


  /**
   * Returns the main quantities of the calculation results to be shown
   * @return {Map<string,float>}
   */
  getResultsQuantities(){
      const quantities = new Map()
      // find last converged scf loop
      let lastConverged = -1
      // console.log(this.scfLoops);
      this.scfLoops.forEach(loop => {
        if (loop.isConverged) lastConverged += 1
      })
      if (lastConverged >= 0) {
        const lastLoop = this.scfLoops[lastConverged]
        // console.log(lastLoop);

        quantities.set('Total Energy (eV)', lastLoop.finalScfEnergies.totalEnergy)

        if (this.runTimeChoices.calculationType !== 'relaxation') {
          const lastIter = lastLoop['iterations'][lastLoop['iterations'].length -1]
          for (let [key,entry] of Object.entries(lastIter['electronInfo'])) {
            quantities.set(entry.info, entry.value)
          }
        }

        if (this.runTimeChoices.isPeriodic) {
          quantities.set('Cell Volume (&#197;<sup>3</sup>)',
            getParallelepipedVolume(this.structureIn.latVectors))
        }

        if (this.runTimeChoices.calculationType == 'relaxation') {
          const finalStructureText = geoTextFromStructure(lastLoop.structure)
          let href = window.URL.createObjectURL(new Blob([finalStructureText], {type: 'text/plain'} ))
          quantities.set('download-link', href)
        }
      }

      return quantities
  }


  /**
   * Returns summary calculation information
   * @return {Map<string,object>}
   */
  getCalculationInfo(){
    const quantities = new Map()
    // console.log(this.calculationInfo, this.finalTimings, this.memory, this.exitMode);
    let infoObjects = [this.calculationInfo, this.finalTimings, this.exitMode]
    infoObjects.forEach( infoObject => {
      if (infoObject !== undefined) {
        for (let [key,entry] of Object.entries(infoObject))
          quantities.set(entry.info, entry.value)
      }
    })
    return quantities
  }


  /**
   * Returns a map of the input files
   * @return {Map<string,string>}
   */
  getInputFilesMap(){
    return this.files.input
  }


  /**
   * Parses the main calculation output file.
   * If fineName is not passed in it's looks for the first one
   * @param  {string} fileName
   */
  parseOutputFile(fileName){

    if (this.files.output.size === 0) return

    if (!fileName) fileName = this.files.output.keys().next().value

    const fileText = this.files.output.get(fileName)
    this.parseFile(fileName, fileText)
  }


  /**
   * Parses the main calculation output file.
   * @param  {string} fileName
   * @param  {string} fileText
   */
  parseFile(fileName, fileText){
    this.normalParser(fileName, fileText)
    // this.parseFileAndInit(fileName, fileText)
    // this.grepGeometryFromOutput()
    this.getSystemInformation()

    this.getDataSeries()
    if (this.runTimeChoices.calculationType === 'relaxation') {
      this.getRelaxationSeries()
    }
    // this.grepResults()
  }


  // parseFileAndInit(filename, fileText){
  //
  //   this.filename = filename
  //
  //   let isPreamble = true
  //   let isInitialization = false
  //   let isGroundstate= false
  //   let isFinale = false
  //   let lines = fileText.split('\n')
  //
  //   lines.forEach(o => {
  //     if (o.indexOf(' Starting initialization')>=0 ) {
  //       isPreamble = false
  //       isInitialization = true
  //     }
  //     if (o.indexOf(' Ending initialization')>=0) {isInitialization = false}
  //     if (o.indexOf('Groundstate module started')>=0) {isGroundstate = true}
  //     if (o.indexOf('Groundstate module stopped')>=0) {isGroundstate = false}
  //
  //     if (isPreamble) {this.output.preamble.push(o)}
  //     if (isInitialization) {this.output.initialization.push(o)}
  //     if (isGroundstate) {this.output.groundstate.push(o)}
  //
  //   })
  // }


  // grepGeometryFromOutput(){
  //   //This is really ugly here, but a fast hack to make the output analyzer working for Exciting.
  //   function *linesIterator(body) {
  //     for (line in body) {yield body[line]}
  //   }
  //   let lines = linesIterator(this.output.initialization)
  //   let linIt = lines.next()
  //   let line = linIt.value
  //   let isFinished = linIt.done
  //   let notEmpty = false
  //
  //   let currentSpecies = ''
  //   while (!isFinished) {
  //     if (line.indexOf(' Species :')>=0) {
  //       //console.log(line.split(/(\s+)/))
  //       currentSpecies = line.split(/(\s+)/)[8].replace(/[()]/g, '')
  //       //console.log(currentSpecies)
  //     }
  //     if (line.indexOf(' Lattice vectors (cartesian) :')>=0) {
  //       notEmpty = true
  //       while(notEmpty) {
  //         linIt = lines.next()
  //         line = linIt.value
  //         let ls = line.split(/(\s+)/)
  //         if (ls[1]!=' '){
  //           let latVect = [parseFloat(ls[2])*this.bohr,parseFloat(ls[4])*this.bohr,parseFloat(ls[6])*this.bohr]
  //           //console.log('LS',latVect)
  //           this.structureIn.latVectors.push(latVect)
  //         }else {notEmpty =false}
  //       }
  //     }
  //     if (line.indexOf('  atomic positions (lattice) :')>=0) {
  //       notEmpty = true
  //       while(notEmpty) {
  //         linIt = lines.next()
  //         line = linIt.value
  //         let ls = line.split(/(\s+)/)
  //         if (ls[1]!=' '){
  //           let relPos = [parseFloat(ls[6]),parseFloat(ls[8]),parseFloat(ls[10])]
  //           //console.log(relPos)
  //           this.structureIn.addAtomData(relPos,currentSpecies,true)
  //         }else {notEmpty =false}
  //       }
  //     }
  //     if (line.indexOf('  atomic positions (cartesian) :')>=0) {
  //       notEmpty = true
  //       while(notEmpty) {
  //         linIt = lines.next()
  //         line = linIt.value
  //         let ls = line.split(/(\s+)/)
  //         if (ls[1]!=' '){
  //           let relPos = [parseFloat(ls[6])*this.bohr,parseFloat(ls[8])*this.bohr,parseFloat(ls[10])*this.bohr]
  //           //console.log(relPos)
  //           this.structureIn.addAtomData(relPos,currentSpecies,false)
  //         }else {notEmpty =false}
  //       }
  //     }
  //
  //     linIt = lines.next()
  //     line = linIt.value
  //     isFinished = linIt.done
  //   }
  // }


  /**
   * Fills the basic system information
   */
  getSystemInformation(){
    let nAtoms = this.structureIn.atoms.length
    let species = {}
    this.structureIn.atoms.forEach(a => {
      let sp = a.species
      species[sp] === undefined ? species[sp] = 1 : ++species[sp]
    })
    let formulaUnit = '' // formula unit
    for (let key in species) {
      let num = species[key]
      formulaUnit += (num === 1 ? key : key+num.toString())
    }
    this.systemInformation.nAtoms = nAtoms
    this.systemInformation.formulaUnit = formulaUnit
  }


  /**
   * Parses the main output file
   * @param  {string} fileName
   * @param  {string} fileText
   */
  normalParser(fileName, fileText) {

    function *linesIterator(body) {
      for (let line in body) {yield body[line]}
    }

    let parseUntil = (regexs,stopRe) => {
      let lines = []
      let line = lineIt.next()
      if (line === undefined) return undefined
      while (!line.done) {
        regexs.forEach( re => {
            if (line.value.match(re)) lines.push(line.value)
        })
        if (line.value.match(stopRe)) return lines
        line = lineIt.next()
      }
      this.errors.push(['Reached end of file before finding',stopRe.toString()])
      return undefined // Should only happe if we stop before
    }

    let waitFor = (waitRe) => {
      let line = lineIt.next()
      if (line === undefined) return undefined
      while (!line.done) {
        if (line.value.match(waitRe)) {
          return line
        }
        line = lineIt.next()
      }
      this.errors.push(['Did not find the following expression',waitRe.toString()])
      return undefined
    }

    let checkForUntil = (checkRe,stopRe) => {
      let line = lineIt.next()
      // console.log(line);
      while (!line.done) {
        if (line.value.match(checkRe)) return true
        else if (line.value.match(stopRe))  return false
        line = lineIt.next()
      }
      this.errors.push(['Reached end of file before finding',stopRe.toString()])
      return undefined
    }

    let matchNextLine = (regex) => {
      let line = lineIt.next()
      // console.log(line.value.match(regex));
      return line.value.match(regex)
    }

    let getLinesInBetween = (startRe,stopRe) => {
      let lines = []
      let line = waitFor(startRe)
      line = lineIt.next()
      if (!line) return undefined
      while (!line.done) {
        if (line.value.match(stopRe)) return lines
        lines.push(line.value)
        line = lineIt.next()
      }
      this.errors.push(['Reached end of file before finding',stopRe])
      return undefined // Should only happe if we stop before
    }

    function getCalculationInfo() {
      let line = waitFor(/\sEXCITING/g)
      if (line === undefined) return undefined
      let version = line.value.split(/\s+/)[2]
      line = waitFor(/version hash id/g)
      let hashId = line.value.split(/\s+/)[4]
      // console.log(version, hashId);
      return {
        'version': {'value':version, 'info': 'Version'},
        'hashID': {'value':hashId.slice(0,9), 'info':'Hash Id'}
      }
    }

    let getCalculationType = () => {
      let rtc = this.runTimeChoices
      rtc.isPeriodic = true
      let lines = parseUntil(
        [/Ground-state run/g,/Structural optimisation/g],
        /Starting initialization/g
      )
      if (lines === undefined) return
      if (lines[0].match(/Ground-state run/g)) rtc.calculationType = 'singlePoint'
      else if (lines[0].match(/Structural optimisation/g)) rtc.calculationType = 'relaxation'
      if (rtc.calculationType === 'relaxation') rtc.hasForces = true
    }
    let parseInitialization = () => {

      let line = waitFor(/\s+Lattice vectors/g)
      // console.log(line);
      let initStructure = new Structure()
      // console.log(initStructure);
      initStructure.latVectors = []
      initStructure.latVectors.push(matchNextLine(floatRegex).map(parseFloat2AA))
      initStructure.latVectors.push(matchNextLine(floatRegex).map(parseFloat2AA))
      initStructure.latVectors.push(matchNextLine(floatRegex).map(parseFloat2AA))
      // console.log(initStructure);

      let lines = getLinesInBetween(/Brillouin zone volume/g,/Total number of atoms per unit cell/g)

      let isPositionBlock = false
      let isFract = false
      let species = ''

      // console.log(lines);
      lines.forEach(line => {
        if (line.match(/Species\s/g)) {
          species = line.match(/\((.*)\)/)[1]
          // console.log(species);
        }
        if (line.match(/atomic positions/g) && !isPositionBlock) {
          isFract = line.match(/\(([^)]+)\)/)[1] === "lattice"
          isPositionBlock = true
          // console.log(isPositionBlock);
        } else if (isPositionBlock) {
          // console.log(line);
          let position = line.match(floatRegex)
          if (position) {
            if (isFract)
              initStructure.addAtomData(position.map(parseFloat),species.trim(), isFract)
            else
              initStructure.addAtomData(position.map(parseFloat2AA),species.trim(), isFract)
          } else {
            isPositionBlock = false
          }
        }
      })
      // console.log(initStructure);
      this.structureIn = initStructure
      currentStructure = initStructure
      line = waitFor('Spin treatment')
      if (!line) return undefined
      else {
        let spin = line.value.split(/\s+/)[4]
        if (spin === 'spin-unpolarised') this.runTimeChoices.spin = 'none'
        if (spin === 'spin-polarised') this.runTimeChoices.spin = 'collinear'
      }
      line = waitFor('Ending initialization')
      if (!line) return undefined
    }

    function getScfLoops(runTimeChoices) {
      // Parse first SCF loop (usually output level normal)
      let scfLoops = []
      let line = waitFor('Self-consistent loop started')
      if (!line) return undefined
      // console.log(line.value);
      let [isConverged, iterations] = getIterations(runTimeChoices)
      // console.log(isConverged, iterations);
      let firstScfLoop = {
        'isConverged': isConverged,
        'iterations': iterations,
        'structure': currentStructure,
        'finalScfEnergies': {}
      }
      if (!isConverged) return [firstScfLoop]
      let lastIteration = getLastIteration()
      if (!lastIteration) return [firstScfLoop]
      firstScfLoop.iterations.push(lastIteration)
      // console.log(lastIteration);
      firstScfLoop['finalScfEnergies']['totalEnergy'] = lastIteration.scfEnergies.totalEnergy
      // console.log(firstScfLoop);
      if (runTimeChoices.calculationType === 'relaxation') {
        line = waitFor(/Maximum force magnitude/g)
        if (!line) return [firstScfLoop]
        firstScfLoop['maxForceComponent'] = parseFloat(line.value.match(floatRegex)[0])*HARTREE/BOHR
      }
      scfLoops.push(firstScfLoop)
      // console.log(runTimeChoices.calculationType);
      if (runTimeChoices.calculationType === 'relaxation') {
        let [isConverged, relaxationScfLoops] = getRelaxationScfLoops()
        // console.log(relaxationScfLoops);
        scfLoops = [firstScfLoop,...relaxationScfLoops]
      }
      return scfLoops
    }

    function getRelaxationScfLoops() {
      let relaxationScfLoops = []
      let isNotConverged = true
      while(isNotConverged) {
        isNotConverged = checkForUntil(/Optimization step/g,/Force convergence target achieved/g)
        if (isNotConverged === false) {
          // console.log('I am here!!!!!!');
          return [isNotConverged,relaxationScfLoops]}
        else if (isNotConverged === undefined) {
          // console.log('I am here1!!!!!!');
          return [false, relaxationScfLoops]
        }
        else {
          let optimizationStep = getOptimizationStep()
          if(!optimizationStep) return [false, relaxationScfLoops]
          relaxationScfLoops.push(optimizationStep)
        }
      }
    }

    function getOptimizationStep() {
      let optimizationStep = {'finalScfEnergies': {}, 'maxForceComponent':undefined}
      let line = waitFor(/Maximum force magnitude/g)
      if (!line) return undefined
      optimizationStep['maxForceComponent'] = parseFloat(line.value.match(floatRegex)[0])*HARTREE/BOHR

      line = waitFor(/Total energy at this optimization step/g)
      if (!line) return undefined
      optimizationStep['finalScfEnergies']['totalEnergy'] = parseFloat2eV(line.value.match(floatRegex))

      let lines = getLinesInBetween(/Atomic positions at this step/g,/Total atomic forces/g)
      if (!lines) return undefined
      // console.log(lines);
      let updatedStructure = new Structure()
      updatedStructure.latVectors = currentStructure.latVectors
      lines.forEach(line => {
        if (line.includes('atom')) {
          let position = line.match(floatRegex)
          let species = line.split(/\s+/).filter(i => i)[2]
          // console.log(position,species);
          updatedStructure.addAtomData(position.map(parseFloat),species.trim(),true)
        }
      })
      optimizationStep['structure'] = updatedStructure
      optimizationStep['isConverged'] = true
      return optimizationStep
    }

    function getIterations(runTimeChoices) {
      let iterations = []
      let isNotConverged = true
      while(isNotConverged) {
        isNotConverged = checkForUntil(/SCF iteration number/g,/Convergence targets achieved\./g)
        // console.log('getIteration isConverged',isNotConverged);
        if (isNotConverged === false)  return [true,iterations]
        else if (isNotConverged === undefined) return [false, iterations]
        else {
          let iteration = getIteration(runTimeChoices)
          // console.log(iteration);
          if (!iteration) return [false, iterations]
          iterations.push(iteration)
        }
      }
    }

    function getIteration(runTimeChoices) {
      let iteration = {}
      iteration['scfEnergies'] = {}
      iteration['electronInfo'] = {}

      let line = waitFor(/Total energy/g)
      if (!line) return undefined
      iteration['scfEnergies']['totalEnergy'] = parseFloat2eV(line.value.match(floatRegex))
      // console.log(iteration);
      line = waitFor(/Fermi energy/g)
      if (!line) return undefined
      iteration['electronInfo']['fermiEnergy'] = {
        'value': parseFloat2eV(line.value.match(floatRegex)),
        'info': 'Fermi Energy (eV)'
      }
      // console.log(iteration);
      let convAcc = getConvAcc(runTimeChoices)
      if (!convAcc) return undefined
      iteration['convergenceAccuracy'] = convAcc
      // console.log(iteration);
      return iteration
    }

    function getLastIteration() {
      let iteration = {}
      iteration['scfEnergies'] = {}
      iteration['electronInfo'] = {}

      let line = waitFor(/Total energy/g)
      if (!line) return undefined
      iteration['scfEnergies']['totalEnergy'] = parseFloat2eV(line.value.match(floatRegex))
      // console.log(iteration);
      line = waitFor(/Fermi energy/g)
      if (!line) return undefined
      iteration['electronInfo']['fermiEnergy'] = {
        'value': parseFloat2eV(line.value.match(floatRegex)),
        'info': 'Fermi Energy (eV)'
      }

      line = waitFor(/Estimated fundamental gap/g)
      if (!line) return undefined
      iteration['electronInfo']['gap'] = {
        'value': parseFloat2eV(line.value.match(floatRegex)),
        'info': 'Estimated fundamental gap (eV)'
      }

      return iteration
    }

    function getConvAcc(runTimeChoices) {
      let convAcc = {}
      let line = waitFor(/RMS change in effective potential/g)
      if (!line) return undefined
      convAcc['effectivePotential'] = line.value.match(floatRegex)[0]

      line = waitFor(/Absolute change in total energy/g)
      if (!line) return undefined
      convAcc['totalEnergy'] = line.value.match(floatRegex)[0]

      line = waitFor(/Charge distance/g)
      if (!line) return undefined
      convAcc['chargeDistance'] = line.value.match(floatRegex)[0]

      if (runTimeChoices.hasForces) {
        line = waitFor(/Abs. change in max-nonIBS-force/g)
        if (!line) return undefined
        convAcc['forces'] = line.value.match(floatRegex)[0]
      }
       return convAcc
    }

    let getFinalTimings = () => {
      let finalTimings = {}
      let line = waitFor(/Total time spent/g)
      // console.log(line);
      if (!line) return undefined
      finalTimings['totalTime'] = {
        'value': parseFloat(line.value.match(floatRegex)),
        'info': 'Total Time (s)'
      }
      return finalTimings
    }

    let getExitMode = () => {
      let exitMode = {}
      let line = waitFor(/EXCITING.*stopped/g)
      // console.log(line);
      let value
      if (line === undefined) value = 'no'
      else value = line.value ? 'yes': 'no'
      exitMode['exitMode'] = {
        'value': value,
        'info': 'Calculation exited regularly'
      }
      return exitMode
    }

    let currentStructure
    let lines = fileText.split('\n')
    let lineIt = linesIterator(lines)

    this.calculationInfo = getCalculationInfo()
    // console.log(this.calculationInfo);
    getCalculationType()
    parseInitialization()
    this.scfLoops = getScfLoops(this.runTimeChoices)
    // console.log(lineIt.next());
    // console.log(this.scfLoops);

    this.finalTimings = getFinalTimings()
    // console.log(this.finalTimings);
    this.exitMode = getExitMode()

  } // normalParser


  // grepResults(){
  //   console.log('Start grepping results')
  //   let isConverged = false
  //   let isNewGeo = false
  //   let iter = {}
  //   let structure = []
  //   let isScfLoop = false
  //
  //   function *linesIterator(body) {
  //     for (line in body) {yield body[line]}
  //   }
  //
  //   let lines = linesIterator(this.output.groundstate)
  //
  //   let scfItems = [
  //     [' Total energy                               :', line => parseFloat(line.split(/(\s+)/)[8]), []],
  //     [' RMS change in effective potential (target) :', line => parseFloat(line.split(/(\s+)/)[16]), []],
  //     [' Absolute change in total energy   (target) :', line => parseFloat(line.split(/(\s+)/)[16]), []],
  //     [' Charge distance                   (target) :', line => parseFloat(line.split(/(\s+)/)[10]), []],
  //     [' Estimated fundamental gap                  :', line => parseFloat(line.split(/(\s+)/)[10]), []]
  //   ]
  //
  //   let grepScfData = (line) => {
  //
  //     scfItems.forEach( item => {
  //       if (line.includes(item[0])){item[2].push(item[1](line))}
  //     })
  //     if (line.includes('Convergence targets achieved. Performing final SCF iteration')) {
  //       iter['scfConvergence'] = scfItems.map(item => item[2])
  //       scfItems.forEach(item => item[2] = [])
  //     }
  //
  //     //if (line.includes('Self-consistent loop stopped')){
  //     //  iter['scfConvergence'] = scfItems.map(item => item[2])
  //     //  scfItems.forEach(item => item[2] = [])
  //     //  isScfLoop = false
  //     //}
  //   }
  //
  //
  //   let linIt = lines.next()
  //   let line = linIt.value
  //   let isFinished = linIt.done
  //
  //   while (!isFinished) {
  //
  //     if (isScfLoop) grepScfData(line)
  //     else if (line.includes('Self-consistent loop started')) { isScfLoop = true }
  //
  //     //else { //We are outside the SCF loop, but potentially befor the next iteration
  //     //  grepPostScfData(line)
  //       //if (line.includes('Updated atomic structure:')) isNewGeo = true
  //       //else if (line.includes('Fractional coordinates:')) isNewGeo = false
  //       //else if (isNewGeo && (line.includes('lattice_vector')||line.includes('atom'))) {structure.push(line)}
  //     //}
  //
  //     linIt = lines.next()
  //     line = linIt.value
  //     isFinished = linIt.done
  //   }
  //
  //   if (scfItems[0][2].length > 0) {
  //       //iter['scfConvergence'] = scfData
  //       //iter['scEnergy'] = postScfItems[0][2]
  //       let lastScfIter = scfItems[0][2].length
  //       iter['scEnergy'] = [scfItems[0][2][lastScfIter-1]*HARTREE]
  //       console.log(iter);
  //       //this.results.push(iter)
  //       this.results.push(iter)
  //   }
  //
  // }

}
