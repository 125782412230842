/**
 * @author Iker Hurtado
 *
 * @fileoverview It's a UI component implementing a pagination control
 */


import UIComponent from './UIComponent.js'
import prevArrowIcon from '../../img/prev-arrow-white-icon.png'
import nextArrowIcon from '../../img/next-arrow-white-icon.png'

/**
 * UI pagination control
 */
export default class PaginationSelector extends UIComponent{

  /**
   * @param {string} label Component label next to the control buttons
   * @param {int} startPage Starting page. Default: 1
   */
  constructor(label, startPage = 1){

    super('div', '.Pagination-selector')

    // component state
    this.startPage = startPage
    this.page = startPage
    this.pageNum

    this.setHTML(`
        <span class="page-selector-label"> ${label} </span>&nbsp;
        <button class="prev-page"> 
          <img src="${prevArrowIcon}" height="14px" style="vertical-align: middle;">
        </button>
        <span class="current-page-label"></span>
        <button class="next-page"> 
          <img src="${nextArrowIcon}" height="14px" style="vertical-align: middle;">
        </button>
    `)

    this.pageLabel = this.getElement('.current-page-label')

    const prevButton = this.getElement('button.prev-page')
    const nextButton = this.getElement('button.next-page')
    prevButton.addEventListener( 'click', e => {
      if (this.page > this.startPage){
        this.page--
        this.updatePageLabel()
        this.prevListener(this.page)
      } 
    })
    nextButton.addEventListener( 'click', e => {
      if ((this.pageNum + this.startPage - 1) > this.page){
        this.page++
        this.updatePageLabel()
        this.nextListener(this.page)
      }
    })
  }


  /**
   * Initilizes the component up to the number of pages
   * @param {int} pageNum
   */
  init(pageNum){
    this.pageNum = pageNum
    this.updatePageLabel()
  }


  /**
   * Set the page number
   * @param {int} page
   */
  setPage(page){
    this.page = page
    this.updatePageLabel()
  }


  /**
   * Update the page label being shown
   */
  updatePageLabel(){
    this.pageLabel.textContent = this.page+'/'+ (this.pageNum + this.startPage - 1)
  }

  
  /**
   * Set the listener that will be called when the previous button is clicked
   * @param {function} listener
   */
  setPrevListener(listener){
    this.prevListener = listener
  }


  /**
   * Set the listener that will be called when the next button is clicked
   * @param {function} listener
   */
  setNextListener(listener){
    this.nextListener = listener
  }
}