/**
 * @author Sebastian Kokott,  Iker Hurtado
 *
 * @overview File holding the Output base class
 */


/**
 * Base class modeling a calculation output
 */
export default class Output {

  constructor(){

  	// Object storing the output files
    this.files = {
    	dos: new Map(),
      absorption: new Map(),
      dielectric: new Map(),
    	output: new Map(),
    	input: new Map()
    }

    // Set to true when it's filled up
    this.filledUp = false

		this.inputs = {}
		// this.outputs = {}
		this.results = []
		this.runTimeChoices = {
      'calculationType':'singlePoint', // 'singlePoint','relaxation'
      'spin':'none', // 'none','collinear'
      'hasForces':false,
      'isPeriodic':false,
      'hasStress':false,
      'outputLevel':'normal', // 'normal', 'MD_light'
      'hasSOC': false,
      'hasHirshfeld': false,
      'hasMulliken': false,
      'hasMBD': false
    }
    this.systemInformation = {
      'nAtoms':0,
      'formulaUnit': undefined,
      'volume': undefined
    }
    this.controlIn = undefined
    this.structureIn = undefined
		this.errors = []
    this.ksEv = undefined
    this.workflows = []
  }

  /**
   * Parses the all the calculation output files
   * @param  {Array<object>} filesData
   */
  parseFiles(filesData){}

  /**
   * Returns if it's filled up
   * @return {Boolean}
   */
  isFilledUp(){
    return this.filledUp
  }

  /**
   * Returns if the calculation is a relaxation
   * @return {boolean}
   */
  isRelaxation(){
    return this.runTimeChoices.calculationType === 'relaxation'
  }

  /**
   * Parses the main calculation output file.
   * If fileName is not passed in it, looks for the first one
   * @param  {string} fileName
   */
  parseOutputFile(fileName){}

  /**
   * Parses the main calculation output file.
   * @param  {string} fileName
   * @param  {string} fileText
   */
  parseFile(fileName, fileText){}

  /**
   * Returns the structure got from the geometry input file
   * @return {Structure}
   */
  getStructure(){
  	return this.structureIn
  }

  /**
   * Returns the DOS data from the first file read
   * @return {object}
   */
  getFirstFileDosData(){
    return ( this.files.dos.size > 0 ? this.files.dos.get(this.files.dos.keys().next().value) : undefined )
  }

  getBsInfo(){}
  // /**
  //  * Returns the Absorption data from the first file read
  //  * @return {object}
  //  */
  // getFirstFileAbsorptionData(){
  //   return ( this.files.absorption.size > 0 ? this.files.absorption.get(this.files.absorption.keys().next().value) : undefined )
  // }

}
