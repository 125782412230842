/**
 * @author Iker Hurtado
 *
 * @overview File holding the Settings UI component
 */

import UIComponent from './common/UIComponent.js'
import Form from './common/Form.js'
import {Conf} from './Conf.js'
import * as AppState from './State.js'
import * as ModalPopup from './common/ModalPopup.js'


/**
 * Simple form on a modal popup enabling the change of application level setting
 */
export default class Settings extends UIComponent{

  constructor(){

    super('div', '.Settings')
        
    this.setHTML(`
        <div style="text-align: center">APPLICATION SETTINGS</div>
    `)

    this.form = new Form('settings-form')
    this.form.disableUserMsg()
    this.form.addField('decimalDigits','Number of decimal digits', 'input-text',
      Conf.settings.decimalDigits, 'integer', 0, true)
    this.form.addField('symmetryThreshold','Symmetry Threshold','input-text',
      Conf.settings.symmetryThreshold, 'float', 0, true, false, "Å")
    this.button = this.form.addButton('UPDATE')
    this.e.appendChild(this.form.e)

    this.button.addEventListener( 'click', _ => {
        Conf.settings = this.form.getFieldsNameValue()
        AppState.updateSettings(Conf.settings)
        ModalPopup.hideModal()
    })
  }

}
