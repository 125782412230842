/**
 * @author Iker Hurtado
 *
 * @overview File holding the module State class
 */

import {generateSupercell, generateSlab} from '../common/util.js'
import {Conf} from '../Conf.js'
import {CHANGE} from '../State.js'
import Change from "./Changes";


/**
 * Structure Builder state and implements the undo/redo functionality
 */
export default class State{

  constructor(){

    // Fractional state and listener
    this.fractional = false //  fractional coordinates
    this.fractionalListeners = []

    // Structure state and listeners
    this.structure = undefined
    this.structureIDcounter = -1
    this.structureID = -1
    this.structureStates = {}

    this.structureListeners = []
    this.atomHighlightListeners = []
    this.speciesColorListeners = []

    // Undo/redo system
    this.changeList = []

    this.undoIndex = -1 // Points to the last element
    this.undoRedoListener = undefined
  }

  // Fractional state - Atoms data can be shown in fractional or cartesian coordinates

  /**
   * Returns the if the view is in fractional (true) or cartesian (false)
   * @return {boolean}
   */
  getFractional(){
    return this.fractional
  }

  /**
   * Sets the fractional state and emits the corresponding event
   * @param fractional {boolean}
   */
  setFractional(fractional){
    this.fractional = fractional
    this.fractionalListeners.forEach( component => {
      // console.log('component',component)
      component.updateFractional(this.fractional)
    })
  }

  /**
   * Change factory method. Could be possibly given an atomic change
   * @param changeType {string} a type of change as given in the root State
   * @param atomIndex {int}
   * @param detail {Object}
   * @param setChange {boolean}
   * @returns {Change}
   */
  change(changeType = undefined,
         atomIndex = undefined,
         detail = undefined,
         setChange = true) {
    return new Change(this, changeType, atomIndex, detail, setChange)
  }

  // Structure state - Data of the current structure being shown

  /**
   * Returns the current structure
   * @return {Structure}
   */
  getStructure(){
    return this.structure
  }

  /**
   * Returns a structure atom position in fractional or cartesian
   * coordinates depending on the fractional state
   * @param  {int} i Atom index in the structure
   * @return {number[]}
   */
  getAtomPosition(i){
    return ( this.fractional ?
      this.structure.getAtomFractPosition(i) :
      this.structure.atoms[i].position )
  }

  /**
   * Sets a new structure in the state and a new structure event is emitted.
   * The undo/redo is reset (default)
   * @param {Structure} s
   * @param {boolean} undoRedoReset
   */
  setStructure(s, undoRedoReset = true){
    this.saveOldState()
    this.structure = s
    this.structureIDcounter += 1
    this.structureID = this.structureIDcounter
    this.structure.id = this.structureIDcounter
    this.structureStates[this.structureID] = {
      'structure': this.structure,
      'undoIndex': this.undoIndex,
      'changeList': this.changeList
    }
    if (undoRedoReset) this.resetUndoRedo() // regular behavior

    if (!this.structure.isAPeriodicSystem()) this.setFractional(false)

    this.emitNewStructure()
    // console.log('this.structureStates',this.structureStates)
  }

  saveOldState() {
    // console.log('saveOldState:ID',this.structureID)
    if (this.structureID >= 0) {
      this.structureStates[this.structureID] = {
        'structure': this.structure,
        'undoIndex': this.undoIndex,
        'changeList': this.changeList
      }
    }
  }

  selectStructure(structureID,save=true) {
    if(save) this.saveOldState()
    let selected = this.structureStates[structureID]
    // console.log('Huhu', this.structureStates)
    this.structure = selected.structure
    this.undoIndex = selected.undoIndex
    this.changeList =  selected.changeList
    this.structureID = structureID
    this.emitNewSelection()
  }

  /**
   * Resets the state structure and a new structure event is emitted.
   */
  resetStructure(){
    this.structure.reset()
    this.structure.fileSource = 'New Structure'
    this.resetUndoRedo()
    if (!this.structure.isAPeriodicSystem()) this.setFractional(false)
    this.emitNewStructure()
  }

  /**
   * Transforms the structure in a supercell of it.
   * A new structure event is emitted
   * @param  {Array<string>} dimArray Array specifying the multiplication of the axes
   * @param  {boolean} setChange If the change (undo/redo) will be registered
   * @return {boolean} Returns true if everything was ok
   */
  repeatStructure(dimArray, setChange = false){  // multiply? structure
    let supercell = generateSupercell(dimArray, this.structure)
    if (supercell.atoms.length < 30000){
      if (setChange) this.change(CHANGE.STRUCTURE.SUPERCELL, undefined, [this.structure, supercell]).do()
      else this.setStructure(supercell, true)
      return true
    }
    else return false
  }

  /**
 * Makes the slab of the structure.
 * A new structure event is emitted
 * @param  {Object} slabData a dict of slab generation data
 * @param  {boolean} setChange If the change (undo/redo) will be registered
 * @return {boolean} Returns true if everything was ok
 */
  async makeSlab(slabData, setChange = false){
    let slab = await generateSlab(slabData, this.structure)
    if (slab.atoms.length < 30000){
      if (setChange) this.change(CHANGE.STRUCTURE.SURFACE, undefined, [this.structure, slab]).do()
      this.setStructure(slab, true)
      return true
    }
    else return false
  }

  /**
   * Updates the structure lattice
   * A structure change event is emitted
   * @param  {number[][]} vectors
   * @param  {boolean} scaleAtomsPosition
   *   If true the atoms positions will scale with the new lattice vectors
   * @param  {boolean} _setChange If the change (undo/redo) will be registered
   */
  updateLatticeVectors(vectors, scaleAtomsPosition = false, _setChange = true){
    // vectors === undefined means  lattice vectors are removed from the structure
      this.change(CHANGE.STRUCTURE.LATTICE_VECTORS, undefined,
        [this.structure.latVectors, vectors, scaleAtomsPosition]).do()
  }

  /**
   * Updates an atom species. A structure change event is emitted
   * @param  {int} atomIndex
   * @param  {string} species
   * @param  {boolean} _setChange If the change (undo/redo) will be registered
   */
  updateAtomSpecies(atomIndex, species, _setChange = true){
    let update = [this.structure.atoms[atomIndex].species, species]
    this.change(CHANGE.STRUCTURE.ATOM.SPECIES, atomIndex, update).do()
  }

  /**
   * Updates an atom initial moment. A structure change event is emitted
   * @param  {int} atomIndex
   * @param  {float} initMoment
   * @param  {boolean} _setChange If the change (undo/redo) will be registered
   */
  updateAtomInitMoment(atomIndex, initMoment, _setChange = true){
    let update = [this.structure.atoms[atomIndex].initMoment, initMoment]
    this.change(CHANGE.STRUCTURE.ATOM.INITMOMENT, atomIndex, update).do()
  }
  
  /**
   * Updates an atom initial charge. A structure change event is emitted
   * @param  {int} atomIndex
   * @param  {float} charge
   * @param  {boolean} _setChange If the change (undo/redo) will be registered
   */
  updateAtomCharge(atomIndex, charge, _setChange = true){
      let update = [this.structure.atoms[atomIndex].charge, charge]
      this.change(CHANGE.STRUCTURE.ATOM.CHARGE, atomIndex, update).do()
  }

  /**
   * Updates an atom constraint. A structure change event is emitted
   * @param  {int} atomIndex
   * @param  {boolean} constraint
   * @param  {boolean} _setChange If the change (undo/redo) will be registered
   */
  updateAtomConstraint(atomIndex, constraint, _setChange = true){
    let update = [this.structure.atoms[atomIndex].constraint, constraint]
    this.change(CHANGE.STRUCTURE.ATOM.CONSTRAINT, atomIndex, update).do()
  }

  /**
   * Updates an atom position. A structure change event is emitted
   * @param  {int} atomIndex
   * @param  {number[]} values
   * @param  {boolean} isFractional If the position is specified in
   *   fractional coordinates (true) or cartesian (false)
   */
  updateAtomPosition(atomIndex, values, isFractional){
    const f = (isFractional === undefined ? this.fractional : isFractional)
    // change addition
    let newPosition = (f ? this.structure.getCartesianCoordinates(values) : values)
    // Using slice() in order to generate anew array, not a reference
    let update = [this.structure.atoms[atomIndex].position.slice(), newPosition]
    this.change(CHANGE.STRUCTURE.ATOM.MOVE, atomIndex, update).do()
  }

  // /**
  //  * Updates an atom position but the change (undo/redo) will not be registered.
  //  * A structure change event is emitted
  //  * @param  {int} atomIndex
  //  * @param  {Array<float>} values
  //  */
  // updateAtomPositionNoChange(atomIndex, values){
  //   this.structure.updateAtomPosition(atomIndex, values, false)
  //   this.emitStructureChange({type: CHANGE.STRUCTURE.ATOM.MOVE, atomIndex: atomIndex})
  // }


  /**
   * Adds an undefined atom to the structure.
   * A structure change event is emitted
   * @param  {boolean} _setChange If the change (undo/redo) will be registered
   */
  addUndefinedAtom(_setChange = true){
    this.change(CHANGE.STRUCTURE.ATOM.ADD).do()
  }


  /**
   * Removes and atom from the structure. A structure change event is emitted
   * @param  {int} atomIndex
   * @param  {boolean} _setChange If the change (undo/redo) will be registered
   */
  removeAtom(atomIndex, _setChange = true){
    //
    this.change(CHANGE.STRUCTURE.ATOM.REMOVE, atomIndex,
      [undefined, atomIndex, this.structure.atoms[atomIndex]]).do()
  }

  // Species color change - The species showing color can be changed

  /**
   * Changes the color of a species. A species color change event is emitted
   * @param  {string} species
   * @param  {string} color Color code
   */
  changeSpeciesColor(species, color){
    // Update the species color in Conf.js
    Conf.setSpeciesColor(species, color)
    //console.log('changeSpeciesColor', species, color)
    this.speciesColorListeners.forEach( component => {
      component.changeSpeciesColor(species, color)
    })
  }

  // Subscriptions and event emission

  /**
   * Subscribes a component to the main module events:
   * structure, fractional and species color
   * @param  component {*}
   */
  subscribeToAll( component ){
    this.structureListeners.push(component)
    this.fractionalListeners.push(component)
    this.speciesColorListeners.push(component)
  }

  /**
   * Subscribes a component to the structure events:
   * @param  component {UIComponent}
   */
  subscribeToStructure( component ){
    this.structureListeners.push(component)
  }

  // /**
  //  * Subscribes a component to the species color event:
  //  * @param  component {UIComponent}
  //  */
  // subscribeToSpeciesColorChange( component ){
  //   this.speciesColorListeners.push(component)
  // }

  /**
   * Emits a structure change event
   * @param  {object} change
   */
  emitStructureChange(change){
    // console.log('emitStructureChange', change)
    this.structureListeners.forEach( component => {
      component.updateStructure(this.structure, change )
    })
  }

  /**
   * Emits a new structure event
   */
  emitNewStructure(){
    this.structureListeners.forEach( component => {
      // console.log('emitNewStructure',  component, component.constructor.name)
      component.setNewStructure(this.structure)
    })
  }

  emitNewSelection(){
    this.structureListeners.forEach( component => {
      // console.log('emitNewSelection',  component, this.structure)
      component.selectStructure(this.structure)

    })
  }

  /**
   * Subscribes a component to the atom highlight event:
   * @param  component {UIComponent}
   */
  subscribeToAtomHighlight( component ){
    this.atomHighlightListeners.push(component)
  }

  /**
   * Commands an atom highlight event
   * @param  {object} atomIndex
   * @param  {boolean} on
   */
  highlightAtom(atomIndex, on = true){
    this.emitHighlightAtom(atomIndex, on)
  }

  /**
   * Emits an atom highlight event
   * @param  {object} atomIndex
   * @param  {boolean} on
   */
  emitHighlightAtom(atomIndex, on){
    this.atomHighlightListeners.forEach( component => {
      //console.log('emitStructureChange ',  component)
      component.highlightAtom(atomIndex, on)
    })
  }

  // Undo/redo functionality section

  /**
   * Adds a change to the undo/redo list and move the pointer
   * @param change {Change}  change instance
   */
  addChange(change){
    // Check if the index is not pointing to the last change
    if (this.undoIndex < this.changeList.length-1){
      this.changeList.length = this.undoIndex+1
    }
    this.changeList.push(change)
    this.undoIndex++ // Pointing to the last element
    this.undoRedoListener(this.undoIndex+1, this.changeList.length)
    //console.log('addChange:', this.changeList, this.undoIndex)
  }

  /**
   * Resets the undo/redo system
   */
  resetUndoRedo(){
    this.changeList = []
    this.undoIndex = -1
    this.undoRedoListener(this.undoIndex+1, this.changeList.length)
  }

  /**
   * Performs an undo operation: update the undo/redo list
   * and carries out the appropriate operation on the structure state
   */
  undo(){
    //console.log('this.undoIndex:', this.undoIndex)
    if (this.undoIndex < 0) return

    let change = this.changeList[this.undoIndex]
    change.undo()
    this.undoIndex--
    this.undoRedoListener(this.undoIndex+1, this.changeList.length)
  }

  /**
   * Performs a redo operation: update the undo/redo list
   * and carries out the appropriate operation on the structure state
   */
  redo(){
    //console.log('redoIndex:', this.undoIndex)
    if (this.undoIndex === this.changeList.length-1) return
    else{
      this.undoIndex++
      this.undoRedoListener(this.undoIndex+1, this.changeList.length)
    }
    let change = this.changeList[this.undoIndex]
    change.do()
  }

  /**
   * Sets the listener that will be called when an undo/redo operation
   * is carried out
   * @param {function} listener
   */
  setUndoRedoListener(listener){
    this.undoRedoListener = listener
  }
}
