/**
 * @author Andrey Sobolev
 *
 * @overview The Editor for the structure builder workflow step (includes a button to the StructureBuilder)
 */
import {WorkflowStep, WorkflowStepEditor} from "./workflow-step";
import StructureBuilderMod from "../structure-builder-mod/StructureBuilderMod";
import StructureInfo from "../structure-builder-mod/StructureInfo";


export class StructureBuilderStep extends WorkflowStep {
  static config = {
    ...WorkflowStep.config,
    group: 'Structure',
    name: 'Structure Builder',
    type: 'StructureBuilder',
  }

  constructor(parent, stepId) {
    super(parent, stepId);
    this.editor = new StructureBuilderStepEditor(this)
    this.component = new StructureBuilderMod()
    this.label = 'Create or upload geometry'
    this.properties = {}
  }

  save() {
    this.properties.structure = this.component.getCurrentStructure()
    this.editor.update()
    return true
  }

  validate() {
    return this.properties.structure !== undefined
  }

  destroy() {
    this.workflow.structure = undefined
  }
}

export class StructureBuilderStepEditor extends WorkflowStepEditor {

  constructor(parent) {
    super(parent);
    this.setHTML(`<button class="swd-button">Choose a structure</button>
                  <div class="swd-structure-info" style="display:block"></div>`)
    this.structureInfo = new StructureInfo(undefined)
    this.getElement('.swd-structure-info').appendChild(this.structureInfo.e)
  }

  update(){
    this.step.properties = this.parent.properties
    this.structureInfo.updateTable(this.step.properties.structure.structureInfo)
    this.ctx.notifyPropertiesChanged()
  }

}