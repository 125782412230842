
/**
 * @author Iker Hurtado
 *
 * @overview This file is a small and convenient 2D drawing library.
 * It uses the canvas browser API.
 * It's meant to be simple, efficient and customizable.
 * The DOS and BS graphs are based on it.
 */

/**
 * The Canvas class represents a html canvas and provides utilities to draw
 * and interact with simple geometrical elements (lines, circles, text, etc) 
 */
export default class Canvas{

  /**
   * @param {HTMLElement} hostElement - A DOM element can be given to host the 
   * canvas.
   * @param {string} idOrClass - An id or class for the canvas element
   */
  constructor(hostElement, idOrClass){

  	this.canvas = document.createElement('canvas')
    this.ctx = this.canvas.getContext('2d')

    // If hostElement -> 
    // it determines the graph size and is updated when it changes
    if (hostElement){ 
      this.hostElement = hostElement
      this.canvas.width = hostElement.clientWidth
      this.canvas.height = hostElement.clientHeight
      hostElement.appendChild(this.canvas)
    }
    
    if (idOrClass !== undefined)
  	  if (idOrClass.startsWith('#')) this.canvas.id = idOrClass.substring(1)
      else this.canvas.className = idOrClass.substring(1) 

	  this.scroll = 0 // Y scroll

    // Data structures
    this.elements = [] // All the elements to draw in the canvas
    // The elements (shapes) can be grouped
    this.groupMap = new Map() // Map< group: string, elements: Array<Element> >
    this.groupMap.set('root', []) // Init -> the default group root is created

    // Event handling on the drawn elements
    this.beingHovered = undefined // boolean, pointing to an element or undefined
    this.listenerMap = new Map() // Map<eventType: string, listener: function>
    // mouse event listeners
    this.CLICK_ON_ELEMENT = 'clickOnElement'
    this.HOVER_OVER_ELEMENT = 'hoverOverElement'
    this.ENTER_ELEMENT = 'enterElement'
    this.LEAVE_ELEMENT = 'leaveElement'

    this.canvas.addEventListener('click', e => {
  	  const element = this.getElement(e)
  	  if (element) { 
  	     let listener = this.listenerMap.get(this.CLICK_ON_ELEMENT)
         if (listener) listener(element)
  	     this.clearAndDrawAll()
  	  }
	  })

    this.canvas.addEventListener('mousemove', e => {
      const element = this.getElement(e)
      
      if (element) { // Inside an element

        if (!this.beingHovered && this.cursor) // it comes from outside any element
          this.canvas.style.cursor = this.cursor
        
        if (this.beingHovered !== element){ // element change
          this.beingHovered = element
          let listener = this.listenerMap.get(this.ENTER_ELEMENT)
          if (listener){
            listener(element)
            this.clearAndDrawAll()
          } 
        }

        let listener = this.listenerMap.get(this.HOVER_OVER_ELEMENT)
        if (listener){
          listener(element)
          this.clearAndDrawAll()
        } 

      }else{ // outside any element 
        if (this.beingHovered){ // it comes from an element
          if (this.cursor) this.canvas.style.cursor = 'default'
          this.beingHovered = undefined
          let listener = this.listenerMap.get(this.LEAVE_ELEMENT)
          if (listener) listener(element)
          this.clearAndDrawAll()
        } 
      }
    })

    // Hit region detection: underlying mechanism to support events on shapes
    this.hitCanvas = document.createElement('canvas')
    // Set the width and height seems necessary
    this.hitCanvas.width = this.canvas.width
    this.hitCanvas.height = this.canvas.height
    this.hitCtx = this.hitCanvas.getContext('2d')
    // Map associating an element to a color identifying it (random generation)
    this.hitMap = new Map() // Map<color<string>, element<HTMLElement>> 

    window.addEventListener('resize', this.resize.bind(this), false)
  }


   /**
   * It returns an element that was hit by a mouse event
   * @param {MouseEvent} e - Event thrown on the element
   * @return {HTMLElement} Element hit by the interaction
   */
  getElement(e){
    const canvasPos = this.getPosition()
    const x = e.clientX - canvasPos.x
    const y = e.clientY - canvasPos.y
    const pixel = this.hitCtx.getImageData(x, y, 1, 1).data
    return this.hitMap.get(`rgb(${pixel[0]},${pixel[1]},${pixel[2]})`)
  }

  /**
   * It returns the position of the canvas element
   * @return {object} X and Y coordinates of the canvas
   */
  getPosition(){
    let xPosition = 0, yPosition = 0;
    let el = this.canvas
    while (el) {
      xPosition += (el.offsetLeft - el.scrollLeft + el.clientLeft);
      yPosition += (el.offsetTop - el.scrollTop + el.clientTop);
      el = el.offsetParent;
    }
    return { x: xPosition, y: yPosition - this.scroll } // Y axis scroll
  }

  /**
   * It resizes the canvas element to its host element
   */
  resize(){
    if (this.hostElement){
     this.canvas.width = this.hostElement.clientWidth
     this.canvas.height = this.hostElement.clientHeight
     this.clearAndDrawAll()
    }
  }


  /**
   * It updates the vertical scroll figure
   * @param {number} scrollY - Current vertical scroll
   */
  adaptToScroll(scrollY){
  	this.scroll = scrollY
  }


  /**
   * It adds a group of elements. It must be explicitly created
   * @param {string} name - Group name (identifier)
   */
  addGroup(name){
  	this.groupMap.set(name, [])
  }


  /**
   * It removes the elements belonging to a group
   * @param {string} name - Group name (identifier)
   */
  removeGroupElements(name){ 
    if (!this.groupMap.has(name))  return

  	this.groupMap.get(name).forEach( e => {
  		this.elements.splice(this.elements.indexOf(e), 1)
  	})
  	this.groupMap.delete(name) 
  	this.groupMap.set(name, [])
  }

  /**
   * It adds a new element (shape) to the canvas
   * @param {string} type - Shape type
   * @param {object} data - Data defining the characteristics of the element
   * @param {string} group - Group name the element will belong to
   * @return {Element} The new element is returned
   */
  addElement(type, data, group = 'root'){
  	let element
  	switch (type) {
      case 'line': element = new Line(data); break;
      case 'polyline': element = new Polyline(data); break;
	    case 'circle': element = new Circle(data);	break;
      case 'rectangle': element = new Rectangle(data); break;
	    case 'text': element = new Text(data); break;
      case 'image': element = new Bitmap(data); break;
	  //default:
	  }

    // A color working like identifier for the element is generated
	  // Caution: the color could repeat with very large number of elements
    const colorKey = getRandomColor()
    element.hitColor = colorKey
    this.hitMap.set(colorKey, element)

    // The element is added to the general store and to a group
    element.type = type
  	this.groupMap.get(group).push(element)
    this.elements.push(element)

    return element

    function getRandomColor() {
      const r = Math.round(Math.random() * 255);
      const g = Math.round(Math.random() * 255);
      const b = Math.round(Math.random() * 255);
      return `rgb(${r},${g},${b})`;
    }
  }

  /**
   * It removes an element (shape) from the canvas
   * @param {Element} element - Element to remove
   * @param {string} group - Group name the element belongs to
   */
  removeElement(element, group = 'root'){
    this.elements.splice(this.elements.indexOf(element), 1)

    let groupElements = this.groupMap.get(group)
    groupElements.splice(groupElements.indexOf(element), 1)
  }

  /**
   * It draws all the canvas elements
   */
  drawAll(){
  	this.elements.forEach( e => {
  		e.draw(this.ctx, this.hitCtx)
  	})
  }


  /**
   * First it clears the canvas and second it draws all the elements
   */
  clearAndDrawAll(){
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    this.drawAll()
  }


  /**
   * It draws the elements in a group
   * @param {string} name - Group name
   */
  drawGroup(name){
    this.groupMap.get(name).forEach( e => {
      e.draw(this.ctx, this.hitCtx)
    })
  }


  /**
   * It resets the class data structures 
   */
  reset(){
    this.elements = [] // All the elements to draw in the canvas
    this.groupMap = new Map()
    this.groupMap.set('root', [])

    this.beingHovered = undefined // pointing to an element or undefined
    this.listenerMap = new Map() 

    this.hitMap = new Map()
  }


  /**
   * It clears the canvas 
   */
  clearCanvas() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  /**
   * It fills the canvas with background color
   * @param {string} color - Color code
   */
  fillCanvas(color) {
    this.ctx.save()
    this.ctx.fillStyle = color
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)
    this.ctx.restore()
  }


  /**
   * It fills a canvas rectangle with color
   * @param {string} color - Color code
   * @param {number} x - top-left corner X coordinate of the rectangle
   * @param {number} y - top-left corner Y coordinate of the rectangle
   * @param {number} width - rectangle width
   * @param {number} height - rectangle height
   */
  fillRect(color, x, y, width, height) {
    this.ctx.save()
    this.ctx.fillStyle = color
    this.ctx.fillRect(x, y, width, height)
    this.ctx.restore()
  }


  /**
   * It sets an external listener for a type of event on elements
   * @param {string} type - Event type supported
   * @param {function} listener - Listener
   */
  setEventListener(type, listener){
  	this.listenerMap.set(type, listener)
  }


  /**
   * It sets a css cursor to be shown when the mouse is hovering an element
   * @param {string} cssCursor - css cursor name
   */
  setCursorOnElement(cssCursor){
    this.cursor = cssCursor
  }

}


/**
 * The Element class represents a shape on the canvas. It's a base class for 
 * the real elements (lines, circles, text, etc) to be drawn
 */
class Element {

  /**
   * @param {object} params - Object defining the shape characteristics 
   */
  constructor(params) {
    this.style = params.style // this param is always
  }
  
  /**
   * This is the method called when the element is effectively drawn.
   * This is the common implementation. In addition, every specific instance can
   * add more code.
   * @param {CanvasRenderingContext2D} ctx - Canvas context
   * @param {CanvasRenderingContext2D} hitCtx - Canvas context for hit region detection
   */
  draw(ctx, hitCtx) {
    ctx.save()

    if (this.style){
      if (this.style.color) ctx.fillStyle = this.style.color
      if (this.style.strokeColor) ctx.strokeStyle = this.style.strokeColor
      if (this.style.strokeWidth) ctx.lineWidth = this.style.strokeWidth
      if (this.style.dash) ctx.setLineDash([12, 15])
    }

    // In case of stroke it's not used on the hit mask
    hitCtx.fillStyle = this.hitColor
    // This must be implemented by the specific shape
    this._draw(ctx, hitCtx)

    ctx.restore()
  }
  /**
   * Abstract method that specifically does the drawing
   * @param {CanvasRenderingContext2D} ctx - Canvas context
   * @param {CanvasRenderingContext2D} hitCtx - Canvas context for hit region detection
   */
  _draw(ctx, hitCtx= undefined) {}
}


/**
 * Simple line on the canvas. 
 * No hit region for lines, they don't respond to events
 */
class Line extends Element{

  /**
   * @param {object} params - Object defining the shape characteristics 
   */
  constructor(params) {
    super(params) // Common parameters: param.style
    // shape specific parameters
    this.coors = params.coors
  }

  /**
   * This is a shape specific method called when the element is drawn.
   * It does the effective drawing
   * @param {CanvasRenderingContext2D} ctx - Canvas context
   * @param {CanvasRenderingContext2D} hitCtx - Canvas context for hit region detection
   */
  _draw(ctx, hitCtx= undefined) {
    ctx.beginPath();
    ctx.moveTo(this.coors[0], this.coors[1])
    for (let i = 2; i < this.coors.length; ) {
      ctx.lineTo(this.coors[i++], this.coors[i++])
    }
    ctx.stroke()
    // No hit region for lines
  }
}


/**
 * Polyline on the canvas. 
 * No hit region for polylines, they don't respond to events
 */
class Polyline extends Element{

  /**
   * @param {object} params - Object defining the shape characteristics 
   */
  constructor(params) {
    super(params)  // Common parameters: param.style
    // shape specific parameters
    this.coors = params.coors
    this.lines = params.lines
  }

  /**
   * This is a shape specific method called when the element is drawn.
   * It does the effective drawing
   * @param {CanvasRenderingContext2D} ctx - Canvas context
   * @param {CanvasRenderingContext2D} hitCtx - Canvas context for hit region detection
   */
  _draw(ctx, hitCtx= undefined) {
    ctx.beginPath()
    this.lines.forEach( line => {
      ctx.moveTo(line[0], line[1]);  
      for (let i = 2; i < line.length;) ctx.lineTo(line[i++], line[i++])
    })
    
    ctx.stroke()
    // No hit region for lines
  }
}


/**
 * Circle on the canvas. 
 */
class Circle extends Element{

  /**
   * @param {object} params - Object defining the shape characteristics 
   */
  constructor(params) {
    super(params)  // Common parameters: param.style
    // shape specific parameters
    this.coors = params.coors
    this.r = params.r;
    this.x = params.x;
    this.y = params.y;
  }
  
  /**
   * This is a shape specific method called when the element is drawn.
   * It does the effective drawing
   * @param {CanvasRenderingContext2D} ctx - Canvas context
   * @param {CanvasRenderingContext2D} hitCtx - Canvas context for hit region detection
   */
  _draw(ctx, hitCtx) {
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.r, 0, 2 * Math.PI, true);
    ctx.closePath();
    ctx.fill();
    if (this.style.strokeColor) ctx.stroke()

    hitCtx.beginPath()
    hitCtx.arc(this.x, this.y, this.r, 0, 2 * Math.PI, true)
    hitCtx.closePath()
    hitCtx.fill()
  }
}


/**
 * Rectangle on the canvas. 
 */
class Rectangle  extends Element{

  /**
   * @param {object} params - Object defining the shape characteristics 
   */
  constructor(params) {
    super(params)  // Common parameters: param.style
    // shape specific parameters
    this.coors = params.coors
    this.x = params.x;
    this.y = params.y;
    this.w = params.w;
    this.h = params.h;
  }

  /**
   * This is a shape specific method called when the element is drawn.
   * It does the effective drawing
   * @param {CanvasRenderingContext2D} ctx - Canvas context
   * @param {CanvasRenderingContext2D} hitCtx - Canvas context for hit region detection
   */
  _draw(ctx, hitCtx) {
    ctx.beginPath();
    ctx.rect(this.x, this.y, this.w, this.h);
    ctx.fill()
    if (this.style.strokeColor) ctx.stroke()

    hitCtx.beginPath()
    hitCtx.rect(this.x, this.y, this.w, this.h)
    hitCtx.fill()
  }
}


/**
 * Text on the canvas. 
 * No hit region, the text doesn't respond to events
 */
class Text extends Element{

  /**
   * @param {object} params - Object defining the shape characteristics 
   */
  constructor(params) {
    super(params)  // Common parameters: param.style
    // shape specific parameters
    this.coors = params.coors
    this.text = params.text
    this.x = params.x
    this.y = params.y
    this.boxWidth = undefined
  }

  /**
   * This is a shape specific method called when the element is drawn.
   * It does the effective drawing
   * @param {CanvasRenderingContext2D} ctx - Canvas context
   * @param {CanvasRenderingContext2D} hitCtx - Canvas context for hit region detection
   */
  _draw(ctx, hitCtx= undefined) {

    if (this.style) { // Text related styles
      if (this.style.font) ctx.font = this.style.font
      if (this.style.align) ctx.textAlign = this.style.align
      if (this.style.baseline) ctx.textBaseline = this.style.baseline
    }
    this.boxWidth = ctx.measureText(this.text)

    if (this.style && this.style.rotation){
      ctx.translate(this.x, this.y);
      ctx.rotate(this.style.rotation);
      ctx.fillText(this.text, 0, 0)
      ctx.resetTransform();
    }else{
      ctx.fillText(this.text, this.x, this.y)
    }
  }
}

/**
 * Bitmap on the canvas. 
 */
class Bitmap extends Element{

  /**
   * @param {object} params - Object defining the shape characteristics 
   */
  constructor(params) {
    super(params) // Common parameters: param.style
    // shape specific parameters
    this.coors = params.coors
    this.img = params.img
    this.x = params.x
    this.y = params.y
    this.w = params.w
    this.h = params.h
  }
   
  /**
   * This is a shape specific method called when the element is drawn.
   * It does the effective drawing
   * @param {CanvasRenderingContext2D} ctx - Canvas context
   * @param {CanvasRenderingContext2D} hitCtx - Canvas context for hit region detection
   */ 
  draw(ctx, hitCtx) { // hitCtx not being used, only for real shapes
    ctx.drawImage(this.img, this.x, this.y, this.w, this.h)
    // The stroke is not used on the hit mask
    hitCtx.beginPath()
    hitCtx.rect(this.x, this.y, this.w, this.h)
    hitCtx.fillStyle = this.hitColor
    hitCtx.fill()
  }
}