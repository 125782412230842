/**
 * @author Iker Hurtado
 *
 * @overview Utility file for the Output Analyzer module
 */


// import * as THREE from "../../lib/three.js"
import * as THREE from "three"
import OutputAims from './OutputAims.js'
import OutputExciting from './OutputExciting.js'
import * as UserMsgBox from "../common/UserMsgBox";


// Global utility functions
window.showElement = (element, show = true) => { element.style.display = ( show ? '' : 'none' ) }
window.log = (...msg) => { console.log(...msg) }
window.error = (...msg) => { console.error(...msg) }

window.onerror = function(message, url, line, col, error) {
  UserMsgBox.setError(`${message}\n At ${line}:${col} of ${url}`)
}


/**
 * Returns an FHIAims or Exciting output instance based
 * on the output files imported
 * @param  {Array<object>} filesData
 * @return {Output}
 */
export async function getOutputInstance(filesData){
  let outputAims = new OutputAims()
  let outputExciting = new OutputExciting()
  outputExciting.parseFiles(filesData)
  if (outputExciting.isFilledUp()) return outputExciting
  return new Promise(resolve => {
     outputAims.parseFiles(filesData).then((output) => {if (output.isFilledUp()) resolve(output)})
  })
  }


export function geoTextFromStructure(structure) {
  let text = ''
  let latticeVectors = structure.latVectors
  if (latticeVectors) {
    latticeVectors.forEach(v => {
      text += 'lattice_vector '+ v.join(' ') +'\n'
    })
  }
  structure.atoms.forEach(atom => {
    text += `atom ${atom.position.join(' ')} ${atom.species}\n`
  })
  return text
}


/**
 * Joins a list of text lines in a text and returns it
 * @param  {Array<string>} lines
 * @return {string}
 */
export function lineArrayToText(lines){
   let controlInText = ''
   lines.forEach( line => {
     controlInText += line+'\n'
   })
   return controlInText
}


/**
 * Returns the volume of a parallelepiped,
 * defined by three vectors
 * @param  {Array<Array>} vectors
 * @return {number}
 */
export function getParallelepipedVolume(vectors) { // units = Angstroms
   let v0 = new THREE.Vector3().fromArray(vectors[0])
   let v1 = new THREE.Vector3().fromArray(vectors[1])
   let v2 = new THREE.Vector3().fromArray(vectors[2])
   return Math.abs(v0.cross(v1).dot(v2))
}


/** Calculates the gaussian centered around `x0` with width `broad` at point `x`
 *
 * @param broad {number}
 * @param x {number}
 * @param x0 {number}
 * @returns {number}
 */
export function gauss(broad, x, x0){
  return 1.0/(broad*Math.sqrt(2*Math.PI))*Math.exp(-0.5*(x-x0)**2/broad**2)
}


/**
 * Returns HTML code wrapping and laying out output quantities
 * @param  quantities {Map<string,object>}
 * @param  valuesAsNumbers {boolean}
 * @return {string}
 */
export function getHtmlRows(quantities, valuesAsNumbers = true){
  let html = ''
  quantities.forEach( (quantity, label) => {
    if (label === 'download-link'){
      html += `<tr> <td><a class="f-geometry-download-link" download="geometry.in"
               href="${quantity}" >Download final geometry </a></td></tr>`
    }
    else
      html += `<tr> <td>${label}</td> <td>${valuesAsNumbers ? quantity.toFixed(5) : quantity}</td> </tr>`
  })
  return html
}

// cool-warm colormap from MPL
let colors = [[0.2481, 0.326, 0.7777], [0.3992, 0.5285, 0.9285], [0.5652, 0.6994, 0.9966], [0.729, 0.8175, 0.9732],
  [0.8674, 0.8644, 0.8626], [0.9582, 0.7712, 0.6803], [0.9594, 0.6103, 0.4894], [0.8771, 0.3946, 0.3117], [0.7233, 0.0689, 0.163]]
const colorSpace = colors.length - 1

const rgb = (r, g, b) =>
  `rgb(${r},${g},${b})`;

function color(x) {
  if (x < 0 || x > 1) return undefined
  if (x === 0.) return rgb(...colors[0].map(i => Math.round(i * 255)))
  if (x === 1.) return rgb(...colors[colorSpace].map(i => Math.round(i * 255)))
  let lo = Math.floor(x * colorSpace)
  let hi = Math.ceil(x * colorSpace)
  let r = Math.round(((hi - x * colorSpace)*colors[lo][0] + (x * colorSpace - lo)*colors[hi][0]) * 255)
  let g = Math.round(((hi - x * colorSpace)*colors[lo][1] + (x * colorSpace - lo)*colors[hi][1]) * 255)
  let b = Math.round(((hi - x * colorSpace)*colors[lo][2] + (x * colorSpace - lo)*colors[hi][2]) * 255)
  return rgb(r, g, b)
}

export function arrayToColors(x, aroundZero = false) {
  let absMax = Math.max(...x.map(i => Math.abs(i)))
  let hi = aroundZero ? absMax : Math.max(...x)
  let lo = aroundZero ? -absMax : Math.min(...x)
  console.log(lo, hi)
  return x.map(i => color((i - lo) / (hi - lo)))
}

/** Recursively merge update object into target
 *
 * @param target {Object} an Object to update
 * @param update {Object} an update for `target`
 *
 * @returns {Object} updated target
 */
export function deepMerge(target, update) {
  // for each key/value pair in update object
  for (const [key,value] of Object.entries(update)){
    // if target has the relevant key and
    // the type in target and update is the same
    // update value if string, number or boolean
    if (['string','number','boolean'].includes(typeof value) || Array.isArray(value)){
      target[key] = value;
    } else {
      // if type is object then go one level deeper
      if (typeof value === 'object'){
         deepMerge(target[key], value)
      }
    }
  }
  return target
}


/** Transpose the 2D array
 * @param arr 2d array
 * @returns {*} transposed array
 */
export function transpose(arr) {
  return arr[0].map((col, j) => arr.map(row => row[j]))
}