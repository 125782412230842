/**
 * @author Andrey Sobolev
 *
 * @fileOverview File holding the Bands and Density of states dashboard based on Plotly.js
 */

import {PlotlyDashboard} from "./dashboard";
import {DOSPlotter} from "./DOSPlotter";
import BSPlotter from "./BSPlotter";


const ENERGY_AXIS_MAX = 20
const ENERGY_AXIS_MIN = -20


export class BSDOSDashboard extends PlotlyDashboard {
	bsLabel = 'band-structure-graph'
	dosLabel = 'dos-graph'

  /** A band structure and DOS plotter
   *
   * @param parent {HTMLElement}
   * @param uiClass {string}
   * @param width {number}
   * @param height {number}
   * @param hasBS {boolean} a band structure flag
   * @param hasDOS {boolean} a density of states flag
   * @param hasSpin {boolean} a spin polarization flag
   * @param hasSOC {boolean} a spin-orbit coupling flag
   */
  constructor(parent, uiClass, width, height,
              hasBS= true, hasDOS= true, hasSpin = false, hasSOC = false) {
    super(parent, uiClass);
    this.hasBS = hasBS
    this.hasDOS = hasDOS
    this.hasSpin = hasSpin
    this.hasSOC = hasSOC

    this.setHTML(`
			<div class="graph-box">
				<div>
		      <div id="${this.bsLabel}" > </div>
<!--					<button id="segment-button"></button>-->
				</div>
				<div>
		      <div id="${this.dosLabel}" > </div>
					<button id="dos-button"></button>
				</div>
			</div>
      <div class="graph-controls-box" >
        <div>
          <div style="text-align: center">
          </div>
          <!-- Here goes the form -->
        </div>
      </div>
    `)
		// this.bsButton = this.getElement('#segment-button')
		this.dosButton = this.getElement('#dos-button')

    let w_BS = this.hasDOS ? width * 0.7 : width
    let w_DOS = this.hasBS ? width * 0.3 : width
    if (this.hasBS) this.attach('#' + this.bsLabel, w_BS, height)
    if (this.hasDOS) this.attach('#' + this.dosLabel, w_DOS, height)

    if (this.hasDOS)
      this.dosButton.innerHTML = 'Select DOS File'
    else
      this.dosButton.style.display = 'none'
    this.createForm('OutputAnalyzer-graph-controls-form')
    const formDiv = this.getElement('.graph-controls-box > div')
    formDiv.appendChild(this.form.e)

  }

  couple() {
    super._couple(this.bsLabel, this.dosLabel);
  }

  /** Sets band structure data for plotting`
   * @param bsData {array}
   */
  setBSData(bsData) {
    if (!this.hasBS) return;
    console.log('BSData:', bsData)
    let plotter = new BSPlotter(this.graphs[this.bsLabel], bsData, this.hasSpin, this.hasSOC)
    plotter.plotData(ENERGY_AXIS_MIN, ENERGY_AXIS_MAX)
    plotter.updateForm(this.form, 'Band structure', 'bsDiv')

  }

  /** Sets DOS data for plotting
   * @param dosFiles {Map<string,array>}
   */
  setDOSData(dosFiles) {
    if (!this.hasDOS) return;
		//  check for molecular DOS
		let molDosName = Array.from(dosFiles.keys())[0]
		const isMolecule = molDosName.includes("MolDOS")

		let plotter = new DOSPlotter(this.graphs[this.dosLabel], dosFiles, isMolecule, this.hasSpin)
		plotter.plotData( ENERGY_AXIS_MIN, ENERGY_AXIS_MAX)
    plotter.updateForm(this.form, 'Density of states', 'dosDiv')
		this.dosButton.addEventListener('click', _ => plotter.onDosButtonClick())
  }

}