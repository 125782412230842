/**
 * @author Iker Hurtado
 *
 * @fileoverview File holding the ViewOptionsDropDown UI component
 */

import DropDownMenu from '../common/DropDownMenu.js'


/**
 * Dropdown enabling the selection of different visualization possibilities
 * on the StructureViewer
 */
export default class BZViewOptionsDropDown extends DropDownMenu{

  constructor(){

    super('View options')

    this.defaultValues = []

    this.REC_UNIT_CELL_OPTION = 'show-rec-unit-cell'
    this.REC_CART_AXES_OPTION = 'show-rec-lattice-vectors-parameters'
    this.REC_LATTICE_AXES_OPTION = 'show-lattice-vectors-parameters'

    this.addOption(this.REC_UNIT_CELL_OPTION, 'Show reciprocal cell', true)
    this.addOption(this.REC_CART_AXES_OPTION, 'Show Cartesian axes', true)
    this.addOption(this.REC_LATTICE_AXES_OPTION, 'Show reciprocal axes', true)
  }


  /**
   * Adds a new option (as a checkbox)
   * @param {string} name Option identificator
   * @param {string} label Label being shown
   * @param {boolean} defaultValue If checked by default
   * @param {boolean} onlyShowIfPeriodic Only shown for periodic structures
   */
  addOption(name, label, defaultValue){
    this.container.innerHTML += `<li><input type="checkbox" name="${name}" } >${label}</li>`
    // ${defaultValue ? 'checked' : ''
    this.defaultValues.push(defaultValue)

  }

  reset(){
    let checkboxes = this.getElements('input')
    this.options = this.getElements('li')

    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = this.defaultValues[i]
    }
    //console.log('this.checkboxes', checkboxes)
  }

  /**
   * If an option is checked or not
   * @param {string} option Option identificator
   * @return {boolean}
   */
  isOptionChecked(option){
    return this.getElement('input[name="'+option+'"]').checked
  }


  /**
   * Sets the listener that will be called when a checkbox is clicked
   * @param {function} listener
   */
  setCheckListener(listener){
    this.container.addEventListener('click', listener)
  }

}
