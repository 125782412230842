export const Fields_Exciting = [
  {
    label: 'Basic Settings',
    fields:{
      species: {
        // If there are no flags attributes the field name is taken ('species' in this case)
        text:'Geometry species',
        inputType:'input-text',
        required: true,
        dataType: 'string',
        explanation: `
            This field reflects the different species included in your structure.
            The ControlGenerator accepts any valid element of the periodic table.
        `,
        workflow: 'default'
      },
      xctype: { // XC functional for Exciting
        text:'XC functional',
        inputType:'select',
        required: true,
        dataType: 'string',
        value: ['', 'pz-lda:LDA_PZ', 'pw-lda:LDA_PW', 'pbe:GGA_PBE', 'pbesol:GGA_PBE_SOL','pbe0:HYB_PBE0','hf:EXX'],
        explanation: `
            Select the exchange correlation (XC) functional for your calculation. <br>
            <b>pz-lda:</b> Perdew-Zunger LDA<br>
            <b>pw-lda:</b> Perdew-Wang LDA<br>
            <b>pbe:</b> Perdew-Burke-Ernzerhof GGA<br>
            <b>pbesol:</b> pbe optimized for solids<br>
            <b>pbe0:</b> pbe + hf hybrid functional<br>
            <b>hf:</b> Hartee-Fock method
        `,
        workflow: 'default'
      },
      kgrid: {
        flag: 'ngridk',
        text:'k-points grid',
        inputType:'input-text:3',
        required: false, // REquired in some cases not always
        dataType: ['integer','integer','integer'],
        explanation: 'Enter the number of k-points along each reciprocal lattice vector.',
        workflow: 'default'
      },
      rgkmax: { // Only for Exciting code
        text:'rgkmax',
        inputType:'input-text',
        required: true,
        dataType: 'float',
        workflow: 'default'
      },

    }
  }, // End BasicSettings

  {
    label: 'Output',
    fields: {
      bandStructure: {
        text:'Band Structure',
        inputType:'input-text:1:k-point density',
        dataType: 'float',
        required: true,
        explicitInclusion: false,
        explanation: `
            Specifies the k-point density (Number of k-points times AA) for the band path. Recommendation: 40.
        `,
        workflow: 'BandStructure'
      },
      dos: {
        text:'DOS',
        inputType:'input-text:3:Energy start,Energy end,Number of sampling points',
        required: false,
        explicitInclusion: 'checkbox',
        dataType:['float','float','integer'],
        workflow: 'default'
      }
    }
  }, // End output

  {
    label: 'Structure Optimization and Forces',
    fields: {
      relax: { // Only for Exciting
        text:'Relax Atom Positions',
        inputType:'no-input',
        required: false,
        explicitInclusion: 'checkbox',
        dataType: 'none',
        workflow: 'default'
      },
      tforce: {
        text:'Calculate Forces',
        inputType:'no-input',
        dataType: 'boolean',
        required: false,
        explicitInclusion: 'checkbox',
        explanation: `
           Activates the calculation of the forces at the end of an SCF cycle.
        `,
        workflow: 'default'
      },
    }
  }, // End OptimizationForcesStress

  {
    label: 'Numerical Accuracy',
    fields: {
      epsengy: {
        text:'Total Energy Accuracy',
        inputType:'input-text',
        dataType:'float',
        required: false,
        explicitInclusion: 'checkbox',
        explanation: `
           Sets the SCF-convergence criteria for the total energy.
        `,
        workflow: 'default'
      },
      epschg: {
        text:'Total Density Accuracy',
        inputType:'input-text',
        dataType:'float',
        required: false,
        explicitInclusion: 'checkbox',
        explanation: `
           Sets the SCF-convergence criteria for the total electron density.
        `,
        workflow: 'default'
      },
      epsforcescf: {
        text:'Forces Accuracy',
        inputType:'input-text',
        dataType:'float',
        required: false,
        explicitInclusion: 'checkbox',
        explanation: `
           Sets the SCF-convergence criteria for the forces.
        `,
        workflow: 'default'
      },
      epspot: {  // Only exciting
        text:'Effective Potential',
        inputType:'input-text',
        dataType:'float',
        required: false,
        explicitInclusion: 'checkbox',
        workflow: 'default'
      }
    }
  } // End numericalAccuracy

]
