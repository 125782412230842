
import UIComponent from './UIComponent.js'



class ModalPopup extends UIComponent{

  constructor(){

    super('div', '.ModalPopup')

    this.setHTML(`

        <div class="ModalPopup__overlay"></div>

        <span class="ModalPopup__close-button"> [ CLOSE ] </span>

        <div class="ModalPopup__content"></div>
    `)

    let closeModal = () => { this.e.style.display = 'none' }

    closeModal()

    this.contentEl = this.getElement('.ModalPopup__content')

    this.getElement('.ModalPopup__overlay').addEventListener( 'click', closeModal)

    this.getElement('span').addEventListener( 'click', closeModal)

  }

}


// Creates the application modal popup
const appModal = new ModalPopup()
document.querySelector('body').appendChild(appModal.e)


export function setModalContent(content){
  appModal.contentEl.innerHTML = content
}


export function setModalComponent(component,add=false){
  if (!add)
    appModal.contentEl.innerHTML = ''
  appModal.contentEl.appendChild(component)
}


export function showModal(resetScroll = false){
  appModal.e.style.display = 'block'
  if (resetScroll) appModal.contentEl.scrollTop = 0
}


export function hideModal(){
  appModal.e.style.display = 'none'
}

export function reset(){
  appModal.contentEl.innerHTML = ''
}
