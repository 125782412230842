/**
 * @author Andrey Sobolev
 *
 * @overview The parent class for the Workflow Designer step editors
 */
import UIComponent from "../common/UIComponent";


export class WorkflowStep {
  static config = {
    componentType: 'task',
    properties: {}
  }
  constructor(parent, stepId) {
    this.workflow = parent
    this.id = stepId
    this.editor = new WorkflowStepEditor(this)
  }

  /** A kind of class destructor, runs when it's removed from definition
   */
  destroy() {
  }

  save() {
    return true
  }

    /** A validator for the given step
   * @returns {boolean}
   */
  validate() {
    return true;
  }
}


export class WorkflowStepEditor extends UIComponent {
  constructor(parent){
    super('div', '.swd-editor');
    this.parent = parent
    this.step = undefined
    this.ctx = undefined
  }

  /** Provides an HTML element of the editor
   *
   * @param step
   * @param ctx
   * @returns {HTMLElement}
   */
  provide(step, ctx){
    this.step = this.step === undefined ? step : this.step
    this.ctx = this.ctx === undefined ? ctx : this.ctx
    this.getElement('.swd-button').addEventListener('click', _ => this._reroute())
    return this.e
  }

  /** Reroute to the needed workflow step with hash mechanism
   */
  _reroute() {
    let hashParts = document.location.hash.split('#')
    document.location.hash = (hashParts[1] + '#' + hashParts[2] + '#' + this.parent.id)
  }

}