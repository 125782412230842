/**
 * @author Iker Hurtado
 *
 * @fileoverview File holding the ViewOptionsDropDown UI component
 */

import DropDownMenu from '../common/DropDownMenu.js'


/**
 * Dropdown enabling the selection of different visualization possibilities 
 * on the StructureViewer
 */
export default class ViewOptionsDropDown extends DropDownMenu{

  constructor(){

    super('View options')
       
    this.defaultValues = []
    this.onlyShowIfPeriodic = []  

    this.UNIT_CELL_OPTION = 'show-unit-cell'
    this.LATTICE_VECTORS_OPTION = 'show-lattice-vectors-parameters'
    this.BONDS_OPTION = 'show-bonds'
    this.BONDS_BOUNDARIES_OPTION = 'show-bonds-across-boundaries'
    this.ATOMS_DUPLICATED_OPTION = 'show-atoms-duplicated'
    this.WRAP_ATOMS_OPTION = 'wrap-atoms-into-cell'
    this.ORTHOGRAPHIC_CAMERA = 'orthographic-camera'

    this.addOption(this.ORTHOGRAPHIC_CAMERA, 'Orthographic Camera', true, false)
    this.addOption(this.UNIT_CELL_OPTION, 'Show unit cell', true, true)
    this.addOption(this.LATTICE_VECTORS_OPTION, 'Show lattice vectors and parameters', true, true)
    this.addOption(this.BONDS_OPTION, 'Show bonds', true, false)
    this.addOption(this.BONDS_BOUNDARIES_OPTION, 'Bonds across boundaries', false, true)
    this.addOption(this.ATOMS_DUPLICATED_OPTION, 'Duplicate atoms on cell borders', false, true)
    this.addOption(this.WRAP_ATOMS_OPTION, 'Wrap atoms into cell', false, true)
  }


  /**
   * Adds a new option (as a checkbox)
   * @param {string} name Option identificator
   * @param {string} label Label being shown
   * @param {boolean} defaultValue If checked by default
   * @param {boolean} onlyShowIfPeriodic Only shown for periodic structures
   */
  addOption(name, label, defaultValue, onlyShowIfPeriodic){
    this.container.innerHTML += `<li><input type="checkbox" name="${name}" } >${label}</li>` 
    // ${defaultValue ? 'checked' : ''
    this.defaultValues.push(defaultValue)
    this.onlyShowIfPeriodic.push(onlyShowIfPeriodic)

  }


  /**
   * Resets the component, the option checkboxes
   * @param  {boolean} If eriodic structure
   */
  reset(periodic,orthographicCamera){
    let checkboxes = this.getElements('input')
    // console.log(checkboxes);
    this.options = this.getElements('li')

    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = this.defaultValues[i]
      this.options[i].style.display = (!periodic && this.onlyShowIfPeriodic[i] ? 'none' : 'block')
    }
    checkboxes[0].checked = orthographicCamera
    //console.log('this.checkboxes', checkboxes)
  }


  /**
   * Returns the bonds across option HTML element
   * @return {HTMLElement}
   */
  getBondsAcrossOptionElement(){
    return this.getElement('input[name="'+this.BONDS_BOUNDARIES_OPTION+'"]').parentNode
  }


  /**
   * If the bonds across option is checked or not
   * @return {boolean}
   */
  isBondsAcrossOptionChecked(){
    return this.getElement('input[name="'+this.BONDS_BOUNDARIES_OPTION+'"]').checked
  }


  /**
   * If the bonds option is checked or not
   * @return {boolean}
   */
  isBondsOptionChecked(){
    return this.getElement('input[name="'+this.BONDS_OPTION+'"]').checked
  }
  
  // isOrthographicCameraChecked(){
  //   return this.getElement('input[name="'+this.ORTHOGRAPHIC_CAMERA+'"]').checked
  // }


  /**
   * If an option is checked or not
   * @param {string} option Option identificator
   * @return {boolean}
   */
  isOptionChecked(option){
    return this.getElement('input[name="'+option+'"]').checked
  }


  /**
   * Sets the listener that will be called when a checkbox is clicked
   * @param {function} listener 
   */
  setCheckListener(listener){
    this.container.addEventListener('click', listener)
  }
  
}
