import UIComponent from '../common/UIComponent.js'
import Structure from '../common/Structure.js'


export default class StructureSelector extends UIComponent{

  /**
   * @param  {State} moduleState
   *   Object representing the state of the Structure builder module
   */
  constructor(moduleState){
    super('div', '#StructureSelector')

    this.moduleState = moduleState

    this.setHTML(`
      <div class="structure-bar">
        <span class="structures"></span>
        <span class="add-new-structure"></span>
      </div>
    `)

    let newStructure = () => {
      // console.log('Hello World')
      let s = new Structure()
      // console.log(this.moduleState.structureID)
      let id = this.moduleState.structureID + 1
      // console.log('check here', id)
      s.fileSource = "New Structure " + id
      this.moduleState.setStructure(s)
      // console.log('check here',this.moduleState.structureStates)
    }

    let addSpan = document.createElement('span')
    addSpan.innerHTML = '+'
    addSpan.classList.add('addon-box')
    addSpan.classList.add('add')
    addSpan.addEventListener('click',newStructure)
    this.getElement('.add-new-structure').appendChild(addSpan)
  }

  setNewStructure(structure){

    let selectStructure = (e) => {
      let id = e.target.value
      // console.log(e.target.parentElement);
      this.setSelection(e.target.parentElement);
      // console.log("selectr structure", e.target.value)
      this.moduleState.selectStructure(id);
    }

    let removeStructure = (e) => {

      let keys = Object.keys(this.moduleState.structureStates)
      // console.log(keys)
      if (keys.length > 1) {
        let id = e.target.value
        let removeSpan = document.getElementById('structureSelector'+id).parentElement
        let hasClass = removeSpan.classList.contains('structure-selected')
        delete this.moduleState.structureStates[id]
        keys = Object.keys(this.moduleState.structureStates)
        // console.log(this.moduleState.structureStates)
        let nextID = parseInt(keys.pop())
        if (hasClass) {
          let nextSpan = document.getElementById('structureSelector'+nextID)
          this.setSelection(nextSpan.parentElement)
        }
        this.moduleState.selectStructure(nextID,false)
      } else{
        this.moduleState.resetStructure()
      }

      // console.log(nextID)
      // console.log(parent)
      e.target.parentElement.remove()
    }

    let span = document.createElement('span')
    span.classList.add('structureSelector')
    span.classList.add('addon-box')
    let selectSpan = document.createElement('span')
    selectSpan.innerText = structure.fileSource
    selectSpan.value = this.moduleState.structureID
    selectSpan.id = "structureSelector"+this.moduleState.structureID
    selectSpan.addEventListener('click',selectStructure)
    span.appendChild(selectSpan)
    let removeSpan = document.createElement('span')
    removeSpan.innerHTML ='&times'
    removeSpan.value = this.moduleState.structureID
    removeSpan.classList.add('remove')
    removeSpan.addEventListener('click',removeStructure)
    span.appendChild(removeSpan)
    this.getElement('.structures').appendChild(span)
    this.setSelection(span)
  }

  setSelection(DOMelement) {
    let list = [...DOMelement.parentElement.children]
    // console.log(list);
    list.forEach(child => {
      child.classList.remove("structure-selected")
    });
    DOMelement.classList.add("structure-selected");
  }

  updateStructure(structure, change){

  }

  selectStructure(){

  }


}
