/**
 * @author Iker Hurtado
 *
 * @fileoverview File holding the UIComponent base class
 */

/**
 * Base class for building UI components
 */
export default class UIComponent{

  /**
   * @param  {string} htmlTag HTMLElement type wrapping the component
   * @param  {string} idOrClass a class name (prefixed by .) or 
   * an identificator (prefixed by #) must be provided
   */
  constructor(htmlTag, idOrClass){//, appModule = ''){

  	this.e = document.createElement(htmlTag)

  	if (idOrClass === undefined) return
  	if (idOrClass.startsWith('#')) this.e.id = idOrClass.substring(1)
    else this.e.className = idOrClass.substring(1)  
  }

  /**
   * Sets the initial component HTML
   * @param {string} html
   */
  setHTML(html){
  	this.e.innerHTML = html
  }

  /**
   * Returns the first internal HTML elements fulfilling the query selector query
   * @param  {string} query Regular query selector query
   * @return {HTMLElement|HTMLCanvasElement|HTMLInputElement}
   */
  getElement(query){
  	return this.e.querySelector(query)
  }


  /**
   * Returns all the internal HTML elements fulfilling the query selector query
   * @param  {string} query Regular query selector query
   * @return {NodeListOf<HTMLElement|HTMLInputElement>}
   */
  getElements(query){
  	return this.e.querySelectorAll(query)
  }


  /**
   * Adds a new HTMLElement to the component
   * @param {string} htmlTag 
   * @param {string} innerHTML new element HTML content
   */
  addElement(htmlTag, innerHTML = ''){
    let newElement = document.createElement(htmlTag)
    newElement.innerHTML = innerHTML
    this.e.appendChild(newElement)
    return newElement
  }
  
}