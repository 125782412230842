/**
 * @author Andrey Sobolev
 *
 * @fileOverview File holding the abstract class for Plotters
 */


export class Plotter {
  /** Abstract plotter class
   *
   * @param {InteractiveGraph} graph
   * @param  {Map<string, array>|object|array} data Object with DOS files data
   */
  constructor(graph, data) {
    this.graph = graph
    this.data = data
    this.formControls = {}
  }

  /** Updates control form
   * @param form {Form}
   * @param sectionName {string}
   * @param sectionClass {string}
   */
  updateForm(form, sectionName, sectionClass) {
    let sectionBox = form.addSection(sectionName)
    sectionBox.classList.add(sectionClass)
    for (const group of this.graph.lineGroups()) {
      let thicknessField = form.addField('linesThickness', 'Thickness ' + group, 'input-range',
          [0.8, 4, this.graph.getThickness(group)], 'float')
      thicknessField.wrapper.classList.add('bsDiv')
      thicknessField.HTMLFields[0].addEventListener('input', e => {
          const multiplier = parseFloat(e.target.value)
          this.graph.changeDataLinesStyle(group, multiplier, undefined)
        })
      let colorField = form.addField('linesColor', 'Color ' + group, 'input-color',
          this.graph.getColor(group), 'string')
      colorField.wrapper.classList.add('bsDiv')
      colorField.HTMLFields[0].addEventListener('input', e => {
          this.graph.changeDataLinesStyle(group, undefined, e.target.value)
        })
    this.formControls[group] = [thicknessField, colorField]
    }
  }
}