/**
 * @author Iker Hurtado, Andrey Sobolev
 *
 * @fileOverview File holding the AbsorptionPlotter graph
 */

import {Plotter} from "./plotter";
import {transpose} from "./util";


const X_AXIS_LABEL = 'Freq. (eV)'
const Y_AXIS_LABEL = 'Absorption Function'
const AB_DEFAULT_COLOR = '#f26430'


/**
 * A plotter displaying absorption data
 */
export class AbsorptionPlotter extends Plotter {

  /**
   * Sets the absorption data to be plotted.
   * @param xAxisMin {number}
   * @param xAxisMax {number}
   */
  plotData(xAxisMin, xAxisMax){

    for (const [name, data] of this.data.entries()) {
      const groupName = name.match(/(absorption_.*)_[xyz]_[xyz].out/)[1]
      if (!this.graph.hasLineGroup(groupName))
        this.graph.addLineGroup(groupName, {defaultColor: AB_DEFAULT_COLOR})
      const [x, y] = transpose(data)
      // xAxisMin = Math.max(xAxisMin, Math.min(...x))
      // xAxisMax = Math.min(xAxisMax, Math.max(...x))
      xAxisMin = Math.min(...x)
      xAxisMax = Math.max(...x)
      this.graph.setLineData([x, y], groupName)
    }
    this.graph.setAxisLabelsAndRange(X_AXIS_LABEL, Y_AXIS_LABEL, false, xAxisMin, xAxisMax)
    this.graph.draw()
  }
}
