/**
 * @author Andrey Sobolev <sobolev@fhi.mpg.de>
 *
 * @overview File holding the class describing undoable changes to the Structure
 */

import {CHANGE} from "../State";


export default class Change {
/**
 * A class representing changes to structure
 * @param state {State}
 * @param changeType {string|undef}
 * @param atomIndex {number|undef}
 * @param detail {Object|undef}
 * @param setChange {boolean} if there's a need to register for undo possibility
 */
  constructor(state,
              changeType,
              atomIndex = undefined,
              detail= undefined,
              setChange = true) {
    this.state = state
    this.steps = []
    // atomIndex, detail can be undefined/null, e.g. when adding atoms
    if (changeType !== null )
      this.addStep(changeType, atomIndex, detail)

    if (setChange) this.state.addChange(this)
    this.done = false
  }

  addStep(changeType, atomIndex, detail) {
    this.steps.push(new ChangeStep(this.state, changeType, atomIndex, detail))
  }

  do() {
    if (!this.done) {
      this.steps.forEach(s => s.run())
    }
    this.done = true
  }

  undo() {
    if (this.done){
      this.steps.slice().reverse().forEach(s => s.run(true))
    }
    this.done = false
  }
}

class ChangeStep {
  constructor (state, changeType, atomIndex, detail) {
    this.state = state
    this.type = changeType
    this.atom = atomIndex
    this.detail = detail !== undefined ? detail : [undefined, undefined]
  }

  run(undo = false) {
    const res = undo ? this.detail[0] : this.detail[1]

    switch (this.type) {
      case CHANGE.STRUCTURE.SUPERCELL:
        this.state.setStructure(this.detail.update[1], false)
        break;

      case CHANGE.STRUCTURE.ATOM.SPECIES:
        this.state.structure.updateAtomSpecies(this.atom, res)
        this.state.emitStructureChange({type: CHANGE.STRUCTURE.ATOM.SPECIES, atomIndex: this.atom})
        break;

      case CHANGE.STRUCTURE.ATOM.INITMOMENT:
        this.state.structure.updateAtomInitMoment(this.atom, res)
        this.state.emitStructureChange({type: CHANGE.STRUCTURE.ATOM.INITMOMENT, atomIndex: this.atom})
        break;

      case CHANGE.STRUCTURE.ATOM.CHARGE:
        this.state.structure.updateAtomCharge(this.atom, res)
        this.state.emitStructureChange({type: CHANGE.STRUCTURE.ATOM.CHARGE, atomIndex: this.atom})
        break;

      case CHANGE.STRUCTURE.ATOM.CONSTRAINT:
        this.state.structure.updateAtomConstraint(this.atom, res)
        this.state.emitStructureChange({type: CHANGE.STRUCTURE.ATOM.CONSTRAINT, atomIndex: this.atom})
        break;

      case CHANGE.STRUCTURE.ATOM.MOVE:
        this.state.structure.updateAtomPosition(this.atom, res)
        this.state.emitStructureChange({type: CHANGE.STRUCTURE.ATOM.MOVE, atomIndex: this.atom})
        break;

      case CHANGE.STRUCTURE.ATOM.ADD:
        const isNewAtom = (this.atom === undefined)
        if (isNewAtom) this.atom = this.state.structure.addUndefinedAtom()
        else {  // that is undoing the remove atom change
          const data = this.detail[2]
          this.state.structure.addAtomData(data.position, data.species, false,
            data.initMoment, data.constraint, data.charge, this.atom)
        }
        this.state.emitStructureChange({type: CHANGE.STRUCTURE.ATOM.ADD, atomIndex: this.atom})
        // change the type to atom.remove for undo; add the atomic number to result
        this.type = CHANGE.STRUCTURE.ATOM.REMOVE
        this.detail[0] = this.atom
        break;

      case CHANGE.STRUCTURE.ATOM.REMOVE:
        this.state.structure.atoms.splice(this.atom, 1)
        this.state.emitStructureChange({type: CHANGE.STRUCTURE.ATOM.REMOVE, atomIndex: this.atom})
        // change the type for undoing
        this.type = CHANGE.STRUCTURE.ATOM.ADD
        break;

      case CHANGE.STRUCTURE.LATTICE_VECTORS:
        const vectors = res
        const scaleAtomsPositions = this.detail[2]
        this.state.setFractional(false)
        if (vectors === undefined) // remove lattice vectors
          this.state.structure.removeLatticeVectors()
        else this.state.structure.updateLatticeVectors(vectors, scaleAtomsPositions)
        this.state.emitStructureChange({type: CHANGE.STRUCTURE.LATTICE_VECTORS})
        break;
    }
  }
}