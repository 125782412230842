/**
 * @author Andrey Sobolev
 *
 * @fileOverview File holding the Absorption and Dielectric function dashboard based on Plotly.js
 */
import {PlotlyDashboard} from "./dashboard";
import {AbsorptionPlotter} from "./AbsorptionPlotter";
import {DielectricPlotter} from "./DielectricPlotter";


const FREQUENCY_AXIS_MAX = 0.1
const FREQUENCY_AXIS_MIN = 0.01


export class AbDiDashboard extends PlotlyDashboard {

  abLabel = "absorption-graph"
  diLabel = "dielectric-graph"
  /** A band structure and DOS plotter
   *
   * @param parent {HTMLElement}
   * @param uiClass {string}
   * @param width {number}
   * @param height {number}
   * @param hasAb {boolean} a flag to include absorption graphs
   * @param hasDi {boolean} a flag to include dielectric function graphs
   */
  constructor(parent, uiClass, width, height, hasAb, hasDi) {
    super(parent, uiClass);
    this.setHTML(`
			<div>
	      <div id="${this.abLabel}" > </div>
<!--				<button id="absorption-button"></button>-->
			</div>
			<div>
	      <div id="${this.diLabel}" > </div>
<!--				<button id="dielectric-button"></button>-->
			</div>
      <div class="graph-controls-box" >
        <div>
          <!-- Here it goes the form -->
        </div>
      </div>
    `)
  if (hasAb) this.attach('#' + this.abLabel, width, height, {yaxis: {autorange: true}})
  if (hasDi) this.attach('#' + this.diLabel, width, height, {yaxis: {autorange: true}})
  this.createForm('OutputAnalyzer-graph-controls-form')
  const formDiv = this.getElement('.graph-controls-box > div')
  formDiv.appendChild(this.form.e)
  }

  /** Sets absorption data for plotting
   * @param absorptionFiles {Map<string, array>}
   */
  setAbData(absorptionFiles) {
    if (!absorptionFiles) return;

    let plotter = new AbsorptionPlotter(this.graphs[this.abLabel], absorptionFiles)
    plotter.plotData(FREQUENCY_AXIS_MIN, FREQUENCY_AXIS_MAX)
    plotter.updateForm(this.form, 'Absorption', 'dosDiv')

  }

  /** Sets absorption data for plotting
   * @param dielectricFiles {Map<string, array>}
   */
  setDiData(dielectricFiles) {
    if (!dielectricFiles) return;

    let plotter = new DielectricPlotter(this.graphs[this.diLabel], dielectricFiles)
    plotter.plotData(FREQUENCY_AXIS_MIN, FREQUENCY_AXIS_MAX)
    plotter.updateForm(this.form, 'Dielectric function', 'dosDiv')
  }
}

		// let absorptionButton = this.getElement('#absorption-button') // <button> </button>
		// absorptionButton.innerHTML = 'Select Absorption File' // <button>Select Band Segment</button>
		// absorptionButton.addEventListener('click', _ => {
		// 	let absorptionNames = Array.from(absorptionFiles.keys())
		// 	let content = `<ul>`
		// 	for (let k = 0; k < absorptionNames.length; k++) { // For every  segment
		// 		let absorptionName = absorptionNames[k]
		// 		content += `<input type="checkbox" id="${absorptionName}" name="absorptionChoice" value="${absorptionName}" checked>${absorptionName}<br>`
		// }
		// 	content += `</ul>`
		// 	ModalPopup.setModalContent(content)
		// 	let chooseSegment = document.createElement('button')
		// 	chooseSegment.innerHTML = 'Apply'
		// 	chooseSegment.addEventListener('click', _ => {
		// 		let cb = document.querySelectorAll(`input[name="absorptionChoice"]:checked`)
		// 		let newAbsorptionFiles = new Map()
		// 		console.log(cb)
		// 		this.resetFieldsAndPlots()
		// 		// cb.forEach((checkbox) => {})
    //
		// 		this.absorptionPlotter.setData(newAbsorptionFiles, FREQUENCY_AXIS_MIN, FREQUENCY_AXIS_MAX)
		// 	})
		// 	ModalPopup.setModalComponent(chooseSegment,true)
		// 	ModalPopup.showModal(true)
		// 	})
    //
		// 	let dielectricButton = this.getElement('#dielectric-button') // <button> </button>
		// 	dielectricButton.innerHTML = 'Select Dielectric File' // <button>Select Band Segment</button>
		// 	dielectricButton.addEventListener('click', _ => {
		// 		let dielectricNames = Array.from(dielectricFiles.keys())
		// 		let content = `<ul>`
		// 		for (let k = 0; k < dielectricNames.length; k++) { // For every  segment
		// 			let dielectricName = dielectricNames[k]
		// 			if (dielectricName === this.currentlyPlottedDielectric){
		// 				content += `<input type="radio" id="${dielectricName}" name="dielectricChoice" value="${dielectricName}" checked>${dielectricName}<br>`
		// 			}
		// 			else if (dielectricName !== this.currentlyPlottedDielectric) {
		// 				content += `<input type="radio" id="${dielectricName}" name="dielectricChoice" value="${dielectricName}">${dielectricName}<br>`
		// 			}
    //
		// 	}
		// 		content += `</ul>`
		// 		ModalPopup.setModalContent(content)
		// 		let chooseSegment = document.createElement('button')
		// 		chooseSegment.innerHTML = 'Apply'
		// 		chooseSegment.addEventListener('click', _ => {
		// 			let cb = document.querySelectorAll(`input[name="dielectricChoise"]:checked`)
		// 			let newDielectricFiles = new Map()
		// 			console.log(cb)
		// 			this.resetFieldsAndPlots()
		// 			cb.forEach((checkbox) => {
		// 				newDielectricFiles.set(checkbox.value, dielectricFiles.get(checkbox.value))
		// 				this.currentlyPlottedDielectric = checkbox.value
		// 			})
		// 			let maxYAxisDecider = []
		// 			let F_AXIS_MAX = -10000
		// 			let F_AXIS_MIN = -10000
		// 			let newDielectricData = newDielectricFiles.size > 0 ? newDielectricFiles.get(newDielectricFiles.keys().next().value) : undefined
		// 			for (let i = 0; i < newDielectricData.length; i++){
    //
		// 				maxYAxisDecider.push(newDielectricData[i][1])
		// 				maxYAxisDecider.push(newDielectricData[i][2])
		// 				maxYAxisDecider.push(newDielectricData[i][3])
		// 				maxYAxisDecider.push(newDielectricData[i][4])
		// 				maxYAxisDecider.push(newDielectricData[i][5])
		// 				maxYAxisDecider.push(newDielectricData[i][6])
		// 				maxYAxisDecider.push(newDielectricData[i][7])
		// 				maxYAxisDecider.push(newDielectricData[i][8])
		// 			}
		// 			F_AXIS_MAX = Math.max.apply(F_AXIS_MAX, maxYAxisDecider);
		//       F_AXIS_MIN = Math.min.apply(F_AXIS_MIN, maxYAxisDecider);
		// 			this.dielectricPlotter.setData(newDielectricFiles, F_AXIS_MIN, F_AXIS_MAX)
		// 		})
		// 		ModalPopup.setModalComponent(chooseSegment,true)
		// 		ModalPopup.showModal(true)
		// 		})