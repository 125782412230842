/**
 * @author Iker Hurtado, Andrey Sobolev
 *
 * @fileOverview File holding the BSPlotter graph
 */

import {InteractiveGraph} from "./InteractiveGraphPlotly";
import {Plotter} from "./plotter";


const X_AXIS_LABEL = 'Band path'
const Y_AXIS_LABEL = 'Energy (eV)'
const SPIN_DEFAULT_COLOR = ['#f26430','#009ddc','#30f243']

/**
 * Implements a Band Structure plotter.
 */
export default class BSPlotter extends Plotter{

  /**
   * @param {InteractiveGraph} graph
   * @param {array} bsData Band structure data
   * @param {boolean} hasSpin A flag for spin polarization
   * @param {boolean} hasSOC A flag for spin-orbit coupling
   */
  constructor(graph, bsData,hasSpin,  hasSOC) {
    super(graph, bsData)
    this.hasSOC = hasSOC
    this.hasSpin = hasSpin
    this.factor = 1
  }


  /**
   * Returns the highest and lowest values of the bands
   * @param  {array} data
   * @return {Array<number>}
   */
  getEnergyExtrema(data){
    let bandMax = -10000;
    let bandMin = 10000;
    for (let i = 0; i < data.length; i++) { // Per segment
      const bands = data[i].band_energies[0].flat()
      const bands2 = data[i].band_energies.length > 1 ? data[i].band_energies[1].flat() : []
      const bands3 = data[i].band_energies.length > 2 ? data[i].band_energies[2].flat() : []

      bandMin = Math.min(bandMin, ...bands, ...bands2, ...bands3)
      bandMax = Math.max(bandMax, ...bands, ...bands2, ...bands3)
    }
    return [bandMin/this.factor, bandMax/this.factor];
  }

  /**
   * Draws a k-point label on the X axis
   * @param  {int} x
   * @param  {string} label
   */
  drawKPointLabel(x, label){
    this.graph.setXAxisTickText(x, label)
  }

  /**
   * Sets the band structure data to be plotted.
   * Also, the top and bottom X values can be passed
   * @param {int} eAxisMin
   * @param {int} eAxisMax
   */
  plotData(eAxisMin = -100, eAxisMax = 100){

    // Gather all the points per band (divided by spin) crossing the segments
    let energyExtrema = this.getEnergyExtrema(this.data)
    let minEnergyVal = energyExtrema[0]
    let maxEnergyVal = energyExtrema[1]

    this.graph.setAxisLabelsAndRange(X_AXIS_LABEL, Y_AXIS_LABEL, true, 0, 1,
      eAxisMin, eAxisMax, minEnergyVal, maxEnergyVal)


    // Calculates de distance
    let totalDistance= 0
    let prevBandNum = 0
    for (let k = 0; k < this.data.length; k++) {
      prevBandNum = this.data[k].segment_num
      let kPoints= this.data[k].band_k_points
      totalDistance += kPointDistance(kPoints,kPoints.length-1)
    }
    let currentDistance = 0
    let prevLastLabel = undefined

    for (let k = 0; k < this.data.length; k++) {  // For every segment
      let segment = this.data[k]
      let kPoints = segment.band_k_points
      const kDistances = kPoints.map((el, j) =>
        (currentDistance + kPointDistance(kPoints, j)) / totalDistance)

      // if SOC or spin calculation, k-points repeat
      let n = kDistances.length
      let labels = segment.band_segm_labels

      // dealing with k-point labels
      let segmentDistance = kPointDistance(kPoints,kPoints.length-1);

      // Set k-points labels
      if (labels !== undefined) {
        if (prevLastLabel !== undefined && prevLastLabel !== labels[0]){
            this.drawKPointLabel(currentDistance/totalDistance, getSymbol(prevLastLabel)+'|'+getSymbol(labels[0]))
        } else
          this.drawKPointLabel(currentDistance/totalDistance, getSymbol(labels[0]))
        // The last label
        if (k === this.data.length -1)
          this.drawKPointLabel(1, getSymbol(labels[1]));
        prevLastLabel = labels[1];
      }

      for (const [i, energies] of segment.band_energies.entries()) {
        const groupName = segment.bsName[i][0]
        if (energies.length > 0) {
          // add line group
          if (!this.graph.hasLineGroup(groupName))
            this.graph.addLineGroup(groupName, {defaultColor: SPIN_DEFAULT_COLOR[i]})

          // transpose energies array
          const kBands = energies[0].map((col, j) => energies.map(row => row[j] / this.factor))
          for (const band of kBands) {
            this.graph.setLineData([kDistances.slice(0, n), band], groupName)
          }
        }
      }
      currentDistance += segmentDistance;
    }
    // Drawing part
    this.graph.draw()
  }

//   onBSButtonClick() {
//   			let segmentButton = this.getElement('#segment-button') // <button> </button>
// 			segmentButton.innerHTML = 'Select Band Segment' // <button>Select Band Segment</button>
// 			segmentButton.addEventListener('click', _ => {
// 				let content = ``
// 				for (let k = 0; k < bsData.length; k++) { // For every  segment
// 					let bandName = bsData[k].bsName
// 					let checked = this.bandFilesConfig.get(k).selected ? 'checked' : ''
// 					content += `<input type="checkbox" id="bsSeg" name="${bandName}" value="${k}" ${checked}>${bsData[k].segmentName}<br>`
// 			}
// 			  ModalPopup.setModalContent(content)
// 				let chooseSegment = document.createElement('button')
//         chooseSegment.innerHTML = 'Apply'
// 				chooseSegment.addEventListener('click', _ => {
// 					//this.canvas.reset()
// 					let newBsData = []
// 					let cb = document.querySelectorAll(`input[id="bsSeg"]:checked`)
// 					// let values = []
// 					// let spins = []
// 					this.bandFilesConfig.forEach((value) => {
// 						value.selected = false
// 					})
// 					cb.forEach((checkbox) => {
// 					 this.resetFieldsAndPlots()
// 					 this.bandFilesConfig.get(parseInt(checkbox.value)).selected = true
//            // values.push(checkbox.value.index)//Old works only for both spins at a time
// 					 newBsData.push(bsData[checkbox.value])//Old works only for both spins at the same time
//            })
//
//           this.bsPlotter.setBandStructureData(newBsData, ENERGY_AXIS_MIN, ENERGY_AXIS_MAX, hasSOC)
// 					//console.log(values)
// 				})
// 				ModalPopup.setModalComponent(chooseSegment,true)
// 				ModalPopup.showModal(true)
// 				//ModalPopup.c('Button Pushed')
// 				})
// }
}


/**
 * Returns the distance between first k-point and given for the position
 * @param  {Array<float>} kPoints
 * @param  {int} position
 * @return {number}
 */
function kPointDistance(kPoints, position){
  let p0= kPoints[0];
  let p1= kPoints[position];
  let deltaX= p1[0] - p0[0];
  let deltaY= p1[1] - p0[1];
  let deltaZ= p1[2] - p0[2];
  return Math.sqrt(deltaX*deltaX + deltaY*deltaY + deltaZ*deltaZ);
}


/**
 * Returns the k-point label. Transforms several possible Gamma labels
 * in the symbol
 * @param  {string} label
 * @return {string}
 */
function getSymbol(label){
  if (label === 'Gamma' || label === 'GAMMA' || label === 'G') return 'Γ';
  else return label;
}
