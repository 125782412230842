/**
 * @author Andrey Sobolev
 *
 * @overview The Editor for the control generator workflow step (includes a button to the ControlGenerator)
 */
import {WorkflowStep, WorkflowStepEditor} from "./workflow-step";
import ControlGeneratorMod from "../control-generator-mod/ControlGeneratorMod";
import {setError} from "../common/UserMsgBox";


export class CalculationStep extends WorkflowStep {
  static config = {
    ...WorkflowStep.config,
    group: 'Calculations'
  }
    constructor(parent, stepId) {
    super(parent, stepId);
    this.editor = new ControlGeneratorEditor(this)
    this.component = new ControlGeneratorMod()
    this.controlName = ''
    this.properties = {}
  }

  save() {
    if (!this.component.runChecks()) return false
    this.properties.control = this.component.getInputFilesRequestJson()
    this.editor.update()
    return true
  }

  validate() {
    return this.workflow.hasStructure(this.id) && this.properties.control !== undefined;
  }

}

export class DFTCalculation extends CalculationStep {
  static config = {
    ...CalculationStep.config,
    name: 'DFT Calculation',
    type: 'DFTCalculation',
  }
  constructor(parent, stepId) {
    super(parent, stepId);
    this.label = 'DFT calculation: Generate control.in'
    this.controlName = 'default'
    this.component.init(this.controlName)
    this.initialized = true
  }
}


export class GWCalculation extends CalculationStep {
  static config = {
    ...CalculationStep.config,
    name: 'GW Calculation',
    type: 'GWCalculation',
  }

  constructor(parent, stepId) {
    super(parent, stepId);
    this.label = 'GW calculation: Generate control.in'
    this.controlName = 'GW'
    this.component.init(this.controlName)
    this.initialized = true
  }
}

export class ControlGeneratorEditor extends WorkflowStepEditor {
    constructor(parent) {
    super(parent);
    this.setHTML(`<button class="swd-button">Build control.in</button>`)
  }

  _reroute() {
    if (!this.parent.workflow.hasStructure(this.parent.id)) {
      setError('You have to set structure first!')
      return
    }
    super._reroute();
    this.parent.component.setStructure(this.parent.workflow.getStructure(this.parent.id))
  }

  update() {
      this.step.properties = this.parent.properties
      this.ctx.notifyPropertiesChanged()
  }

}


