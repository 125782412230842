/**
 * @author Andrey Sobolev
 * @year 2022
 *
 * @overview A file containing classes for all the form elements: Radio, Input, noInput and Select
 */

import * as Tooltip from './Tooltip.js'
import {floatInputValidator, floatValidator, intInputValidator, intValidator} from "./util";


class FormElement {
  /**
   * An abstract class for form elements
   * @param component {(UIComponent | HTMLElement)} A wrapper or form
   * @param name {string} an HTML name for the element
   * @param text {string} a label text
   * @param required {boolean} is the field required to run the code? If yes and not given, mark the field
   * @param explicitInclusion {boolean} is the field needed to be explicitly included in the input?
   * @param explanation {string} a tooltip for the field label
   * Shows checkbox if yes
   */
  constructor(component, name, text = '',
              required = false, explicitInclusion = false, explanation = '') {
    this.name = name
    this.labelText = text
    this.required = required
    this.explicitInclusion = explicitInclusion
    this.hidden = false
    this.constraint = undefined
    this.num = 1            // a number of HTML input elements
    this.HTMLFields = []    // an array of HTML input elements
    if (component instanceof HTMLElement) {
      // we're given a div wrapper
      this.wrapper = component
    } else {
      // we're given a Form itself, let's build a wrapper
      this.wrapper = component.addElement('div')
      this.wrapper.className = `${name}-form-field`
      let checkboxHTML = '<div class="explicit-inclusion-box"></div>'
      if (explicitInclusion)
        checkboxHTML = `<div class="explicit-inclusion-box"><input type="checkbox" name="${name}-box"` +
          ` class="${name}-explicitInclusion" ondblclick="this.checked=false;"></div>`
      this.wrapper.innerHTML = checkboxHTML + ' <label>' + this.labelText + `</label><div class="value-box"></div>` +
        `<div class="units-box"></div>`
    }
    this.checkbox = this.wrapper.querySelector('.explicit-inclusion-box input')
    // try to add a tooltip
    if (explanation) {
      this.wrapper.setAttribute('data-explanation', explanation)
      Tooltip.addTargetElement(this.wrapper)
    }
  }

  /**
   * Checks if the form element is included to the input file
   * @returns {boolean}
   */
  isExplicitlyIncluded() {
    let included = false
    if (this.explicitInclusion) {
      included = this.checkbox.checked
    }
    return included
  }

  /**
   * Checks if the form element is visible in the form
   * @returns {boolean}
   */
  isVisible() {
    return !this.hidden
    // return !!( this.labelElement.offsetWidth || this.labelElement.offsetHeight ||
    //   this.labelElement.getClientRects().length );
  }

  /**
   * Checks if the form element is required to build control.in
   * @returns {boolean}
   */
  isRequired() {
    return (this.isVisible() && this.required);
  }

  hide() {
    this.wrapper.style.display = 'none'
    this.hidden = true
  }

  show() {
    this.wrapper.style.display = 'flex'
    this.hidden = false
  }

  validate() {
    let isValid = true
    if (this.isRequired() || this.isExplicitlyIncluded())
      for (let i = 0; i < this.num; i++) {
        if (this.HTMLFields[i].value === '') {
          markField(this.HTMLFields[i])
          isValid = false
        } else unmarkField(this.HTMLFields[i])
      }
    return isValid
  }

  /**
   * Sets a constraint to the element; entangles its visibility with masters' values
   * @param constraint {Constraint}
   */
  setConstraint(constraint) {
    this.constraint = constraint
    // this.constraint.masters.forEach(f => console.log(f.HTMLFields[0].setEventListener))
    if (this.constraint.condition !== 'included') {   // trigger on value change
      this.constraint.masters.forEach(f =>
        f.HTMLFields.forEach(el => {
          el.addEventListener('change', _ => trigger(this, constraint))
        }))
    } else {   // trigger on explicit inclusion
      this.constraint.left.checkbox.addEventListener('change', _ => trigger(this, constraint))
    }
    // triggers field event or include event based on constraint
    this.constraint.trigger(this.constraint.condition === 'included')

    function trigger(instance, constraint) {
      if (constraint.evaluate()) instance.show()
            else instance.hide()
    }
  }

  /**
   * Dispatches event from the element of HTMLField
   * @param e {Event}
   */
  dispatchEvent(e) {
    this.HTMLFields.forEach(el => el.dispatchEvent(e))
  }

  /**
   * Dispatches event from the explicit inclusion checkbox
   * @param e {Event}
   */
  dispatchIncludeEvent(e) {
    if (this.explicitInclusion) this.checkbox.dispatchEvent(e)
  }
}

export class NoInputField extends FormElement{
  /**
   * A constructor for a field with no inputs
   * @param wrapper {(UIComponent | HTMLElement)}
   * @param name {string} a name for the radio
   * @param text {string} a label text
   * @param required {boolean} is the field required to run the code? If yes and not given, mark the field
   * @param explicitInclusion {boolean} is the field needed to be explicitly included in the input?
   * @param explanation {string} a tooltip for the field label
   * Shows checkbox if yes
   */
  constructor(wrapper, name, text, required, explicitInclusion, explanation='') {
    super(wrapper, name, text, required, explicitInclusion, explanation)
  }
  validate() {
    return true
  }
  getValue() {
    return ""
  }
}

export class RadioField{
  /**
   * A RadioField constructor
   * @param component {UIComponent} the div wrapper for the radios
   * @param name {string} a name for the radio
   * @param value {Object} a map with other form elements
   * @param required {boolean} is the field required to run the code? If yes and not given, mark the field
   */
  #name;

  constructor(component, name, value, required) {
    this.component = component
    this.#name = name
    this.required = required
    this.hidden = false
    this.fields = []
    this.constraint = undefined
    for (const [k, v] of Object.entries(value)) {
      const wrapper = this.component.addElement('div')
      const field_name = v['flag'] ? v['flag'] : k
      wrapper.className = `${field_name}-form-field`
      wrapper.innerHTML = `<div class="radio-chooser"><input type="radio" name="${name}" value="${k}"></div><label>` + v['text'] +
        `</label><div class="value-box"></div><div class="units-box"></div>`
      const field = new InputField(wrapper, field_name, v['text'], v['inputType'], v['dataType'],
        1, this.required, false, v['units'], v['explanation'], {'radio_value': k})
      this.fields.push(field)
    }

    // disable currently non-selected inputs
    this.radios = this.component.getElements(`[name=${name}]`)
    this.radios.forEach( f => f.addEventListener('change', radioCheckHandler) )
    // check the first element in the radio by default
    let instance = this
    this.radios[0].setAttribute('checked', 'true')
    let event = new Event('change');
    this.radios[0].dispatchEvent(event)

    function radioCheckHandler(e) {
      instance.fields.forEach(f =>
        f.HTMLFields.forEach(s => s.disabled = (e.target.value !== f.extras['radio_value'])))
    }
  }
  isVisible() {
    return !this.hidden
  }
  isRequired() {
    return (this.isVisible() && this.required);
  }
  show() {
    this.fields.forEach(f => f.show())
    this.hidden = false
  }
  hide() {
    this.fields.forEach(f => f.hide())
    this.hidden = true
  }
  isExplicitlyIncluded() {
    return false
  }
  /**
   * Returns a checked field
   * @returns {FormElement}
   */
  checkedField() {
    const checkedValue = this.component.getElement(`input[name=${this.#name}]:checked`)["value"]
    for (const field of this.fields) {
      if (field.extras['radio_value'] === checkedValue) return field
    }
  }
  get name() {
    const field = this.checkedField()
    return field.name
  }
  getValue(){
    const field = this.checkedField()
    if (field instanceof FormElement)
      return field.getValue()
  }
  validate() {
    return this.checkedField().validate()
  }
}

export class InputField extends FormElement{
  /**
   * An InputField constructor
   * @param wrapper {(UIComponent | HTMLElement)}
   * @param name {string}
   * @param text {string}
   * @param type {string} a type of input control (contains the information on the quantity of subfields and their names)
   * @param datatype {(string|Array<string>)} a data type from the input (for the validator)
   * @param group
   * @param required {boolean} is the field required to run the code? If yes and not given, mark the field
   * @param explicitInclusion {boolean} is the field needed to be explicitly included in the input?
   * Shows checkbox if yes
   * @param units {string|Array<string>} a units name to go after the input box
   * @param explanation {string} a tooltip for the field label
   * @param extras {Object} extra parameters to the field
   */
  constructor(wrapper, name, text, type,
              datatype, group=0, required=false,
              explicitInclusion=false, units='', explanation='', extras={}) {
    super(wrapper, name, text, required, explicitInclusion, explanation)
    // Check if the field is multiple (num > 1), it is composed by several input field
    if (datatype instanceof Array) this.datatypes = datatype; else this.datatypes = [datatype]
    this.extras = extras
    this.HTMLFieldNames = []
    this.type = type

    if (type.includes(':')){
      let a = type.split(':')
      this.type = a[0]
      this.num = parseInt(a[1])
      if (a.length === 3) this.HTMLFieldNames = a[2].split(',')
    }
    if (this.HTMLFieldNames.length === 0) {
      this.#createUnnamedFields(units)
    } else {
      this.#createNamedFields(units)
    }
    // add validators to fields
    for (let i = 0; i < this.num; i++) {
      switch (this.datatypes[i]) {
        case 'integer':
          this.HTMLFields[i].addEventListener('input', intInputValidator)
          this.HTMLFields[i].addEventListener('blur', intValidator)
          break;
        case 'float':
          this.HTMLFields[i].addEventListener('input', floatInputValidator)
          this.HTMLFields[i].addEventListener('blur', floatValidator)
          break;
        case 'default':
          break;
      }
    }
  }
  /**
   * A helper function creating a number of unnamed fields in one row
   * @param units {string} a string representing field units
   */
  #createUnnamedFields(units) {
    for (let i = 0; i < this.num; i++) {
      // field without subfields
      let field = document.createElement('input')
      field.type = 'text'
      field.style.width = (100/this.num)+'%'
      const valueInputBox = this.wrapper.querySelector('.value-box')
      valueInputBox.appendChild(field)
      this.HTMLFields.push(field)
    }
    const unitsBox = this.wrapper.querySelector('.units-box')
    unitsBox.innerHTML = units
  }
  /**
   * A helper function creating a number of unnamed fields in one row
   * @param units {Array<string>} an array of strings representing field units
   */
  #createNamedFields(units) {
    const valueInputBox = this.wrapper.querySelector('.value-box')
    const unitsBox = this.wrapper.querySelector('.units-box')
    for (let i = 0; i < this.num; i++) {
      // field with named subfields
      let field = document.createElement('input')
      field.type = 'text'
      let subfieldsBox = document.createElement('div')
      subfieldsBox.className = 'named-subfield'
      let label = document.createElement('span')
      label.innerHTML = this.HTMLFieldNames[i]
      subfieldsBox.appendChild(label)
      subfieldsBox.appendChild(field)
      valueInputBox.appendChild(subfieldsBox)

      let subfieldsUnitsBox = document.createElement('div')
      subfieldsUnitsBox.className = 'named-subfield-units'
      let unitText = document.createElement('div')
      unitText.innerHTML = units ? units[i] : ''
      subfieldsUnitsBox.appendChild(unitText)
      unitsBox.appendChild(subfieldsUnitsBox)
      this.HTMLFields.push(field)
    }
  }

  /**
   * Returns value/values of the field
   * @returns {*[]|*}
   */
  getValue() {
    const parsers = {'integer': parseInt, 'float': parseFloat, 'string': (v) => v }
    let value = []
    for (let i = 0; i < this.num; i++) {
      value.push(parsers[this.datatypes[i]](this.HTMLFields[i].value))
    }
    return (this.num === 1) ? value[0] : value
  }
  setValue(value) {
    if (!(value instanceof Array)) value = [value]
    if (value.length !== this.num) throw(`Cannot set value to ${this.name}`)
    for (let i = 0; i < this.num; i++)
      this.HTMLFields[i].value = value[i]
  }
  // clearValue() {
  //   for (let i = 0; i < this.num; i++)
  //     this.HTMLFields[i].setValue('')
  // }
}

export class SelectField extends FormElement {
  /**
   * A SelectField constructor
   * @param wrapper {(UIComponent | HTMLElement)}
   * @param name {string}
   * @param text {string}
   * @param type {string} a type of input control (contains the information on the quantity of subfields and their names)
   * @param value {array<string>} An array of values for the select control
   * @param datatype {(string|Array<string>)} a data type from the input (for the validator)
   * @param group
   * @param required {boolean} is the field required to run the code? If yes and not given, mark the field
   * @param explicitInclusion {boolean} is the field needed to be explicitly included in the input?
   * Shows checkbox if yes
   * @param units {string} a units name to go after the input box
   * @param explanation {string} a tooltip for the field label
   * @param extras {Object} extra parameters to the field
   */
  constructor(wrapper, name, text, type,
              value, datatype, group = 0, required = false,
              explicitInclusion = false, units = '', explanation='', extras = {}) {
    super(wrapper, name, text, required, explicitInclusion, explanation)
    let field = document.createElement('select')
    value.forEach( v => {
      let option = document.createElement('option')
      if (v.includes(':')) {
        option.text = v.split(':')[0]
        option.value = v.split(':').slice(1).join(':')
      } else
        option.text = v
      field.add(option)
    })
    const valueInputBox = this.wrapper.querySelector('.value-box')
    valueInputBox.appendChild(field)
    this.HTMLFields.push(field)
  }

  getValue() {
    const value = this.HTMLFields[0].value
    if (value[0] !== "{") return value
    else return JSON.parse(value)
  }
}

export class RangeField extends FormElement {
  constructor(wrapper, name, text, values, group = 0, required = false,
              explicitInclusion = false, explanation='') {
    super(wrapper, name, text, required, explicitInclusion, explanation);
    let field = document.createElement('input')
    field.type = 'range'
    field.style.width = '100%'
    const [minValue, maxValue, value] = values
    field.min = minValue
    field.max = maxValue
    field.step = 'any'
    field.value = value
    const valueInputBox = this.wrapper.querySelector('.value-box')
    valueInputBox.appendChild(field)
    this.HTMLFields.push(field)
  }
  setValue(value) {
    this.HTMLFields[0].value = value[2]
  }
}

export class ColorField extends FormElement {
  constructor(wrapper, name, text, value, group = 0, required = false,
              explicitInclusion = false, explanation='') {
    super(wrapper, name, text, required, explicitInclusion, explanation);
    let field = document.createElement('input')
    field.type = 'color'
    field.style.width = '100%'
    field.value = value
    const valueInputBox = this.wrapper.querySelector('.value-box')
    valueInputBox.appendChild(field)
    this.HTMLFields.push(field)
  }
  getValue() {
    return this.HTMLFields[0].value
  }
  setValue(value) {
    this.HTMLFields[0].value = value
  }
}

// utility functions
/**
 * Marks a required field
 * @param field {HTMLElement}
 */
function markField(field) {
  field.classList.add('markedField')
}

/**
 * Unmarks a required field
 * @param field {HTMLElement}
 */
function unmarkField(field) {
  field.classList.remove('markedField')
}