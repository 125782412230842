/**
 * @author Iker Hurtado
 *
 * @fileOverview File holding the Workflow  module main class
 */

import UIComponent from '../common/UIComponent.js'
import {workflows} from './workflows.js'
import StructureBuilderMod from '../structure-builder-mod/StructureBuilderMod.js'
import OutputAnalyzerMod from '../output-analyzer-mod/OutputAnalyzerMod.js'
import ControlGeneratorMod from '../control-generator-mod/ControlGeneratorMod.js'
import DownloadInputFilesPage from './DownloadInputFilesPage.js'
import WorkflowDesignerPage from "../workflow-designer/workflow-designer-page";
import * as AppState from '../State.js'
import prevArrowIcon from '../../img/prev-arrow-white-icon.png'
import nextArrowIcon from '../../img/next-arrow-white-icon.png'
import {setError} from "../common/UserMsgBox";


/**
 * Returns a component (step content components) instance.
 * Maybe this implementation can be improved
 * @param  {string} componentId
 * @return {UIComponent}
 */
function getComponentInstance(componentId){
	if (componentId === 'StructureBuilder') return new StructureBuilderMod()
	else if (componentId === 'OutputAnalyzer') return new OutputAnalyzerMod()
	else if (componentId === 'ControlGenerator') return new ControlGeneratorMod()
	else if (componentId === 'DownloadInputFilesPage') return new DownloadInputFilesPage()
	else if (componentId === 'WorkflowDesigner') return new WorkflowDesignerPage()

}


/**
 * Workflow module UI component
 */
export default class Workflow extends UIComponent{

  /**
   * @param  {string} workflowId
   */
  constructor(workflowId){

  	super('div', '.Workflow')

  	this.setHTML(`
    	<div class="header-box"></div>
    	<div class="component-box"></div>
    `)

    this.workflowId = workflowId

    this.step = 0 // current  step array index
    // This points to the farthest step reached by the user
    // in order to prevent the user from access farther steps by the URL fragment
    this.maxStep = 0
    this.componentIds = []
    this.stepDataMap = new Map()

    this.config = workflows[workflowId]
  	this.header = new AssistantHeader(this)

  	this.config.steps.forEach( (stepConf, i) => {

      const componentId = stepConf.component.split(':')[0]
      const componentInstance = getComponentInstance(componentId)

      this.stepDataMap.set(componentId, {
        label: 'Step '+(i+1)+'. '+stepConf.title,
        nextButtonText: stepConf.nextButtonText,
        component: componentInstance,
        componentId: componentId,
        componentInitParams: stepConf.component.split(':')[1],
        initialized: false
      })

      this.componentIds.push(componentId)
	  })

  	this.e.querySelector('.header-box').appendChild(this.header.e)
  	this.componentBox = this.e.querySelector('.component-box')
  }

  /**
   * Does nothing but it's mandatory for every application module
   */
  init(){
  }


  /**
   * Returns the current (step showing) component id
   * @return {string}
   */
  getComponentId(){
  	return this.componentIds[this.step]
  }


  /**
   * Processes the module URL fragment
   * @param  {string} fragment
   */
  processUrlFragment(fragment){
  	this.setStep(fragment)
  }


  /**
   * Increases the step counter
   */
  increaseStep(){
  	this.step += 1
    // update the farthest step reached in the session
  	if (this.step > this.maxStep) this.maxStep = this.step
  }


  /**
   * Moves the workflow to the next step
   */
  setNextStep() {
    //log('setNextStep', this.step, this.stepData[this.step-1])
    const componentId = this.componentIds[this.step]
    const nextComponentId = this.componentIds[this.step + 1]
    if (componentId === 'ControlGenerator') { // Async step advance
      // This server request, in addition to get the tar with files, is performing checking
      // of the control generator params. If it fails that indicates the params sent are not right
      let cgComponent = this.stepDataMap.get('ControlGenerator').component
      cgComponent.requestDownloadInfo(downloadInfo => {
        console.log(downloadInfo);
        cgComponent.requestInputFiles(filesTar => {
          this.increaseStep()
          this.setStep('DownloadInputFilesPage')
          // Access the DownloadInputFilesPage instance
          let dComponent = this.stepDataMap.get('DownloadInputFilesPage').component
          dComponent.setDownloadInfo(downloadInfo)
          dComponent.setInputFiles(filesTar)
          document.location.hash = this.workflowId + '-workflow#DownloadInputFilesPage'
        })
      })

    } else if (componentId === 'WorkflowDesigner') {
      let wdComponent = this.stepDataMap.get(componentId).component
      const step = wdComponent.getCurrentStep()
      if (step === undefined) {
        // validate the workflow
        if (!wdComponent.workflow.isValid()) {
          setError('The workflow is not valid')
          return;
        }
        // Go to Download files page...
        this.increaseStep()
        wdComponent.workflow.requestInputFiles(filesTar => {
          this.setStep('DownloadInputFilesPage')
          // Access the DownloadInputFilesPage instance
          let dComponent = this.stepDataMap.get('DownloadInputFilesPage').component
          dComponent.setInputFiles(filesTar)
        })
        // setDownloadInfo - set information about the download (e.g. band path)
        // dComponent.setDownloadInfo(wdComponent.workflow.designer.getDefinition())
        document.location.hash = this.workflowId+'-workflow#'+nextComponentId
        return
      }
      if (wdComponent.getCurrentStep().save())
        document.location.hash = this.workflowId+'-workflow#'+componentId

    } else { // Sync
      this.increaseStep()
  		if (componentId === 'StructureBuilder'){
  	    this.setStep('ControlGenerator') // The control generator needs to be set before : research  // this.stepDataMap.get('ControlGenerator').component.init()
  	    const structure = this.stepDataMap.get('StructureBuilder').component.getCurrentStructure()
  			this.stepDataMap.get('ControlGenerator').component.setStructure(structure)
  		}
  		// Default case
  		document.location.hash = this.workflowId+'-workflow#'+nextComponentId
  	}
  }


  /**
   * Goes back to the previous step
   */
  setPrevStep(){
  	const componentId = this.componentIds[this.step]
    if (componentId !== "WorkflowDesigner") this.step -= 1
  	document.location.hash = this.workflowId+'-workflow#'+this.componentIds[this.step]
  }


  /**
   * Sets up and initializes a new workflow step
   * @param {string} componentId
   */
  setStep(componentId){
    let componentParam = undefined
    if (componentId.indexOf('#') > 0) {
      [componentId, componentParam] = componentId.split('#')
    }
  	const index = this.componentIds.indexOf(componentId)
  	if (index > this.maxStep){
      document.location.hash = this.workflowId+'-workflow#'+this.componentIds[0]
      return
    }

  	this.step = index
  	let stepData = this.stepDataMap.get(componentId)
    if (componentParam)
      stepData = stepData.component.workflow.steps.get(componentParam)

  	this.header.setStep(stepData)

  	this.componentBox.innerHTML = ''
    // console.log('COMPONENT: ', stepData)
    if (stepData.component) {
    	this.componentBox.appendChild(stepData.component.e)
    	if (!stepData.initialized){
    		stepData.component.init(stepData.componentInitParams)
    		stepData.initialized = true
        AppState.subscribeToCodeChange(stepData.component)
        AppState.subscribeToSettingsChange(stepData.component)
    	}
    }
  }


  /**
   * Updates the component for a code change (application level event)
   * @param  {string} code
   */
  updateForCode(code){
  }


  /**
   * Updates the component for a setting change (application level event)
   * @param  {object} newSettings
   */
  updateSettings(newSettings){
  }

}



/**
 * Workflow component header. It shows step info and controls the advance
 * through steps
 */
class AssistantHeader extends UIComponent{

  /**
   * @param  {Workflow} workflow Workflow instance
   */
  constructor(workflow){
    super('div', '.AssistantHeader')

    this.setHTML(`

      <div class="prev-button-box">
        <button class="prev" >
          &nbsp; <img src="${prevArrowIcon}" height="22px" style="" alt=""> &nbsp; Go back
        </button>
      </div>

      <div class="workflow-centered-box">
    	  <div class="workflow-title-box">${workflow.config.text} workflow</div>
        <div class="step-label-box"></div>
      </div>

      <div class="next-button-box">
        <button class="next" >
          <span class="next-button-extra-text"></span>
          Go ahead &nbsp; <img src="${nextArrowIcon}" height="22px" alt=""> &nbsp;
        </button>
      </div>
    `)

    this.stepLabel = this.getElement('.step-label-box')
    this.buttons = this.getElements('button')
    this.buttons[0].addEventListener('click', _ => {
    	workflow.setPrevStep()
    })
    this.buttons[1].addEventListener('click', _ => {
    	workflow.setNextStep()
    })

    this.nextButtonExtraText = this.e.querySelector('.next-button-extra-text')
  }


  /**
   * Sets up a new workflow step
   * @param {object} stepData
   */
  setStep(stepData){
    this.stepLabel.innerHTML = stepData.label
    this.buttons[0].style.visibility =
      ( ['StructureBuilder', 'WorkflowDesigner'].includes(stepData.componentId) ? 'hidden' : 'visible')

    this.buttons[1].style.visibility =
      ( stepData.componentId === 'OutputAnalyzer'  ? 'hidden' : 'visible')

    this.nextButtonExtraText.innerHTML =
      (stepData.nextButtonText ? stepData.nextButtonText : '')
  }

}
