/**
 * @author Andrey Sobolev
 *
 * @overview A workflow designer UI component based on the Sequential Workflow Designer
 * (https://github.com/nocode-js/sequential-workflow-designer/)
 */

import UIComponent from "../common/UIComponent";
import DesignedWorkflow from "./designed-workflow";


export default class WorkflowDesignerPage extends UIComponent {

  constructor() {
    super('div', '.WorkflowDesignerPage')
    this.setHTML(`
      <div class="workflow-designer" style="flex: 1;height: 600px"></div>
    `)
  }

  /**
   * Mandatory for all Workflow steps
   */
  init(){
    const placeholder = this.getElement('.workflow-designer');
    this.workflow = new DesignedWorkflow(placeholder)
  }

  /** Finds current workflow step
   * @returns {WorkflowStep}
   */
  getCurrentStep() {
    return this.getStep(document.location.hash.split('#')[3])
  }

  /** Gets a step from the workflow by its id value
   *
   * @param stepId {string} a step hash id value
   * @returns {WorkflowStep}
   */
  getStep(stepId) {
    if (stepId === undefined) return undefined
    return this.workflow.steps.get(stepId)
  }

  /** Returns the workflow definition
   *
   * @returns {{sequence: [], properties: {}}} the current workflow definition
   */
  getDefinition() {
    return this.workflow.designer.getDefinition()
  }

  updateForCode(code) {
    console.log(code)
  }
}