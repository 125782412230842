/**
* @author Sebastian Kokott
*
* @fileOverview File holding the Structure Info UI component
*/

import UIComponent from '../common/UIComponent.js'


export default class StructureInfo extends UIComponent{
  constructor(moduleState) {
    super('span', '.StructureInfoField')
    this.moduleState = moduleState // We might need this to update the info panel later on...
    this.setHTML(`
        <div class="StructureInfo">
          <table></table>
        </div>
    `)
  }

  setNewStructure(structure){
    if (structure.structureInfo === undefined){
      structure.structureInfo = {}
    }
    this.updateStructureInfo()
  }

  selectStructure(_structure){
    this.updateStructureInfo()
  }

  updateTable(structureInfo){
    let table = this.getElement('table')
    table.innerHTML = ''
    if (structureInfo !== undefined) {
      for (const [_, value] of Object.entries(structureInfo)) {
        // console.log(key, value);
        let tr = document.createElement("tr")
        let td1 = document.createElement("td")
        td1.innerHTML = value.info_str
        let td2 = document.createElement("td")
        if (Array.isArray(value.value)) {
          td2.innerHTML = value.value.join(', ')
        } else
          td2.innerHTML = value.value
        tr.appendChild(td1)
        tr.appendChild(td2)
        table.appendChild(tr)
      }
    }

  }

  updateStructure(){
    this.updateStructureInfo()
  }

  updateStructureInfo() {
    let structure = this.moduleState.getStructure()
    structure.updateInfo().then(() => this.updateTable(structure.structureInfo))
  }

  updateSettings() {
    this.updateStructureInfo()
  }

}
