/**
 * @author Sebastian Kokott
 *
 * @fileOverview Brillouin zone viewer
 */

import * as THREE from 'three';
import {ConvexGeometry} from "three/examples/jsm/geometries/ConvexGeometry"
import ThreeViewer from '../structure-viewer/ThreeViewer.js'
import {invert33,multiplyScalar,transpose33} from '../common/math.js'
import * as util from '../structure-viewer/util.js'
import ScreenshotTaker from '../common/ScreenshotTaker.js'
import BZViewOptionsDropDown from './BZViewOptionsDropDown.js'
import * as UserMsgBox from "../common/UserMsgBox";


const CELL_BASIS_COLORS = ["#C52929", "#47A823", "#3B5796"];


export default class BZViewer {

  constructor(hostElement){
    this.hostElement = hostElement
    this.heading = document.createElement('div')
    this.heading.innerHTML = '<h2>Brillouin Zone Viewer</h2>'
    // this.heading.style.position = 'absolute'
    this.heading.style.width = '100%'
    this.heading.style.boxSizing = 'content-box'
    this.heading.style.textAlign = 'center'
    this.hostElement.appendChild(this.heading)
		this.threeViewer = new ThreeViewer(hostElement)

    this.threeViewer.init()

    this.createViewingControls()
    this.screenTaker = new ScreenshotTaker('bz')
    this.screenTaker.e.style.marginTop = '3px';
    this.screenTaker.e.style.paddingLeft = '30px'
    this.screenTaker.setScreenshotListener( linkElement => {
       this.threeViewer.render()
       linkElement.href = this.threeViewer.renderer.domElement.toDataURL()
       this.threeViewer.render()
    })


    this.viewerControls.appendChild(this.screenTaker.e)
  }

  setNewStructure(structure,reset=true,paths=undefined){
    if(!structure.isAPeriodicSystem()) {
      this.hostElement.style.display = 'none'
    } else {
      this.hostElement.style.display = 'block'
      this.threeViewer.clearScenes()
      // Clear canvas context
      this.setupVisualization(structure.latVectors, reset, paths)
    }
    // console.log('this.threeViewer.camera.position',this.threeViewer.camera.position)

  }
  selectStructure(structure){
    this.setNewStructure(structure)
  }

  updateStructure(structure){
    this.setNewStructure(structure)
  }

  async setupVisualization(data, resetZoom=true, paths=undefined) {

    let recCell = []
    transpose33(invert33(data)).forEach(v => {
      recCell.push(multiplyScalar(v,2.0 * Math.PI))
    })
    this.recCell = util.createBasisVectors(recCell)
    // console.log('this.recCell',this.recCell);
    this.root = new THREE.Group()
    this.root.name = 'bz' //'structure'
    this.threeViewer.mainScene.add(this.root)
    // console.log(this.recCell);
    // Set position and adjust the zoom to the structure size: if the structure is smaller the zoom is larger
    this.root.position.copy(new THREE.Vector3(0,0,0))
    if (resetZoom){
      let diagonal = this.recCell[0].clone().add(this.recCell[1])
            .add(this.recCell[2])
        const factor = this.threeViewer.hostElement.clientWidth/820+0.2
        console.log('factor',factor);
        this.threeViewer.adjustZoomToStructSize(diagonal.length()*factor)//this.editable ?  diagonal.length() : diagonal.length()*0.4 )
    }

    // this.bz = new THREE.Geometry()
    this.latticeVectors = this.createLatticeVectors(this.recCell)
    this.recCartAxes = this.createRecCartAxes()
    // this.recCartAxes.visible = false
    this.root.add(this.latticeVectors)
    this.root.add(this.recCartAxes)
    // this.bz_vertices = this.request_bz_vertices(recCell)
    let bz_vertices = await this.request_bz_vertices(recCell)

    this.createBZ(bz_vertices.vertices)
    // console.log(paths);
    if (paths)
      this.createPath(paths)
    else
      this.createPath(bz_vertices.path)
    this.recUnitCell = this.createCell( 0x000000, 1.5)
    // this.recUnitCell.visible = false
    this.root.add(this.recUnitCell)

    this.latticeParamsLabels = this.createLatticeParamsLabels()
    this.latticeParamsLabels.name = 'recLattice-parameters'
    this.latticeParamsLabels.position.copy(new THREE.Vector3(0,0,0))
    this.threeViewer.infoScene.add(this.latticeParamsLabels)

    this.threeViewer.render()
    this.viewOptionsDropdown.reset()

    return true
  }

  async request_bz_vertices(recCell){
    let response = await fetch('/get-bz-vertices', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      body: JSON.stringify(recCell)
    })

    if (response.ok){ // File or server controlled errors
      // It's needed to get the text because of the FileNotFoundError checking
      let vertices = await response.text() //await response.arrayBuffer()
      return JSON.parse(vertices)
    }else{ // Unknown server ERROR
      UserMsgBox.setError('Unknown server ERROR ')
    }
  }

  createBZ(bz_vertices){
    let lineMaterial = new THREE.LineBasicMaterial({ color: 0x000000,
			linewidth: 1.5
    })
    let bzPoints = []
    this.bzLines = new THREE.Group()
    bz_vertices.forEach(face => {
      
      let linePoints = []
      let first
      face.forEach((v,iv) => {
        bzPoints.push(new THREE.Vector3(...v))
        if (iv === 0) first = new THREE.Vector3(...v)
        linePoints.push(new THREE.Vector3(...v))
      })
      linePoints.push(first)
      let lineGeometry = new THREE.BufferGeometry().setFromPoints(linePoints)
      this.bzLines.add(new THREE.Line(lineGeometry,lineMaterial))

    })
    this.geometry = new THREE.Mesh(
      new ConvexGeometry( bzPoints ),
      new THREE.MeshPhongMaterial({
        color: '#80d1e3',
        transparent: true,
        opacity: 0.2,
        side: THREE.DoubleSide,
        depthWrite:false,
        depthTest: true
      })
    )
    this.root.add(this.bzLines)
    this.root.add(this.geometry)

  }

  createPath(bzPath){
    const minVectorLength = Math.min(this.recCell[0].length(),
      this.recCell[1].length(), this.recCell[2].length())
    const labelSize = 2.5 * minVectorLength
    this.bzPath = new THREE.Group()
    let lineMaterial = new THREE.LineBasicMaterial({ color: 0xD64D28,
      linewidth: 10.0
    })
    let myLabels = []
    bzPath.forEach(path => {
      let linePoints = []
      // console.log(path)
      // Create Path
      path[1].forEach((fracPoint,i) => {
        let point = new THREE.Vector3(0.0,0.0,0.0)
        fracPoint.forEach((comp,i) => {
          // console.log(this.recCell[i],comp);
          point.add(this.recCell[i].clone().multiplyScalar(comp))
        })
        let label = path[0][i]
        if(!myLabels.some(l => l === label)) {
          if (label === 'G' || label.toLowerCase() === 'gamma') label = '\u0393'
          let labelPos = point.clone().add(new THREE.Vector3(0.05,0.05,0.05))
          let threeLabel = this.createLabel(label, 0x000000, labelPos, labelSize)
          this.bzPath.add(threeLabel)
        }
        // console.log('point',point);
        linePoints.push(point)
      })

      // console.log(lineGeometry.vertices);
      this.bzPath.add(new THREE.Line(new THREE.BufferGeometry().setFromPoints(linePoints),lineMaterial))
    })

    this.root.add(this.bzPath)
  }

  getHTMLBandPath(paths){
    let table = document.createElement('table')
    table.classList.add('band-path-table')
    paths.forEach((path,j) => {
      let headerTr = document.createElement('tr')
      let pathTh = document.createElement('th')
      pathTh.colSpan = 2
      pathTh.style.textAlign = 'left'
      pathTh.style.padding = '8px 0 4px 0'
      pathTh.innerHTML = `Path ${j+1}: ` + path[0].join('-')
      headerTr.appendChild(pathTh)
      table.appendChild(headerTr)
      path[1].forEach((segment,i) => {
        let segmentTr = document.createElement('tr')
        let labelTd = document.createElement('td')
        labelTd.innerHTML = path[0][i]
        let pointTd = document.createElement('td')
        pointTd.innerHTML = '['+segment.join()+']'
        segmentTr.appendChild(labelTd)
        segmentTr.appendChild(pointTd)
        table.appendChild(segmentTr)
      })
    })
    return table
  }

  createLatticeVectors(basisVectors){

    let latticeVectors = new THREE.Group();
    const origin = new THREE.Vector3();

    let minVectorLength = Math.min(basisVectors[0].length(),
      basisVectors[1].length(), basisVectors[2].length())
      // console.log('minVectorLength', minVectorLength)

    for (let basisIndex = 0; basisIndex < 3; ++basisIndex) {
      const color = CELL_BASIS_COLORS[basisIndex];
      const basisVector = basisVectors[basisIndex].clone();
      // Add basis vector colored line
      const vectorMaterial = new THREE.MeshBasicMaterial({
        color: color});
      const radius = (minVectorLength > 20 ? 0.2 : minVectorLength*0.01)
      let basisVectorMesh =
        util.createCylinder(basisVector.clone().multiplyScalar(0.5), basisVector, radius, 10, vectorMaterial);
      latticeVectors.add(basisVectorMesh);
      // Add an arrow following the basis vector direction
      let arrowMesh = util.createArrow(origin, basisVector, minVectorLength);
      latticeVectors.add(arrowMesh);
    }
    return latticeVectors;
  }

  createLabel(label, color, position, minVectorLength){
    // Configure canvas
    let canvas = document.createElement('canvas');
    const size = 512;
    canvas.width = size;
    canvas.height = size;
    let ctx = canvas.getContext('2d');
    // Draw label
    ctx.fillStyle = color;
    ctx.textAlign = "center";
    ctx.font = 15*minVectorLength+'px Arimo'
    ctx.lineWidth = 0.5 //(minVectorLength < 5 ? 3 : 8)
    ctx.strokeStyle = "#000000";
    ctx.strokeText(label, size / 2, size / 2);
    ctx.fillText(label, size / 2, size / 2);

    let texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    let sprite = new THREE.Sprite(new THREE.SpriteMaterial({ map: texture ,transparent: true,opacity: 0.9, depthWrite:false,depthTest: true}));

    sprite.position.copy(position)

    sprite.scale.set(2, 2, 1);
    return sprite;
  }

  createViewingControls(){

    this.viewerControls = document.createElement('div');
    // this.viewerControls.appendChild(this.heading)
    this.hostElement.appendChild(this.viewerControls);
    this.viewerControls.className = 'viewing-controls'

    this.viewOptionsDropdown = new BZViewOptionsDropDown()//this)
    this.viewerControls.appendChild(this.viewOptionsDropdown.e)

    this.viewOptionsDropdown.setCheckListener( e => {
      //console.log('setCheckListener e', e.target.name)
      if (e.target.name === this.viewOptionsDropdown.REC_UNIT_CELL_OPTION) {
        this.recUnitCell.visible = e.target.checked
        console.log('REC_UNIT_CELL_OPTION');

      }else if (e.target.name === this.viewOptionsDropdown.REC_CART_AXES_OPTION){
        this.recCartAxes.visible = e.target.checked
        console.log('REC_CART_AXES_OPTION');
        // console.log('this.root', this.bonds, this.bonds.visible)
      }else if (e.target.name === this.viewOptionsDropdown.REC_LATTICE_AXES_OPTION){
        this.latticeVectors.visible = e.target.checked
        this.latticeParamsLabels.visible = e.target.checked
        console.log('REC_LATTICE_AXES_OPTION');
      }
      this.threeViewer.render()
    })

  }

  createRecCartAxes(){
    let latticeVectors = new THREE.Group();
    const origin = new THREE.Vector3();
    const labels = ["x*", "y*", "z*"];

    const minVectorLength = Math.min(this.recCell[0].length(),
      this.recCell[1].length(), this.recCell[2].length())

    const labelSize = 2.5 * minVectorLength
      // console.log('minVectorLength', minVectorLength)
    const cartL = minVectorLength /4
    const cartAxes = [[cartL,0,0],[0,cartL,0],[0,0,cartL]]
    for (let basisIndex = 0; basisIndex < 3; ++basisIndex) {
      const color = 0x585858;
      // console.log(...cartAxes[basisIndex]);
      const basisVector = new THREE.Vector3(...cartAxes[basisIndex]);
      let labelPos = basisVector.clone().normalize()
        .multiplyScalar(basisVector.length()*1.1 + (cartL+3)*0.1)
      let vectorLabelSprite = this.createLabel(labels[basisIndex], color, labelPos, labelSize)
      let arrowMesh = util.createArrow(origin, basisVector, cartL,color);
      latticeVectors.add(vectorLabelSprite)
      latticeVectors.add(arrowMesh);
    }
    return latticeVectors;
  }

  createLatticeParamsLabels(){

    let latticeParams = new THREE.Group();
    const labels = ["a*", "b*", "c*"];

    const minVectorLength = Math.min(this.recCell[0].length(),
      this.recCell[1].length(), this.recCell[2].length())
    const labelSize = 2.5 * minVectorLength

    for (let basisIndex = 0; basisIndex < 3; ++basisIndex) {
      const color = CELL_BASIS_COLORS[basisIndex];
      const basisVector = this.recCell[basisIndex].clone();
      // const nextBasisVector = this.recCell[(basisIndex+1)%3].clone();
      let labelPos = basisVector.clone().normalize()
        .multiplyScalar(basisVector.length() + (minVectorLength+3)*0.1 +0.1)
      let vectorLabelSprite = this.createLabel(labels[basisIndex], color, labelPos, labelSize)
      latticeParams.add(vectorLabelSprite);

    }
    return latticeParams;
  }

  /**
   * Creates the cell box
   * @param  {number} color
   * @param  {int} linewidth
   */
  createCell(color, linewidth) {

    const basisVectors = this.recCell;
    let origin = new THREE.Vector3(); // 0,0,0 point
    let cell = new THREE.Group(); // holding the cell lines
    let lineMaterial = new THREE.LineBasicMaterial({ color: color,
      linewidth: linewidth });

    // Draws two lines per basis vector to complete the cube (cell)
    // The first line is compound of 3 segments and the second of 1
    // 3+1 = 4 segments (cube edges) x 3 basis vectors = 12 total cube edges
    for (let len = basisVectors.length, i = 0; i < len; ++i) {

      const basisVector = basisVectors[i].clone();

      // Forms the first line (3 segments) geometry
      // let lineGeometry = new THREE.Geometry();
      let thirdPoint = basisVectors[(i + 1) % len].clone().add(basisVector);
      let fourthPoint = basisVectors[(i + 2) % len].clone().add(thirdPoint);
      // lineGeometry.vertices.push(origin, basisVector, thirdPoint, fourthPoint);
      let lineGeometry = new THREE.BufferGeometry().setFromPoints([
        origin, basisVector, thirdPoint, fourthPoint
      ])
      cell.add(new THREE.Line(lineGeometry, lineMaterial));
      // lineGeometry.computeLineDistances();

      // Forms the second line (1 segment) geometry
      // let lineGeometry2 = new THREE.Geometry();
      // lineGeometry2.vertices.push(basisVector,
        // basisVector.clone().add(basisVectors[(i === 0 ? 2 : i-1)]));
      cell.add(new THREE.Line(
        new THREE.BufferGeometry().setFromPoints([
          basisVector,
          basisVector.clone().add(basisVectors[(i === 0 ? 2 : i-1)])
        ]), 
        lineMaterial
      ));
       //console.log('cell created:',cell);
    }
    return cell
  }

  getBandPath(bsData) {
    let paths = []
    let path = [[],[]]
    let endPair = undefined
    bsData.forEach(segment => {
      let start = segment['band_k_points'][0]
      let end = segment['band_k_points'][segment['band_k_points'].length-1]
      let isNextPath = endPair && !start.every((value, index) => value === endPair[1][index])
      if (isNextPath){
        path[0].push(endPair[0])
        path[1].push(endPair[1])
        paths.push(path.slice())
        path = [[],[]]
      }
      let labels = segment['band_segm_labels']
      path[0].push(labels? isGamma(labels[0]) : '?')
      path[1].push(start)
      endPair = [labels? isGamma(labels[1]) : '?',end]
    })
    path[0].push(endPair[0])
    path[1].push(endPair[1])
    paths.push(path)
    // console.log(paths)
    return paths

    function isGamma(string) {
      if (string === 'G' || string.toLowerCase() === 'gamma')
        return '\u0393'
      else
        return string
    }
  }

}
