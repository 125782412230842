/**
 * @author Andrey Sobolev
 *
 * @overview A designed workflow class based on the Sequential Workflow Designer
 * (https://github.com/nocode-js/sequential-workflow-designer/)
 */
import { Designer } from 'sequential-workflow-designer';
import {StructureBuilderStep} from "./structure-builder-step";
import {DFTCalculation, GWCalculation} from "./calculation-step";
import {ConvergenceStep} from "./convergence-step";
import {definition} from "./definition";
import {configuration} from "./configuration";
import * as UserMsgBox from "../common/UserMsgBox";


const WORKFLOW_STEPS = [
  StructureBuilderStep,
  DFTCalculation,
  GWCalculation,
  ConvergenceStep
]

const defaultConfigValues = {
  theme: 'light', // optional, default: 'light'
  isReadonly: false, // optional, default: false
  undoStackSize: 10, // optional, default: 0 - disabled, 1+ - enabled
}


export default class DesignedWorkflow {
  /**
   * A central class for the self-designed Workflow
   * @param placeholder {HTMLElement} a DOM element for the workflow designer
   */
  constructor(placeholder) {
    // creating configuration
    this.configuration = {...defaultConfigValues, ...configuration}
    this.stepClasses = WORKFLOW_STEPS.reduce((o, stepClass) =>
      ({ ...o, [stepClass.config.type]: stepClass}), {})
    this.steps = new Map()
    this.properties = {}

    // build `groups` section of configuration
    let groups = Object.fromEntries([...new Set(WORKFLOW_STEPS.map(
      step => [step.config.group, []]))])
    for (const v of WORKFLOW_STEPS) {
      let config = v.config
      const group = config.group; delete config.group
      groups[group].push(config)
    }
    this.configuration.toolbox.groups = Object.keys(groups).map((k) => {
      return {
        name: k,
        steps: groups[k]
      }
    })

    this.configuration.editors.stepEditorProvider = (step, ctx) => this.steps.get(step.id).editor.provide(step, ctx)
    this.configuration.validator.step = (step, _) => this.steps.get(step.id).validate()
    this.designer = Designer.create(placeholder, definition, this.configuration);
    this.designer.state.onDefinitionChanged.subscribe((e) => changeDefinition(e))

    const instance = this
    function changeDefinition(event) {
      const currentDef = instance.designer.getDefinition()
      if (event.changeType === 6) {
        // adding steps
        let step = currentDef.sequence.filter(s => s.id === event.stepId)[0]
        instance.steps.set(step.id, new instance.stepClasses[step.type](instance, step.id))
      } else if (event.changeType === 4) {
        // deleting steps
        instance.steps.get(event.stepId).destroy()
        instance.steps.delete(event.stepId)
      } else {
        // undo/redo operations (changeType === 8) should be handled here
      }
    }
  }

  isValid() {
    return this.designer.isValid()
  }

  /** Check if the workflow has structure set
   * @param stepId {string} an id for which we are searching structure in the workflow upstream
   * @return {boolean}
   */
  hasStructure(stepId) {
    for (const step of this.designer.getDefinition().sequence) {
      if (step.properties.structure !== undefined) return true
      if (stepId === step.id) return false;
    }
  }

  /** Check if the workflow has structure set
   * @param stepId {string} an id for which we are searching structure in the workflow upstream
   * @return {boolean}
   */
  hasControl(stepId) {
    for (const step of this.designer.getDefinition().sequence) {
      if (step.properties.control !== undefined) return true
      if (stepId === step.id) return false;
    }
  }

  /** Returns an upstream structure for stepId
   * @param stepId {string} an id for which we are searching structure in the workflow upstream
   * @return {Structure}
   */
  getStructure(stepId) {
    let structure = undefined
    for (const step of this.designer.getDefinition().sequence) {
      if (step.properties.structure !== undefined) structure = step.properties.structure
      if (stepId === step.id) return structure;
    }
  }

  /** Build the tar archive with the files that are asked for
   * @param listener {function} a callback to run after archive creation
   * @return {Promise<void>}
   */
  async requestInputFiles(listener) {
    let response = await fetch('/generate-workflow', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      body: JSON.stringify(this.designer.getDefinition())
    })
    if (response.ok) {  // File or server controlled errors
      let tarFiles = await response.text() //await response.arrayBuffer()
      listener(tarFiles)
    } else
      UserMsgBox.setError('Unknown server error')
    }
}
